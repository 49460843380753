import { RouteConfig } from 'vue-router'

// ROLES
import RDashboard from '@/components/roles/RDashboard.vue'
import RLeads from '@/components/roles/RLeads.vue'
import RProfil from '@/components/roles/RProfil.vue'
import RStatistics from '@/components/roles/RStatistics.vue'
import RCustomTracking from '@/components/roles/RCustomTracking.vue'

// PAGES
import PHomePageDashboard from '@/components/pages/common/homepage/PHomePageDashboard.vue'
import PHomePageLeads from '@/components/pages/common/homepage/PHomePageLeads.vue'

import PLogin from '@/components/pages/common/PLogin.vue'
import PAdminProviders from '@/components/pages/admin/PAdminProviders.vue'
import PAdminClients from '@/components/pages/admin/PAdminClients.vue'
import POrder from '@/components/pages/common/POrder.vue'
import PLead from '@/components/pages/common/PLead.vue'
import PWebhook from '@/components/pages/common/PWebhook.vue'
import PAdminProvider from '@/components/pages/admin/PAdminProvider.vue'
import PAdminClient from '@/components/pages/admin/PAdminClient.vue'
import PAdminAreas from '@/components/pages/admin/PAdminAreas.vue'
import PAdminUsers from '@/components/pages/admin/PAdminUsers.vue'
import PClientOrders from '@/components/pages/client/PClientOrders.vue'
import PProviderSitekeys from '@/components/pages/provider/PProviderSitekeys.vue'
import PSupport from '@/components/pages/common/PSupport.vue'
import PForgotPassword from '@/components/pages/common/PForgotPassword.vue'
import PRenewPassword from '@/components/pages/common/PRenewPassword.vue'
import PAdminLeadsRgpd from '@/components/pages/admin/PAdminLeadsRgpd.vue'
import PClientInvoices from '@/components/pages/client/PClientInvoices.vue'
import PAdminLeadsRejected from '@/components/pages/admin/PAdminLeadsRejected.vue'
import PCreateAccount from '@/components/pages/common/PCreateAccount.vue'
import PActionsRgpd from '@/components/pages/admin/PActionsRgpd.vue'
import PAdminReminders from '@/components/pages/admin/PAdminReminders.vue'
import PAdminOrders from '@/components/pages/admin/PAdminOrders.vue'
import PAdminOrdersCompletion from '@/components/pages/admin/PAdminOrdersCompletion.vue'
import PAdminAreaCompletion from '@/components/pages/admin/PAdminAreaCompletion.vue'
import PAdminClientCompletion from '@/components/pages/admin/PAdminClientCompletion.vue'

// ELSE
import OTableAdminStatisticsProviders from '@/components/organisms/admin/OTableAdminStatisticsProviders.vue'
import OTableAdminStatisticsAreas from '@/components/organisms/admin/OTableAdminStatisticsAreas.vue'
import OTableProviderStatisticsAreas from '@/components/organisms/provider/OTableProviderStatisticsAreas.vue'
import OTableAdminStatisticsClients from '@/components/organisms/admin/OTableAdminStatisticsClients.vue'
import OTableAdminStatisticsSitekeys from '@/components/organisms/admin/OTableAdminStatisticsSitekeys.vue'
import OTableProviderStatisticsSitekeys from '@/components/organisms/provider/OTableProviderStatisticsSitekeys.vue'
import ODrawerAdminLeadDetail from '@/components/organisms/admin/ODrawerAdminLeadDetail.vue'
import OAdminProviderDashboard from '@/components/organisms/admin/OAdminProviderDashboard.vue'
import OAdminProviderTableUsers from '@/components/organisms/admin/OAdminProviderTableUsers.vue'
import OAdminProviderTableSitekeys from '@/components/organisms/admin/OAdminProviderTableSitekeys.vue'
import OAdminProviderTableEarnings from '@/components/organisms/admin/OAdminProviderTableEarnings.vue'
import OAdminProviderNew from '@/components/organisms/admin/OAdminProviderNew.vue'
import OAdminProviderUserNew from '@/components/organisms/admin/OAdminProviderUserNew.vue'
import OAdminClientNew from '@/components/organisms/admin/OAdminClientNew.vue'
import OAdminUserNew from '@/components/organisms/admin/OAdminUserNew.vue'
import OAdminUserDelete from '@/components/organisms/admin/OAdminUserDelete.vue'
import OAdminClientDashboard from '@/components/organisms/admin/OAdminClientDashboard.vue'
import OAdminClientTableOrders from '@/components/organisms/admin/OAdminClientTableOrders.vue'
import OAdminClientTableManualInvoices from '@/components/organisms/admin/OAdminClientTableManualInvoices.vue'
import OAdminClientTableWebhooks from '@/components/organisms/admin/OAdminClientTableWebhooks.vue'
import OProviderSitekeyNew from '@/components/organisms/provider/OProviderSitekeyNew.vue'
import OAdminProviderSitekeyNew from '@/components/organisms/admin/OAdminProviderSitekeyNew.vue'
import ODrawerProviderLeadDetail from '@/components/organisms/provider/ODrawerProviderLeadDetail.vue'
import OClientLeadDetail from '@/components/organisms/client/OClientLeadDetail.vue'
import OLeadZero from '@/components/organisms/common/OLeadZero.vue'
import OClientLeadExport from '@/components/organisms/client/OClientLeadExport.vue'
import OAdminClientOptions from '@/components/organisms/admin/OAdminClientOptions.vue'
import OAdminProviderAreasEdit from '@/components/organisms/admin/OAdminProviderAreasEdit.vue'
import OAdminClientProfil from '@/components/organisms/admin/OAdminClientProfil.vue'
import OAdminProviderProfil from '@/components/organisms/admin/OAdminProviderProfil.vue'
import ODrawerAdminLeadRejectedDetail from '@/components/organisms/admin/OAdminDrawerLeadRejectedDetail.vue'
//Custom Tracking
import OTableAdminCustomTrackingCampaingId from '@/components/organisms/admin/customTracker/OTableAdminCustomTrackingCampaingId.vue'
import OTableAdminCustomTrackingSource from '@/components/organisms/admin/customTracker/OTableAdminCustomTrackingSource.vue'
import OTableAdminCustomTrackingSiteId from '@/components/organisms/admin/customTracker/OTableAdminCustomTrackingSiteId.vue'
import OTableAdminCustomTrackingKeyword from '@/components/organisms/admin/customTracker/OTableAdminCustomTrackingKeyword.vue'
import OTableAdminCustomTrackingAdId from '@/components/organisms/admin/customTracker/OTableAdminCustomTrackingAdId.vue'
import OTableAdminCustomTrackingAdsetId from '@/components/organisms/admin/customTracker/OTableAdminCustomTrackingAdsetId.vue'
import OTableAdminCustomTrackingFlux from '@/components/organisms/admin/customTracker/OTableAdminCustomTrackingFlux.vue'
import OTableAdminCustomTrackingRequal from '@/components/organisms/admin/customTracker/OTableAdminCustomTrackingRequal.vue'
import OTableProviderCustomTrackerAdId from '@/components/organisms/provider/customTrackers/OTableProviderCustomTrackerAdId.vue'
import OTableProviderCustomTrackerKeyword from '@/components/organisms/provider/customTrackers/OTableProviderCustomTrackerKeyword.vue'
import OTableProviderCustomTrackerSource from '@/components/organisms/provider/customTrackers/OTableProviderCustomTrackerSource.vue'
import OTableProviderCustomTrackerCampaignId from '@/components/organisms/provider/customTrackers/OTableProviderCustomTrackerCampaignId.vue'
import OTableProviderCustomTrackerSiteId from '@/components/organisms/provider/customTrackers/OTableProviderCustomTrackerSiteId.vue'
import OTableProviderCustomTrackerAdsetId from '@/components/organisms/provider/customTrackers/OTableProviderCustomTrackerAdsetId.vue'
import OTableProviderCustomTrackerFlux from '@/components/organisms/provider/customTrackers/OTableProviderCustomTrackerFlux.vue'
import OTableProviderCustomTrackerRequal from '@/components/organisms/provider/customTrackers/OTableProviderCustomTrackerRequal.vue'

import PNotFound from '@/components/pages/common/PNotFound.vue'
import { getModule } from 'vuex-module-decorators'
import AuthenticationModule from '@/store/authentication'
import store from '@/store'
import defineAbilityFor from '@/services/ability'
import Vue from 'vue'
import NotificationModule from '@/store/notification'

export const routes: Array<RouteConfig> = [
  {
    path: '/404',
    alias: '*',
    component: PNotFound,
    name: 'NotFound',
    meta: {
      title: "La page n'existe pas"
    }
  },
  {
    path: '/',
    redirect: '/dashboard'
  },
  //Dashboard
  {
    path: '/dashboard',
    alias: '/taleaux_de_bord',
    redirect: '/dashboard/accueil',
    name: 'Dashboard',
    component: PHomePageDashboard,
    children: [
      {
        path: 'accueil',
        alias: 'home',
        component: RDashboard,
        meta: {
          requiresAuth: true,
          title: 'Tableau de bord'
        }
      },
      {
        path: 'statistiques',
        alias: 'statistics',
        name: 'Statistic',
        redirect: '/statistiques/thematiques',
        component: RStatistics,
        children: [
          {
            path: 'thematiques',
            alias: 'areas',
            name: 'StatisticArea',
            components: {
              admin: OTableAdminStatisticsAreas,
              provider: OTableProviderStatisticsAreas
            },
            meta: {
              type: 'areas'
            }
          },
          {
            path: 'partenaires',
            alias: 'providers',
            name: 'StatisticProvider',
            components: {
              admin: OTableAdminStatisticsProviders
            },
            meta: {
              type: 'providers'
            }
          },
          {
            path: 'sitekeys',
            name: 'StatisticSitekey',
            components: {
              admin: OTableAdminStatisticsSitekeys,
              provider: OTableProviderStatisticsSitekeys
            },
            meta: {
              type: 'sitekeys'
            }
          },
          {
            path: 'clients',
            name: 'StatisticClient',
            components: {
              admin: OTableAdminStatisticsClients
            },
            meta: {
              type: 'clients'
            }
          }
        ],
        meta: { requiresAuth: true, title: 'Statistiques' }
      },
      {
        path: 'customTracking',
        name: 'CustomTracking',
        component: RCustomTracking,
        redirect: '/customTracking/campaignId',
        children: [
          {
            path: 'campaignId',
            name: 'CampaignID',
            components: {
              admin: OTableAdminCustomTrackingCampaingId,
              provider: OTableProviderCustomTrackerCampaignId
            },
            meta: {
              type: 'campaign_id'
            }
          },
          {
            path: 'source',
            name: 'Source',
            components: {
              admin: OTableAdminCustomTrackingSource,
              provider: OTableProviderCustomTrackerSource
            },
            meta: {
              type: 'source'
            }
          },
          {
            path: 'siteId',
            name: 'Site Id',
            components: {
              admin: OTableAdminCustomTrackingSiteId,
              provider: OTableProviderCustomTrackerSiteId
            },
            meta: {
              type: 'site_id'
            }
          },
          {
            path: 'keyword',
            name: 'Keyword',
            components: {
              admin: OTableAdminCustomTrackingKeyword,
              provider: OTableProviderCustomTrackerKeyword
            },
            meta: {
              type: 'keyword'
            }
          },
          {
            path: 'adId',
            name: 'AdId',
            components: {
              admin: OTableAdminCustomTrackingAdId,
              provider: OTableProviderCustomTrackerAdId
            },
            meta: {
              type: 'ad_id'
            }
          },
          {
            path: 'adsetId',
            name: 'AdsetId',
            components: {
              admin: OTableAdminCustomTrackingAdsetId,
              provider: OTableProviderCustomTrackerAdsetId
            },
            meta: {
              type: 'adset_id'
            }
          },
          {
            path: 'flux',
            name: 'Flux',
            components: {
              admin: OTableAdminCustomTrackingFlux,
              provider: OTableProviderCustomTrackerFlux
            },
            meta: {
              type: 'flux'
            }
          },
          {
            path: 'requal',
            name: 'Requalification',
            components: {
              admin: OTableAdminCustomTrackingRequal,
              provider: OTableProviderCustomTrackerRequal
            },
            meta: {
              type: 'requal'
            }
          }
        ],
        meta: { requiresAuth: true, title: 'CustomTracking' }
      },
      {
        path: 'facturation',
        component: PAdminReminders,
        meta: {
          requiresAuth: true,
          title: 'AAF'
        }
      },
      {
        path: 'completion/areas',
        name: 'Completion Thématique',
        component: PAdminAreaCompletion
      },
      {
        path: 'completion/clients',
        name: 'Completion Clients',
        component: PAdminClientCompletion
      },
      {
        path: 'completion/orders',
        name: 'Completion Orders',
        component: PAdminOrdersCompletion
      },
      {
        path: 'commandes',
        component: PAdminOrders,
        meta: {
          requiresAuth: true,
          title: 'Commandes'
        }
      }
    ]
  },
  //Clients
  {
    path: '/clients',
    name: 'Clients',
    component: PAdminClients,
    children: [
      {
        path: 'creation',
        alias: 'new',
        component: OAdminClientNew
      }
    ],
    meta: { requiresAuth: true, title: 'Clients' }
  },
  //Clients admin
  {
    path: '/clients/:clientId',
    name: 'Client',
    redirect: '/clients/:clientId/dashboard',
    component: PAdminClient,
    children: [
      {
        path: 'dashboard',
        component: OAdminClientDashboard,
        props: true
      },
      {
        path: 'commandes',
        component: OAdminClientTableOrders,
        props: true
      },
      {
        path: 'factures-manuelles',
        alias: 'manual-invoices',
        component: OAdminClientTableManualInvoices,
        props: true
      },
      {
        path: 'profil',
        component: OAdminClientProfil
      },
      {
        path: 'connecteurs',
        component: OAdminClientTableWebhooks
      },
      {
        path: 'options',
        component: OAdminClientOptions
      }
    ],
    meta: { requiresAuth: true, title: 'Clients' }
  },
  //Clients other pages admin
  {
    path: '/clients/:clientId/commandes/creation',
    alias: '/clients/:clientId/orders/new',
    name: 'ClientOrderNew',
    component: POrder,
    meta: { requiresAuth: true, title: 'Nouvelle commande client' }
  },
  {
    path: '/clients/:clientId/commandes/:orderId/edition',
    alias: '/clients/:clientId/orders/:orderId/new',
    name: 'ClientOrderEdit',
    component: POrder,
    meta: { requiresAuth: true, title: "Edition d'une commande client" }
  },
  {
    path: '/clients/:clientId/connecteurs/creation',
    alias: '/clients/:clientId/webhooks/new',
    name: 'ClientWebhookNew',
    component: PWebhook,
    meta: { requiresAuth: true, title: 'Nouveau connecteur client' }
  },
  {
    path: '/clients/:clientId/connecteurs/:webhookId/edition',
    alias: '/clients/:clientId/webhooks/:webhookId/edit',
    name: 'ClientWebhookEdit',
    component: PWebhook,
    meta: { requiresAuth: true, title: "Edition d'un connecteur client" }
  },
  {
    path: '/partenaires',
    alias: '/providers',
    name: 'Providers',
    component: PAdminProviders,
    children: [
      {
        path: 'creation',
        alias: 'new',
        component: OAdminProviderNew
      }
    ],
    meta: { requiresAuth: true, title: 'Partenaires' }
  },
  //Provider admin
  {
    path: '/partenaires/:providerId',
    alias: '/providers/:providerId',
    name: 'Provider',
    redirect: '/partenaires/:providerId/dashboard',
    component: PAdminProvider,
    children: [
      {
        path: 'dashboard',
        component: OAdminProviderDashboard
      },
      {
        path: 'utilisateurs',
        alias: 'users',
        component: OAdminProviderTableUsers
      },
      {
        path: 'utilisateurs/creation',
        alias: 'users/new',
        components: {
          default: OAdminProviderTableUsers,
          modal: OAdminProviderUserNew
        }
      },
      {
        path: 'sitekeys',
        component: OAdminProviderTableSitekeys
      },
      {
        path: 'sitekeys/creation',
        components: {
          default: OAdminProviderTableSitekeys,
          modal: OAdminProviderSitekeyNew
        }
      },
      {
        path: 'remunerations',
        alias: 'earnings',
        component: OAdminProviderTableEarnings
      },
      {
        path: 'profil',
        component: OAdminProviderProfil
      },
      {
        path: 'thematiques',
        alias: 'areas',
        component: OAdminProviderAreasEdit
      }
    ],
    meta: { requiresAuth: true, title: 'Partenaires' }
  },
  {
    path: '/thématiques',
    alias: '/areas',
    name: 'Areas',
    component: PAdminAreas,
    meta: { requiresAuth: true, title: 'Thématiques' }
  },
  //Roles users admin
  {
    path: '/utilisateurs',
    alias: '/users',
    name: 'Users',
    component: PAdminUsers,
    children: [
      {
        path: 'creation',
        alias: 'users/new',
        component: OAdminUserNew
      },
      {
        path: ':id/suppression',
        alias: 'users/delete',
        component: OAdminUserDelete
      }
    ],
    meta: { requiresAuth: true, title: 'Utilisateurs' }
  },
  {
    path: '/actions',
    name: 'ActionsRgpd',
    component: PActionsRgpd,
    meta: { requiresAuth: true, title: 'Actions RGPD' }
  },
  {
    path: '/connexion',
    alias: '/login',
    name: 'Login',
    component: PLogin,
    meta: {
      title: 'Connexion'
    }
  },
  {
    path: '/profil',
    name: 'Profil',
    component: RProfil,
    meta: { requiresAuth: true, title: 'Profil' }
  },
  {
    path: '/factures',
    alias: '/invoices',
    name: 'Invoices',
    component: PClientInvoices,
    meta: { requiresAuth: true, title: 'Factures' }
  },
  //Todo not use
  // {
  //   path: '/remunerations',
  //   alias: '/earnings',
  //   name: 'Earnings',
  //   component: PProviderEarnings,
  //   meta: { requiresAuth: true, title: 'Partenaires' }
  // },
  {
    path: '/sitekeys',
    name: 'Sitekeys',
    component: PProviderSitekeys,
    children: [
      {
        path: 'creation',
        alias: 'new',
        component: OProviderSitekeyNew
      }
    ],
    meta: { requiresAuth: true, title: 'Partenaires' }
  },
  {
    path: '/leads/creation',
    alias: '/leads/new',
    name: 'LeadNew',
    component: PLead,
    meta: { requiresAuth: true, title: 'Nouveau lead' }
  },
  {
    path: '/leads',
    redirect: '/leads/tableau-de-bord',
    name: 'Lead',
    component: PHomePageLeads,
    children: [
      {
        path: 'tableau-de-bord',
        alias: 'dashboard',
        name: 'LeadsAccueil',
        component: RLeads,
        children: [
          {
            path: ':id',
            components: {
              admin: ODrawerAdminLeadDetail,
              provider: ODrawerProviderLeadDetail,
              client: OClientLeadDetail
            }
          }
        ],
        meta: {
          requiresAuth: true,
          title: 'Tableau de bord'
        }
      },
      {
        path: 'leads-rejected',
        alias: 'leads-rejeted',
        name: 'LeadsRejected',
        component: PAdminLeadsRejected,
        children: [
          {
            path: ':id',
            props: true,
            component: ODrawerAdminLeadRejectedDetail
          }
        ],
        meta: { requiresAuth: true, title: 'Liste des leads rejetés' }
      },
      {
        path: 'rgpd',
        name: 'Rgpd',
        component: PAdminLeadsRgpd,
        meta: {
          requiresAuth: true,
          title: 'Liste des leads RGPD'
        }
      },
      {
        path: 'lead_zero ',
        name: 'LeadZero',
        components: {
          client: OLeadZero
        }
      },
      {
        path: 'csv',
        name: 'CSV',
        components: {
          client: OClientLeadExport
        }
      }
    ],
    meta: { requiresAuth: true, title: 'Liste des leads' }
  },
  {
    path: '/commandes',
    alias: '/orders',
    name: 'Order',
    component: PClientOrders,
    meta: { requiresAuth: true, title: 'Liste des commandes' }
  },
  {
    path: '/commandes/creation',
    alias: '/orders/new',
    component: POrder,
    meta: { requiresAuth: true, title: 'Nouvelle commande client' }
  },
  {
    path: '/support',
    name: 'Support',
    component: PSupport,
    meta: { requiresAuth: true, title: 'Clients' }
  },
  {
    path: '/password/create_account/:id/:tokenId',
    name: 'CreationPassword',
    component: PCreateAccount,
    meta: {
      title: 'Création de mot de passe'
    }
  },
  {
    path: '/password/forgot',
    name: 'ForgotPassword',
    component: PForgotPassword,
    meta: {
      title: 'Mot de passe oublié'
    }
  },
  {
    path: '/password/renew/:token',
    name: 'RenewPassword',
    component: PRenewPassword,
    meta: {
      title: 'Renouveler le mot de passe'
    }
  },
  {
    path: '/deconnexion',
    alias: '/logout',
    name: 'Logout',
    meta: { title: 'Déconnexion' },
    beforeEnter: async (to, from, next) => {
      const authenticationModule = getModule(AuthenticationModule, store)
      const notificationModule = getModule(NotificationModule, store)
      await authenticationModule.logout()
      notificationModule.deleteAllPersisted()
      Vue.prototype.$ability.update(defineAbilityFor(null))
      next('/login')
    }
  }
]
