
































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import ATextMedium from '@/components/atoms/text/ATextMedium.vue'
import MDisableTextField from '@/components/molecules/MDisableTextField.vue'
import MCards from '@/components/molecules/MCards.vue'
import { getModule } from 'vuex-module-decorators'
import ClientModule from '@/store/client'
import AreaModule from '@/store/area'
import OrderModule from '@/store/order'

@Component({
  components: {
    AInputTextField,
    ATextMedium,
    MDisableTextField,
    MCards
  }
})
export default class MWebhookStepTwo extends Vue {
  public clientModule = getModule(ClientModule, this.$store)
  public areaModule = getModule(AreaModule, this.$store)
  public orderModule = getModule(OrderModule, this.$store)

  @PropSync('value') valueSynced!: string | number | null

  @Prop() category!: string

  get orders() {
    return this.clientModule.orders.items.map((order) => {
      return {
        id: order.orderId,
        displayName: order.name
      }
    })
  }

  get areas() {
    return this.areaModule.areas.map((area) => {
      if (
        area?.displayName === 'Rachat de crédit' ||
        area?.displayName === 'Pinel'
      ) {
        return {
          id: area.id,
          displayName: area.displayName
        }
      } else {
        return {
          id: area.id,
          displayName: area.displayName,
          disabled: true
        }
      }
    })
  }
}
