import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import AuthenticationModule from './authentication'
import { FiltersMeta } from './filter'

export enum Roles {
  SUPER = 'SUPER',
  ADMIN = 'ADMIN',
  CLIENT = 'CLIENT',
  PROVIDER = 'PROVIDER',
  OPERATOR = 'OPERATOR'
}

export interface SignUp {
  email: string
  password?: string
  firstName: string
  lastName: string
  phone: string
  companyName?: string
  notificationSms?: boolean
  role?: SignUpRoleBasic | SignUpRoleAdmin
  providerId?: number
}

export type SignUpRoleBasic = 'CLIENT' | 'PROVIDER'
export type SignUpRoleAdmin = 'SUPER' | 'ADMIN' | 'OPERATOR'

export interface User {
  id: string
  email: string
  password: string
  firstName: string
  lastName: string
  phone: string
  refreshToken?: string
  companyCreator: boolean
  isDisabled: boolean
  company?: Company
  role: Role
}
export interface UsersFields {
  email: string
  firstName?: string
  lastName?: string
  phone?: string
  password?: string
  currentPassword?: string
}
export interface EditFields {
  password?: string
  firstName: string
  lastName: string
  phone: string
  currentPassword?: string
}
export interface UpdateUser {
  email: string
  firstName?: string | null
  lastName?: string | null
  phone?: string | null
  password?: string
  currentPassword?: string
}

export interface UpdateUserFields {
  id: string
  data: UsersFields | null
}

export interface Role {
  createdAt: string
  id: number
  name: string
  updatedAt: string
}

export interface CurrentUser {
  id: string
  email: string
  role: Roles
  firstName: string
  lastName: string
  phone: string
  companyId?: number
  companyCreator?: boolean
  clientId?: number
  providerId?: number
}
export interface Company {
  id: number
  name: string
  displayName?: string | null
  registrationNumber?: string | null
  vat?: string | null
  address?: Address | null
  users?: User[] | null
  notification?: Notification | null
}

export interface Notification {
  id: number
  sms: boolean
}

export interface Address {
  id: number
  line1: string
  line2?: string | null
  state?: string | null
  country: string
  city: string
  postalCode?: string | null
}

export interface UserRequest {
  items: User[]
  meta?: FiltersMeta
}

@Module({ namespaced: true, name: 'user' })
export default class UserModule extends VuexModule {
  currentUser: CurrentUser | null = null
  user: User | null = null

  users: User[] = []

  get isAdmin() {
    return (
      this.currentUser?.role == Roles.SUPER ||
      this.currentUser?.role == Roles.ADMIN
    )
  }

  get isClient() {
    return this.currentUser?.role == Roles.CLIENT
  }

  get isProvider() {
    return this.currentUser?.role == Roles.PROVIDER
  }

  @Mutation
  saveCurrentUser(curentUser: CurrentUser) {
    this.currentUser = curentUser
  }

  @Mutation
  saveAll(users: User[]) {
    this.users = users
  }

  @Action({ commit: 'saveCurrentUser' })
  async me(): Promise<CurrentUser> {
    const { data } = await Vue.prototype.$http.get(
      '/auth/me',
      {},
      {
        errorHandler: false
      }
    )
    return data
  }

  @Action({ commit: 'saveAll' })
  async fetchOne(id: string) {
    const { data } = await Vue.prototype.$http.get(`/users/${id}`)
    return data
  }

  @Action({ commit: 'saveAll' })
  async fetchAll() {
    const { data } = await Vue.prototype.$http.get('/users', {
      useQueryParams: true
    })
    return data
  }

  @Action
  async signup(signupFields: SignUp) {
    await Vue.prototype.$http.post('/users/signup', signupFields, {
      successHandler: {
        message: 'Le compte client a bien été créé'
      }
    })
  }

  @Action
  async signupProviderUser(signupFields: SignUp) {
    await Vue.prototype.$http.post(
      '/users/signup/provider/user',
      signupFields,
      {
        successHandler: {
          message: 'Le compte fournisseur a bien été créé'
        }
      }
    )
  }

  @Action
  async edit(editedFields: UpdateUserFields): Promise<User> {
    const { data } = await Vue.prototype.$http.put(
      `/users/${editedFields!.id}`,
      editedFields!.data,
      {
        successHandler: {
          message: 'Informations mises à jour avec succès'
        },
        errorHandler: {
          message: 'Une erreur est survenue lors de la mise à jour'
        }
      }
    )
    return data
  }

  @Action
  async createAdminUser(signupFields: SignUp) {
    await Vue.prototype.$http.post('/users', signupFields, {
      successHandler: true
    })
  }

  @Action
  async delete(id: string) {
    const { data } = await Vue.prototype.$http.delete(`/users/${id}`)
    return data
  }

  @Action
  async checkTokenPassword(userInfos: {
    userUUID: string
    userToken: string
    newpass: string
  }): Promise<{ statusCode: number; message: string; access: boolean }> {
    const { data } = await Vue.prototype.$http.get(
      `/users/password/${userInfos.userUUID}/${userInfos.userToken}`
      // A voir avec la gestion des http status (avec un status code 400 passe en success)
      // ,
      // {
      //   successHandler: {
      //     message: 'Lien valide'
      //   },
      //   errorHandler: {
      //     message: 'Ce lien est expiré.'
      //   }
      // }
    )
    return data
  }

  @Action
  async setFirstPassword(userInfos: {
    userUUID: string
    userToken: string
    newpass: string
  }): Promise<{ statusCode: number; message: string; access: boolean }> {
    const { data } = await Vue.prototype.$http.put(
      '/auth/reset',
      {
        resetToken: userInfos.userToken,
        password: userInfos.newpass
      },
      {
        successHandler: {
          message: 'Votre mot de passe a bien été mise à jour.'
        },
        errorHandler: {
          message: 'Une erreur est survenue lors de la mis à jour.'
        }
      }
    )
    return data
  }
}
