var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-client-table-orders section white"},[_c('MBaseModal',{ref:"modal",attrs:{"modalProps":_vm.modalProps}}),_c('MTableHeading',{attrs:{"title":_vm.$tc('order.name', 1)},scopedSlots:_vm._u([{key:"btn",fn:function(){return [_c('AButtonPrimary',{staticClass:"ml-4 font-weight-bold",attrs:{"text":_vm.$t('order.actions.new')},nativeOn:{"click":function($event){return _vm.newOrder.apply(null, arguments)}}})]},proxy:true}])}),_c('MTable',{attrs:{"headers":_vm.headers,"items":_vm.clientModule.orders.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('MTableActions',{attrs:{"itemId":item.id,"tableActions":_vm.tableActions},on:{"iconAction":function($event){return $event(item)}}})]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('ACheckboxSwitchOrderStatus',{attrs:{"order":item},on:{"switched":_vm.updateOrderStatus}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"grey--text text--darken-3 ma-0"},[_vm._v(_vm._s(item.name))])]}},{key:"item.area",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"grey--text text--darken-2 ma-0"},[_vm._v(_vm._s(item.area))])]}},{key:"item.connectorName",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"grey--text text--darken-3 ma-0",class:item.connectorName ? 'font-weight-bold' : 'font-italic'},[_vm._v(" "+_vm._s(item.connectorName ? item.connectorName : 'pas de connecteur')+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"grey--text ma-0"},[_vm._v(" "+_vm._s(_vm.$options.filters.timestampToDateTime(item.createdAt))+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"green--text text--accent-4 ma-0"},[_vm._v(_vm._s(_vm._f("formatPrice")(item.price)))])]}},{key:"item.capping",fn:function(ref){
var item = ref.item;
return [_c('p',{domProps:{"textContent":_vm._s(
          item.capping <= 0
            ? _vm.$tc('order.capping', 1)
            : _vm.$tc('order.capping', 2, { capping: item.capping })
        )}})]}},{key:"item.cappingMax",fn:function(ref){
        var item = ref.item;
return [_c('p',{domProps:{"textContent":_vm._s(
          item.cappingMax <= 0
            ? _vm.$tc('order.capping', 1)
            : _vm.$tc('order.capping', 2, { capping: item.cappingMax })
        )}})]}},{key:"item.customValue",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.duration(item))+" ")]}},{key:"item.customValueIcon",fn:function(ref){
        var item = ref.item;
return [_c(_vm.orderType(item),{tag:"component"})]}}],null,true)}),_c('MTableFooter',{attrs:{"tableProperties":_vm.clientModule.orders.meta}}),_c('MBaseModal',{ref:"duplicateOrder",attrs:{"modalProps":_vm.modalDuplicateOrder}}),_c('MBaseModal',{ref:"summaryOrder",attrs:{"modalProps":_vm.modalsummaryOrder},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('MSummaryOrder',{attrs:{"item":_vm.itemSummaryOrder}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }