






















import { Component, Prop, Vue } from 'vue-property-decorator'
import ATitleSmall from '@/components/atoms/title/ATitleSmall.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'

export interface ProfilPayment {
  createdAt?: Date
  updatedAt?: Date
  id?: string
  balance?: number
  type: string
  stripeCustomer?: string
  invoiceThreshold?: number
}

@Component({
  components: {
    ATitleSmall,
    AButtonPrimary
  }
})
export default class MFormProfilPayment extends Vue {
  @Prop() isDisabled!: boolean
  @Prop() payment!: ProfilPayment
}
