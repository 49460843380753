



















import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class ALoaderLogo extends Vue {
  @PropSync('loading') loadingSynced!: boolean
}
