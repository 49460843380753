






import { Component } from 'vue-property-decorator'
import { VBtn } from 'vuetify/lib'
@Component
export default class AButtonSmallRound extends VBtn {}
