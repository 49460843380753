














































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import ABaseTitle from '@/components/atoms/title/ABaseTitle.vue'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import MDisableTextField from '@/components/molecules/MDisableTextField.vue'
import ACheckbox from '@/components/atoms/checkbox/ACheckbox.vue'
import { TypePriceResponse } from '@/store/area'
import ATextField from '@/components/atoms/text/ATextField.vue'

@Component({
  components: {
    ABaseTitle,
    ATitleMedium,
    MDisableTextField,
    ACheckbox,
    ATextField
  }
})
export default class MOrderStepSix extends Vue {
  @Prop() area!: string
  @Prop() type!: string
  @Prop() zips!: number
  @Prop() notificationsMail!: string[]
  @Prop() limits!: { daily: number; global: number }
  @Prop() period!: string
  @Prop() budget!: number
  @Prop() isUpdate!: boolean
  @Prop() status!: string
  @Prop() connectorName?: string

  public typePrice = {
    EXCLU: TypePriceResponse.EXCLU,
    MUTU: TypePriceResponse.MUTU
  }

  public checkStatus = false
}
