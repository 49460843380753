













import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class MMArk extends Vue {
  @Prop() mark!: number

  stars: boolean[] = []

  color(mark: boolean) {
    return mark ? 'primary' : 'light'
  }

  mounted() {
    let starNumber = this.mark
    for (let i = 0; i < 5; i++) {
      if (starNumber > 0) {
        this.stars.push(true)
        starNumber--
      } else {
        this.stars.push(false)
      }
    }
  }
}
