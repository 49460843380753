import Vue from 'vue'
import { Action, Module, VuexModule } from 'vuex-module-decorators'
import { Filters } from '@/store/filter'

export interface DownloadCsvParams {
  clientId: number
  leadIds: number[]
  filters?: Filters
}

export interface DownloadProviderCsvParams {
  providerId: number
  data?: {
    sitekeyId?: number
    setAsExported: boolean
  }
}

export interface DownloadClientCsvParams {
  clientId: number
  data?: {
    orderId?: string
    leads?: number[]
    alreadyExportedLeads?: boolean
    startAt?: Date
    endAt?: Date
  }
}
@Module({ namespaced: true, name: 'csv' })
export default class CsvModule extends VuexModule {
  @Action
  async downloadClientLeads(params: DownloadClientCsvParams): Promise<void> {
    const { data } = await Vue.prototype.$http.get(
      `/csv/client/${params.clientId}`,
      {
        params: params.data,
        errorHandler: {
          message: 'Pas de leads a exporter'
        },
        successHandler: {
          message: 'Votre selection à bien été exportée.'
        },
        responseType: 'blob'
      }
    )

    const fileURL = window.URL.createObjectURL(new Blob([data]))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', 'leads.csv')
    document.body.appendChild(fileLink)
    fileLink.click()
    document.body.removeChild(fileLink)
  }

  @Action
  async downloadProviderLeads(
    params: DownloadProviderCsvParams
  ): Promise<void> {
    const { data } = await Vue.prototype.$http.get(
      `/csv/provider/${params.providerId}`,
      {
        params: params.data,
        errorHandler: {
          message: 'Pas de leads a exporter'
        },
        successHandler: {
          message: 'Votre selection à bien été exportée.'
        },
        responseType: 'blob'
      }
    )

    const fileURL = window.URL.createObjectURL(new Blob([data]))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', 'leads.csv')
    document.body.appendChild(fileLink)
    fileLink.click()
    document.body.removeChild(fileLink)
  }
}
