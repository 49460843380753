






















import { Component, PropSync, Vue } from 'vue-property-decorator'
//Atoms
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
//Molecules
import MFormSetPassword from '@/components/molecules/form/MFormSetPassword.vue'
import { ValidationObserver } from 'vee-validate'

//Template
import TCardFluid from '@/components/templates/TCardFluid.vue'
import { PasswordFields } from '@/store/interfaces/components/IPasswordFields'

@Component({
  components: {
    ATitleMedium,
    AButtonPrimary,
    MFormSetPassword,
    ValidationObserver,
    TCardFluid
  }
})
export default class MFormNewAccount extends Vue {
  @PropSync('passwordProperties') passwordFieldsSynced!: PasswordFields

  submit() {
    this.$emit('submit')
  }
}
