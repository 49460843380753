
































































































import { Component, Vue, Watch } from 'vue-property-decorator'
//Atoms
import ASelectAvatar from '@/components/atoms/select/ASelectAvatar.vue'
import ASideBarItem from '@/components/atoms/ASideBarItem.vue'
import ABaseTitle from '@/components/atoms/title/ABaseTitle.vue'
import ATitleLogo from '@/components/atoms/title/ATitleLogo.vue'
//Molécules
import MSideBar from '@/components/molecules/MSideBar.vue'
//Store
import { getModule } from 'vuex-module-decorators'
import AuthenticationModule from '@/store/authentication'
import UserModule, { Roles } from '@/store/user'

export interface SideBarItemsProps {
  route: string
  title: string
}
export interface SidebarAvatar {
  title: string
  route: string
  icon: string
}

@Component({
  components: {
    ASideBarItem,
    ASelectAvatar,
    ABaseTitle,
    ATitleLogo,
    MSideBar
  }
})
export default class OSideBar extends Vue {
  public authenticationModule = getModule(AuthenticationModule, this.$store)
  /**
   * @Store import Module UserModule. Juste for fetch name user
   */
  public userModule = getModule(UserModule, this.$store)

  /**
   * @var drawer for navbar width Vuetify
   */
  public drawer = true

  get user() {
    return this.userModule.currentUser!
  }
  get sharedItems(): SideBarItemsProps[] {
    return [
      {
        route: '/profil',
        title: this.$i18n.t('account.text') as string
      }
    ]
  }
  @Watch('nav')
  group() {
    this.drawer = false
  }
  mounted() {
    //check role
    this.userModule.me()
  }
  public roleEnum = {
    SUPER: Roles.SUPER,
    ADMIN: Roles.ADMIN,
    CLIENT: Roles.CLIENT,
    PROVIDER: Roles.PROVIDER,
    OPERATOR: Roles.OPERATOR
  }
  /**
   * return color in terms of role
   */
  get colorRoleName() {
    const role = this.userModule.currentUser?.role
    switch (role) {
      case 'SUPER':
        return 'yellow--text'
      case 'ADMIN':
        return 'yellow--text'
      case 'CLIENT':
        return 'secondary--text'
      case 'PROVIDER':
        return 'primary-text--text'
      default:
        return 'primary--text'
    }
  }
  /**
   * @data for Input select
   * logout , support and profil
   * Admin doesn't have /support
   */
  public avatarAdmin: SidebarAvatar[] = [
    {
      title: 'profil.name',
      route: '/profil',
      icon: 'mdi-account-multiple'
    },
    { title: 'logout', route: '/logout', icon: 'mdi-logout' }
  ]
  public avatar: SidebarAvatar[] = [
    {
      title: 'support.name',
      route: '/support',
      icon: 'mdi-email-multiple-outline'
    },
    {
      title: this.$tc('profil.title', 1),
      route: '/profil',
      icon: 'mdi-account-multiple'
    },
    { title: 'logout', route: '/logout', icon: 'mdi-logout' }
  ]

  get rgpdItems(): SideBarItemsProps[] {
    return [
      {
        route: '/actions',
        title: this.$tc('rgpd.name')
      }
    ]
  }

  get adminItems(): SideBarItemsProps[] {
    return [
      {
        route: '/dashboard',
        title: this.$tc('dashboard.name')
      },
      {
        route: '/leads',
        title: this.$tc('lead.name', 2)
      },
      {
        route: '/clients',
        title: this.$tc('client.name', 2)
      },
      {
        route: '/partenaires',
        title: this.$tc('provider.name', 2)
      },
      {
        route: '/thématiques',
        title: this.$tc('area', 2)
      },
      {
        route: '/utilisateurs',
        title: this.$tc('user.name', 2)
      }
      //Todo delete for this moment (stripe)
      // {
      //   route: '/transactions',
      //   title: this.$tc('transaction.name', 2)
      // }
      // {
      //   route: '/commercial',
      //   title: this.$tc('sales.name', 1)
      // },
    ]
  }

  get clientItems(): SideBarItemsProps[] {
    return [
      {
        route: '/leads',
        title: this.$tc('lead.asClient', 2)
      },
      {
        route: '/commandes',
        title: 'Mes commandes'
      }
    ]
  }

  get providerItems(): SideBarItemsProps[] {
    return [
      {
        route: '/dashboard',
        title: this.$tc('dashboard.name')
      },
      {
        route: '/leads',
        title: this.$tc('lead.name', 2)
      },

      // {
      //   route: '/remunerations',
      //   title: this.$tc('provider.earning', 2)
      // },
      {
        route: '/sitekeys',
        title: this.$tc('provider.sitekey', 2)
      }
    ]
  }
}
