










































import { Component, Mixins, Watch } from 'vue-property-decorator'

//Molécules
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import MixinTables from '@/components/molecules/table/MixinTables.vue'
import MBaseModal, {
  ModalProps
} from '@/components/molecules/modal/MBaseModal.vue'

//Store
import { getModule } from 'vuex-module-decorators'
import UserModule, { CurrentUser } from '@/store/user'
import AuthenticationModule from '@/store/authentication'
import NotificationModule from '@/store/notification'
import ClientModule, { Client } from '@/store/client'

//Plugins
import { TableActionsProps } from '@/types'
import { DataTableHeader } from 'vuetify'
import CsvModule from '@/store/csv'

@Component({
  components: {
    MTableHeading,
    MTableFooter,
    MTableActions,
    MTable,
    MBaseModal,
    AButtonPrimary
  }
})
export default class OTableAdminClients extends Mixins(MixinTables) {
  public clientModule = getModule(ClientModule, this.$store)
  public authenticationModule = getModule(AuthenticationModule, this.$store)
  public notificationModule = getModule(NotificationModule, this.$store)
  public userModule = getModule(UserModule, this.$store)
  public csvModule = getModule(CsvModule, this.$store)
  public currentClient: Client | null = null

  public headers: DataTableHeader<Client>[] = [
    { text: 'Nom', value: 'name' },
    { text: 'Téléphone', value: 'phone', sortable: false },
    { text: 'E-mail', value: 'email' },
    { text: 'Actions', value: 'actions', sortable: false }
  ]

  get tableActions(): TableActionsProps[] {
    return [
      {
        icon: '$view',
        action: this.view,
        tooltip: this.$t('client.actions.show') as string
      },
      {
        icon: '$edit',
        action: this.edit,
        tooltip: this.$t('client.actions.edit') as string
      },
      {
        icon: '$download',
        action: this.downloadCSV,
        tooltip: this.$t('client.actions.export') as string
      },
      {
        icon: '$share',
        action: this.logasClient,
        tooltip: this.$t('client.actions.logas') as string
      }
    ]
  }

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  async request() {
    this.loading = true
    await this.clientModule.fetchAll()
    this.loading = false
  }

  public read(item: Client) {
    this.$router.push({ path: `/clients/${item.clientId}` })
  }

  public view(item: Client) {
    this.$router.push({
      path: '/leads',
      query: { client: item.clientId.toString() }
    })
  }

  public edit(item: Client) {
    this.$router.push({ path: `/clients/${item.clientId}/profil` })
  }

  public newClient() {
    this.$router.push({ path: '/clients/creation' })
  }

  public async downloadCSV(item: Client) {
    this.modal.dialog = true
    this.currentClient = item
  }

  public async logasClient(item: Client) {
    await Promise.all([
      this.authenticationModule.logas({
        id: { clientId: item.clientId },
        currentRoute: this.$route.fullPath
      }),
      this.userModule.me()
    ])
    this.$router.push('/leads/tableau-de-bord')
    const { firstName, lastName } = this.userModule.currentUser as CurrentUser
    this.notificationModule.add({
      color: 'yellow',
      timeout: -1,
      show: true,
      message: `Connecté en tant que ${firstName} ${lastName}`,
      persist: true
    })
  }

  get modal() {
    return this.$refs.modal as MBaseModal
  }

  public get modalProps(): ModalProps {
    return {
      title: this.$tc('client.actions.export'),
      actionText: this.$tc('client.actions.export'),
      modalAction: this.submit
    }
  }

  public async submit() {
    await this.csvModule.downloadClientLeads({
      clientId: this.currentClient!.clientId
    })
    this.modal.dialog = false
  }
}
