
























import { Component, PropSync, Vue } from 'vue-property-decorator'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import ABaseSelect from '@/components/atoms/select/ABaseSelect.vue'

@Component({
  components: {
    AInputTextField,
    ABaseSelect
  }
})
export default class MFormLeadProfession extends Vue {
  @PropSync('profession') professionSynced!: string
  @PropSync('contract') contractSynced!: string
  @PropSync('salary') salarySynced!: number
  @PropSync('cap-consumption') capConsumSynced!: number
  @PropSync('treasury') treasurySynced!: number

  public items = [
    { name: 'CDI', id: 1 },
    { name: 'CDD', id: 2 },
    { name: 'Sans emplois', id: 3 }
  ]
  public atoms = [
    {
      text: this.professionSynced,
      rules: 'required',
      label: 'Profession',
      name: 'profession'
    },
    {
      text: this.salarySynced,
      rules: 'required',
      label: 'Salaire / revenu',
      name: 'salary'
    },
    {
      text: this.capConsumSynced,
      rules: 'required',
      label: 'Capital Consommation',
      name: 'cap-consumption'
    },
    {
      text: this.treasurySynced,
      rules: 'required',
      label: 'Trèsorerie',
      name: 'treasury'
    }
  ]
}
