






















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ACardArea extends Vue {
  @Prop() text!: string
  @Prop() active!: boolean
  @Prop() icon!: string[]
}
