















































































import { Component, Mixins } from 'vue-property-decorator'
import AMenu from '@/components/atoms/AMenu.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonSecondary from '@/components/atoms/button/AButtonSecondary.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import ABaseSelect from '@/components/atoms/select/ABaseSelect.vue'
import ABaseText from '@/components/atoms/text/ABaseText.vue'
import MixinDates from './MixinDates.vue'

@Component({
  components: {
    AMenu,
    AButtonPrimary,
    AButtonSecondary,
    AInputTextField,
    ABaseSelect,
    ABaseText
  }
})
export default class MFilterDates extends Mixins(MixinDates) {
  public active = false

  public openPanel() {
    if (this.$refs.after) {
      this.active = !this.active
    }
  }
}
