









import { Component, Prop, Vue } from 'vue-property-decorator'
import ABaseButton from '@/components/atoms/button/ABaseButton.vue'

@Component({
  components: {
    ABaseButton
  }
})
export default class AButtonSecondary extends Vue {
  @Prop({ type: String, required: true }) text!: string
}
