
import FilterModule, { Period } from '@/store/filter'
import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component
export default class MixinDates extends Vue {
  public filterModule = getModule(FilterModule, this.$store)

  get periodFormatted() {
    return this.period[0] === '' && this.period[1] === ''
      ? 'Tout'
      : `Du ${this.period[0]} au ${this.period[1]}`
  }

  get period(): string[] {
    return [
      this.filterModule.filters.startAt || '',
      this.filterModule.filters.endAt || ''
    ]
  }

  set period(period: string[]) {
    this.filterModule.addPeriod(period[0])

    if (this.filterModule.period.length === 2) {
      this.filterModule.period.sort((a, b) =>
        Vue.prototype.$dayjs(a).isAfter(Vue.prototype.$dayjs(b)) ? 1 : -1
      )
      this.filterModule.updateFilter({
        startAt: this.filterModule.period[0],
        endAt: this.filterModule.period[1],
        page: 1
      })
    }
  }

  get customPeriod() {
    const customPeriod = this.filterModule.predefinedPeriod.find(
      (item) => item.startAt === this.period[0] && item.endAt === this.period[1]
    )

    if (customPeriod) {
      return customPeriod.value
    }

    return this.filterModule.customPeriod
  }

  set customPeriod(period: string) {
    const predefinedPeriod = this.filterModule.predefinedPeriod.find(
      (item) => item.value === period
    )

    this.filterModule.updateFilter({
      startAt: (predefinedPeriod as Period).startAt,
      endAt: (predefinedPeriod as Period).endAt,
      page: 1
    })
  }
  /**
   * @function formatDate
   * @param { date } @type { Date }
   * @return string
   * @description
   * parse date and return with good format
   */
  public formatDate(date: Date | string): string {
    const dateFormat = Vue.prototype.$dayjs(date).format('DD/MM/YYYY')
    return dateFormat
  }
  /**
   * @function resultNbDays
   * @param { startAt } @type { Date }
   * @param { endAt } @type { Date }
   * @returns { string }
   * @description
   * fetch subtract startat and endat and retrun result with préfix
   * similary at duration inside filters.ts but normalie you put the fonction in mixin not in filter.
   */
  public resultNbDays(startAt: Date, endAT: Date): string {
    const duration = Vue.prototype
      .$dayjs(endAT)
      .diff(Vue.prototype.$dayjs(startAt), 'day')
    const phrase = `${duration} jours / ${duration}`
    return phrase
  }
  /**
   * @description transform string date in Date at format iso
   * @exemple @see OAdminClientTableManuelInvoices.vue
   * @param str @type { string }
   * @return @type { Date }
   * @info add an add function from dayjs because toIsoString substract 1day
   */
  public formatStringToDate(str: string): Date {
    return Vue.prototype.$dayjs(str).add(1, 'day').toISOString()
  }
}
