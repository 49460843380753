import Vue from 'vue'
import { Action, Module, VuexModule } from 'vuex-module-decorators'
import { Order } from '@/store/order'
import { Client, ClientsAreas } from '@/store/client'
import { Company } from '@/store/user'

interface WebhookState {
  webhooks: Webhook[]
}

export interface WebhookFields {
  id?: number
  name: string
  type: 'POST' | 'GET'
  url: string
  // payload?: Payload[] | string
  clientId?: number
  orderId?: string
  areaId?: number
}

export interface Webhook {
  id: number
  name: string
  type: 'GET' | 'POST'
  url: string
  payload?: string
  company: Company
  client?: Client
  clientsAreas?: ClientsAreas
  order?: Order
  linkedTo?: LinkedTo
}

export type LinkedTo =
  | 'CLIENT'
  | 'CLIENT_AREA'
  | 'ORDER'
  | 'PROVIDER'
  | 'PROVIDER_AREA'
  | 'SITEKEY'

export interface WebhookCategory {
  id: string
  displayName: string
  disabled?: boolean
}

export enum WebhookCategories {
  GLOBAL = 'global',
  ORDER = 'order',
  THEME = 'theme'
}

export interface UpdateWebhookFields {
  id: string
  data: WebhookFields
}

export type Payload = { key: string; value: string }

@Module({ namespaced: true, name: 'webhook' })
export default class WebhookModule extends VuexModule implements WebhookState {
  webhooks = []

  @Action
  async new(webhookFields: WebhookFields) {
    const { data } = await Vue.prototype.$http.post(
      '/webhooks',
      webhookFields,
      {
        successHandler: {
          message: 'Le connecteur a bien été créé'
        }
      }
    )

    return data
  }

  @Action
  async update(webhookFields: UpdateWebhookFields) {
    const { data } = await Vue.prototype.$http.put(
      `/webhooks/${webhookFields.id}`,
      webhookFields.data,
      {
        successHandler: {
          message: 'Le connecteur a bien été mis à jour'
        }
      }
    )

    return data
  }

  @Action
  async delete(id: number) {
    await Vue.prototype.$http.delete(`/webhooks/${id}`, {
      successHandler: {
        message: 'Le connecteur a bien été supprimé'
      }
    })
  }
}
