

































import MTable from '@/components/molecules/table/MTable.vue'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import OrderModule, { orderCompletion } from '@/store/order'
import { Component, Vue } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {
    MTableHeading,
    MTableFooter,
    MTable
  }
})
export default class PAdminOrdersCompletion extends Vue {
  public orderModule = getModule(OrderModule, this.$store)
  public loading = true

  public headers: DataTableHeader<orderCompletion>[] = [
    { text: this.$tc('orderItems.area'), value: 'thematique', sortable: false },
    {
      text: this.$tc('orderItems.clientName'),
      value: 'company_name',
      sortable: false
    },
    {
      text: this.$tc('orderItems.orderName'),
      value: 'order_name',
      sortable: false
    },
    {
      text: this.$tc('orderItems.shareLimit'),
      value: 'type',
      sortable: false,
      align: 'center'
    },
    {
      text: this.$tc('orderItems.completionTotal'),
      value: 'completionTotal',
      sortable: false,
      width: 250
    },
    {
      text: this.$tc('orderItems.capping'),
      value: 'capping',
      sortable: false,
      align: 'center'
    },
    {
      text: this.$tc('orderItems.cappingMax'),
      value: 'capping_max',
      sortable: true,
      align: 'center'
    },
    {
      text: this.$tc('orderItems.nbLeads'),
      value: 'nb_leads',
      sortable: false,
      align: 'center'
    },
    {
      text: this.$tc('orderItems.planning'),
      value: 'planning',
      sortable: false
    },
    {
      text: this.$tc('orderItems.livedDay'),
      value: 'livedDay',
      sortable: false,
      align: 'center'
    },
    {
      text: this.$tc('orderItems.todayObjectif'),
      value: 'todayObjectif',
      sortable: false
    }
  ]

  async created() {
    await this.orderModule.fetchCompletions('orders')
    this.loading = false
  }
}
