














































import { Component, Vue } from 'vue-property-decorator'
//Atoms
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonLight from '@/components/atoms/button/AButtonLight.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import ALinkSmall from '@/components/atoms/link/ALinkSmall.vue'
import ABaseImage from '@/components/atoms/image/ABaseImage.vue'
import ATextExtra from '@/components/atoms/text/ATextExtra.vue'
import ATextMediumLarge from '@/components/atoms/text/ATextMediumLarge.vue'
import ATextLarge from '@/components/atoms/text/ATextLarge.vue'
//Stores
import { getModule } from 'vuex-module-decorators'
import AuthenticationModule from '@/store/authentication'
//Plugins
import { ValidationObserver } from 'vee-validate'

@Component({
  components: {
    AButtonPrimary,
    AButtonLight,
    AInputTextField,
    ALinkSmall,
    ABaseImage,
    ATextExtra,
    ATextMediumLarge,
    ATextLarge,
    ValidationObserver
  }
})
export default class OForgotPassword extends Vue {
  public authenticationModule = getModule(AuthenticationModule, this.$store)

  public email = ''

  public async resetPassword() {
    await this.authenticationModule.resetPassword(this.email)
    await this.$router.push('/login')
  }
}
