










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AButtonRouter extends Vue {
  @Prop() text!: string
}
