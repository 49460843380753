
























import { Component, Vue } from 'vue-property-decorator'
//Atoms
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonLight from '@/components/atoms/button/AButtonLight.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import ALinkSmall from '@/components/atoms/link/ALinkSmall.vue'
import ABaseImage from '@/components/atoms/image/ABaseImage.vue'
import AInputTextFieldPassword from '@/components/atoms/input/AInputTextFieldPassword.vue'
// Templates
import TCardFluid from '@/components/templates/TCardFluid.vue'

//Stores
import { getModule } from 'vuex-module-decorators'
import AuthenticationModule, {
  RenewPassword,
  ResetPassword
} from '@/store/authentication'
//Plugins
import { ValidationObserver } from 'vee-validate'
import MFormRenewPassword from '@/components/molecules/form/MFormRenewPassword.vue'

@Component({
  components: {
    AButtonPrimary,
    AButtonLight,
    AInputTextField,
    ALinkSmall,
    ABaseImage,
    AInputTextFieldPassword,
    ValidationObserver,
    MFormRenewPassword,
    TCardFluid
  }
})
export default class ORenewPassword extends Vue {
  public authenticationModule = getModule(AuthenticationModule, this.$store)

  public passwords: RenewPassword = {
    password: '',
    confirm: ''
  }

  public async renewPassword() {
    const passwordWithToken: ResetPassword = {
      password: this.passwords.password,
      resetToken: this.$route.params.token
    }
    await this.authenticationModule.renewPassword(passwordWithToken)
    await this.$router.replace('/login')
  }
}
