var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-admin-custom-tracking"},[_c('MTableHeading',{attrs:{"title":_vm.$tc('customTracking.children.keyword', 2)}}),_c('MTable',{attrs:{"headers":_vm.headers,"items":_vm.trackerCampaignId.items,"loading":_vm.loading,"item-per-page":_vm.itemPerPage},scopedSlots:_vm._u([{key:"header.sales",fn:function(){return [_c('div',{staticClass:"secondary--text"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('stats.ca'))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('stats.ca'))+" / "+_vm._s(_vm.$tc('lead.name', 1)))])])]},proxy:true},{key:"header.netSales",fn:function(){return [_c('div',{staticClass:"secondary--text"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('stats.caNet'))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('stats.caNet'))+" / "+_vm._s(_vm.$tc('lead.name', 1)))])])]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.name))])]}},{key:"item.leads",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"grey--text text--darken-3"},[_vm._v(_vm._s(item.leads))])]}},{key:"item.leadsSold",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"grey--text text--darken-3"},[_vm._v(_vm._s(item.leadsSold))])]}},{key:"item.netLeads",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"grey--text text--darken-3"},[_vm._v(_vm._s(item.netLeads))])]}},{key:"item.sales",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"green--text text--accent-4"},[_vm._v(_vm._s(_vm._f("formatPrice")(item.sales)))]),_c('br'),_c('strong',{staticClass:"green--text text--accent-4"},[_vm._v(_vm._s(_vm._f("formatPrice")(item.salesPerLead))+"/lead")])]}},{key:"item.netSales",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"green--text text--accent-4"},[_vm._v(_vm._s(_vm._f("formatPrice")(item.netSales)))]),_c('br'),_c('strong',{staticClass:"green--text text--accent-4"},[_vm._v(_vm._s(_vm._f("formatPrice")(item.netSalesPerLead))+"/lead")])]}}],null,true)}),_c('MTableFooter',{attrs:{"tableProperties":_vm.trackerCampaignId.meta}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }