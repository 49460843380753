var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-provider-statistics"},[_c('MTableHeading',{attrs:{"title":_vm.$tc('statistic.perArea', 2)}}),_c('MTable',{attrs:{"headers":_vm.headers,"items":_vm.providerModule.statistics.areas.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"header.sales",fn:function(){return [_c('div',{staticClass:"secondary--text"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('stats.ca'))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('stats.ca'))+" / "+_vm._s(_vm.$tc('lead.name', 1)))])])]},proxy:true},{key:"header.netSales",fn:function(){return [_c('div',{staticClass:"secondary--text"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('stats.caNet'))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('stats.caNet'))+" / "+_vm._s(_vm.$tc('lead.name', 1)))])])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('MTableActions',{attrs:{"itemId":item.id,"tableActions":_vm.tableActions},on:{"iconAction":function($event){return $event(item)}}})]}},{key:"item.sales",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"green--text text--accent-4 ma-0"},[_vm._v(_vm._s(_vm.$options.filters.formatPrice(item.sales)))]),_c('br'),_c('strong',{staticClass:"green--text text--accent-4 ma-0"},[_vm._v(_vm._s(_vm.$options.filters.formatPrice(item.salesPerLead))+"/lead")])]}},{key:"item.netSales",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"green--text text--accent-4 ma-0"},[_vm._v(_vm._s(_vm.$options.filters.formatPrice(item.netSales)))]),_c('br'),_c('strong',{staticClass:"green--text text--accent-4 ma-0"},[_vm._v(_vm._s(_vm.$options.filters.formatPrice(item.netSalesPerLead))+"/lead")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$options.filters.timestampToDate(item.createdAt))+" ")]}}],null,true)}),_c('MTableFooter',{attrs:{"tableProperties":_vm.providerModule.statistics.areas.meta}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }