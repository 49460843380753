








import { Component, Vue, Prop } from 'vue-property-decorator'
@Component
export default class TSkeleton extends Vue {
  @Prop() loading!: boolean
  @Prop() types!: string
}
