import { render, staticRenderFns } from "./OAdminClientProfil.vue?vue&type=template&id=5011bfaa&scoped=true&"
import script from "./OAdminClientProfil.vue?vue&type=script&lang=ts&"
export * from "./OAdminClientProfil.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5011bfaa",
  null
  
)

export default component.exports