import { Mutation, VuexModule, Module, Action } from 'vuex-module-decorators'
import store from '@/store'
import Vue from 'vue'

export interface ResponseMatchingLogs {
  matchingDate: Date
  steps: MatchingLogs[]
}
export interface MatchingLogs {
  id: number
  name: string
  rejectedOrders: RejectedOrdersMatching[]
}
export interface RejectedOrdersMatching {
  id?: number
  clientName: string
  orderName: string
  reason: string
}

@Module({ namespaced: true, store, name: 'matching' })
export default class MatchingModule extends VuexModule {
  responseMatchingLogs: ResponseMatchingLogs = {
    matchingDate: new Date(),
    steps: []
  }

  @Mutation
  saveAllLogs(responseMatchingLogs: ResponseMatchingLogs) {
    this.responseMatchingLogs = responseMatchingLogs
  }

  @Action({ commit: 'saveAllLogs' })
  async fetchMatchingLogsLeads(leadId: number) {
    const { data } = await Vue.prototype.$http.get(`/matching-logs/${leadId}`)
    return data
  }
}
