









































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ADatePicker from '@/components/atoms/datePicker/ADatePicker.vue'
import ABaseSelect from '@/components/atoms/select/ABaseSelect.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AMultiSelectBase from '@/components/atoms/multiselect/AMultiSelectBase.vue'
import AButtonSmallRound from '@/components/atoms/button/AButtonSmallRound.vue'
import AButtonAndOr from '@/components/atoms/button/AButtonAndOr.vue'
import { AreaKey, FormatedConditionToken } from '@/store/area'
import {
  FormatedOrderCondition,
  GetOperatorResponse,
  IntervalOperatorEnum,
  Operator
} from '@/store/order'
@Component({
  components: {
    ADatePicker,
    ABaseSelect,
    AInputTextField,
    ATitleMedium,
    AMultiSelectBase,
    AButtonSmallRound,
    AButtonAndOr
  }
})
export default class MPatternCondition extends Vue {
  @Prop() operators!: GetOperatorResponse
  @Prop() areaKeys!: AreaKey[]
  //@Prop() condition!: FormatedOrderCondition
  @Prop() choiceCondition!: FormatedOrderCondition
  @Prop() token!: FormatedConditionToken
  @Prop() tokenIndex!: number
  @Prop() conditionIndex!: number
  @Prop() readonly?: boolean

  public intervalOperatorEnum = {
    IN_INTERVAL: IntervalOperatorEnum.IN_INTERVAL,
    NOT_IN_INTERVAL: IntervalOperatorEnum.NOT_IN_INTERVAL,
    AGE_BETWEEN: IntervalOperatorEnum.AGE_BETWEEN
  }

  @Watch('token.operator')
  onOperatorChange() {
    if (this.token.operator !== undefined) {
      const isInterval = (
        Object.values(this.intervalOperatorEnum) as string[]
      ).includes(this.token.operator)
      if (isInterval) {
        this.token.values = [undefined, undefined]
      } else {
        const operatorInputType = this.operatorInputType(this.token)
        this.token.values = operatorInputType === 'ENUM' ? [] : [undefined]
      }
    } else {
      this.token.values = []
    }
  }

  operatorInputType(token: FormatedConditionToken) {
    const areaKey: AreaKey = this.areaKeys.find(
      (ak) => ak.id === token.areaKeyId
    ) as AreaKey
    const operator: Operator = this.operators[areaKey.dataType].find(
      (operator) => operator.operator === token.operator
    ) as Operator
    return operator?.inputDataType
  }
}
