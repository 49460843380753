










import { Component, Prop, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider
  }
})
export default class AInputRadio extends Vue {
  @Prop() label!: string
  @Prop() value!: string
}
