











import { Component, Vue } from 'vue-property-decorator'
import TTable from '@/components/templates/TTable.vue'
import OTableAdminAreas from '../../organisms/admin/OTableAdminAreas.vue'

@Component({
  components: {
    OTableAdminAreas,
    TTable
  }
})
export default class PAdminAreas extends Vue {}
