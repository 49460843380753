import { render, staticRenderFns } from "./OAdminLeadDetail.vue?vue&type=template&id=7a288068&scoped=true&"
import script from "./OAdminLeadDetail.vue?vue&type=script&lang=ts&"
export * from "./OAdminLeadDetail.vue?vue&type=script&lang=ts&"
import style0 from "./OAdminLeadDetail.vue?vue&type=style&index=0&id=7a288068&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a288068",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VDivider,VTreeview})
