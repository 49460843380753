
















import { Component, Vue, PropSync } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import MRadioMenu from '@/components/molecules/radio/MRadioMenu.vue'

@Component({
  components: {
    ATitleMedium,
    AInputTextField,
    MRadioMenu
  }
})
export default class MFormLeadCo extends Vue {
  @PropSync('co-revenu') revenuSynced!: string
  @PropSync('co-contrat') contratSynced!: string
  @PropSync('co-profession') professionSynced!: string

  public radiodata = [
    { label: 'Oui', value: 'radio_1' },
    { label: 'Non', value: 'radio_2' }
  ]

  public atoms = [
    {
      text: this.revenuSynced,
      rules: {
        required: true,
        numeric: true
      },
      label: 'Co-revenu',
      name: 'Co-revenu'
    },
    {
      text: this.contratSynced,
      rules: {
        required: true,
        numeric: true
      },
      label: 'Co-contrat',
      name: 'Co-contrat'
    },
    {
      text: this.professionSynced,
      rules: {
        required: true,
        numeric: true
      },
      label: 'Co-profession',
      name: 'Co-profession'
    }
  ]
}
