var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-admin-users"},[_c('MTableHeading',{attrs:{"title":_vm.$tc('user.name', 2)}},[_c('template',{slot:"btn"},[_c('AButtonPrimary',{staticClass:"ml-4 font-weight-bold",attrs:{"text":_vm.$t('user.actions.new')},nativeOn:{"click":function($event){return _vm.newUser.apply(null, arguments)}}})],1)],2),_c('MTable',{attrs:{"headers":_vm.headers,"items":_vm.userModule.users.items,"loading":_vm.loading},on:{"sort":_vm.sort},scopedSlots:_vm._u([{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.lastName))])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"grey--text text--darken-3 ma-0"},[_vm._v(_vm._s(item.phone))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"grey--text text--darken-3 ma-0"},[_vm._v(_vm._s(item.email))])]}},{key:"item.role.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"grey--text text--darken-3 ma-0"},[_vm._v(" "+_vm._s(_vm.$t(("roles." + (item.role.name))))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('MTableActions',{attrs:{"item":item,"tableActions":_vm.tableActions},on:{"iconAction":function($event){return $event(item)}}})]}}],null,true)}),_c('MTableFooter',{attrs:{"tableProperties":_vm.userModule.users.meta}}),_c('MBaseModal',{ref:"editUsers",attrs:{"modalProps":_vm.modalsummaryEditUser},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('MFormEditAdmin',{attrs:{"editFields":_vm.editUser.editField},on:{"update:editFields":function($event){return _vm.$set(_vm.editUser, "editField", $event)},"update:edit-fields":function($event){return _vm.$set(_vm.editUser, "editField", $event)}}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }