
















































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
//Plugins
import { DataTableHeader } from 'vuetify'
//Stores
import { getModule } from 'vuex-module-decorators'
import { Area } from '@/store/statistic'
import StatisticModule from '@/store/statistic'
//Molecules
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import MixinTables from '@/components/molecules/table/MixinTables.vue'
import MTableFooterFrontSorting from '@/components/molecules/table/MTableFooterFrontSorting.vue'
//Types
import { TableActionsProps } from '@/types'

@Component({
  components: {
    MTableHeading,
    MTableActions,
    MTable,
    MTableFooterFrontSorting
  }
})
export default class OTableAdminStatisticsAreas extends Mixins(MixinTables) {
  public statisticModule = getModule(StatisticModule, this.$store)
  // Thème | NB Lead brut | Lead Net | CA Brut / lead | CA Net / lead | Marge Brut /lead | Marge Net /lead

  public headers: DataTableHeader<Area>[] = [
    {
      text: this.$t('stats.areaTitle') as string,
      value: 'name',
      sortable: false
    },
    {
      text: this.$tc('stats.lead', 1) as string,
      value: 'leads',
      sortable: false
    },
    {
      text: this.$tc('stats.leadNet', 1) as string,
      value: 'leadsSold',
      sortable: false
    },
    { text: this.$t('stats.ca') as string, value: 'sales', sortable: false },
    {
      text: this.$t('stats.caNet') as string,
      value: 'netSales',
      sortable: false
    },
    {
      text: this.$t('stats.marginBrut') as string,
      value: 'margin',
      sortable: false
    },
    {
      text: this.$t('stats.marginNet') as string,
      value: 'netMargin',
      sortable: false
    },
    { text: 'Actions', value: 'actions', sortable: false }
  ]

  get tableActions(): TableActionsProps[] {
    return [
      {
        icon: '$leads',
        action: this.seeLeads,
        tooltip: this.$i18n.tc('lead.actions.show') as string
      },
      {
        icon: '$stats',
        action: this.seeStats,
        tooltip: this.$i18n.tc('statistic.actions.show') as string
      }
    ]
  }

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  async request() {
    this.loading = true
    await this.statisticModule.fetchAllAreas()
    this.loading = false
  }

  public seeLeads(item: Area) {
    this.$router.push({
      path: '/leads',
      query: { area: item.id.toString() }
    })
  }

  public seeStats(item: Area) {
    this.$router.push({
      path: '/dashboard',
      query: { area: item.id.toString() }
    })
  }
}
