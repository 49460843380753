





















import { Component, PropSync, Vue } from 'vue-property-decorator'
import AOverlay from '@/components/atoms/AOverlay.vue'

@Component({
  components: {
    AOverlay
  }
})
export default class MDrawer extends Vue {
  @PropSync('drawer', { type: Boolean }) boolean!: boolean
}
