














import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class AInputTextFieldSearch extends Vue {
  @PropSync('search') searchSynced!: string
}
