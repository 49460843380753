var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block"},[(_vm.onProfilPage)?_c('v-row',[_c('v-col',{staticClass:"d-block",attrs:{"md":"6","cols":"12"}},[_c('AInputTextFieldPassword',{attrs:{"value":_vm.current,"label":_vm.$t('login.currentPassword'),"rules":{
          required_if: _vm.$t('user.password.fields.new')
        }},on:{"update:value":function($event){_vm.current=$event},"updateField":function($event){return _vm.progressValidation($event)},"onClick":function($event){_vm.validationDisplay = true}}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"d-block",attrs:{"md":"6","cols":"12"}},[_c('AInputTextFieldPassword',{attrs:{"value":_vm.pass,"label":_vm.$t('login.newPassword'),"name":_vm.$t('user.password.fields.new'),"rules":{
          regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&]).{12,}$/,
          required_if: _vm.$t('user.password.fields.current')
        },"error-count":0},on:{"update:value":function($event){_vm.pass=$event},"updateField":function($event){return _vm.progressValidation($event)},"onClick":function($event){_vm.validationDisplay = true}}}),_c('v-expand-transition',[(_vm.validationDisplay)?_c('div',[_c('AProgressBar',{staticClass:"mt-n5",attrs:{"value":_vm.valueProgress,"color":_vm.colorProgress,"rounded":"","background-opacity":0.2,"height":6},on:{"update:value":function($event){_vm.valueProgress=$event}}}),_c('ATextSmall',{staticClass:"mt-1",class:(_vm.colorProgress + "--text text--darken-1"),attrs:{"text":_vm.message}}),_c('div',{staticClass:"d-block mt-6"},[_c('v-row',[_c('ATextMedium',{staticClass:"primary--text font-italic font-weight-medium ml-2",attrs:{"text":_vm.$t('user.password.criteria.desc')}})],1),_vm._l((_vm.criteriaArray),function(item,index){return _c('ATextField',{key:index,staticClass:"mb-n6",attrs:{"textField":{
                icon: item.valid ? 'mdi-check-bold' : 'mdi-close',
                iconSize: '14',
                iconStyle: item.valid
                  ? 'green darken-1 white--text ma-n1 py-1 px-1 rounded-circle'
                  : 'red--text white ma-n1 py-1 px-1',
                text: item.text,
                style: 'text-body-2 font-weight-medium'
              }}})})],2)],1):_vm._e()])],1),(!_vm.onNewUser)?_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('AInputTextFieldPassword',{attrs:{"value":_vm.confirmed,"rules":"confirmed:nouveau mot de passe","name":_vm.$t('user.password.fields.confirmed'),"label":_vm.$t('login.signUp.password.confirmation')},on:{"update:value":function($event){_vm.confirmed=$event}}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }