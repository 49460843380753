








import { Component, Vue } from 'vue-property-decorator'
import OLeadSteps from '@/components/organisms/common/OLeadSteps.vue'
import TCardWithStepper from '@/components/templates/TCardWithStepper.vue'

@Component({
  components: {
    TCardWithStepper,
    OLeadSteps
  }
})
export default class PLead extends Vue {}
