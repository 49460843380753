var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-admin-clients"},[_c('MBaseModal',{ref:"modal",attrs:{"modalProps":_vm.modalProps}}),_c('MTableHeading',{attrs:{"title":_vm.$tc('client.name', 2)},scopedSlots:_vm._u([{key:"btn",fn:function(){return [_c('AButtonPrimary',{staticClass:"ml-4 font-weight-bold",attrs:{"text":_vm.$t('client.actions.new')},nativeOn:{"click":function($event){return _vm.newClient.apply(null, arguments)}}})]},proxy:true}])}),_c('MTable',{staticClass:"table-cursor",attrs:{"headers":_vm.headers,"items":_vm.clientModule.clients.items,"loading":_vm.loading},on:{"actionRow":_vm.read,"sort":_vm.sort},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.name))])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"grey--text text--darken-3 ma-0"},[_vm._v(_vm._s(item.phone))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"grey--text text--darken-3 ma-0"},[_vm._v(_vm._s(item.email))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('MTableActions',{attrs:{"itemId":item.id,"tableActions":_vm.tableActions},on:{"iconAction":function($event){return $event(item)}}})]}}],null,true)}),_c('MTableFooter',{attrs:{"tableProperties":_vm.clientModule.clients.meta}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }