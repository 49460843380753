













import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'
//Molecule
import MConditionalFitler from '@/components/molecules/order/MConditionalFilter.vue'
import MCardCustomCondition from '@/components/molecules/order/stepper/MCardCustomCondition.vue'
import MCardProduct from '@/components/molecules/order/stepper/MCardProduct.vue'
import MCardCondition from '@/components/molecules/order/stepper/MCardCondition.vue'
//Interface
import { Area } from '@/store/area'
//Mixin
import MixinOrder from '@/components/molecules/filter/MixinOrder.vue'
import MCardProductRefactor from './stepper/MCardProductRefactor.vue'

@Component({
  components: {
    MConditionalFitler,
    MCardCustomCondition,
    MCardProduct,
    MCardProductRefactor,
    MCardCondition
  }
})
export default class MOrderStepThree extends Mixins(MixinOrder) {
  /**
   * @description area prop return area select at step One
   * @prop {object}
   * @alias Area
   * */
  @Prop() area!: Area
  @Prop() isUpdate?: number
  @PropSync('value') valueSynced!: number | null
}
