












































import { TableProperties } from '@/types'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
//Template
import TSkeleton from '@/components/templates/TSkeleton.vue'
//Store
import { getModule } from 'vuex-module-decorators'
import LoaderModule from '@/store/loader'
@Component({
  components: {
    TSkeleton
  }
})
export default class MTable extends Vue {
  @Prop() items!: unknown[]
  @Prop() headers!: unknown[]
  @Prop() height?: string
  @Prop({ type: Boolean, default: false }) disableSort?: boolean
  @PropSync('sortBy') sortBySynced?: string[] /**Which value sort */
  @PropSync('sortDesc') sortDescSynced?: boolean /** active sort */
  @PropSync('selected') selectedSynced!: unknown[]

  // Type not appropriate. 'Meta' type should be more appropriate here.
  @PropSync('tableProperties') tablePropertiesSynced!: TableProperties

  public loaderModule = getModule(LoaderModule, this.$store)

  getPageCount(pageCount: number) {
    if (!this.tablePropertiesSynced) return
    this.tablePropertiesSynced.totalPages = pageCount
  }

  getItemsPerPage(nbr: number) {
    if (nbr) return nbr
    if (this.tablePropertiesSynced && this.tablePropertiesSynced.itemsPerPage) {
      return this.tablePropertiesSynced.itemsPerPage
    }
    return
  }
  mounted() {
    this.loaderModule.loadingPage(false)
  }
}
