






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ABaseTitle extends Vue {
  @Prop({ type: String, required: true }) text!: string
  @Prop({ type: String, required: true }) tag!: string
}
