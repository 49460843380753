















import { Component, PropSync, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { VTextarea } from 'vuetify/lib/components'

@Component({
  components: {
    ValidationProvider
  }
})
export default class ABaseTextArea extends VTextarea {
  @PropSync('value', { type: String }) valueSynced!: string
  @Prop() rows!: number
}
