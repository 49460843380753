































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import ATextMedium from '@/components/atoms/text/ATextMedium.vue'
import MDisableTextField from '@/components/molecules/MDisableTextField.vue'
import MListVariables from '@/components/molecules/list/MListVariables.vue'
import MPayload from '@/components/molecules/MPayload.vue'
import MCards from '@/components/molecules/MCards.vue'

@Component({
  components: {
    AInputTextField,
    ATextMedium,
    MDisableTextField,
    MListVariables,
    MPayload,
    MCards
  }
})
export default class MWebhookStepThree extends Vue {
  @Prop() verbs!: { id: string; displayName: string }
  @PropSync('type') typeSynced!: string
  @PropSync('url') urlSynced!: string
  @Prop() variables!: { name: string; displayName: string }[]
}
