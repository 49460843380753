



























































































//Atoms
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AMenu from '@/components/atoms/AMenu.vue'
import ATextMedium from '@/components/atoms/text/ATextMedium.vue'
import ABaseSnackBar from '@/components/atoms/snackbar/ABaseSnackBar.vue'
// Molecules
import ADatePickerManualInvoice from '@/components/atoms/datePicker/ADatePickerManualInvoice.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import ManualInvoiceModule, {
  ManualInvoiceSummaryResponseDto
} from '@/store/manualInvoice'
import MixinTables from '@/components/molecules/table/MixinTables.vue'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableEditDialog from '@/components/molecules/table/MTableEditDialog.vue'
import MTableProgressCircular from '@/components/molecules/table/MTableProgressCircular.vue'
//Plugins and Types
import { DataTableHeader } from 'vuetify'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { NestApiResponse } from '@/types'
import { Component, Mixins, Watch } from 'vue-property-decorator'
//Store
import { getModule } from 'vuex-module-decorators'
import NotificationModule from '@/store/notification'
import UserModule from '@/store/user'

@Component({
  components: {
    ADatePickerManualInvoice,
    MTableHeading,
    MTable,
    MTableActions,
    MTableFooter,
    MTableEditDialog,
    AButtonPrimary,
    ValidationProvider,
    ValidationObserver,
    MTableProgressCircular,
    AMenu,
    ATextMedium,
    ABaseSnackBar
  }
})
export default class OAdminClientTableManualInvoices extends Mixins(
  MixinTables
) {
  public manualInvoicesModule = getModule(ManualInvoiceModule, this.$store)
  public notificationModule = getModule(NotificationModule, this.$store)
  // eslint-disable-next-line prettier/prettier
  public manualInvoices: null | NestApiResponse<ManualInvoiceSummaryResponseDto> = null
  public userModule = getModule(UserModule, this.$store)

  public headers: DataTableHeader<ManualInvoiceSummaryResponseDto>[] = [
    { text: 'Période de facturation', value: 'invoicePeriod' },
    {
      text: 'Date recepetion Email Appel à facture',
      value: 'billingDate',
      sortable: false
    },
    // { text: 'Statut', value: 'invoiceStatus' },
    // { text: 'Numéro de facture', value: 'invoiceNo' },
    { text: 'Montant livré', value: 'issuedAmt', sortable: false },
    { text: 'Montant facturé', value: 'invoiceAmt', sortable: false },
    { text: 'Montant contesté', value: 'contestationAmt', sortable: false },
    {
      text: 'Taux de contestation',
      value: 'contestationPercent',
      sortable: false
    }
  ]

  public dateBilling = ''
  @Watch('companyManualInvoices.billingDate', { immediate: true })
  async changeDate() {
    await this.request()
  }
  @Watch('$route', { immediate: true })
  async onRouteChanges() {
    await this.request()
  }

  async request() {
    this.loading = true
    // eslint-disable-next-line prettier/prettier
    this.manualInvoices = await this.manualInvoicesModule.fetchManualInvoicesByClientId(+this.$route.params.clientId)
    this.userModule.me()
    this.loadDataFromState()
    this.loading = false
  }
  get role() {
    return this.userModule.currentUser!.role
  }

  async editContestationWithPercent(
    percent: number,
    manualInvoiceId: string,
    billingDate: Date | null
  ) {
    const contestationPercentRatio = percent / 100
    await this.editManualInvoice({
      manualInvoiceId,
      contestationPercentRatio,
      billingDate
    })
  }

  async editContestationWithInvoiceAmt(
    index: number,
    invalid: boolean,
    invoiceAmt: number,
    manualInvoiceId: string,
    issuedAmt: number,
    billingDate: Date | null
  ) {
    if (invalid) {
      this.loadDataFromState()
      return
    }
    const invoiceState = this.invoiceState(invoiceAmt, issuedAmt)
    this.setUpdateStatus(index, true)
    await this.editManualInvoice({ manualInvoiceId, invoiceAmt, billingDate })
    this.setUpdateStatus(index, false)
    this.displayNotification(invoiceState)
  }

  /**
   * @function displayNotification
   * @description Dsiplay the right snack bar according the invoice state depending on the invoice percent
   */
  public displayNotification(invoiceState: string | undefined) {
    switch (invoiceState) {
      case 'underInvoice':
        this.notificationModule.save({
          color: 'error',
          message: this.$t('invoice.messages.underInvoicing') as string,
          show: true,
          persist: false,
          timeout: 5000
        })
        break
      case 'qualityInvoice':
        this.notificationModule.save({
          color: 'green',
          message: this.$t('invoice.messages.qualityInvoice') as string,
          show: true,
          persist: false,
          timeout: 5000
        })
        break
      case 'perfect':
        this.notificationModule.save({
          color: 'green',
          message: this.$t('invoice.messages.perfectInvoice') as string,
          show: true,
          persist: false,
          timeout: 5000
        })
        break
      case 'watchInvoice':
        this.notificationModule.save({
          color: 'yellow',
          message: this.$t('invoice.messages.highInvoice') as string,
          show: true,
          persist: false,
          timeout: 5000
        })
        break
      case 'overInvoice':
        this.notificationModule.save({
          color: 'error',
          message: this.$t('invoice.messages.overInvoicing') as string,
          show: true,
          persist: false,
          timeout: 5000
        })
        break

      default:
        break
    }
  }

  /**
   * @function invoiceState
   * @description return a state according the invoice percent
   */
  public invoiceState(invoiceAmt: number, issuedAmt: number) {
    let invoicePercent = 0
    invoicePercent = Math.round((100 * invoiceAmt) / issuedAmt)

    if (invoicePercent < 90) {
      return 'underInvoice'
    } else if (invoicePercent >= 90 && invoicePercent <= 95) {
      return 'qualityInvoice'
    } else if (invoicePercent > 95 && invoicePercent <= 100) {
      return 'perfect'
    } else if (invoicePercent > 100 && invoicePercent <= 150) {
      return 'watchInvoice'
    } else if (invoicePercent > 150) {
      return 'overInvoice'
    }
  }

  async editManualInvoice({
    manualInvoiceId,
    contestationPercentRatio,
    invoiceAmt,
    billingDate
  }: {
    manualInvoiceId: string
    contestationPercentRatio?: number
    invoiceAmt?: number
    billingDate?: Date | null
  }) {
    const dateOfDay = this.formatStringToDate(new Date().toDateString())
    await this.manualInvoicesModule.updateManualInvoice({
      id: manualInvoiceId,
      body: {
        ...this.createInvoiceAmt(invoiceAmt),
        contestationPercentRatio,
        billingDate: billingDate === null ? dateOfDay : billingDate
      }
    })
    this.loadDataFromState()
  }

  loadDataFromState() {
    this.manualInvoices = this.manualInvoicesModule.companyManualInvoices
  }

  setUpdateStatus(idx: number, status: boolean) {
    this.manualInvoices!.items[idx].isLoading = status
  }

  createInvoiceAmt(invoiceAmt: number | undefined) {
    return invoiceAmt ? { invoiceAmt: +invoiceAmt } : undefined
  }

  beforeDestroy() {
    this.manualInvoices = null
    this.manualInvoicesModule.destroy()
  }
  /**
   * @function saveDate
   * @description send at api new date
   */
  public saveDate(date: string, id: string, invoiceAmt?: number) {
    const billingDate = this.formatStringToDate(date)
    this.manualInvoicesModule.updateManualInvoice({
      body: {
        ...this.createInvoiceAmt(invoiceAmt),
        billingDate
      },
      id
    })
  }
}
