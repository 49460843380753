import { render, staticRenderFns } from "./ABaseImage.vue?vue&type=template&id=f27b8160&scoped=true&"
import script from "./ABaseImage.vue?vue&type=script&lang=ts&"
export * from "./ABaseImage.vue?vue&type=script&lang=ts&"
import style0 from "./ABaseImage.vue?vue&type=style&index=0&id=f27b8160&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f27b8160",
  null
  
)

export default component.exports