import { AbilityBuilder, Ability } from '@casl/ability'
import { CurrentUser, Roles } from '@/store/user'

export default function defineAbilityFor(currentUser: CurrentUser | null) {
  const { can, rules } = new AbilityBuilder<Ability>()
  if (!currentUser) {
    console.log('IS GUEST')
  } else if (currentUser) {
    can('see', 'Sidebar')
    can('read', 'Dashboard')
    can('read', 'Logout')
    can('read', 'NotFound')

    if (currentUser.role === Roles.SUPER) {
      console.log('IS ADMIN')
      can('show', 'SidebarLinks', { role: Roles.SUPER }) // Show provider links
      can('show', 'MenuLinks', { role: Roles.SUPER }) // show menu top page
      can('read', 'Roles')
      can('read', 'StatisticProvider')
      can('read', 'StatisticArea')
      can('read', 'StatisticClient')
      can('read', 'StatisticSitekey')
      can('read', 'Lead')
      can('read', 'LeadsRejected')
      can('read', 'Client')
      can('read', 'ClientOrderNew')
      can('read', 'ClientOrderEdit')
      can('read', 'ClientWebhookNew')
      can('read', 'ClientWebhookEdit')
      can('read', 'Clients')
      can('read', 'Providers')
      can('read', 'Provider')
      can('read', 'Transactions')
      can('read', 'Rgpd')
      can('manage', 'Profil')
      can('read', 'Users')
      can('read', 'Areas')
      can('read', 'Sales')
      can('read', 'ActionsRgpd')
      can('delete', 'Users', {
        role: { $in: [Roles.ADMIN, Roles.OPERATOR] }
      })
      can('create', 'Roles', {
        role: { $in: [Roles.SUPER, Roles.ADMIN, Roles.OPERATOR] }
      })
      can('manage', 'Order')
      can('show', 'Rating')
    } else if (currentUser.role === Roles.ADMIN) {
      can('show', 'SidebarLinks', { role: Roles.SUPER }) // Show provider links
      can('show', 'MenuLinks', { role: Roles.SUPER }) //show menu top page
      can('read', 'Roles')
      can('read', 'StatisticProvider')
      can('read', 'StatisticArea')
      can('read', 'StatisticClient')
      can('read', 'StatisticSitekey')
      can('read', 'Lead')
      can('read', 'LeadsRejected')
      can('read', 'Client')
      can('read', 'ClientOrderNew')
      can('read', 'ClientOrderEdit')
      can('read', 'ClientWebhookNew')
      can('read', 'ClientWebhookEdit')
      can('read', 'Clients')
      can('read', 'Providers')
      can('read', 'Provider')
      can('read', 'Transactions')
      can('manage', 'Profil')
      can('read', 'Rgpd')
      can('read', 'Users')
      can('read', 'Areas')
      can('read', 'Sales')
      can('read', 'ActionsRgpd')
      can('delete', 'Users', {
        role: { $in: [Roles.OPERATOR] }
      })
      can('create', 'Roles', {
        role: { $in: [Roles.ADMIN, Roles.OPERATOR] }
      })
      can('manage', 'Order')
      can('show', 'Rating')
    } else if (currentUser.role === Roles.CLIENT) {
      console.log('IS CLIENT')
      can('read', 'Support')
      can('show', 'SidebarLinks', { role: Roles.CLIENT }) // Show client links
      can('show', 'MenuLinks', { role: Roles.CLIENT }) // show menu top page
      can('show', 'Lead')
      can('export', 'Lead')
      // Routes
      can('read', 'Order')
      can('manage', 'Profil')
      can('read', 'Lead')
      //can('read', 'StripeSetup')
      can('show', 'Rating')
    } else if (currentUser.role === Roles.PROVIDER) {
      console.log('IS PROVIDER')
      can('show', 'SidebarLinks', { role: Roles.PROVIDER }) // Show provider links
      can('show', 'MenuLinks', { role: Roles.PROVIDER }) //show menu top page
      // Routes
      can('manage', 'Profil')
      can('read', 'Lead')
      can('read', 'Sitekeys')
      can('read', 'Support')
      can('read', 'StatisticProvider')
      can('read', 'StatisticArea')
      can('read', 'StatisticClient')
      can('read', 'StatisticSitekey')
      can('read', 'Earnings')
    } else if (currentUser.role === Roles.OPERATOR) {
      console.log('IS OPERATOR')
      can('show', 'SidebarLinks', { role: Roles.OPERATOR }) // Show provider links
      can('show', 'MenuLinks', { role: Roles.OPERATOR }) //show menu top page
      can('edit', 'Lead')
      // Routes
      can('read', 'Lead')
    }
  }

  return rules
}
