






































import { Component, Prop, PropSync } from 'vue-property-decorator'
//Atoms
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonSecondary from '@/components/atoms/button/AButtonSecondary.vue'
import MixinDates from '@/components/molecules/filter/MixinDates.vue'
@Component({
  components: {
    AButtonPrimary,
    AButtonSecondary
  }
})
export default class ADatePickerManualInvoice extends MixinDates {
  @Prop() label!: string
  @PropSync('value') valueSynced!: string | Date
  @Prop() message!: string
  public menu: boolean | null = null
}
