import { render, staticRenderFns } from "./OAdminTableLeadsRejected.vue?vue&type=template&id=3b957f82&scoped=true&"
import script from "./OAdminTableLeadsRejected.vue?vue&type=script&lang=ts&"
export * from "./OAdminTableLeadsRejected.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b957f82",
  null
  
)

export default component.exports