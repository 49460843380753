



















































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import { SitekeyFields } from '@/store/sitekey'
import ABaseSelect from '../../atoms/select/ABaseSelect.vue'
import ATitleMedium from '../../atoms/title/ATitleMedium.vue'
import AIconAction from '../../atoms/AIconAction.vue'
import ATextMedium from '../../atoms/text/ATextMedium.vue'

@Component({
  components: {
    AInputTextField,
    ABaseSelect,
    ATitleMedium,
    AIconAction,
    ATextMedium
  }
})
export default class MFormSitekey extends Vue {
  @PropSync('sitekeyFields') sitekeyFieldsSynced!: SitekeyFields
  @Prop({ default: false }) isUpdate!: boolean
  @Prop() clients!: boolean

  public conversionPanel = false
  public fluxPanel = false

  mounted() {
    this.conversionPanel = this.isUpdate
  }

  expandPanelFlux() {
    this.fluxPanel = !this.fluxPanel
  }

  expandPanel() {
    this.conversionPanel = !this.conversionPanel
    this.sitekeyFieldsSynced = {
      name: this.sitekeyFieldsSynced.name,
      providerId: this.sitekeyFieldsSynced.providerId,
      conversionName: this.conversionPanel ? '' : null,
      conversionType: this.conversionPanel ? '' : null,
      customerId: this.conversionPanel ? '' : null
    }
  }
}
