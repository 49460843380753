











import { Component, Vue, Watch } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AChartLine from '@/components/atoms/chart/AChartLine.vue'
import AChartPie from '@/components/atoms/chart/AChartPie.vue'
import { getModule } from 'vuex-module-decorators'
import ProviderModule from '@/store/provider'

@Component({
  components: {
    ATitleMedium,
    AChartLine,
    AChartPie
  }
})
export default class OAdminProviderDashboardStatistics extends Vue {
  public providerModule = getModule(ProviderModule, this.$store)

  public lineSeries = [
    {
      name: 'Leads vendus',
      color: '#1E90FF',
      marker: {
        symbol: 'triangle'
      },
      data: []
    },
    {
      name: "Chiffre d'affaires brut",
      color: '#DC1616',
      marker: {
        symbol: 'circle'
      },
      tooltip: {
        valueSuffix: '€'
      },
      data: []
    }
  ]

  @Watch('providerModule.dashboard')
  onDashboardReady() {
    this.updateChartLine()
    this.updateChartsPie()
  }

  private updateChartsPie() {
    const chartPieOne = this.$children[2].$children[0] as any
    const chartPieTwo = this.$children[3].$children[0] as any

    chartPieOne.chart.series[0].setData(
      this.providerModule.dashboard?.areas.items
    )

    chartPieTwo.chart.series[0].setData(
      this.providerModule.dashboard?.sitekeys.items
    )
  }

  private updateChartLine() {
    const chartLine = this.$children[1].$children[0] as any

    chartLine.chart.xAxis[0].setCategories(
      this.providerModule.dashboard?.stats.dates.map((date) =>
        Vue.prototype.$dayjs(date).format('DD/MM/YYYY')
      )
    )

    chartLine.chart.series[0].setData(
      this.providerModule.dashboard?.stats.leadsSold
    )

    chartLine.chart.series[1].setData(
      this.providerModule.dashboard?.stats.sales
    )
  }
}
