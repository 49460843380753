var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-table-reminders section white"},[_c('MTableHeading',{attrs:{"title":_vm.$tc('orderItems.titleDashboard'),"disableSearch":true}}),_c('MTable',{attrs:{"headers":_vm.headers,"items":_vm.orderModule.ordersDashboard,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('ACheckboxSwitchOrderStatus',{attrs:{"order":item},on:{"switched":_vm.updateOrderStatus}})]}},{key:"item.customValueIcon",fn:function(ref){
var item = ref.item;
return [_c(_vm.orderType(item),{tag:"component"})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"green--text text--accent-4 ma-0"},[_vm._v(_vm._s(_vm._f("formatPrice")(item.price)))])]}},{key:"item.weight",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.weight))])]}},{key:"item.capping",fn:function(ref){
var item = ref.item;
return [_c('p',{domProps:{"textContent":_vm._s(
          item.capping <= 0
            ? _vm.$tc('order.capping', 1)
            : _vm.$tc('order.capping', 2, { capping: item.capping })
        )}})]}},{key:"item.cappingMax",fn:function(ref){
        var item = ref.item;
return [_c('p',{domProps:{"textContent":_vm._s(
          item.cappingMax <= 0
            ? _vm.$tc('order.capping', 1)
            : _vm.$tc('order.capping', 2, { capping: item.cappingMax })
        )}})]}},{key:"item.endAt",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"grey--text ma-0"},[_vm._v(" "+_vm._s(_vm.$options.filters.timestampToDateTime(item.endAt))+" ")])]}}],null,true)}),_c('MTableFooter')],1)}
var staticRenderFns = []

export { render, staticRenderFns }