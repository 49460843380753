import Vue from 'vue'
import { ArrayToMap } from '@/types'
import { LeadStatus } from '@/store/lead'
import { i18n } from '@/plugins/i18n'
import { Lead } from '@/store/client'

export interface LeadTableStatus {
  icon: string
  text: string
  class: string
}

Vue.filter('duration', function (startAt: number, endAt: number, suffix = ''):
  | string
  | number {
  if (!endAt) return 'Illimité'

  const begin = Vue.prototype.$dayjs.unix(startAt).format('YYYY/MM/DD')
  const end = Vue.prototype.$dayjs.unix(endAt).format('YYYY/MM/DD')

  let duration = Vue.prototype
    .$dayjs(end)
    .diff(Vue.prototype.$dayjs(begin), 'day')

  if (duration < 0) {
    duration = 0
  }

  return `${duration} ${suffix}`
})

Vue.filter('timestampToFullDate', function (timestamp: number):
  | string
  | number {
  if (!timestamp) return ''
  const fullDate = Vue.prototype
    .$dayjs(timestamp)
    .format('dddd DD MMMM YYYY à HH:mm')
  return fullDate
})

Vue.filter('timestampToDate', function (timestamp: number): string | number {
  if (!timestamp) return ''
  const dateTime = Vue.prototype.$dayjs(timestamp).format('DD/MM/YYYY')
  return dateTime.charAt(0).toUpperCase() + dateTime.slice(1)
})

Vue.filter('timestampToDateTime', function (timestamp: number):
  | string
  | number {
  if (!timestamp) return ''
  const dateTime = Vue.prototype.$dayjs(timestamp).format('DD/MM/YYYY HH:mm')
  return dateTime.charAt(0).toUpperCase() + dateTime.slice(1)
})

Vue.filter('displayStatus', function (lead: Lead): string {
  if (!lead) return ''

  const translations = {
    converted: 'converti',
    called: 'contacté',
    declined: 'refusé',
    appointment: 'rdv',
    tcl: 'différé'
  }

  if (lead && lead.status && lead.status !== 'UNREAD') {
    return (translations as ArrayToMap)[lead.status.toLowerCase()]
  } else if ((lead.status as string) === 'SOLD_EXCLU') return 'exclusif'

  return 'mutualisé'
})

Vue.filter('dateToTime', function (date: number): string {
  if (!date) return ''
  return Vue.prototype.$dayjs(date).format('HH:mm')
})

Vue.filter('price', function (price: number): string {
  if (!price) return '0 €'
  return `${price} €`
})

// EVERYTHING UNDER IS NEW FILTER
Vue.filter('formatPrice', function (price: number): string | undefined {
  const formattedPrice = new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(price)

  return `${formattedPrice} €`
})

Vue.filter('periodAsDays', function (startAt: number, endAt: number): number {
  const begin = Vue.prototype.$dayjs.unix(startAt).format('YYYY/MM/DD')
  const end = Vue.prototype.$dayjs.unix(endAt).format('YYYY/MM/DD')

  return Vue.prototype.$dayjs(end).diff(Vue.prototype.$dayjs(begin), 'day')
})

Vue.filter('diff', function (startAt: number, endAt: number, suffix = ''):
  | string
  | number {
  let diff = Vue.prototype
    .$dayjs(endAt)
    .diff(Vue.prototype.$dayjs(startAt), 'day')

  if (diff < 0) {
    diff = 0
  }

  return `${diff} ${suffix}`
})

Vue.filter('numberWithSpaces', function (nbr: number): string {
  if (!nbr) return '0'
  return nbr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
})

Vue.filter('leadStatusIcon', function (status: string): string {
  if (status === LeadStatus.SOLD_MUTU) return '$mutu'
  if (status === LeadStatus.SOLD_EXCLU) return '$exclu'
  return ''
})

Vue.filter('translateWebhookType', function (type: string): string {
  let translation = 'webhook.types.global'
  switch (type) {
    case 'ORDER':
      translation = 'webhook.types.order'
      break
    case 'CLIENT_AREA':
      translation = 'webhook.types.theme'
      break
  }
  return translation
})

Vue.filter('capitalize', function (str: string): string {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
})

Vue.filter('camelCaseToKebabCase', function (str: string): string {
  return str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? '-' : '') + $.toLowerCase()
  )
})

Vue.filter('leadTableStatus', function (str: string): LeadTableStatus {
  const leadTableStatus: LeadTableStatus = {
    icon: '',
    text: '',
    class: ''
  }
  const mutu = str == LeadStatus.SOLD_MUTU
  const exclu = str == LeadStatus.SOLD_EXCLU
  /**
   * return class in function at text
   */
  switch (str) {
    case 'MATCHING' || 'QUALIFIED' || 'READY_FOR_SALE' || 'NEW':
      leadTableStatus.class = 'grey--text d-inline'
      break
    case 'WAITING_FOR_ANONYMISE':
      leadTableStatus.class = 'grey--text d-inline'
      break
    case 'ANONYMISED' || 'OUT_OF_TARGET' || 'TEST_REJECTED':
      leadTableStatus.class = 'red--text d-inline'
      break
    default:
      leadTableStatus.class = 'primary--text text--accent-3 d-inline'
      break
  }
  if (mutu || exclu) {
    leadTableStatus.icon = '$sold'
    mutu
      ? (leadTableStatus.text = `${i18n.tc('lead.status.SOLD_MUTU', 2)}`)
      : (leadTableStatus.text = `${i18n.tc('lead.status.SOLD_EXCLU', 2)}`)
  } else {
    leadTableStatus.icon = '$unsold'
    leadTableStatus.text = `${i18n.t(`lead.status.${str}`)}` //fetch i18N for translate text value
  }
  return leadTableStatus
})

Vue.filter('percentage', function (nbr: number): string {
  if (nbr === -1) return ''
  return `${nbr} %`
})

Vue.filter('unlimited', function (nbr: number): string {
  if (nbr === -1) return 'Illimité'
  return `${nbr}`
})

Vue.filter('aiconNumber', function (shareLimit: number) {
  switch (shareLimit) {
    case 1:
      return 'AiconNumberOne'
    case 2:
      return 'AiconNumberTwo'
    case 3:
      return 'AiconNumberThree'
    case 4:
      return 'AiconNumberFour'
  }
})
