import { render, staticRenderFns } from "./AIconNextEnd.vue?vue&type=template&id=05641021&scoped=true&"
import script from "./AIconNextEnd.vue?vue&type=script&lang=ts&"
export * from "./AIconNextEnd.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05641021",
  null
  
)

export default component.exports