















import { Component, Vue, PropSync } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'

@Component({
  components: {
    ATitleMedium,
    AInputTextField
  }
})
export default class MFormLeadConso extends Vue {
  @PropSync('nb-conso') consoSynced!: string
  @PropSync('price') priceSynced!: string

  public atoms = [
    {
      text: this.consoSynced,
      rules: {
        required: true,
        numeric: true
      },
      label: 'Nombre de crédit conso',
      name: 'Nombre de crédit conso'
    },
    {
      text: this.priceSynced,
      rules: {
        required: true,
        numeric: true
      },
      label: 'Remboursement mensuel conso',
      name: 'Remboursement mensuel conso'
    }
  ]
}
