





























































































import { Component, Vue, PropSync } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import MRadioMenu from '@/components/molecules/radio/MRadioMenu.vue'
// import AFilterSelectSiteKey from '@/components/atoms/select/AFilterSelectSiteKey.vue'
import ABaseSelect from '@/components/atoms/select/ABaseSelect.vue'

@Component({
  components: {
    ATitleMedium,
    AInputTextField,
    MRadioMenu,
    ABaseSelect
    // AFilterSelectSiteKey
  }
})
export default class MFormLeadGeneral extends Vue {
  @PropSync('birth') birthSynced!: string
  @PropSync('address') addressSynced!: string
  @PropSync('zip') zipSynced!: string
  @PropSync('nbChild') childrenSynced!: string
  @PropSync('details') deatilsSynced!: string

  public radiodata = [
    { label: 'Mme', value: 'radio_1' },
    { label: 'M.', value: 'radio_2' }
  ]

  public data = ''
  public city = ''

  public items = [
    { name: 'Célibataire', id: 1 },
    { name: 'Marié', id: 2 }
  ]
  public city_items = [
    { name: 'Paris', id: 1 },
    { name: 'Lyon', id: 2 },
    { name: 'Bordeaux', id: 3 }
  ]

  public data_general_info_2 = [
    {
      text: this.addressSynced,
      rules: 'required',
      label: 'Adresse',
      name: 'Adresse'
    },
    {
      text: this.zipSynced,
      rules: {
        required: true,
        digits: 5
      },
      label: 'Code Postal',
      name: 'Code Postal'
    },
    {
      text: this.deatilsSynced,
      rules: 'required',
      label: 'Détails',
      name: 'Détails'
    }
  ]
}
