




import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ADisableLabel extends Vue {
  @Prop() text!: string
}
