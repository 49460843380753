






























import { Component, Vue } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import MFormCreateAdminUser from '@/components/molecules/form/MFormCreateAdminUser.vue'
import { ValidationObserver } from 'vee-validate'
import UserModule, { SignUp } from '@/store/user'
import { getModule } from 'vuex-module-decorators'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonLight from '@/components/atoms/button/AButtonLight.vue'
//Plug-in
import PhoneNumber from 'awesome-phonenumber'

@Component({
  components: {
    ATitleMedium,
    MFormCreateAdminUser,
    ValidationObserver,
    AButtonPrimary,
    AButtonLight
  }
})
export default class OAdminUserNew extends Vue {
  private userModule = getModule(UserModule, this.$store)

  public userFields: SignUp = {
    firstName: '',
    lastName: '',
    phone: '',
    email: ''
  }

  public async submit() {
    //Transform phone number in +33 (internatianal)
    const phone = new PhoneNumber(this.userFields.phone, 'FR').getNumber()
    this.userFields.phone = phone
    await this.userModule.createAdminUser(this.userFields)
    this.$router.push({ path: '/utilisateurs' })
  }
}
