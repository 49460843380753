











import { Component, Prop, Vue } from 'vue-property-decorator'
import MModalForm from '@/components/molecules/modal/MModalForm.vue'
import { ModalProps } from '@/components/molecules/modal/MBaseModal.vue'
import MFormExportCsv from '@/components/molecules/form/MFormExportCsv.vue'
import { getModule } from 'vuex-module-decorators'
import ClientModule from '@/store/client'
import UserModule from '@/store/user'
import FilterModule from '@/store/filter'
import CsvModule from '@/store/csv'

@Component({
  components: {
    MModalForm,
    MFormExportCsv
  }
})
export default class OClientLeadExport extends Vue {
  public clientModule = getModule(ClientModule, this.$store)
  public userModule = getModule(UserModule, this.$store)
  public filterModule = getModule(FilterModule, this.$store)
  public csvModule = getModule(CsvModule, this.$store)

  public exportGroup = 1
  @Prop() selected!: number[]

  public get modalProps(): ModalProps {
    return {
      title: this.$tc('lead.export'),
      actionText: this.$tc('lead.actions.export'),
      modalAction: this.exportLeads,
      redirect: 'leads'
    }
  }

  exportLeads() {
    let exportData = null

    switch (this.exportGroup) {
      case 0:
        exportData = {
          leads: this.selected
        }
        break
      case 1:
        break
      case 2:
        exportData = {
          leads: this.selected,
          alreadyExportedLeads: false
        }
        break
      case 3:
        exportData = {
          alreadyExportedLeads: false
        }
        break
      default:
        break
    }

    this.csvModule.downloadClientLeads({
      clientId: +this.userModule.currentUser!.clientId!,
      data: exportData as any
    })
  }
}
