








































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import ATitleSmall from '@/components/atoms/title/ATitleSmall.vue'
import ADisableLabel from '@/components/atoms/ADisableLabel.vue'
import ABaseTextArea from '@/components/atoms/textarea/ABaseTextArea.vue'
import ACheckbox from '@/components/atoms/checkbox/ACheckbox.vue'

import MDisableTextField from '@/components/molecules/MDisableTextField.vue'
import MMark from '@/components/molecules/MMark.vue'
import MNoteEditable from '@/components/molecules/MNoteEditable.vue'

import { LeadProperty } from '@/store/lead'
import ClientModule, { OrderLeadStatusEnum } from '@/store/client'
import UserModule from '@/store/user'

@Component({
  components: {
    ADisableLabel,
    ATitleMedium,
    ATitleSmall,
    ABaseTextArea,
    MDisableTextField,
    MMark,
    MNoteEditable,
    ACheckbox
  }
})
export default class OClientLeadDetail extends Vue {
  //Todo check price of to sell with back
  public clientModule = getModule(ClientModule, this.$store)
  public userModule = getModule(UserModule, this.$store)

  public clientId = this.userModule.currentUser!.clientId
  // eslint-disable-next-line @typescript-eslint/ban-types
  public filters = this.$options.filters as { [key: string]: Function }
  public noSpamClick = false

  @Watch('$route.params', { immediate: true })
  onRouteChanges() {
    this.request()
  }
  public leadStatusCall: boolean | null = null

  get falseValue() {
    return OrderLeadStatusEnum.UNREAD
  }

  get trueValue() {
    return OrderLeadStatusEnum.READ
  }

  get lead() {
    return this.clientModule.leadDetail
  }

  private async request() {
    const leadId = +this.$route.params.id
    if (leadId) {
      await this.clientModule.fetchOneLead({
        clientId: +this.clientId!,
        leadId: leadId
      })
    }
  }
  created() {
    this.leadStatusCall =
      this.clientModule.leadDetail!.orderLeadStatus === OrderLeadStatusEnum.READ
        ? true
        : false
  }

  /**
   * @function saveOrderLeadStatus
   * @description call the request to update the status and to avoid spam click the button is disable during few seconds.
   */
  saveOrderLeadStatus() {
    this.noSpamClick = true
    setTimeout(() => (this.noSpamClick = false), 5000)
    const leadId = +this.$route.params.id
    this.clientModule.updateClientStatusLead({
      clientId: +this.clientId!,
      leadId: leadId,
      status: this.leadStatusCall
        ? OrderLeadStatusEnum.READ
        : OrderLeadStatusEnum.UNREAD
    })
  }

  formatLeadProperty(lp: LeadProperty) {
    // TODO: Json trad
    if (lp.name === 'DOB') return this.filters.timestampToDate(lp.value)
    return lp.value
  }

  /**
   * @function saveComment
   * @description call the request to update the comment.
   */
  saveComment(event: string | null) {
    const leadId = +this.$route.params.id
    event === '' ? (event = null) : null
    this.clientModule.updateClientComment({
      clientId: +this.clientId!,
      leadId: leadId,
      comment: event
    })
  }
}
