



























import AInputTextFieldRefacto from '@/components/atoms/input/AInputTextFieldRefacto.vue'

import { Component, Prop, PropSync } from 'vue-property-decorator'
import { VEditDialog } from 'vuetify/lib'

@Component({
  components: {
    AInputTextFieldRefacto
  }
})
export default class MTableEditDialog extends VEditDialog {
  @PropSync('item') itemSynced!: any
  @Prop({ type: Boolean }) formatPrice!: boolean
  @Prop({ type: Boolean }) percent!: boolean
  @Prop({ type: Boolean }) invalid!: boolean

  @Prop({ type: String }) name!: string
  @Prop() rules!: string
}
