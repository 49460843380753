import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import store from '@/store'
import router from '@/router'
import NotificationModule, {
  SnackBar,
  SnackBarHandler
} from '@/store/notification'
import { getModule } from 'vuex-module-decorators'
import AuthenticationModule from '@/store/authentication'
import FilterModule from '@/store/filter'

function handleSuccess(success: AxiosResponse): AxiosResponse {
  if (success.config.successHandler) {
    const successNotification: SnackBar = {
      color: 'green',
      timeout:
        (success.config.successHandler as SnackBarHandler)?.timeout || 5000,
      message:
        (success.config.successHandler as SnackBarHandler)?.message ||
        success.data.message ||
        "L'action a réussi",
      show: true
    }

    store.dispatch('notification/add', successNotification)
  }

  return success
}

async function handleError(error: AxiosError) {
  const authenticationModule = getModule(AuthenticationModule, store)
  const notificationModule = getModule(NotificationModule, store)

  if (error) {
    if (error.response && error.config.url !== '/auth/login') {
      if (error.config.url === '/auth/me' && error.response.status === 401) {
        try {
          //if unhotorized fetch new token
          await authenticationModule.renewToken()
          //fetch error and hidden notification
          const errorNotification: SnackBar = {
            color: 'error',
            message: 'Pas authorisé',
            show: false
          }
          notificationModule.add(errorNotification)
        } catch (_error) {
          router.push('/logout')
          console.error(_error)
        }
      }
    }
  }

  if ('errorHandler' in error.config && error.config.errorHandler === false) {
    throw error
  }

  const errorNotification: SnackBar = {
    color: 'error',
    timeout: (error.config.errorHandler as SnackBarHandler)?.timeout || 5000,
    message:
      error.message === 'Network Error'
        ? error.message
        : (error.config.errorHandler as SnackBarHandler)?.message ||
          (error.response?.data as string),
    show: true
  }

  store.dispatch('notification/add', errorNotification)

  throw error
}

export default (): AxiosInstance => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })

  instance.interceptors.request.use((config: AxiosRequestConfig) => {
    const filterModule = getModule(FilterModule, store)

    // ajout des query params dans notre requête axios
    if (config.useQueryParams) {
      config.params = { ...config.params, ...filterModule.filters }
    }

    return config
  })

  instance.interceptors.response.use(handleSuccess, handleError)

  return instance
}
