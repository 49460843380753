





















import { Component, Vue } from 'vue-property-decorator'
//Molecule
import MMenu from '@/components/molecules/menu/MMenu.vue'
//Template
import THomepage from '@/components/templates/homepage/THomepage.vue'

//Interfaces
import { NavBar } from '@/store/interfaces/components/INavigation'

@Component({
  components: {
    MMenu,
    THomepage
  }
})
export default class PHomePageDashboard extends Vue {
  public get menuDashboardAdmin(): NavBar[] {
    return [
      {
        title: this.$tc('dashboard.name'),
        route: '/dashboard/accueil'
      },
      {
        title: this.$tc('theme.name', 2),
        route: '/dashboard/statistiques/thematiques'
      },
      {
        title: this.$tc('provider.name', 2),
        route: '/dashboard/statistiques/partenaires'
      },
      {
        title: this.$tc('client.name', 2),
        route: '/dashboard/statistiques/clients'
      },
      {
        title: this.$tc('sitekey.name', 2),
        route: '/dashboard/statistiques/sitekeys'
      },
      {
        title: this.$tc('customTracking.name', 2),
        route: '/dashboard/customTracking/campaignId',
        children: [
          {
            title: this.$tc('customTracking.children.campaignId', 2),
            route: '/dashboard/customTracking/campaignId'
          },
          {
            title: this.$tc('customTracking.children.source', 2),
            route: '/dashboard/customTracking/source'
          },
          {
            title: this.$tc('customTracking.children.siteId', 2),
            route: '/dashboard/customTracking/siteid'
          },
          {
            title: this.$tc('customTracking.children.keyword', 2),
            route: '/dashboard/customTracking/keyword'
          },
          {
            title: this.$tc('customTracking.children.adid', 2),
            route: '/dashboard/customTracking/adid'
          },
          {
            title: this.$tc('customTracking.children.adsetId', 2),
            route: '/dashboard/customTracking/adsetId'
          },
          {
            title: this.$tc('customTracking.children.flux', 2),
            route: '/dashboard/customTracking/flux'
          },
          {
            title: this.$tc('customTracking.children.requal', 2),
            route: '/dashboard/customTracking/requal'
          }
        ]
      },
      {
        title: this.$tc('reminders.name', 1),
        route: '/dashboard/facturation'
      },
      {
        title: this.$tc('order.name', 2),
        route: '/dashboard/commandes'
      },
      {
        title: this.$tc('completion.areas', 1),
        route: '/dashboard/completion/areas'
      },
      {
        title: this.$tc('completion.clients', 1),
        route: '/dashboard/completion/clients'
      },
      {
        title: this.$tc('completion.orders', 1),
        route: '/dashboard/completion/orders'
      }
    ]
  }
  public get menuDashboardProvider(): NavBar[] {
    return [
      {
        title: this.$tc('dashboard.name'),
        route: '/dashboard/accueil'
      },
      {
        title: this.$tc('theme.name', 2),
        route: '/dashboard/statistiques/thematiques'
      },
      {
        title: this.$tc('sitekey.name', 2),
        route: '/dashboard/statistiques/sitekeys'
      },
      {
        title: this.$tc('customTracking.name', 2),
        route: '/dashboard/customTracking/campaignId',
        children: [
          {
            title: this.$tc('customTracking.children.campaignId', 2),
            route: '/dashboard/customTracking/campaignId'
          },
          {
            title: this.$tc('customTracking.children.source', 2),
            route: '/dashboard/customTracking/source'
          },
          {
            title: this.$tc('customTracking.children.siteId', 2),
            route: '/dashboard/customTracking/siteid'
          },
          {
            title: this.$tc('customTracking.children.keyword', 2),
            route: '/dashboard/customTracking/keyword'
          },
          {
            title: this.$tc('customTracking.children.adid', 2),
            route: '/dashboard/customTracking/adid'
          },
          {
            title: this.$tc('customTracking.children.adsetId', 2),
            route: '/dashboard/customTracking/adsetId'
          },
          {
            title: this.$tc('customTracking.children.flux', 2),
            route: '/dashboard/customTracking/flux'
          },
          {
            title: this.$tc('customTracking.children.requal', 2),
            route: '/dashboard/customTracking/requal'
          }
        ]
      }
    ]
  }
}
