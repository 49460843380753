








import { Component, Prop, Vue } from 'vue-property-decorator'
import * as Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'

@Component({
  components: {
    highcharts: Chart
  }
})
export default class AChartLine extends Vue {
  // eslint-disable-next-line prettier/prettier
  @Prop() series!: []
  @Prop() titleYAxis?: string

  public chartLineOptions: Highcharts.Options = {}
  created() {
    this.chartLineOptions = {
      chart: {},
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'top',
        itemStyle: {
          color: '#0039CB',
          fontWeight: '300'
        }
      },
      tooltip: {
        useHTML: true,
        borderWidth: 0,
        shadow: false,
        backgroundColor: 'rgba(255,255,255,0)',
        headerFormat:
          '<div class="text-center" style="color:white;padding:5px 10px;border-radius:15px;background-color:{series.color}">' +
          '<p class="mb-0">{point.key}</p>',
        pointFormat:
          '<p class="mb-0">{series.name} :</p>' +
          '<p class="mb-0">{point.y:,.0f} {series.userOptions.tooltip.valueSuffix}</p>',
        footerFormat: '</div>'
      },
      yAxis: {
        min: 0,
        labels: {
          enabled: true
        },
        title: {
          text: this.titleYAxis
        },
        gridLineWidth: 0
      },
      xAxis: {
        categories: [],
        lineColor: '#0046F9',
        labels: {
          style: {
            color: '#0039CB',
            fontSize: '12px'
          }
        }
      },
      series: this.series
    }
  }
  destoyed() {
    this.chartLineOptions = {}
  }
}
