
































import { SnackBar } from '@/store/notification'
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class ABaseSnackBar extends Vue {
  @Prop()
  notification!: SnackBar
}
