








import { Component, Prop, Vue } from 'vue-property-decorator'
import * as Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'

@Component({
  components: {
    highcharts: Chart
  }
})
export default class AChartPie extends Vue {
  // eslint-disable-next-line indent, prettier/prettier
  @Prop({ default: 'Default title', required: true, type: String }) title!: string

  @Prop({ default: 200, type: Number }) height!: number

  public chartPieOptions: Highcharts.Options = {}

  created() {
    this.chartPieOptions = {
      credits: {
        enabled: false
      },
      tooltip: {
        useHTML: true,
        borderWidth: 0,
        shadow: false,
        backgroundColor: 'rgba(255,255,255,0)',
        headerFormat:
          '<div class="text-center" style="color:white;padding:5px 10px;border-radius:15px;background-color:{point.color}">' +
          '<p class="mb-0">{point.key}</p>',
        pointFormat: '<p class="mb-0">{point.y:,.0f} €</p>',
        footerFormat: '</div>'
      },
      chart: {
        type: 'pie',
        height: this.height,
        reflow: true,
        events: {
          render: function () {
            const title = this.title
            title.attr({
              x: this.plotLeft + this.plotWidth / 2
            })
          }
        }
      },
      title: {
        text: this.title,
        align: 'center',
        verticalAlign: 'middle',
        style: {
          color: '#0039CB',
          fontSize: '12px'
        }
      },
      legend: {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        itemMarginBottom: 10,
        itemMarginTop: 10,
        x: -105,
        maxHeight: this.height,
        labelFormat: '{name} : {y:,.0f} € ({percentage:.0f}%)',
        itemStyle: {
          fontWeight: '300',
          color: '#0039CB',
          fontSize: '12px'
        }
      },
      plotOptions: {
        pie: {
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true,
          colors: ['#0091ff', '#c8cce8', '#0048ff', '#a8c8ff']
        }
      },
      series: [
        {
          innerSize: '65%',
          type: 'pie',
          colorByPoint: true,
          data: []
        }
      ]
    }
  }
  destoyed() {
    this.chartPieOptions = {}
  }
}
