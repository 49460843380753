





































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import TTable from '@/components/templates/TTable.vue'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import MixinTables from '@/components/molecules/table/MixinTables.vue'
import ProviderModule from '@/store/provider'
import { getModule } from 'vuex-module-decorators'
import { TableActionsProps } from '@/types'
import UserModule, { User } from '@/store/user'

@Component({
  components: {
    TTable,
    MTableHeading,
    MTableFooter,
    MTableActions,
    MTable,
    AButtonPrimary
  }
})
export default class OAdminProviderTableUsers extends Mixins(MixinTables) {
  public providerModule = getModule(ProviderModule, this.$store)
  public userModule = getModule(UserModule, this.$store)

  public headers: DataTableHeader<User>[] = [
    { text: 'Nom', value: 'name' },
    { text: 'Téléphone', value: 'phone' },
    { text: 'E-mail', value: 'email' },
    { text: 'Actions', value: 'actions', sortable: false }
  ]

  get tableActions(): TableActionsProps[] {
    return [{ icon: '$edit', action: this.edit }]
  }

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  async request() {
    this.loading = true
    await this.providerModule.fetchUsers(+this.$route.params.providerId)
    this.loading = false
  }

  async remove(id: string) {
    await this.userModule.delete(id)
    this.request()
  }

  public read() {
    return
  }

  public edit() {
    return
  }

  public share() {
    return
  }

  public newProvider() {
    this.$router.push({
      path: `/partenaires/${this.$route.params.providerId}/utilisateurs/creation`
    })
  }
}
