























import { Component, Prop, Vue } from 'vue-property-decorator'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonSecondary from '@/components/atoms/button/AButtonSecondary.vue'
import AButtonLight from '@/components/atoms/button/AButtonLight.vue'

@Component({
  components: {
    AButtonPrimary,
    AButtonSecondary,
    AButtonLight
  }
})
export default class MWebhookActions extends Vue {
  @Prop() step!: number
  @Prop() createOrEdit!: string
}
