















































import { Component, PropSync, Prop, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider
  }
})
export default class AInputTextField extends Vue {
  @PropSync('value') valueSynced!: string
  @Prop() deleteIcon!: boolean

  public deletable = true

  destroy() {
    this.deletable = false
  }
}
