




























import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import TDashboardComplex from '@/components/templates/TDashboardComplex.vue'
import MFilterHeading from '@/components/molecules/filter/MFilterHeading.vue'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import MStatNumbers from '@/components/molecules/MStatNumbers.vue'
import OAdminProviderDashboardStatistics from '@/components/organisms/admin/OAdminProviderDashboardStatistics.vue'
import OAdminProviderDashboardFilters from '@/components/organisms/admin/OAdminProviderDashboardFilters.vue'
import { getModule } from 'vuex-module-decorators'
import ProviderModule from '@/store/provider'

@Component({
  components: {
    TDashboardComplex,
    MFilterHeading,
    ATitleMedium,
    MStatNumbers,
    OAdminProviderDashboardStatistics,
    OAdminProviderDashboardFilters
  }
})
export default class OAdminProviderDashboard extends Vue {
  public providerModule = getModule(ProviderModule, this.$store)

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  public async request() {
    this.providerModule.fetchDashboard(this.$route.params.providerId)
  }
}
