

























































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
//Plugins
import { DataTableHeader } from 'vuetify'
//Store
import { getModule } from 'vuex-module-decorators'
import StatisticModule, { Client } from '@/store/statistic'
//Types
import { TableActionsProps } from '@/types'
//Molecules
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import MixinTables, {
  DEFAULT_ITEMS_PER_PAGE
} from '@/components/molecules/table/MixinTables.vue'
import MTableFooterFrontSorting from '@/components/molecules/table/MTableFooterFrontSorting.vue'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import AIconValidation from '../../atoms/icons/AIconValidation.vue'
import AIconClose from '../../atoms/icons/AIconClose.vue'

@Component({
  components: {
    MTableHeading,
    MTableActions,
    MTable,
    MTableFooterFrontSorting,
    MTableFooter,
    AIconValidation,
    AIconClose
  }
})
export default class OTableAdminStatisticsClients extends Mixins(MixinTables) {
  public statisticModule = getModule(StatisticModule, this.$store)

  public headers: DataTableHeader<Client>[] = [
    {
      text: this.$t('stats.client.name') as string,
      value: 'name',
      sortable: false
    },
    {
      text: this.$tc('stats.lead', 1) as string,
      value: 'leads',
      sortable: false
    },
    {
      text: this.$tc('stats.leadNet', 1) as string,
      value: 'leadsSold',
      sortable: false
    },
    { text: this.$t('stats.ca') as string, value: 'sales', sortable: false },
    {
      text: this.$t('stats.caNet') as string,
      value: 'netSales',
      sortable: false
    },
    {
      text: this.$t('stats.marginBrut') as string,
      value: 'margin',
      sortable: false
    },
    {
      text: this.$t('stats.marginNet') as string,
      value: 'netMargin',
      sortable: false
    },
    {
      text: this.$t('stats.hasPendingInvoices') as string,
      value: 'pendingInvoices',
      align: 'center',
      sortable: false
    },
    { text: 'Actions', value: 'actions', sortable: false }
  ]

  get itemsPerPage() {
    return (
      this.statisticModule.clients.meta?.itemsPerPage || DEFAULT_ITEMS_PER_PAGE
    )
  }

  get tableActions(): TableActionsProps[] {
    return [
      {
        icon: '$leads',
        action: this.seeLeads,
        tooltip: this.$i18n.tc('client.actions.show') as string
      },
      {
        icon: '$stats',
        action: this.seeStats,
        tooltip: this.$i18n.tc('client.actions.statsShowClient') as string
      }
    ]
  }

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  async request() {
    this.loading = true
    await this.statisticModule.fetchAllClients()
    this.loading = false
  }

  public seeLeads(item: Client) {
    this.$router.push({
      path: '/leads',
      query: { client: item.id.toString() }
    })
  }

  public seeStats(item: Client) {
    this.$router.push({
      path: `/clients/${item.id.toString()}/dashboard`
    })
  }
}
