




























import { Component, PropSync, Vue } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import ABaseSelect from '@/components/atoms/select/ABaseSelect.vue'

@Component({
  components: {
    AInputTextField,
    ATitleMedium,
    ABaseSelect
  }
})
export default class MFormLeadBank extends Vue {
  @PropSync('bank-of-fr') bankSynced!: string
  @PropSync('year-contract') contractSynced!: string
  @PropSync('charges') chargesSynced!: string
  @PropSync('val-immo') valSynced!: string
  @PropSync('seniority') senioritySynced!: string

  public items = [
    { name: 'Oui', id: 1 },
    { name: 'Non', id: 2 }
  ]

  public atoms = [
    {
      rules: {
        required: true,
        numeric: true
      },
      label: 'Année de contrat',
      name: 'Année de contrat',
      text: this.contractSynced
    },
    {
      rules: {
        required: true,
        numeric: true
      },
      label: 'Charges',
      name: 'Charges',
      text: this.chargesSynced
    },
    {
      rules: {
        required: true,
        numeric: true
      },
      label: 'Valeur immobilière',
      name: 'Valeur immobilière',
      text: this.valSynced
    },
    {
      rules: {
        required: true,
        numeric: true
      },
      label: 'Ancienneté',
      name: 'Ancienneté',
      text: this.senioritySynced
    }
  ]
}
