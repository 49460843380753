



















































































import { Component, PropSync, Vue } from 'vue-property-decorator'
import ATitleSmall from '@/components/atoms/title/ATitleSmall.vue'
import ATextMedium from '@/components/atoms/text/ATextMedium.vue'
import ACheckboxNoLimit from '@/components/atoms/checkbox/ACheckboxNoLimit.vue'
import AInputTextFieldLimit from '@/components/atoms/input/AInputTextFieldLimit.vue'
import AMenu from '@/components/atoms/AMenu.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonSecondary from '@/components/atoms/button/AButtonSecondary.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import ACheckbox from '@/components/atoms/checkbox/ACheckbox.vue'
import ABaseSelect from '@/components/atoms/select/ABaseSelect.vue'
import AInputNumber from '@/components/atoms/input/AInputNumber.vue'
import ATextMediumLarge from '@/components/atoms/text/ATextMediumLarge.vue'

@Component({
  components: {
    ATitleSmall,
    ATextMedium,
    ACheckboxNoLimit,
    ACheckbox,
    AInputTextField,
    AInputTextFieldLimit,
    AMenu,
    AButtonPrimary,
    AButtonSecondary,
    ABaseSelect,
    AInputNumber,
    ATextMediumLarge
  }
})
export default class MOrderStepCalendarDelivery extends Vue {
  @PropSync('daysHours') daysHoursSync!: {
    day: string
    hours: { text: string; value: boolean }[]
    display: boolean
    sendData: number[]
  }[]
  @PropSync('plan') planning!: number[]

  public helpers = [
    {
      label: 'Toute la semaine',
      display: false,
      plan: [
        16777215, 16777215, 16777215, 16777215, 16777215, 16777215, 16777215
      ]
    },
    {
      label: 'Lundi au vendredi',
      display: false,
      plan: [0, 16777215, 16777215, 16777215, 16777215, 16777215, 0]
    },
    {
      label: 'Tous les matins ( 00H à 12H )',
      display: false,
      plan: [0, 4095, 4095, 4095, 4095, 4095, 0]
    },
    {
      label: 'Toutes les après-midi ( 12H à 00H )',
      display: false,
      plan: [0, 16773120, 16773120, 16773120, 16773120, 16773120, 0]
    }
  ]

  public currentPlanning: number[] = []

  mounted() {
    this.currentPlanning = this.planning
    if (this.planning.length === 0) {
      for (let index = 0; index < this.daysHoursSync.length; index++) {
        this.planning.push(0)
      }
    } else {
      this.updateHours()
    }
  }

  updateHours() {
    for (let index = 0; index < this.currentPlanning.length; index++) {
      const elmt = this.currentPlanning[index]
      const int = elmt.toString(2).padStart(24)
      this.daysHoursSync[index].sendData = []
      for (let i = 0; i < int.length; i++) {
        const char = int.charAt(i)
        this.daysHoursSync[index].sendData.push(Number(char))
        const position = this.daysHoursSync[index].hours.length - 1 - i

        char === '1'
          ? (this.daysHoursSync[index].hours[position].value = true)
          : (this.daysHoursSync[index].hours[position].value = false)
      }
      const one = this.daysHoursSync[index].sendData.find((elt) => elt === 1)
      one === undefined
        ? (this.daysHoursSync[index].display = false)
        : (this.daysHoursSync[index].display = true)
    }
  }

  getHours(
    hour: boolean,
    data: number[],
    indexHour: number,
    indexDay: number
  ): void {
    this.cleanHelpers()
    const posHour = data.length - 1 - indexHour
    // Get the right hour bit
    hour ? data.splice(posHour, 1, 1) : data.splice(posHour, 1, 0)
    // Transform into Number
    const join = data.join('')
    const selectedHours = parseInt(join, 2)
    selectedHours !== 0
      ? this.planning.splice(indexDay, 1, selectedHours)
      : this.planning.splice(indexDay, 1, 0)
  }

  clearHours(
    item: {
      day: string
      hours: { text: string; value: boolean }[]
      display: boolean
      sendData: number[]
    },
    index: number
  ) {
    if (!item.display) {
      item.hours.map((hour) => {
        hour.value = false
      })
      item.sendData = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ]
      this.planning.splice(index, 1, 0)
    }
  }
  cleanHelpers() {
    for (let incr = 0; incr < this.helpers.length; incr++) {
      const element = this.helpers[incr]
      element.display = false
    }
  }
  updateWeek(week: number[], display: boolean, index: number) {
    for (let incr = 0; incr < this.helpers.length; incr++) {
      const element = this.helpers[incr]
      if (incr !== index) {
        element.display = false
      }
    }
    display
      ? (this.currentPlanning = week)
      : (this.currentPlanning = this.planning)
    this.updateHours()
  }

  validate() {
    this.planning = this.currentPlanning
    // console.log(this.planning)
  }
}
