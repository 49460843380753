var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-provider-sitekeys"},[_c('MModal',{ref:"modalEdit",attrs:{"modalProps":_vm.modalEditProps},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',{attrs:{"align":"center","justify":"center","wrap":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('MFormSitekey',{attrs:{"sitekeyFields":_vm.currentSitekey,"isUpdate":true},on:{"update:sitekeyFields":function($event){_vm.currentSitekey=$event},"update:sitekey-fields":function($event){_vm.currentSitekey=$event}}})],1)],1)]},proxy:true}])}),_c('MTableHeading',{attrs:{"title":_vm.$tc('provider.sitekey', 2)},scopedSlots:_vm._u([{key:"btn",fn:function(){return [_c('AButtonPrimary',{staticClass:"ml-4 font-weight-bold",attrs:{"text":_vm.$t('sitekey.actions.new')},nativeOn:{"click":function($event){return _vm.newSitekey.apply(null, arguments)}}})]},proxy:true}])}),_c('MTable',{attrs:{"headers":_vm.headers,"items":_vm.sitekeys,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.conversionName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.conversionName)+" ")]}},{key:"item.conversionType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.conversionType)+" ")]}},{key:"item.customerId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customerId)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$options.filters.timestampToDateTime(item.createdAt))+" ")]}}],null,true)}),_c('MTableFooter',{attrs:{"tableProperties":_vm.providerModule.sitekeys.meta}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }