
















































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import TDashboardComplex from '@/components/templates/TDashboardComplex.vue'
import MFilterHeading from '@/components/molecules/filter/MFilterHeading.vue'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import MStatNumbers from '@/components/molecules/MStatNumbers.vue'
import OAdminClientDashboardFilters from '@/components/organisms/admin/OAdminClientDashboardFilters.vue'
import OAdminClientTableOrders from '@/components/organisms/admin/OAdminClientTableOrders.vue'
import { getModule } from 'vuex-module-decorators'
import ClientModule from '@/store/client'
import OTableClientOrders, {
  ClientOrdersProps
} from '@/components/organisms/client/OTableClientOrders.vue'

@Component({
  components: {
    TDashboardComplex,
    MFilterHeading,
    ATitleMedium,
    MStatNumbers,
    OAdminClientDashboardFilters,
    OAdminClientTableOrders,
    OTableClientOrders
  }
})
export default class OAdminClientDashboard extends Vue {
  public clientModule = getModule(ClientModule, this.$store)
  // eslint-disable-next-line @typescript-eslint/ban-types
  public filters = this.$options.filters as { [key: string]: Function }
  @Prop() clientId!: number

  get clientOrdersProps(): ClientOrdersProps {
    return {
      title: this.filters.capitalize(this.$tc('stats.activeOrders', 2)),
      onlyNewOrders: true
    }
  }

  public async mounted() {
    await this.clientModule.fetchClientDashboard(this.clientId)
  }
}
