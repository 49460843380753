
























































import { Component, PropSync, Vue } from 'vue-property-decorator'
//Atoms
import ATitleSmall from '@/components/atoms/title/ATitleSmall.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import AAvatar from '@/components/atoms/avatar/AAvatar.vue'

import { ProfilProperties } from './MFormProfil.vue'

export interface ProfilIdentity {
  lastName?: string
  firstName?: string
  phone?: string
  email: string
}

@Component({
  components: {
    ATitleSmall,
    AInputTextField,
    AAvatar
  }
})
export default class MFormProfilIdentity extends Vue {
  @PropSync('profilProperties') profilPropertiesSynced!: ProfilProperties

  get profilIdentity() {
    return this.profilPropertiesSynced.profilIdentity
  }

  get title() {
    if (this.profilPropertiesSynced.adminView) {
      return this.$tc('profil.title', 2)
    } else {
      return this.$tc('profil.title', 1)
    }
  }
}
