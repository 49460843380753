









import { Component, Vue, PropSync } from 'vue-property-decorator'

@Component({})
export default class AOverlay extends Vue {
  @PropSync('overlay', { type: Boolean }) syncedOverlay!: boolean
}
