import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { FiltersMeta } from './filter'
import { NestApiResponse } from '@/types'
export enum TrackerName {
  CampaignId = 'Campaign Id',
  Source = 'Source',
  SiteId = ' Site Id',
  Keyword = 'Keyword',
  AdId = 'Ad Id'
}

export interface TrackerDto {
  items: []
  meta?: FiltersMeta
}
@Module({ namespaced: true, name: 'customTracking' })
export default class CustomTrackingModule extends VuexModule {
  customTracking = null
  tracker: NestApiResponse<TrackerDto> = {
    items: []
  }
  trackerProvider: NestApiResponse<TrackerDto> = {
    items: []
  }
  @Mutation
  saveTracker(tracker: NestApiResponse<TrackerDto>) {
    this.tracker = tracker
  }

  @Mutation
  saveTrackerProvider(trackerProvider: NestApiResponse<TrackerDto>) {
    this.trackerProvider = trackerProvider
  }

  @Action({ commit: 'saveTracker' })
  async fetchCustomTracking(trackerName: string): Promise<TrackerDto> {
    const { data } = await Vue.prototype.$http.get(
      `/statistics/trackers/${trackerName}`,
      {
        useQueryParams: true
      }
    )
    return data
  }

  @Action({ commit: 'saveTrackerProvider' })
  async fetchCustomTrackingProvider(fields: {
    providerId: string
    trackerName: string
  }): Promise<TrackerDto> {
    const { data } = await Vue.prototype.$http.get(
      `/providers/${fields.providerId}/trackerstats/${fields.trackerName}`,
      {
        useQueryParams: true
      }
    )
    return data
  }
}
