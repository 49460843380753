



































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
export interface NavPage {
  route: string
  text: string
}
@Component
export default class ANavRouter extends Vue {
  @Prop() items!: NavPage[]
  @PropSync('selectItem') selectedItemSync!: []
}
