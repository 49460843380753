

































































































import { Component, Prop, Vue } from 'vue-property-decorator'
//Atoms
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import ATitleSmall from '@/components/atoms/title/ATitleSmall.vue'
import MDisableTextField from '@/components/molecules/MDisableTextField.vue'
import MMark from '@/components/molecules/MMark.vue'
import ADisableLabel from '@/components/atoms/ADisableLabel.vue'
import AListTooltip from '@/components/atoms/list/AListTooltip.vue'
//Store
import { LeadDetailModel, LeadProperty } from '@/store/lead'

@Component({
  components: {
    AListTooltip,
    ADisableLabel,
    ATitleMedium,
    ATitleSmall,
    MDisableTextField,
    MMark
  }
})
export default class MLeadDetail extends Vue {
  @Prop() lead!: LeadDetailModel

  // eslint-disable-next-line @typescript-eslint/ban-types
  public filters = this.$options.filters as { [key: string]: Function }

  formatLeadProperty(lp: LeadProperty) {
    // TODO: Json trad
    if (lp.name === 'DOB') return this.filters.timestampToDate(lp.value)
    return lp.value
  }
  /**
   * @getter leadDate
   * @description fetch date of sales with client and order associate
   * @return an array phrases for list atom
   */
  public get leadDate() {
    //if salesDates display phrase
    if (this.lead.salesDates) {
      return this.lead.salesDates.map((_date, index) => {
        return `${index + 1}) ${this.filters.timestampToDateTime(
          this.lead.salesDates[index]
        )}: ${this.lead.clients[index]} - ${this.lead.orders[index]}`
      })
    } else return "Pas de date d'achat" //if probleme with api
  }
}
