












import { PaginationResponse, TableProperties } from '@/types'
import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class APagination extends Vue {
  @PropSync('tableProperties') tablePropertiesSynced!: PaginationResponse

  get pageCount() {
    return this.tablePropertiesSynced.totalPages
  }
  get currentPage() {
    return this.tablePropertiesSynced.currentPage
  }
  set currentPage(page: number) {
    this.tablePropertiesSynced.currentPage = page || 1
  }
}
