import { render, staticRenderFns } from "./AChartPie.vue?vue&type=template&id=f168d530&scoped=true&"
import script from "./AChartPie.vue?vue&type=script&lang=ts&"
export * from "./AChartPie.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f168d530",
  null
  
)

export default component.exports