import { Vue } from 'vue-property-decorator'
import { Mutation, VuexModule, Module } from 'vuex-module-decorators'
import {
  CreateOrderConditionDto,
  CreateOrderDto,
  Order,
  OrderStatus
} from '@/store/order'
import { Area, TypePrice } from '@/store/area'

export enum ShareLimt {
  Exclu = 1,
  Mutu = 3
}
export type PriceProduct = {
  defaultExcluPrice: number
  defaultMutuPrice: number
}
@Module({ namespaced: true, name: 'orderStep' })
export default class OrderStepModule extends VuexModule {
  postConditions!: CreateOrderConditionDto[]
  productId!: number
  typePriceValue!: TypePrice

  orderDto: CreateOrderDto = {
    name: '',
    capping: -1,
    cappingMax: -1,
    price: 0,
    startAt: new Date().toISOString(),
    endAt: undefined,
    dailyBudget: 0,
    shareLimit: 1,
    status: OrderStatus.PAUSED,
    clientId: 0,
    zips: [],
    areaId: 0,
    productId: 0,
    areaConditions: [],
    productConditions: [],
    customConditions: [],
    notificationMails: [],
    dropper: undefined,
    planning: []
  }
  /**
   * @var productDefaultPrice
   * use this on stepper 3 for change price when you select product
   */
  productDefaultPrice: PriceProduct = {
    defaultExcluPrice: 0,
    defaultMutuPrice: 0
  }
  /**
   * @var {areaSelected}
   * @description Area selected when creating an order
   */
  areaSelected?: Area

  /**
   * @function selectedArea
   * @param areaSelected @type { Area }
   * @return { Array<Area> }
   * @description add selection Area inside state
   * @deprecated
   */
  @Mutation
  selectedArea(areaSelected: Area) {
    this.areaSelected = areaSelected
  }

  @Mutation
  savePrice(typePriceValue: TypePrice) {
    this.typePriceValue = typePriceValue
  }
  @Mutation
  savePriceProduct(productDefaultPrice: PriceProduct) {
    this.productDefaultPrice = productDefaultPrice
  }
  /**
   * @param order @type { Order | null }
   * @returns CreateOrderDto
   * Mut order in orderDto
   */
  @Mutation
  updateOrderDto(order: Order | null) {
    this.orderDto = {
      ...this.orderDto,
      ...{
        name: order!.name,
        capping: order!.capping,
        cappingMax: order!.cappingMax,
        price: order!.price,
        areaId: order!.clientsAreas?.area.id as number,
        startAt: Vue.prototype.$dayjs(order!.startAt),
        endAt: Vue.prototype.$dayjs(order!.endAt) || null,
        dailyBudget: Number(order!.dailyBudget),
        shareLimit: order!.shareLimit,
        status: order!.status,
        zips: order!.zips.map((zip) => zip.id), // check for send id rather than zip
        areaConditions: [],
        productConditions: [],
        customConditions: [],
        notificationMails: order!.webserviceMailingList,
        connectorName: order!.connectorName,
        dropper: order!.dropper,
        planning: order!.planning
      }
    }
    return this.orderDto
  }
  /**
   * @function clearOrderDto
   * @description this mutation clean object @var orderDto
   * @exemple when you create order, title of order is null
   */
  @Mutation
  clearOrderDto() {
    this.orderDto = {
      name: '',
      capping: -1,
      cappingMax: -1,
      price: 0,
      startAt: new Date().toISOString(),
      endAt: undefined,
      dailyBudget: 0,
      shareLimit: 1,
      status: OrderStatus.PAUSED,
      clientId: 0,
      zips: [],
      areaId: 0,
      productId: 0,
      areaConditions: [],
      productConditions: [],
      customConditions: [],
      notificationMails: [],
      dropper: undefined,
      planning: []
    }
  }
}
