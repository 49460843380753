









import { Component } from 'vue-property-decorator'
import { VProgressCircular } from 'vuetify/lib'

@Component
export default class MTableProgressCircular extends VProgressCircular {}
