


































import { Component, Vue } from 'vue-property-decorator'
//Atoms
import AIconAction from '@/components/atoms/AIconAction.vue'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
//Molecules
import MTableMenu, {
  MenuProps
} from '@/components/molecules/table/MTableMenu.vue'
//Template
import TMenuWithDynamicContent from '@/components/templates/TMenuWithDynamicContent.vue'
//Store
import { getModule } from 'vuex-module-decorators'
import ClientModule from '@/store/client'

@Component({
  components: {
    AIconAction,
    ATitleMedium,
    MTableMenu,
    TMenuWithDynamicContent
  }
})
export default class PAdminClient extends Vue {
  public get menuClient(): MenuProps[] {
    return [
      {
        text: this.$tc('dashboard.full'),
        route: `/clients/${this.$route.params.clientId}/dashboard`
      },
      {
        text: this.$tc('order.name', 2),
        route: `/clients/${this.$route.params.clientId}/commandes`
      },
      {
        text: this.$tc('client.invoice-information'),
        route: `/clients/${this.$route.params.clientId}/factures-manuelles`
      },
      {
        text: this.$tc('webhook.name', 2),
        route: `/clients/${this.$route.params.clientId}/connecteurs`
      },
      {
        text: 'Compte',
        route: `/clients/${this.$route.params.clientId}/profil`
      },
      {
        text: this.$tc('account.options'),
        route: `/clients/${this.$route.params.clientId}/options`
      }
    ]
  }

  public clientModule = getModule(ClientModule, this.$store)

  mounted() {
    const clientId = parseInt(this.$route.params.clientId)
    this.clientModule.fetchOneClient(clientId)
  }
}
