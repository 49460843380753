








































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import { DataTableHeader } from 'vuetify'
import MixinTables from '@/components/molecules/table/MixinTables.vue'
import BillingModule, { StripeItem } from '@/store/billing'
import UserModule from '@/store/user'
import { TableActionsProps } from '@/types'

@Component({
  components: {
    MTableHeading,
    MTableFooter,
    MTableActions,
    MTable,
    AButtonPrimary
  }
})
export default class OClientTableInvoices extends Mixins(MixinTables) {
  public billingModule = getModule(BillingModule, this.$store)
  public userModule = getModule(UserModule, this.$store)
  public selected: StripeItem[] = []

  public headers: DataTableHeader<StripeItem>[] = [
    { text: 'Nom', value: 'number' },
    { text: 'Date', value: 'date' },
    { text: 'Montant', value: 'amount' },
    { text: 'Statut', value: 'status' },
    { text: 'Commandes associées', value: 'orders', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false }
  ]

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  async request() {
    this.loading = true
    await this.billingModule.fetchBillingompanyInvoices(
      +this.userModule.currentUser!.companyId!
    )
    this.loading = false
  }

  get invoices() {
    return this.billingModule.billingCompanyInvoices!
  }

  get tableActions(): TableActionsProps[] {
    return [
      {
        icon: '$download',
        action: this.downloadInvoice,
        tooltip: this.$i18n.tc('invoice.actions.download') as string
      }
    ]
  }

  getOrders(orders: []): number {
    return orders.length
  }

  async downloadSelection() {
    for (let i = 0; i < this.selected.length; i++) {
      const invoice = this.selected[i]
      await this.billingModule.downloadInvoice(invoice)
    }
  }

  async downloadInvoice(item: StripeItem) {
    await this.billingModule.downloadInvoice(item)
  }

  // downloadNewInvoices() {
  //   console.log(this.selected)
  // }
}
