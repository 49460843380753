





















































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import ATitleSmall from '@/components/atoms/title/ATitleSmall.vue'
import ATextMedium from '@/components/atoms/text/ATextMedium.vue'
import ACheckboxNoLimit from '@/components/atoms/checkbox/ACheckboxNoLimit.vue'
import AInputTextFieldLimit from '@/components/atoms/input/AInputTextFieldLimit.vue'
import AMenu from '@/components/atoms/AMenu.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonSecondary from '@/components/atoms/button/AButtonSecondary.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import ACheckbox from '@/components/atoms/checkbox/ACheckbox.vue'
import ABaseSelect from '@/components/atoms/select/ABaseSelect.vue'
import AInputNumber from '@/components/atoms/input/AInputNumber.vue'
import AMultipleSelectChips from '@/components/atoms/select/AMultipleSelectChips.vue'

@Component({
  components: {
    ATitleSmall,
    ATextMedium,
    ACheckboxNoLimit,
    ACheckbox,
    AInputTextField,
    AInputTextFieldLimit,
    AMenu,
    AButtonPrimary,
    AButtonSecondary,
    ABaseSelect,
    AInputNumber,
    AMultipleSelectChips
  }
})
export default class MOrderStepFive extends Vue {
  public dates = []

  @PropSync('capping') cappingSynced!: number
  @PropSync('cappingMax') cappingMaxSynced!: number
  @PropSync('dropper') dropperSynced!: number
  @Prop() price!: number
  @Prop() globalBudget!: number
  @Prop() periodFormatted!: string
}
