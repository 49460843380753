
























import { Component, PropSync, Vue } from 'vue-property-decorator'
//Atoms
import ATitleSmall from '@/components/atoms/title/ATitleSmall.vue'
import AInputTextFieldPassword from '@/components/atoms/input/AInputTextFieldPassword.vue'
import AAvatar from '@/components/atoms/avatar/AAvatar.vue'

import MInputPasswordSolid from '@/components/molecules/input/MInputPasswordSolid.vue'

import { ProfilProperties } from './MFormProfil.vue'
import { ProfilIdentity } from './MFormProfilIdentity.vue'

export type ProfilCredentials = {
  current: string
  new: string
  confirmation: string
}

export interface FieldsToPost extends ProfilIdentity {
  currentPassword?: string
  password?: string
}

@Component({
  components: {
    ATitleSmall,
    AInputTextFieldPassword,
    AAvatar,
    MInputPasswordSolid
  }
})
export default class MFormProfilCredentials extends Vue {
  @PropSync('profilProperties') profilPropertiesSynced!: ProfilProperties

  get profilCredentials() {
    return this.profilPropertiesSynced.profilCredentials
  }
}
