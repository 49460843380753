














































import { Component, Prop, Vue } from 'vue-property-decorator'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import ABaseSelect from '@/components/atoms/select/ABaseSelect.vue'

@Component({
  components: {
    AInputTextField,
    ABaseSelect
  }
})
export default class MPayload extends Vue {
  @Prop() payload!: { key: string; value: string }[]
  @Prop() variables!: { name: string; displayName: string }[]
}
