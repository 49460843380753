













import { Component, Vue } from 'vue-property-decorator'
import OClientTableInvoices from '@/components/organisms/client/OClientTableInvoices.vue'
import TTableWithFilters from '@/components/templates/TTableWithFilters.vue'
import OClientTableInvoicesFilters from '@/components/organisms/client/OClientTableInvoicesFilters.vue'

@Component({
  components: {
    OClientTableInvoices,
    TTableWithFilters,
    OClientTableInvoicesFilters
  }
})
export default class PClientInvoices extends Vue {}
