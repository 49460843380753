























import { Component, Prop, Vue } from 'vue-property-decorator'
interface TextField {
  icon?: string
  iconSize?: string
  iconStyle?: string
  title?: string
  text: string
  style?: string
}
@Component({})
export default class ATextField extends Vue {
  @Prop() textField!: TextField
}
