import { render, staticRenderFns } from "./ACheckboxZip.vue?vue&type=template&id=a635d92a&scoped=true&"
import script from "./ACheckboxZip.vue?vue&type=script&lang=ts&"
export * from "./ACheckboxZip.vue?vue&type=script&lang=ts&"
import style0 from "./ACheckboxZip.vue?vue&type=style&index=0&id=a635d92a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a635d92a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCheckbox})
