


























































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AInputTextFieldSearch from '@/components/atoms/input/AInputTextFieldSearch.vue'
//Template
import TSkeleton from '@/components/templates/TSkeleton.vue'
//Store
import { getModule } from 'vuex-module-decorators'
import FilterModule from '@/store/filter'
import LoaderModule from '@/store/loader'
import ATextSmall from '@/components/atoms/text/ATextSmall.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import { DataTableHeader } from 'vuetify'

export type CustomDataTableHeader<T> = DataTableHeader<T> & {
  display?: boolean | undefined
}

@Component({
  components: {
    ATitleMedium,
    AInputTextFieldSearch,
    TSkeleton,
    ATextSmall,
    AButtonPrimary
  }
})
export default class MTableHeading extends Vue {
  public filterModule = getModule(FilterModule, this.$store)
  public loaderModule = getModule(LoaderModule, this.$store)
  @Prop()
  title!: string
  @PropSync('headers') headersSynced!: CustomDataTableHeader<unknown>[]
  @Prop({ type: Boolean, default: false }) disableSearch?: boolean

  get search() {
    return this.filterModule.filters.search || ('' as string)
  }

  set search(value: string) {
    this.filterModule.updateFilter({ search: value, page: 1 })
  }
  mounted() {
    this.loaderModule.loadingPage(false)
  }
  destroyed() {
    this.search = ''
  }
}
