











import { Component, Vue } from 'vue-property-decorator'
import OTableAdminUsers from '@/components/organisms/admin/OTableAdminUsers.vue'
import OTableAdminClientsFilters from '@/components/organisms/admin/OTableAdminClientsFilters.vue'
import TTable from '@/components/templates/TTable.vue'

@Component({
  components: {
    OTableAdminUsers,
    OTableAdminClientsFilters,
    TTable
  }
})
export default class PAdminUsers extends Vue {}
