




































import { Component, Prop, Vue } from 'vue-property-decorator'
//Atoms
import ATitleSmall from '@/components/atoms/title/ATitleSmall.vue'
import ACheckbox from '@/components/atoms/checkbox/ACheckbox.vue'
import ATextLarge from '@/components/atoms/text/ATextLarge.vue'
import ATextSmall from '@/components/atoms/text/ATextSmall.vue'
import AAvatar from '@/components/atoms/avatar/AAvatar.vue'

@Component({
  components: {
    ATitleSmall,
    ACheckbox,
    ATextLarge,
    ATextSmall,
    AAvatar
  }
})
export default class MKeyApi extends Vue {
  public active = false
  @Prop() apiKey?: string
}
