import { Mutation, VuexModule, Module, Action } from 'vuex-module-decorators'
import dayjs from '@/plugins/dayjs'
import Vue from 'vue'
import { Provider } from './statistic'
import { Client } from './client'
import { Sitekey } from './sitekey'
export interface FilterState {
  filters: Filters
  filtersRequest: FiltersRequest
  period: string[]
  customPeriod: string
  predefinedPeriod: Period[]
}

export interface Filters {
  search?: string
  page?: number
  startAt?: string
  endAt?: string
  area?: number | null
  provider?: number | null
  sitekey?: number | null
  client?: number | null
  order?: number | null
  isCommented?: boolean | null
  isRead?: boolean | null
  isExported?: boolean | null
  limit?: number
  sort?: string
  tracker?: string | null
  trackerValue?: string | null
  hasPendingInvoices?: boolean | null
}

export interface FiltersMeta {
  currentPage: number
  itemCount: number
  itemsPerPage: number
  totalItems: number
  totalPages: number
}

export interface FilterRequest {
  name: string
  id?: number
}
export interface FilterClientCallable {
  name: string
  value: boolean | string
}

export interface FilterPendingInvoice {
  name: string
  value: boolean | string
}
export interface LeadTrackers {
  clicId?: FilterRequest[]
  adId?: FilterRequest[]
  campaignId?: FilterRequest[]
  keyword?: FilterRequest[]
  siteId?: FilterRequest[]
  audience?: FilterRequest[]
  adtext?: FilterRequest[]
  device?: FilterRequest[]
}

//For key comment and read, we change in optinal for orderequest
//Todo create interface for all possibiliter filter
export interface FiltersRequest {
  areas: FilterRequest[]
  clients: FilterRequest[]
  providers: FilterRequest[]
  sitekeys: FilterRequest[]
  status: FilterRequest[]
  orders: FilterRequest[]
  comments?: FilterClientCallable[]
  read?: FilterClientCallable[]
  // zips: FilterRequest[]
  // TODO: trackers need to be defined later in stats
  leadTrackers?: FilterLeadTrackers
  customLeadTrackers?: FiltercustomLeadTrackers
  pendingInvoices?: FilterPendingInvoice[]
}

export interface Period {
  name: string
  value: string
  startAt: string
  endAt: string
}

export type FiltercustomLeadTrackers = Record<string, FilterRequest[]>
export type FilterLeadTrackers = Record<string, FilterRequest[]>
export type fetchFiltercustomLeadTrackersParams = {
  custom?: boolean
  tracker?: string
}

export const LIMIT_EXPORT = 20000

@Module({ namespaced: true, name: 'filter' })
export default class FilterModule extends VuexModule implements FilterState {
  filters: Filters = {}
  filtersRequest: FiltersRequest = {
    areas: [],
    providers: [],
    clients: [],
    sitekeys: [],
    status: [],
    orders: [],
    comments: [],
    read: [],
    // zips: [],
    leadTrackers: {
      clicId: [],
      adId: [],
      campaignId: [],
      keyword: [],
      siteId: [],
      audience: [],
      adtext: [],
      device: []
    },
    customLeadTrackers: {},
    pendingInvoices: []
  }
  sort = ''
  period: string[] = []
  customPeriod = ''
  predefinedPeriod: Period[] = [
    {
      name: 'Hier',
      value: 'yesterday',
      startAt: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      endAt: dayjs().subtract(1, 'day').format('YYYY-MM-DD')
    },
    {
      name: "Aujourd'hui",
      value: 'today',
      startAt: dayjs().format('YYYY-MM-DD'),
      endAt: dayjs().format('YYYY-MM-DD')
    },
    {
      name: 'Cette semaine',
      value: 'thisWeek',
      startAt: dayjs().weekday(0).format('YYYY-MM-DD'),
      endAt: dayjs().weekday(6).format('YYYY-MM-DD')
    },
    {
      name: 'Ce mois',
      value: 'thisMonth',
      startAt: dayjs().startOf('month').format('YYYY-MM-DD'),
      endAt: dayjs().endOf('month').format('YYYY-MM-DD')
    },
    {
      name: 'Mois dernier',
      value: 'lastMonth',
      startAt: dayjs()
        .subtract(1, 'month')
        .startOf('month')
        .format('YYYY-MM-DD'),
      endAt: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    },
    {
      name: 'Semaine dernière',
      value: 'lastWeek',
      startAt: dayjs().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'),
      endAt: dayjs().subtract(1, 'week').endOf('week').format('YYYY-MM-DD')
    },
    {
      name: '7 derniers jours',
      value: 'last7days',
      startAt: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
      endAt: dayjs().format('YYYY-MM-DD')
    },
    {
      name: '30 derniers jours',
      value: 'last30days',
      startAt: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      endAt: dayjs().format('YYYY-MM-DD')
    },
    {
      name: 'Cette année',
      value: 'thisYear',
      startAt: dayjs().subtract(0, 'year').startOf('year').format('YYYY-MM-DD'),
      endAt: dayjs().format('YYYY-MM-DD')
    }
  ]

  @Mutation
  addPeriod(period: string) {
    if (this.period.length === 2) {
      this.period = []
    }

    this.period.push(period)
  }

  @Mutation
  updateCustomPeriod(period: string) {
    this.customPeriod = period
  }

  @Mutation
  reset() {
    this.filters = {}
    this.period = []
    this.customPeriod = ''
  }

  @Mutation
  updateFilter(filter: Filters) {
    for (const [key, value] of Object.entries(filter)) {
      if (!value) {
        Vue.delete(this.filters, key)
        break
      }
      this.filters = {
        ...this.filters,
        ...filter
      }
    }
  }

  @Mutation
  saveFilterStatuses(statuses: FilterRequest[]) {
    this.filtersRequest.status = statuses
  }

  @Mutation
  saveFilterAreas(areas: FilterRequest[]) {
    this.filtersRequest.areas = areas
  }

  @Mutation
  saveFilterOrders(orders: FilterRequest[]) {
    this.filtersRequest.orders = orders
  }

  @Mutation
  saveCustomLeadTrackers(trackers: string[]) {
    const filtercustomLeadTrackers = {} as FiltercustomLeadTrackers
    for (let i = 0; i < trackers.length; i++) {
      filtercustomLeadTrackers[trackers[i]] = []
    }

    this.filtersRequest.customLeadTrackers = filtercustomLeadTrackers
  }

  @Mutation
  saveLeadTrackers({
    tracker,
    data
  }: {
    tracker: keyof LeadTrackers
    data: FilterRequest[]
  }) {
    if (
      this.filtersRequest.leadTrackers &&
      this.filtersRequest.customLeadTrackers
    ) {
      if (tracker in this.filtersRequest.leadTrackers) {
        this.filtersRequest.leadTrackers[tracker] = data
      }

      if (tracker in this.filtersRequest.customLeadTrackers) {
        this.filtersRequest.customLeadTrackers[tracker] = data
      }
    }
  }

  @Mutation
  saveFilterProviders(providers: Provider[]) {
    this.filtersRequest.providers = providers
  }

  @Mutation
  saveFilterClients(clients: Client[]) {
    this.filtersRequest.clients = clients
  }

  @Mutation
  saveFilterSitekeys(sitekeys: Sitekey[]) {
    this.filtersRequest.sitekeys = sitekeys
  }

  @Mutation
  saveFilterIsCommented(comments: FilterClientCallable[]) {
    this.filtersRequest.comments = comments
  }

  @Mutation
  saveFilterIsRead(read: FilterClientCallable[]) {
    this.filtersRequest.read = read
  }

  @Mutation
  saveFilterSort(sort: string) {
    this.sort = sort
  }
  @Mutation
  saveFilterPendingInvoices(pendingInvoices: FilterPendingInvoice[]) {
    this.filtersRequest.pendingInvoices = pendingInvoices
  }

  @Action({ commit: 'saveFilterPendingInvoices' })
  fetchFilterHasPendingInvoices() {
    return [
      {
        name: 'Aucune',
        value: 'false'
      },
      {
        name: 'Une ou plusieur facture',
        value: 'true'
      }
    ]
  }

  @Action({ commit: 'saveFilterIsCommented' })
  fetchFilterIsCommented() {
    return [
      { name: 'annoté', id: 'true' },
      { name: 'non annoté', id: 'false' }
    ]
  }

  @Action({ commit: 'saveFilterIsRead' })
  fetchFilterIsRead() {
    return [
      { name: 'à rappeler', id: 'false' },
      { name: 'déjà rappelé', id: 'true' }
    ]
  }

  @Action({ commit: 'saveFilterStatuses' })
  async fetchFilterStatuses() {
    const { data } = await Vue.prototype.$http.get('/filters/statuses')
    return data
  }

  @Action({ commit: 'saveFilterAreas' })
  async fetchFilterAreas() {
    const { data } = await Vue.prototype.$http.get('/filters/areas')
    return data
  }

  @Action({ commit: 'saveFilterOrders' })
  async fetchFilterOrders(clientId: number) {
    const { data } = await Vue.prototype.$http.get(
      `/filters/orders/${clientId}`
    )
    return data
  }

  @Action({ commit: 'saveFilterProviders' })
  async fetchFilterProviders(value?: string) {
    const query = { search: value }
    const { data } = await Vue.prototype.$http.get('/filters/providers', {
      params: query
    })
    return data
  }

  @Action({ commit: 'saveFilterClients' })
  async fetchFilterClients(value?: string) {
    const query = { search: value }

    const { data } = await Vue.prototype.$http.get('/filters/clients', {
      params: query
    })
    return data
  }

  @Action({ commit: 'saveFilterSitekeys' })
  async fetchFilterSitekeys(value?: string) {
    const query = { search: value }

    const { data } = await Vue.prototype.$http.get('/filters/sitekeys', {
      params: query
    })
    return data
  }

  @Action({ commit: 'saveFilterSitekeys' })
  async fetchFilterSitekeysByProvider(providerId: string) {
    const { data } = await Vue.prototype.$http.get(
      `/filters/sitekeys/${providerId}`
    )
    return data
  }

  @Action
  async fetchFilterLeadTrackers({
    custom,
    tracker
  }: fetchFiltercustomLeadTrackersParams) {
    const params = { custom, tracker }

    const { data } = await Vue.prototype.$http.get('/filters/lead-trackers', {
      params
    })

    if (custom) {
      this.context.commit('saveCustomLeadTrackers', data)
    }

    if (tracker) {
      const trackers: {
        tracker: keyof LeadTrackers | string
        data: fetchFiltercustomLeadTrackersParams
      } = { tracker, data }
      this.context.commit('saveLeadTrackers', trackers)
    }

    return data
  }

  // initFilter will not trigger subscribe in App.vue (avoid infinite loop)
  /**
   * Function that initialize filters of API's routes in other words the query parameters.
   * @param filter 
   * @example 
   * created() {
      this.filterModule.initFilter({ limit: 10 })
    }
   */
  @Mutation
  initFilter(filter: Filters) {
    for (const [key, value] of Object.entries(filter)) {
      if (!value) break
      ;(this.filters as any)[key] = value
    }
    return this.filters
  }
  /**
   * @function reqNbrItemPerPage
   * @param nbr @type { number }
   * @returns @type { number }
   * @description save number of items inside store (per page)
   */
  @Mutation
  reqNbrItemPerPage(nbr: number): number {
    return (this.filters.limit = nbr)
  }

  @Mutation
  resetFilter() {
    this.filters = {}
    this.period = []
    this.customPeriod = ''
  }

  @Mutation
  resetFilterLeadTracker() {
    this.filtersRequest.customLeadTrackers = {}
    this.filtersRequest.leadTrackers = {
      clicId: [],
      adId: [],
      campaignId: [],
      keyword: [],
      siteId: [],
      audience: [],
      adtext: [],
      device: []
    }
  }
}
