import Vue from 'vue'
import { Action, Mutation, VuexModule, Module } from 'vuex-module-decorators'
import { i18n } from '@/plugins/i18n'
import { NestApiResponse } from '@/types'
import { OrderStatus } from './order'
import { FiltersMeta } from './filter'

export interface ZipSales {
  name: string
  value: number | string
  zip: string
}

export interface KPISales {
  activeOrders: number
  leadsSold: number
  sendLeadKos: number
}
export interface SalesValue {
  areaName: string
  mutu: number
  exclu: number
}

export interface OrderSales {
  id: string
  name: string
  isActive: OrderStatus
  isActiveNow: boolean
  shareLimit: number
  endAt: string
  area: string
  capping: number
  completionPercent: number
  cappingMax: number
  price: number
}

export interface OrderSalesRequest {
  items: OrderSales[]
  meta?: FiltersMeta
}

export type SalesResponse = NestApiResponse<Array<[string, number]>>

export const mapData: string[][] = [
  ['fr-bre-mb', '56'],
  ['fr-pdl-vd', '85'],
  ['fr-occ-ad', '11'],
  ['fr-pac-vc', '84'],
  ['fr-ges-hm', '52'],
  ['fr-ges-mr', '51'],
  ['fr-hdf-no', '59'],
  ['fr-occ-hp', '65'],
  ['fr-cvl-in', '36'],
  ['fr-naq-vn', '86'],
  ['fr-naq-dd', '24'],
  ['fr-naq-cm', '17'],
  ['fr-pac-am', '06'],
  ['fr-pac-vr', '83'],
  ['fr-pac-ap', '04'],
  ['fr-ara-ai', '01'],
  ['fr-hdf-as', '02'],
  ['fr-pac-bd', '13'],
  ['fr-occ-av', '12'],
  ['fr-occ-ga', '30'],
  ['fr-ges-ab', '10'],
  ['fr-bfc-co', '21'],
  ['fr-bfc-sl', '71'],
  ['fr-cvl-ch', '18'],
  ['fr-naq-cr', '23'],
  ['fr-pdl-ml', '49'],
  ['fr-naq-ds', '79'],
  ['fr-naq-ct', '16'],
  ['fr-ara-dm', '26'],
  ['fr-ara-ah', '07'],
  ['fr-nor-eu', '27'],
  ['fr-idf-es', '91'],
  ['fr-cvl-el', '28'],
  ['fr-occ-hg', '31'],
  ['fr-idf-hd', '92'],
  ['fr-naq-hv', '87'],
  ['fr-pdl-st', '72'],
  ['fr-cvl-il', '37'],
  ['fr-ara-is', '38'],
  ['fr-bfc-ju', '39'],
  ['fr-bfc-hn', '70'],
  ['fr-ara-lr', '42'],
  ['fr-occ-tg', '82'],
  ['fr-occ-lo', '46'],
  ['fr-naq-lg', '47'],
  ['fr-occ-lz', '48'],
  ['fr-bre-iv', '35'],
  ['fr-ges-mm', '54'],
  ['fr-ges-ms', '55'],
  ['fr-bfc-ni', '58'],
  ['fr-naq-cz', '19'],
  ['fr-ara-pd', '63'],
  ['fr-occ-ge', '32'],
  ['fr-naq-pa', '64'],
  ['fr-ara-sv', '73'],
  ['fr-idf-se', '77'],
  ['fr-idf-vp', '75'],
  ['fr-idf-ss', '93'],
  ['fr-idf-vm', '94'],
  ['fr-hdf-so', '80'],
  ['fr-bfc-tb', '90'],
  ['fr-bfc-db', '25'],
  ['fr-idf-vo', '95'],
  ['fr-ges-vg', '88'],
  ['fr-idf-yv', '78'],
  ['fr-cvl-lc', '41'],
  ['fr-cor-cs', '2A'],
  ['fr-bre-fi', '29'],
  ['fr-cor-hc', '2B'],
  ['fr-nor-mh', '50'],
  ['fr-ges-an', '08'],
  ['fr-occ-ag', '09'],
  ['fr-ges-br', '67'],
  ['fr-nor-cv', '14'],
  ['fr-ara-cl', '15'],
  ['fr-bre-ca', '22'],
  ['fr-naq-gi', '33'],
  ['fr-ges-hr', '68'],
  ['fr-ara-hs', '74'],
  ['fr-occ-he', '34'],
  ['fr-naq-ld', '40'],
  ['fr-pdl-la', '44'],
  ['fr-ges-mo', '57'],
  ['fr-nor-or', '61'],
  ['fr-hdf-pc', '62'],
  ['fr-occ-po', '66'],
  ['fr-pdl-my', '53'],
  ['fr-nor-sm', '76'],
  ['fr-bfc-yo', '89'],
  ['fr-ara-al', '03'],
  ['fr-ara-hl', '43'],
  ['fr-pac-ha', '05'],
  ['fr-cvl-lt', '45'],
  ['fr-hdf-oi', '60'],
  ['fr-ara-rh', '69'],
  ['fr-occ-ta', '81'],
  ['fr-lre-re', '974'],
  ['fr-may-yt', '976'],
  ['fr-gf-gf', '973'],
  ['fr-mq-mq', '972'],
  ['fr-gua-gp', '971']
]

@Module({ namespaced: true, name: 'sales' })
export default class SalesModule extends VuexModule {
  leads: ZipSales[] | null = null
  leadsDemand: ZipSales[] | null = null
  leadsShared: ZipSales[] | null = null
  values: SalesValue | null = null
  kpi: KPISales | null = null
  orders: OrderSalesRequest = {
    items: []
  }

  @Mutation
  saveOrders(orders: OrderSalesRequest) {
    this.orders = { ...this.orders, ...orders }
  }
  @Mutation
  saveValues(values: SalesValue) {
    this.values = values
  }
  @Mutation
  saveKPI(kpi: KPISales) {
    this.kpi = kpi
  }
  @Mutation
  saveLeads(leads: ZipSales[]) {
    this.leads = leads
  }
  @Mutation
  saveLeadsDemand(leadsDemand: ZipSales[]) {
    this.leadsDemand = leadsDemand
  }
  @Mutation
  saveLeadsShared(leadsShared: ZipSales[]) {
    this.leadsShared = leadsShared
  }

  @Action({ commit: 'saveLeads' })
  async fetchLeads() {
    const { data } = await Vue.prototype.$http.get('/statistics/charts/offer', {
      useQueryParams: true
    })
    return data
  }
  @Action({ commit: 'saveLeadsDemand' })
  async fetchLeadsDemand() {
    const { data } = await Vue.prototype.$http.get(
      '/statistics/charts/demand',
      {
        useQueryParams: true
      }
    )
    return data
  }
  @Action({ commit: 'saveLeadsShared' })
  async fetchLeadsShared() {
    const { data } = await Vue.prototype.$http.get.get(
      '/statistics/charts/sharing',
      {
        useQueryParams: true
      }
    )
    return data
  }
  @Action({ commit: 'saveKPI' })
  async fetchKPI() {
    const { data } = await Vue.prototype.$http.get('/statistics/sales/kpi', {
      useQueryParams: true
    })
    return data
  }
  @Action({ commit: 'saveValues' })
  async fetchValuation() {
    const { data } = await Vue.prototype.$http.get(
      '/statistics/sales/valorisation',
      {
        useQueryParams: true
      }
    )
    return data
  }
  @Action({ commit: 'saveOrders' })
  async fetchOrders() {
    const { data } = await Vue.prototype.$http.get('/statistics/sales/orders', {
      useQueryParams: true
    })
    return data
  }

  @Action
  async updateOrderStatus(order: OrderSales) {
    await Vue.prototype.$http.put(
      `/orders/${order.id}/status`,
      {
        status: order.isActive
      },
      {
        successHandler: {
          message: `${order.name} est désormais ${i18n.t(
            `status.orders.${order.isActive}`
          )} `
        }
      }
    )
  }
}
