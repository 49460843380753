





















import { Component, Mixins } from 'vue-property-decorator'
import ABaseSelect from '@/components/atoms/select/ABaseSelect.vue'
import MFilterDates from '@/components/molecules/filter/MFilterDates.vue'
import MFilterHeading from '@/components/molecules/filter/MFilterHeading.vue'
import MixinFilters from '@/components/molecules/filter/MixinFilters.vue'
@Component({
  components: {
    ABaseSelect,
    MFilterDates,
    MFilterHeading
  }
})
export default class OTableAdminClientsFilters extends Mixins(MixinFilters) {
  async mounted() {
    await this.filterModule.fetchFilterAreas()
  }

  created() {
    this.filterModule.initFilter({ limit: 50 })
  }
}
