








import { Component, Vue } from 'vue-property-decorator'
import OOrderSteps from '@/components/organisms/common/OOrderSteps.vue'
import TCardWithStepper from '@/components/templates/TCardWithStepper.vue'

@Component({
  components: {
    TCardWithStepper,
    OOrderSteps
  }
})
export default class POrder extends Vue {}
