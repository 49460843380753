




import { Component, Vue } from 'vue-property-decorator'
import UserModule from '@/store/user'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {
    PAdminCustomTracking: () =>
      import('@/components/pages/admin/PAdminCustomTracking.vue'),
    PProviderCustomTracking: () =>
      import('@/components/pages/provider/PProviderCustomTracking.vue')
  }
})
export default class RCustomTracking extends Vue {
  public userModule = getModule(UserModule, this.$store)
  public component: string | null = null

  created() {
    this.componentFromRole()
  }

  private componentFromRole() {
    if (this.userModule.isAdmin) {
      this.component = 'PAdminCustomTracking'
    } else if (this.userModule.isProvider) {
      this.component = 'PProviderCustomTracking'
    } else return
  }
}
