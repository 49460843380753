import { Mutation, VuexModule, Module } from 'vuex-module-decorators'
@Module({ namespaced: true, name: 'loader' })
export default class LoaderModule extends VuexModule {
  //Todo finis that when you utilise loading in all project
  firstLoad = true
  loading = false
  requestsPending!: number
  showLogo = false

  @Mutation
  loadingPage(firstLoad: boolean) {
    setTimeout(() => {
      this.firstLoad = firstLoad
    }, 1000)
  }
  @Mutation
  show() {
    this.loading = true
  }
  /**
   * @function hide
   * @description It's for ALoaderLogo, display logo animation and Letter logo animation
   */
  @Mutation
  hide() {
    const timeout = 2000
    setTimeout(() => {
      this.showLogo = true
      setTimeout(() => {
        this.loading = false
      }, timeout * 2)
    }, timeout)
  }

  @Mutation
  pending(requestsPending: number) {
    if (requestsPending == 0) this.show()
  }
  @Mutation
  done(requestsPending: number) {
    if (requestsPending >= 1) this.requestsPending--
    if (requestsPending <= 0) this.hide()
  }
}
