var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading)?_c('div',[_vm._l((5),function(index){return _c('TSkeleton',{key:index,staticClass:"mt-5",attrs:{"loading":_vm.$attrs.loading,"types":"heading, list-item-two-line, divider"}})}),_c('TSkeleton',{staticClass:"mt-5",attrs:{"loading":_vm.$attrs.loading,"types":"button"}})],2):_c('div',[_c('MLeadDetail',{attrs:{"lead":_vm.leadDetail}}),_c('v-divider',{staticClass:"my-5"}),_c('MLeadTransaction',{attrs:{"lead":_vm.leadDetail},on:{"show":function($event){return _vm.openLogModal($event)},"forceSendLead":function($event){return _vm.sendLead($event)}}}),_c('v-divider',{staticClass:"mb-3 mt-5"}),_c('div',{staticClass:"d-flex my-5"},[(_vm.leadDetail)?_c('AButtonPrimary',{staticClass:"ml-auto font-weight-bold",attrs:{"text":_vm.$tc('lead.selection.rgpd')},nativeOn:{"click":function($event){return _vm.openRgpdModal.apply(null, arguments)}}}):_vm._e(),_c('MBaseModal',{ref:"rgpd",attrs:{"modalProps":_vm.modalRgpd}}),_c('MBaseModal',{ref:"log",attrs:{"modalProps":_vm.modalLog},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-treeview',{attrs:{"transition":"","items":_vm.items},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
                  snippet__container: !item.children
                }},[_c('pre',{staticClass:"snippet__child"},[_vm._v(_vm._s(item.name))])])]}}])})]},proxy:true}])})],1),_c('v-divider',{staticClass:"mb-3 mt-5"}),(_vm.itemsMatchingLogs)?_c('div',{staticClass:"d-flex flex-column my-5"},[_c('div',{staticClass:"blueDark--text"},[_c('ATitleMedium',{attrs:{"text":"Matching Logs"}}),_c('ATextSmall',{attrs:{"text":"Commande rejeté par le matching, raison ci-dessous."}})],1),_c('div',[_c('ATreeviewLogs',{staticClass:"blueDark--text",attrs:{"items":_vm.itemsMatchingLogs,"paramsLogs":{
            files: _vm.files,
            reject: 'reason',
            color: {
              default: 'blueDark--text',
              other: 'yellow--text'
            }
          }}})],1)]):_c('div',{staticClass:"d-flex flex-column my-5"},[_c('div',{staticClass:"blueDark--text"},[_c('ATextSmall',{attrs:{"text":"Pas de Logs lié au matching"}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }