


























































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
//Interface | Enum | Type
import { AreaKey, TokenTypeEnum } from '@/store/area'
//Atoms
import ADatePicker from '@/components/atoms/datePicker/ADatePicker.vue'
import ABaseSelect from '@/components/atoms/select/ABaseSelect.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AMultiSelectBase from '@/components/atoms/multiselect/AMultiSelectBase.vue'
import AButtonSmallRound from '@/components/atoms/button/AButtonSmallRound.vue'
import AButtonAndOr from '@/components/atoms/button/AButtonAndOr.vue'
//Molecule
import MPatternCondition from '@/components/molecules/order/stepper/MPatternCondition.vue'
//Store
import { FormatedOrderCondition, GetOperatorResponse } from '@/store/order'
//Mixins
import MixinOrder from '@/components/molecules/filter/MixinOrder.vue'
@Component({
  components: {
    ADatePicker,
    ABaseSelect,
    AInputTextField,
    ATitleMedium,
    AMultiSelectBase,
    AButtonSmallRound,
    AButtonAndOr,
    MPatternCondition
  }
})
export default class MCardCustomCondition extends Mixins(MixinOrder) {
  /**
   * @typedef {FormatedOrderCondition}
   * @prop {boolean} isIf
   * @prop {ConditionToken[]} ifConditionChain
   * @prop {ConditionToken[]} comparaisonChain
   */
  @Prop() conditionIndex!: number
  @Prop() condition!: FormatedOrderCondition
  @Prop() operators!: GetOperatorResponse
  @Prop() areaKeys!: AreaKey[]
  @Prop() readonly?: boolean
  /**
   * @var tokenTypeFirstCondition
   * @enum {TokenTypeEnum}
   * @description when you choice first type
   * @exemple
   * IF , COMPARAISON
   */
  public tokenTypeFirstCondition = [
    { type: TokenTypeEnum.IF },
    { type: TokenTypeEnum.COMPARAISON }
  ]
}
