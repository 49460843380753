


























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MListVariables extends Vue {
  @Prop() variables!: { name: string; displayName: string }[]
  @Prop() isVariableUsed!: () => boolean
}
