










import { Component, Mixins } from 'vue-property-decorator'
import MFilterDates from '@/components/molecules/filter/MFilterDates.vue'
import MixinFilters from '@/components/molecules/filter/MixinFilters.vue'
import MFilterHeading from '@/components/molecules/filter/MFilterHeading.vue'

@Component({
  components: {
    MFilterDates,
    MFilterHeading
  }
})
export default class OAdminProviderDashboardFilters extends Mixins(
  MixinFilters
) {
  created() {
    this.preloadFilters()
  }
}
