var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$can('read', 'Client'))?_c('v-row',{staticClass:"mt-5",attrs:{"wrap":""}},[(_vm.item.clientsAreas.client.sharingBlocklist && _vm.item.shareLimit >= 2)?_c('v-col',[_c('span',{staticClass:"yellow white--text pa-2 rounded-lg title"},[_vm._v(" Ce client à des réstrictions de mutualisation avec d'autres clients ")])]):_vm._e()],1):_vm._e(),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',[_c('ATitleMedium',{staticClass:"blueDark--text",attrs:{"text":"Statut"}})],1)],1),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('ATextField',{attrs:{"textField":{
          icon: 'mdi-list-status',
          text: _vm.item.status,
          style:
            'white--text green px-2 py-1 rounded-lg font-weight-black d-flex align-center'
        }}})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',[_c('ATitleMedium',{staticClass:"blueDark--text",attrs:{"text":"Nom"}})],1)],1),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('ATextField',{attrs:{"textField":{
          icon: 'mdi-clipboard-text-outline',
          title: 'Nom de la commande',
          text: _vm.item.name
        }}})],1),_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('ATextField',{attrs:{"textField":{
          icon: 'mdi-image-area-close',
          title: 'Thème',
          text: _vm.item.clientsAreas.area.name
        }}})],1),_c('v-col',{attrs:{"lg":"6","cols":"12"}},[(_vm.item.shareLimit === 1)?_c('ATextField',{attrs:{"textField":{
          icon: 'mdi-account',
          title: 'Type de commande',
          text: 'Exclusif'
        }}}):(_vm.item.shareLimit === 2)?_c('ATextField',{attrs:{"textField":{
          icon: 'mdi-account-multiple',
          title: 'Type de commande',
          text: 'Mutualisé * 2'
        }}}):_c('ATextField',{attrs:{"textField":{
          icon: 'mdi-account-group',
          title: 'Type de commande',
          text: 'Mutualisé * 3'
        }}})],1),_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('ATextField',{attrs:{"textField":{
          icon: 'mdi-alpha-p-box-outline',
          title: 'Produits',
          text: _vm.item.product.displayName
        }}})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',[_c('ATitleMedium',{staticClass:"blueDark--text",attrs:{"text":"Prix"}})],1)],1),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('ATextField',{attrs:{"textField":{
          icon: 'mdi-currency-eur',
          title: 'Prix de vente',
          text: ((_vm.item.price) + " €")
        }}})],1),_c('v-col',{attrs:{"lg":"6","cols":"12"}},[(_vm.item.cappingMax != -1)?_c('ATextField',{attrs:{"textField":{
          icon: 'mdi-cash-multiple',
          title: 'Prix Total',
          text: ((_vm.item.capping * _vm.item.price) + " €")
        }}}):_c('ATextField',{attrs:{"textField":{
          icon: 'mdi-cash-multiple',
          title: 'Prix Total',
          text: 'Pas de limite'
        }}})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',[_c('ATitleMedium',{staticClass:"blueDark--text",attrs:{"text":"Dates"}})],1)],1),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('ATextField',{attrs:{"textField":{
          icon: 'mdi-sort-calendar-descending',
          title: 'Date de début',
          text: _vm.formatDate(_vm.item.startAt)
        }}})],1),_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('ATextField',{attrs:{"textField":{
          icon: 'mdi-sort-calendar-ascending',
          title: 'Date de fin',
          text: _vm.item.endAt ? _vm.formatDate(_vm.item.endAt) : 'Illimité'
        }}})],1),_c('v-col',{attrs:{"lg":"6","cols":"12"}},[_c('ATextField',{attrs:{"textField":{
          icon: 'mdi-clipboard-arrow-right',
          title: 'Durée de la commande',
          text: _vm.item.endAt
            ? _vm.resultNbDays(_vm.item.startAt, _vm.item.endAt)
            : 'Illimité'
        }}})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',[_c('ATitleMedium',{staticClass:"blueDark--text",attrs:{"text":"Zips"}})],1)],1),_c('v-row',{attrs:{"wrap":""}},[_c('AExpansionPanels',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('ATitleMedium',{attrs:{"text":"Nombre de Zips enregistrés : "}})]},proxy:true},{key:"phrase",fn:function(){return [_vm._v(" Cliquez pour voir afficher ")]},proxy:true},{key:"nbItems",fn:function(){return [_c('div',[_c('span',{staticClass:"secondary--text font-weight-black title mr-1"},[_vm._v(_vm._s(_vm.item.zips.length))]),_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" mdi-earth ")])],1)]},proxy:true},{key:"panel-content",fn:function(){return _vm._l((_vm.item.zips),function(zips,key){return _c('AChip',{key:key,staticClass:"ma-1",attrs:{"color":"secondary","textColor":"white--text","closeIcon":false},scopedSlots:_vm._u([{key:"text-chip",fn:function(){return [_vm._v(" "+_vm._s(zips.zip.code)+" "+_vm._s(zips.zip.name)+" ")]},proxy:true}],null,true)})})},proxy:true}])})],1),_c('v-divider',{staticClass:"mt-8 mb-5"}),_c('v-row',{attrs:{"wrap":""}},[_c('v-col',[_c('ATitleMedium',{staticClass:"blueDark--text",attrs:{"text":_vm.$t('lead.actions.wsLogs')}})],1),(
        _vm.item.webserviceMailingList === null && _vm.item.connectorName === null
      )?_c('v-col',{attrs:{"cols":"auto"}},[_c('ATextField',{attrs:{"textField":{
          icon: '',
          iconSize: '18',
          iconStyle: 'white',
          text: 'Inactif',
          style:
            'white--text red px-2 py-1 rounded-lg font-weight-black d-flex align-center'
        }}})],1):_vm._e(),(
        _vm.item.webserviceMailingList !== null || _vm.item.connectorName !== null
      )?_c('v-col',{attrs:{"cols":"auto"}},[_c('ATextField',{attrs:{"textField":{
          icon: '',
          iconSize: '18',
          iconStyle: 'white',
          text: 'Actif',
          style:
            'white--text green px-2 py-1 rounded-lg font-weight-black d-flex align-center'
        }}})],1):_vm._e()],1),(_vm.item.webserviceMailingList !== null)?_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('ATextField',{attrs:{"textField":{
          icon: 'mdi-email',
          title: 'Notifications email',
          text: _vm.item.webserviceMailingList
            .map(function (e, i) { return i + 1 + ') ' + e; })
            .join(' - ')
        }}})],1)],1):_vm._e(),(_vm.item.connectorName !== null)?_c('v-row',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('ATextField',{attrs:{"textField":{
          icon: 'mdi-link-variant',
          title: 'Connecteur',
          text: _vm.item.connectorName
        }}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }