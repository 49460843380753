











import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import NotificationModule, { SnackBar } from '@/store/notification'
import ABaseSnackBar from '@/components/atoms/snackbar/ABaseSnackBar.vue'

@Component({
  components: {
    ABaseSnackBar
  }
})
export default class MSnackBar extends Vue {
  public notificationModule = getModule(NotificationModule, this.$store)

  public onRemove(notification: SnackBar, index: number) {
    this.notificationModule.remove(index)

    if (notification.persist) {
      this.$emit('removeLogas', notification)
    }
  }
}
