
















import { Component, Prop, Vue } from 'vue-property-decorator'
import AButtonRouter from '@/components/atoms/button/AButtonRouter.vue'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AIconAction from '@/components/atoms/AIconAction.vue'

export interface MenuProps {
  route: string
  text: string
}

@Component({
  components: {
    AButtonRouter,
    ATitleMedium,
    AIconAction
  }
})
export default class MTableMenu extends Vue {
  @Prop() title!: string
  @Prop() menu!: MenuProps[]
}
