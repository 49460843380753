








































import { Component, Vue, PropSync } from 'vue-property-decorator'
import AExpansionPanels from '@/components/atoms/expansion/AExpansionPanels.vue'
import ATitleSmall from '@/components/atoms/title/ATitleSmall.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import ATextLarge from '@/components/atoms/text/ATextLarge.vue'
import ABaseButton from '@/components/atoms/button/ABaseButton.vue'
import ACheckbox from '@/components/atoms/checkbox/ACheckbox.vue'

@Component({
  components: {
    AExpansionPanels,
    ATitleSmall,
    AInputTextField,
    ATextLarge,
    ABaseButton,
    ACheckbox
  }
})
export default class MAnonymizePanel extends Vue {
  @PropSync('email') emailSync!: string
  @PropSync('sendEmail') sendEmailSync!: string
}
