















import { Component, Prop } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ABaseTitle.vue'
import ABaseText from '@/components/atoms/text/ABaseText.vue'
import { VAlert } from 'vuetify/lib'

@Component({
  components: {
    ATitleMedium,
    ABaseText
  }
})
export default class MAlert extends VAlert {
  @Prop() title!: string
  @Prop() text!: string
}
