































import { Component, PropSync, Vue } from 'vue-property-decorator'
import APagination from '@/components/atoms/pagination/APagination.vue'
import APaginationSearch from '@/components/atoms/pagination/APaginationSearch.vue'
import APaginationButtonIcon from '@/components/atoms/pagination/APaginationButtonIcon.vue'

import { TableProperties } from '@/types'

@Component({
  components: {
    APagination,
    APaginationSearch,
    APaginationButtonIcon
  }
})
export default class MTableFooterFrontSorting extends Vue {
  @PropSync('tableProperties') tablePropertiesSynced!: TableProperties
}
