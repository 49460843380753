


























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import ACardArea from '@/components/atoms/card/ACardArea.vue'
import { AreaAndIcon } from '@/store/area'
//Template
import TSkeleton from '@/components/templates/TSkeleton.vue'
@Component({
  components: {
    ACardArea,
    TSkeleton
  }
})
export default class MCardsAreas extends Vue {
  @Prop() titles!: AreaAndIcon[]
  @Prop() icon!: string[]
  @PropSync('loading') loadingSynced!: boolean
  @PropSync('value') valueSynced!: number | null
}
