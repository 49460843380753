
































































import { Component, PropSync, Vue } from 'vue-property-decorator'
import ATextMedium from '@/components/atoms/text/ATextMedium.vue'
import ATextMediumLarge from '@/components/atoms/text/ATextMediumLarge.vue'
import ATextLarge from '@/components/atoms/text/ATextLarge.vue'
import ACheckbox from '@/components/atoms/checkbox/ACheckbox.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import ABaseSelect from '@/components/atoms/select/ABaseSelect.vue'
import AMultiSelectBase from '@/components/atoms/multiselect/AMultiSelectBase.vue'
import MInputTextFieldMultiple, {
  TextFieldProps
} from '@/components/molecules/input/MInputTextFieldMultiple.vue'
import AMultipleSelectChips from '@/components/atoms/select/AMultipleSelectChips.vue'
import ClientModule from '@/store/client'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {
    ATextMedium,
    ATextLarge,
    ATextMediumLarge,
    ACheckbox,
    AInputTextField,
    ABaseSelect,
    MInputTextFieldMultiple,
    AMultiSelectBase,
    AMultipleSelectChips
  }
})
export default class MOrderStepWS extends Vue {
  @PropSync('values') fieldsValues!: string[]
  @PropSync('connectorName') connector?: string

  public clientModule = getModule(ClientModule, this.$store)
  public emailDisplay = false
  public connectorDisplay = false

  mounted() {
    this.emailDisplay = this.fieldsValues?.length > 0
    this.connectorDisplay = !!this.connector
  }

  /**
   * @function clearFields
   * @description Clear all text fields when the checkbox is clicked
   */
  clearFields() {
    this.fieldsValues = []
  }

  /**
   * @function clearConnector
   * @description Clear the connector when the checkbox is clicked
   */
  clearConnector() {
    this.connector = undefined
  }

  /**
   * @description Props of dynamic fields
   * @type TextFieldProps
   */
  public dynamicComponent: TextFieldProps = {
    component: 'AInputTextField',
    type: 'text',
    label: 'Email',
    name: 'email',
    rules: 'required|email'
  }
}
