

















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import ACard from '@/components/atoms/card/ACard.vue'

@Component({
  components: {
    ACard
  }
})
export default class MCards extends Vue {
  @Prop() titles!: {
    id: string | number
    displayName: string
    disabled?: boolean
  }[]
  @PropSync('value') valueSynced!: number | string | null
}
