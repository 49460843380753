














import { Component, Vue } from 'vue-property-decorator'
import OAdminTableLeadsRejected from '@/components/organisms/admin/OAdminTableLeadsRejected.vue'
import OAdminTableLeadsRejectedFilters from '@/components/organisms/admin/OAdminClientDashboardFilters.vue'
import TTableWithFilters from '@/components/templates/TTableWithFilters.vue'
import { getModule } from 'vuex-module-decorators'
import LeadRejectedModule, { LeadRejected } from '@/store/leadRejected'

@Component({
  components: {
    TTableWithFilters,
    OAdminTableLeadsRejected,
    OAdminTableLeadsRejectedFilters
  }
})
export default class PAdminLeadsRejected extends Vue {
  public leadRejectedModule = getModule(LeadRejectedModule, this.$store)

  async clickHandler(leadRejected: LeadRejected) {
    const path = `/leads/leads-rejected/${leadRejected.id}`
    if (this.$route.path !== path) {
      await this.$router.push({ path })
    }
  }
}
