





























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import ATextMedium from '@/components/atoms/text/ATextMedium.vue'
import MCards from '@/components/molecules/MCards.vue'

@Component({
  components: {
    AInputTextField,
    ATextMedium,
    MCards
  }
})
export default class MWebhookStepOne extends Vue {
  @PropSync('name') nameSynced!: string
  @PropSync('category') categorySynced!: number | null
  @Prop() categories!: { id: string | number; displayName: string }
}
