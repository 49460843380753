import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { i18n } from '@/plugins/i18n'

export interface AuthenticationState {
  loggedIn: boolean
  loggedAs: boolean
  fromRoute: string | null
}

export interface Login {
  email: string
  password: string
}

export interface RenewPassword {
  confirm: string
  password: string
}

export interface LogAsFields {
  id: { clientId?: number; providerId?: number }
  currentRoute: string
}
/**
 * @deprecated
 */
export interface RenewPasswordViaToken extends Omit<RenewPassword, 'confirm'> {
  token: string
}
export interface ResetPassword {
  password: string
  resetToken: string
}

@Module({ namespaced: true, name: 'authentication' })
export default class AuthenticationModule
  extends VuexModule
  implements AuthenticationState
{
  loggedIn = false
  loggedAs = false
  fromRoute: string | null = null

  get isAuthenticated() {
    return this.loggedIn
  }

  @Mutation
  updateLogin(loggedIn: boolean) {
    this.loggedIn = loggedIn
  }

  @Mutation
  updateLogas(loggedAs: boolean) {
    this.loggedAs = loggedAs
  }
  /**
   * @param route
   */
  @Mutation
  updateFromRoute(route: string | null) {
    this.fromRoute = route
  }

  /**
   * @function login
   * @param log
   * @description fetch login,
   * if ok active overlay from App file
   * else manage error and set loading state at false inside loaderModule
   */
  @Action
  async login(log: { loaderModule: any; loginFields: Login }) {
    await Vue.prototype.$http
      .post('/auth/login', log.loginFields, {
        errorHandler: {
          message: 'Identifiant ou mot de passe incorrect'
        },
        successHandler: {
          message: 'Connexion réussie'
        }
      })
      .then((resp: { status: number }) => {
        if (resp.status === 200 || resp.status === 201) {
          log.loaderModule.show()
        }
      })
      .catch((err: { response: { status: number } }) => {
        if (err.response.status === 500) {
          this.context.commit('updateLogin', true)
        }
        if (err.response.status === 401) {
          log.loaderModule.loading = false
        }
      })
    log.loaderModule.hide()
    this.context.commit('updateLogin', true)
  }

  @Action
  async resetPassword(email: string) {
    await Vue.prototype.$http.post(
      '/auth/forgot',
      { email },
      {
        errorHandler: {
          message: "L'email est introuvable"
        },
        successHandler: {
          message: `${i18n.t('login.resetPassword')} ${email}`
        }
      }
    )
  }

  @Action
  async renewPassword(passwordAndToken: ResetPassword) {
    await Vue.prototype.$http.put(
      '/auth/reset',
      {
        password: passwordAndToken.password,
        resetToken: passwordAndToken.resetToken
      },
      {
        successHandler: true
      }
    )
  }
  /**
   * @function logout
   * @return @type {void}
   */
  @Action
  async logout(): Promise<void> {
    await Vue.prototype.$http.delete('/auth/logout', {
      successHandler: {
        message: 'Déconnexion réussie'
      }
    })

    this.context.commit('updateLogin', false)
    this.context.commit('updateLogas', false)
  }
  /**
   * @function renewToken
   * @return @type {void}
   * @description
   * fetch api and return new token within header (set-cookie)
   * @info
   * Don't renew token log as CLIENT or PROVIDER
   * if refresh in log as you return on SUPER or ADMIN
   */
  @Action
  async renewToken(): Promise<void> {
    await Vue.prototype.$http.post('/auth/refresh', {}, { errorHandler: false })

    this.updateLogin(true)
    this.updateLogas(false)
  }
  /**
   * @function logas
   * @param logasFields @type {LogAsFields}
   * @return @type {void}
   * @description
   * log as CLIENT or Provider (SUPER or ADMIN only)
   */
  @Action
  async logas(logasFields: LogAsFields): Promise<void> {
    await Vue.prototype.$http.post('/auth/logas/', logasFields.id, {
      errorHandler: {
        message: 'Problème de connexion'
      },
      successHandler: {
        message: 'Connexion réussie'
      }
    })
    this.updateLogas(true)
    //return on last route SUPER or ADMIN use
    this.updateFromRoute(logasFields.currentRoute)
  }
}
