














import { Component, Vue } from 'vue-property-decorator'

@Component
export default class AMenu extends Vue {
  menu = false

  close() {
    this.menu = false
  }
}
