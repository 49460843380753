











import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component({})
export default class ACheckboxLead extends Vue {
  @PropSync('value') valueSynced!: []
}
