





























import { VExpansionPanels } from 'vuetify/lib'
import { Component } from 'vue-property-decorator'

@Component
export default class AExpansionPanels extends VExpansionPanels {}
