






































import { Component, PropSync, Vue } from 'vue-property-decorator'
//Atoms
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AInputTextFieldPassword from '@/components/atoms/input/AInputTextFieldPassword.vue'
import AAvatar from '@/components/atoms/avatar/AAvatar.vue'
import ATextMedium from '@/components/atoms/text/ATextMedium.vue'
import ABaseText from '@/components/atoms/text/ABaseText.vue'
import ATextMediumLarge from '@/components/atoms/text/ATextMediumLarge.vue'

import MInputPasswordSolid from '@/components/molecules/input/MInputPasswordSolid.vue'

import { RenewPassword } from '@/store/authentication'

@Component({
  components: {
    ATitleMedium,
    AInputTextFieldPassword,
    AAvatar,
    ATextMedium,
    ABaseText,
    ATextMediumLarge,
    MInputPasswordSolid
  }
})
export default class MFormRenewPassword extends Vue {
  @PropSync('passwordFields') passwordFieldsSynced!: RenewPassword

  get passwordProps() {
    return this.passwordFieldsSynced
  }
}
