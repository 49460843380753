















import { Component, Vue } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AButtonLight from '@/components/atoms/button/AButtonLight.vue'

@Component({
  components: {
    ATitleMedium,
    AButtonLight
  }
})
export default class MFilterHeading extends Vue {}
