import { NestApiResponse } from '@/types'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export type UpdateManualInvoiceBodyRequestDto = {
  contestationPercentRatio?: number
  invoiceAmt?: number
  billingDate?: Date
}

export type UpdateManualInvoiceRequestDto = {
  id: string
  body: UpdateManualInvoiceBodyRequestDto
}

export interface ManualInvoiceSummaryResponseDto {
  contestationAmt: number
  issuedAmt: number
  invoicePercentRatio: number
  contestationPercent: number
  invoiceAmt: number
  manualInvoiceId: string
  invoiceDate: Date | string
  startDate: Date
  endDate: Date
  invoicePeriod: string
  invoiceNo: string
  companyId: number
  invoiceStatus: string
  isLoading?: boolean
  billingDate: Date
}

@Module({ namespaced: true, name: 'manualInvoice' })
export default class ManualInvoiceModule extends VuexModule {
  companyManualInvoices: NestApiResponse<ManualInvoiceSummaryResponseDto> | null =
    null

  @Mutation
  saveCompanyManualInvoices(
    companyManualInvoices: NestApiResponse<ManualInvoiceSummaryResponseDto>
  ) {
    companyManualInvoices.items = companyManualInvoices.items.map((item) => {
      return { ...item, isLoading: false }
    })
    this.companyManualInvoices = companyManualInvoices
  }

  @Mutation
  updateCompanyManualInvoices(
    companyManualInvoices: ManualInvoiceSummaryResponseDto
  ) {
    this.companyManualInvoices!.items = this.companyManualInvoices!.items.map(
      (companyManualInvoice: ManualInvoiceSummaryResponseDto) => {
        if (
          companyManualInvoice.manualInvoiceId ===
          companyManualInvoices.manualInvoiceId
        ) {
          return companyManualInvoices
        }
        return companyManualInvoice
      }
    )
  }

  @Action({ commit: 'saveCompanyManualInvoices' })
  async fetchManualInvoicesByClientId(
    clientId: number
  ): Promise<NestApiResponse<ManualInvoiceSummaryResponseDto>> {
    const { data } = await Vue.prototype.$http.get(
      `/manual-invoices/${clientId}`,
      {
        useQueryParams: true
      }
    )

    return data
  }

  @Action({ commit: 'updateCompanyManualInvoices' })
  async updateManualInvoice({ body, id }: UpdateManualInvoiceRequestDto) {
    const { data } = await Vue.prototype.$http.put(
      `/manual-invoices/${id}`,
      body
    )

    return data
  }

  destroy() {
    this.companyManualInvoices = null
  }
}
