








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ACheckboxZip extends Vue {
  @Prop() all!: boolean
}
