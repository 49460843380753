var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar-nav-icon',{staticClass:"sidebar-button",on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-navigation-drawer',{staticClass:"sidebar-drawer",attrs:{"app":"","left":"","width":176},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('router-link',{staticClass:"sidebar-drawer__logo mt-8 mb-2 mx-auto",attrs:{"to":"/dashboard"}},[_c('ATitleLogo',{staticClass:"logo",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"primary--text"},[_vm._v("Leads")]),_c('span',{staticClass:"blueDark--text"},[_vm._v(".fr")])]},proxy:true}])}),_c('ABaseTitle',{staticClass:"sidebar-drawer__role",class:_vm.colorRoleName,attrs:{"text":_vm.user.role,"tag":"h2"}})],1),_c('v-list',{staticClass:"sidebar-list",attrs:{"nav":""}},[(
          _vm.$can('show', { modelName: 'SidebarLinks', role: 'SUPER' }) ||
          _vm.$can('show', { modelName: 'SidebarLinks', role: 'ADMIN' })
        )?_c('MSideBar',{attrs:{"items":_vm.adminItems}}):_vm._e(),(_vm.$can('show', { modelName: 'SidebarLinks', role: 'CLIENT' }))?_c('MSideBar',{attrs:{"items":_vm.clientItems}}):_vm._e(),(_vm.$can('show', { modelName: 'SidebarLinks', role: 'PROVIDER' }))?_c('MSideBar',{attrs:{"items":_vm.providerItems}}):_vm._e(),_c('div',{staticClass:"sidebar-list__spacer"}),_vm._v(" "+_vm._s(_vm.$route.query.role)+" "),_c('v-list',{staticClass:"sidebar-list",attrs:{"nav":""}},[_c('div',{staticClass:"d-flex"},[(
              _vm.$can('show', { modelName: 'SidebarLinks', role: 'SUPER' }) ||
              _vm.$can('show', { modelName: 'SidebarLinks', role: 'ADMIN' })
            )?_c('MSideBar',{staticClass:"mr-n10",attrs:{"items":_vm.rgpdItems}}):_vm._e(),(
              _vm.$can('show', { modelName: 'SidebarLinks', role: 'SUPER' }) ||
              _vm.$can('show', { modelName: 'SidebarLinks', role: 'ADMIN' })
            )?_c('v-icon',{staticClass:"orange--text",attrs:{"size":"20"}},[_vm._v("mdi-information-outline")]):_vm._e()],1)]),_c('div',{staticClass:"sidebar-list__user"},[(
            _vm.$can('show', { modelName: 'SidebarLinks', role: 'SUPER' }) ||
            _vm.$can('show', { modelName: 'SidebarLinks', role: 'ADMIN' }) ||
            _vm.$can('show', { modelName: 'SidebarLinks', role: 'OPERATOR' })
          )?_c('ASelectAvatar',{attrs:{"items":_vm.avatarAdmin,"user":((_vm.user.firstName) + " " + (_vm.user.lastName))}}):_vm._e(),(
            _vm.$can('show', { modelName: 'SidebarLinks', role: 'CLIENT' }) ||
            _vm.$can('show', { modelName: 'SidebarLinks', role: 'PROVIDER' })
          )?_c('ASelectAvatar',{attrs:{"items":_vm.avatar,"user":((_vm.user.firstName) + " " + (_vm.user.lastName))}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }