




































import { Component, Vue } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import ABaseSelect from '@/components/atoms/select/ABaseSelect.vue'
import ABaseTextArea from '@/components/atoms/textarea/ABaseTextArea.vue'
import { getModule } from 'vuex-module-decorators'
import SupportModule, { Ticket } from '@/store/support'
import { ValidationObserver } from 'vee-validate'
import UserModule, { CurrentUser } from '@/store/user'

@Component({
  components: {
    ATitleMedium,
    AButtonPrimary,
    ABaseSelect,
    ABaseTextArea,
    ValidationObserver
  }
})
export default class OSupport extends Vue {
  public supportModule = getModule(SupportModule, this.$store)
  public userModule = getModule(UserModule, this.$store)

  supportFields: Ticket = {
    subject: '',
    message: ''
  }

  mounted() {
    this.request()
  }

  public request() {
    this.supportModule.fetchAllSubjects()
  }

  public async submit() {
    await this.supportModule.new({
      id: (this.userModule.currentUser as CurrentUser).id,
      data: this.supportFields
    })
    this.clearInput()
  }

  public clearInput() {
    this.supportFields = {
      subject: '',
      message: ''
    }
  }
}
