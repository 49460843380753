

































































































import { Component, PropSync, Vue } from 'vue-property-decorator'
//Atoms
import ATitleSmall from '@/components/atoms/title/ATitleSmall.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import AAvatar from '@/components/atoms/avatar/AAvatar.vue'

import { ProfilProperties } from './MFormProfil.vue'
import { Address } from '@/store/company'

export interface ProfilBilling {
  displayName: string
  registrationNumber: string
  vat: string
  address: Address
}

@Component({
  components: {
    ATitleSmall,
    AInputTextField,
    AAvatar
  }
})
export default class MFormProfilBilling extends Vue {
  @PropSync('profilProperties') profilPropertiesSynced!: ProfilProperties

  get profilBilling() {
    return this.profilPropertiesSynced.profilBilling!
  }
}
