










import { Component, Prop, Vue } from 'vue-property-decorator'
import ATextExtra from '@/components/atoms/text/ATextExtra.vue'
import ATextLarge from '@/components/atoms/text/ATextLarge.vue'

@Component({
  components: {
    ATextExtra,
    ATextLarge
  }
})
export default class MStatNumbers extends Vue {
  @Prop() title!: number
  @Prop() subtitle!: string
}
