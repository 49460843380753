




import UserModule from '@/store/user'
import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {
    PAdminLeads: () => import('@/components/pages/admin/PAdminLeads.vue'),
    PProviderLeads: () =>
      import('@/components/pages/provider/PProviderLeads.vue'),
    PClientLeads: () => import('@/components/pages/client/PClientLeads.vue')
  }
})
export default class Rleads extends Vue {
  public userModule = getModule(UserModule, this.$store)
  public component: string | null = null

  created() {
    this.componentFromRole()
  }

  private componentFromRole() {
    if (this.userModule.isAdmin) {
      this.component = 'PAdminLeads'
    }

    if (this.userModule.isClient) {
      this.component = 'PClientLeads'
    }

    if (this.userModule.isProvider) {
      this.component = 'PProviderLeads'
    }
  }
}
