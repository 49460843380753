import Vue from 'vue'
import { Action, Mutation, VuexModule, Module } from 'vuex-module-decorators'

export interface ZipState {
  zips: ZipByRegion[]
}

export interface ZipByRegion {
  region: string
  data: Zip[]
}

export interface Zip {
  id: number
  region: string
  code: number
  name: string
}
/**
 * @type { OrderZips }
 * @description exclusively when you click on checkbox of zip MOrderStepFour
 */
export type OrderZips = {
  zips: Zip[]
}
@Module({ namespaced: true, name: 'zip' })
export default class ZipModule extends VuexModule implements ZipState {
  zips: ZipByRegion[] = []

  @Mutation
  saveAll(zips: ZipByRegion[]) {
    this.zips = zips
  }

  @Action({ commit: 'saveAll' })
  async fetchAll() {
    const { data } = await Vue.prototype.$http.get('/zips')
    return data
  }
}
