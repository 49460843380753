






import ClientModule from '@/store/client'
import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component
export default class OLeadZero extends Vue {
  public clientModule = getModule(ClientModule, this.$store)
}
