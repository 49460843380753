






































import { Component, Vue } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import MFormSignUp from '@/components/molecules/form/MFormSignUp.vue'
import MInputPasswordSolid from '@/components/molecules/input/MInputPasswordSolid.vue'
import { ValidationObserver } from 'vee-validate'
import UserModule, { SignUp } from '@/store/user'
import { getModule } from 'vuex-module-decorators'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonLight from '@/components/atoms/button/AButtonLight.vue'

@Component({
  components: {
    ATitleMedium,
    MFormSignUp,
    ValidationObserver,
    AButtonPrimary,
    AButtonLight,
    MInputPasswordSolid
  }
})
export default class OAdminProviderUserNew extends Vue {
  private userModule = getModule(UserModule, this.$store)

  public signUpFields: SignUp = {
    firstName: '',
    lastName: '',
    password: '',
    phone: '',
    email: ''
  }

  created() {
    this.signUpFields.providerId = +this.$route.params.providerId
    this.$route.params.name = 'newProvider'
  }

  public async submit() {
    await this.userModule.signupProviderUser(this.signUpFields)
    this.$router.push({
      path: `/partenaires/${this.$route.params.providerId}/utilisateurs`
    })
  }
}
