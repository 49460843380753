































import { Component, Vue } from 'vue-property-decorator'
//Atoms
import AIconAction from '@/components/atoms/AIconAction.vue'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
//molecules
import MTableMenu, {
  MenuProps
} from '@/components/molecules/table/MTableMenu.vue'
//Template
import TMenuWithDynamicContent from '@/components/templates/TMenuWithDynamicContent.vue'
//Store
import { getModule } from 'vuex-module-decorators'
import ProviderModule from '@/store/provider'

@Component({
  components: {
    AIconAction,
    ATitleMedium,
    MTableMenu,
    TMenuWithDynamicContent
  }
})
export default class PAdminProvider extends Vue {
  public get menuProvider(): MenuProps[] {
    return [
      {
        text: this.$tc('dashboard.full'),
        route: `/partenaires/${this.$route.params.providerId}/dashboard`
      },
      {
        text: this.$tc('user.name', 2),
        route: `/partenaires/${this.$route.params.providerId}/utilisateurs`
      },
      {
        text: this.$tc('provider.sitekey', 2),
        route: `/partenaires/${this.$route.params.providerId}/sitekeys`
      },
      {
        text: this.$tc('transaction.earnings'),
        route: `/partenaires/${this.$route.params.providerId}/remunerations`
      },
      {
        text: 'Compte',
        route: `/partenaires/${this.$route.params.providerId}/profil`
      },
      {
        text: 'Thématiques',
        route: `/partenaires/${this.$route.params.providerId}/thematiques`
      }
    ]
  }
  public providerModule = getModule(ProviderModule, this.$store)
  mounted() {
    const providerId = this.$route.params.providerId
    this.providerModule.fetchOne(providerId)
  }
}
