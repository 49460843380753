




















import { Component, Vue, PropSync, Watch } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import MRadioMenu from '@/components/molecules/radio/MRadioMenu.vue'

@Component({
  components: {
    ATitleMedium,
    AInputTextField,
    MRadioMenu
  }
})
export default class MFormLeadOwner extends Vue {
  @PropSync('rent') rentSynced!: string
  @PropSync('own') ownSynced!: string

  public radiodata = [
    { label: 'Oui', value: 'radio-1' },
    { label: 'Non', value: 'radio-2' }
  ]

  public rules = {
    required: true,
    numeric: true
  }

  @Watch('ownSynced')
  changeValue(data: string) {
    this.ownSynced = data
  }
}
