import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface SnackBarState {
  notifications: SnackBar[]
}
export interface SnackBar {
  id?: number
  color: 'green' | 'primary' | 'yellow' | 'error'
  timeout?: number
  message: string
  show: boolean
  persist?: boolean //if persist disconect button
  link?: string
}

export type SnackBarHandler = Omit<SnackBar, 'id' | 'color' | 'show'>

@Module({ namespaced: true, name: 'notification' })
export default class NotificationModule
  extends VuexModule
  implements SnackBarState
{
  notifications: SnackBar[] = []

  @Mutation
  save(newNotification: SnackBar) {
    // Generate an ID to make sure every single notification is different
    newNotification.id = new Date().getTime()
    if (
      !this.notifications.some(
        (notification) => notification.message === newNotification.message
      )
    ) {
      this.notifications.push(newNotification)
    }
  }

  @Mutation
  delete(notificationId: number) {
    this.notifications.splice(notificationId, 1)
  }

  @Mutation
  deleteAllPersisted() {
    this.notifications = this.notifications.filter(
      (notification) => !notification.persist
    )
  }

  @Action
  add(notification: SnackBar) {
    this.context.commit('save', notification)
  }

  @Action
  remove(notificationId: number) {
    this.context.commit('delete', notificationId)
  }

  /**
   * @deprecated
   * @param editedFields
   * @returns
   */
  @Action
  async updateNotification(editedFields: { id: number; sms: boolean }) {
    const { data } = await Vue.prototype.$http.put(
      `/notifications/${editedFields.id}`,
      {
        sms: editedFields.sms
      }
    )

    return data
  }
}
