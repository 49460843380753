






















import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import { getModule } from 'vuex-module-decorators'
import ProviderModule, {
  EditMultipleProviderAreas,
  ProviderArea
} from '@/store/provider'
import MFormProviderAreas from '@/components/molecules/form/MFormProviderAreas.vue'
@Component({
  components: {
    ValidationObserver,
    ATitleMedium,
    MFormProviderAreas
  }
})
export default class OAdmiProviderAreasEdit extends Vue {
  public providerModule = getModule(ProviderModule, this.$store)

  public editProviderAreasPayload: EditMultipleProviderAreas[] = []

  async mounted() {
    await this.providerModule.fetchOne(this.$route.params.providerId)

    this.editProviderAreasPayload = this.providerModule.provider!.areas.map(
      (providerArea: ProviderArea) => {
        return {
          refShare: providerArea.refShare,
          cpl: providerArea.cpl,
          isCpl: providerArea.isCpl,
          id: providerArea.id,
          name: providerArea.area.displayName
        }
      }
    )
  }

  /**
   * @function updatePaylodBeforeSend
   * @description Set property non used at 0 to not store irrevelant values.
   */
  public updatePaylodBeforeSend() {
    this.editProviderAreasPayload.map(
      (providerArea: EditMultipleProviderAreas) => {
        providerArea.isCpl === false
          ? (providerArea.cpl = 0)
          : (providerArea.refShare = 0)
      }
    )
  }
  public async updateProviderAreas() {
    this.updatePaylodBeforeSend()
    await this.providerModule.editMultipleAreas({
      id: +this.$route.params.providerId,
      areas: this.editProviderAreasPayload!
    })
  }
}
