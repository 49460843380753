



































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import APagination from '@/components/atoms/pagination/APagination.vue'
import APaginationButtonIcon from '@/components/atoms/pagination/APaginationButtonIcon.vue'
import APaginationSearch from '@/components/atoms/pagination/APaginationSearch.vue'
import { getModule } from 'vuex-module-decorators'
import FilterModule from '@/store/filter'
import { PaginationResponse } from '@/types'

@Component({
  components: {
    APagination,
    APaginationButtonIcon,
    APaginationSearch
  }
})
export default class MTableFooter extends Vue {
  public filterModule = getModule(FilterModule, this.$store)

  //Choice nb item per page
  public itemsPerPageArray: Array<number> = [10, 20, 50, 100]

  @Prop()
  tableProperties!: PaginationResponse
  //Garage storage
  @Watch('$route', { immediate: true })
  updateLimit(): void {
    this.filterModule.reqNbrItemPerPage(this.tableProperties.itemsPerPage)
  }
  /**
   * @function updateItemsPerPage
   * @param nbr
   * @description listen event click and save number inside variable item per page. Updating filters (limit object only)
   * we set tableProperties.itemPage because we display this
   */
  public updateItemsPerPage(nbr: number): void {
    this.tableProperties.itemsPerPage = nbr
    this.filterModule.updateFilter({ limit: this.tableProperties.itemsPerPage })
  }

  public get page() {
    return this.filterModule.filters.page || 1
  }

  public set page(value: number) {
    this.filterModule.updateFilter({ page: value })
  }
}
