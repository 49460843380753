









































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'

//Atoms
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import ABaseTitle from '@/components/atoms/title/ABaseTitle.vue'
import AMultipleSelectChips from '@/components/atoms/select/AMultipleSelectChips.vue'
//Icon
import AIconInfo from '@/components/atoms/icons/AIconInfo.vue'
//Molecules
import MFormClientWeight from '@/components/molecules/form/MFormClientWeight.vue'
import MExcludeMutuPanel from '@/components/molecules/mutualisation/MExcludeMutuPanel.vue'
import MConnectorPanel from '@/components/molecules/connector/MConnectorPanel.vue'
import MModal, { MModalProps } from '@/components/molecules/modal/MModal.vue'
import MRedirectionClient from '@/components/molecules/connector/MRedirectionClient.vue'
//Store
import { getModule } from 'vuex-module-decorators'
import ClientModule, { Client, ClientCompanies } from '@/store/client'
//Types
import { DataTableHeader } from 'vuetify'

@Component({
  components: {
    ValidationObserver,
    ATitleMedium,
    AButtonPrimary,
    ABaseTitle,
    AIconInfo,
    AMultipleSelectChips,
    MFormClientWeight,
    MExcludeMutuPanel,
    MConnectorPanel,
    MModal,
    MRedirectionClient
  }
})
export default class OAdminClientOptions extends Vue {
  public clientModule = getModule(ClientModule, this.$store)
  //Ponderation
  public weight: string | null = null
  //Limit mutu
  public loading = false

  public returnIdClients: number[] = []

  public returnSelectBlockListId: ClientCompanies[] = []
  public returnClientConnectorList: { name: string }[] = []

  //All clients name
  public clientsNameList: ClientCompanies[] = []
  //Client selection for modal
  public selectClientBlockList: ClientCompanies[] = []
  // All connectors linked to a client
  public clientConnectors: string[] = []
  // All connectors alone (not linked)
  public aloneConnectors: string[] = []
  // list selected alone selector
  public selectAloneConnectorList: string[] = []

  public infosClient: {
    logo: string | undefined
    url: string | undefined
    redirection: boolean | undefined
  } = {
    logo: '',
    url: '',
    redirection: false
  }

  //Table
  public headers: DataTableHeader<Client>[] = [
    { text: 'Nom', value: 'companyName', align: 'start', sortable: true }
  ]
  public headersConnectors: DataTableHeader<string>[] = [
    { text: 'Nom', value: 'name', align: 'start', sortable: true }
  ]
  public get modalProps(): MModalProps {
    return {
      title: this.$t('account.excludeMutu.modal.title') as string,
      actionText: this.$t('account.excludeMutu.modal.buttonSend') as string,
      width: '600px',
      modalActionSubmit: this.updateClientOptions,
      modalActionCancel: this.cancelModal,
      peristent: true
    }
  }

  public get modalConnectorProps(): MModalProps {
    return {
      title: this.$t('account.connector.modal.title') as string,
      actionText: this.$t('account.connector.modal.buttonSend') as string,
      width: '600px',
      modalActionSubmit: this.updateClientOptions,
      modalActionCancel: this.cancelModalConnector,
      peristent: true
    }
  }

  get modal() {
    return this.$refs.modal as MModal
  }
  get modalConnector() {
    return this.$refs.modalConnector as MModal
  }

  @Watch('clientModule.client.sharingBlocklist', { deep: true })
  async onChange() {
    await Promise.all([
      //Reload list
      this.updateClientName(),
      this.updateClientList()
    ])
    //Add clients exclude of mutualisation when he changed
    this.returnIdClients = this.clientModule.client!.sharingBlocklist
  }

  @Watch('clientModule.connectors', { deep: true })
  async onChangeConnector() {
    await Promise.all([
      //Reload list
      this.updateAloneConnectorList(),
      this.updateClientConnetor()
    ])
    this.clientConnectors = this.clientModule.connectors
  }

  async mounted() {
    await Promise.all([
      this.clientModule.fetchOneClient(+this.$route.params.clientId),
      //fetch all for find all clients inside v-select modal (exclude mutu)
      this.clientModule.fetchAll(),
      //fetch client compagnie, name and id
      this.clientModule.fetchClientCompanies(),
      // fetch all alone connector
      this.clientModule.fetchAloneConnectors(),
      // fetch all client connector
      this.clientModule.fetchConnectors(+this.$route.params.clientId)
    ])
    this.weight = this.clientModule.client!.weight.toString()
    await Promise.all([
      this.updateClientList(),
      this.updateClientName(),
      this.updateAloneConnectorList(),
      this.updateClientConnetor(),
      this.updateClientInfos()
    ])
  }
  //Display name of client inside table
  public updateClientName() {
    this.loading = true

    this.clientsNameList = this.clientModule.clientCompanies.filter(
      (item) =>
        this.clientModule.client?.sharingBlocklist &&
        this.clientModule.client.sharingBlocklist.find(
          (id) => id === item.clientId
        )
    )

    this.loading = false
  }
  /**
   * delete current customer
   */
  public updateClientList() {
    this.loading = true
    //client exclu
    const currentBlockList = this.clientModule.client!.sharingBlocklist
    this.selectClientBlockList = this.clientModule.clientCompanies.filter(
      (clients) => {
        if (currentBlockList) {
          return (
            //delete current client
            clients.clientId != +this.$route.params.clientId &&
            //delete client selected
            !currentBlockList.includes(clients.clientId)
          )
        } else this.selectClientBlockList = []
      }
    )
    this.loading = false
  }

  public updateClientInfos() {
    this.loading = true
    this.infosClient.logo = this.clientModule.client?.logo
    this.infosClient.url = this.clientModule.client?.thankYouUrl
    this.infosClient.redirection = this.clientModule.client?.redirection
    this.loading = false
  }

  public updateClientConnetor() {
    this.loading = true
    this.clientConnectors = this.clientModule.connectors
    this.loading = false
  }

  public updateAloneConnectorList() {
    this.loading = true
    // connector alone
    this.aloneConnectors = this.clientModule.aloneConnectors
    this.loading = false
  }

  public showModalExcludeMutu() {
    this.loading = true
    this.modal.dialog = true
    this.loading = false
  }

  public showModalConnector() {
    this.loading = true
    this.modalConnector.dialog = true
    this.loading = false
  }

  public async removeLogo() {
    await this.clientModule.removeClientLogo(+this.$route.params.clientId)
    await this.clientModule.fetchOneClient(+this.$route.params.clientId)
    this.updateClientInfos()
  }

  public async updateClientOptions() {
    // add ids existing
    if (
      this.clientModule.client?.sharingBlocklist ||
      this.clientModule.connectors
    ) {
      this.loading = true
      const currentConnectorClient: string[] = this.clientModule.connectors
      const connectorToSend: string[] = currentConnectorClient.concat(
        this.selectAloneConnectorList
      )
      await this.clientModule.updateClient({
        id: this.$route.params.clientId,
        sharingBlocklist: this.returnIdClients,
        weight: +this.weight! as number,
        connectors: connectorToSend,
        logo: this.infosClient.logo,
        redirection: this.infosClient.redirection,
        thankYouUrl: this.infosClient.url
      })

      await this.clientModule.fetchConnectors(+this.$route.params.clientId)
      await this.clientModule.fetchOneClient(+this.$route.params.clientId)

      this.loading = false
    } else {
      this.returnIdClients = []
      this.selectAloneConnectorList = []
    }

    this.selectAloneConnectorList = []
    this.modal.dialog = this.loading === false ? false : true
    this.modalConnector.dialog = this.loading === false ? false : true
  }
  public cancelModal() {
    this.modal.dialog = false
    this.returnSelectBlockListId = []
  }
  public cancelModalConnector() {
    this.modalConnector.dialog = false
    this.selectAloneConnectorList = []
  }
  //delete client list inside table
  public deleteLimitMutu() {
    if (
      this.returnSelectBlockListId.length > 0 &&
      this.clientModule.client!.sharingBlocklist.length > 0
    ) {
      const subtractClientFromList = this.returnSelectBlockListId.map(
        (client) => {
          return client.clientId
        }
      )
      const clientsStay: number[] =
        this.clientModule.client!.sharingBlocklist.filter((id) => {
          return !subtractClientFromList.includes(id)
        })
      const currentConnectorClient: string[] = this.clientModule.connectors
      this.clientModule.updateClient({
        id: this.$route.params.clientId,
        sharingBlocklist: clientsStay,
        weight: this.clientModule.client?.weight as number,
        connectors: currentConnectorClient,
        logo: this.infosClient.logo,
        redirection: this.infosClient.redirection,
        thankYouUrl: this.infosClient.url
      })
    }
  }

  public async deleteClientsConnector() {
    if (
      this.returnClientConnectorList.length > 0 &&
      this.clientModule.connectors.length > 0
    ) {
      const currentBlockList: number[] = this.clientsNameList.map((client) => {
        return client.clientId
      })
      const subtractClientConnectorsFromList: string[] =
        this.returnClientConnectorList.map((connector) => {
          return connector.name
        })
      const clientsConnectorStay: string[] =
        this.clientModule.connectors.filter((connectorName) => {
          if (!subtractClientConnectorsFromList.includes(connectorName))
            return connectorName
        })
      await this.clientModule.updateClient({
        id: this.$route.params.clientId,
        sharingBlocklist: currentBlockList,
        weight: this.clientModule.client?.weight as number,
        connectors: clientsConnectorStay,
        logo: this.infosClient.logo,
        redirection: this.infosClient.redirection,
        thankYouUrl: this.infosClient.url
      })
      await this.clientModule.fetchConnectors(+this.$route.params.clientId)
    }
  }
}
