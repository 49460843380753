import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

export interface SupportState {
  subjects: Subject[]
}

export interface Subject {
  name: string
  value: 'QUESTION' | 'CHANGE_BILLING_MODE' | 'FEEDBACK' | 'BUG_REPORT'
}

export interface Ticket {
  subject: 'QUESTION' | 'CHANGE_BILLING_MODE' | 'FEEDBACK' | 'BUG_REPORT' | ''
  message: string
}

export interface TicketFields {
  id: string | number
  data: Ticket
}

@Module({ namespaced: true, name: 'support' })
export default class SupportModule extends VuexModule implements SupportState {
  subjects: Subject[] = []

  @Mutation
  saveAllSubjects(subjects: Subject[]) {
    this.subjects = subjects
  }

  @Action
  async new(ticketFields: TicketFields) {
    const { data } = await Vue.prototype.$http.post(
      `/support/${ticketFields.id}`,
      ticketFields.data,
      { successHandler: true }
    )
    return data
  }

  @Action({ commit: 'saveAllSubjects' })
  async fetchAllSubjects() {
    const { data } = await Vue.prototype.$http.get('/support/subjects')
    return data
  }
}
