




import { Component, Vue } from 'vue-property-decorator'
import MNotFound from '@/components/molecules/MNotFound.vue'
@Component({
  components: {
    MNotFound
  }
})
export default class NotFound extends Vue {}
