import { render, staticRenderFns } from "./ATitleMedium.vue?vue&type=template&id=67fba538&scoped=true&"
import script from "./ATitleMedium.vue?vue&type=script&lang=ts&"
export * from "./ATitleMedium.vue?vue&type=script&lang=ts&"
import style0 from "./ATitleMedium.vue?vue&type=style&index=0&id=67fba538&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67fba538",
  null
  
)

export default component.exports