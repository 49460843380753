































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import { DataTableHeader } from 'vuetify'
import MixinTables, {
  DEFAULT_ITEMS_PER_PAGE
} from '@/components/molecules/table/MixinTables.vue'
import LeadRejectedModule, { LeadRejected } from '@/store/leadRejected'
import AChexboxLead from '@/components/atoms/checkbox/AChexboxLead.vue'

export interface LeadTableStatus {
  icon: string
  text: string
}

@Component({
  components: {
    MTableHeading,
    MTableFooter,
    MTableActions,
    MTable,
    AButtonPrimary,
    AChexboxLead
  }
})
export default class OTableAdminLeadsRejected extends Mixins(MixinTables) {
  public leadRejectedModule = getModule(LeadRejectedModule, this.$store)

  public headers: DataTableHeader<LeadRejected>[] = [
    { text: 'Email', value: 'email' },
    { text: 'Téléphone', value: 'phone' },
    { text: "Type d'erreur", value: 'type' },
    { text: "Message d'erreur", value: 'message' },
    { text: 'Reposté ?', value: 'isReposted' },
    { text: 'Date et heure', value: 'createdAt' }
  ]

  get itemsPerPage() {
    return (
      this.leadRejectedModule.leadRejectedCollection.meta?.itemsPerPage ||
      DEFAULT_ITEMS_PER_PAGE
    )
  }

  getCollection() {
    return this.leadRejectedModule.leadRejectedCollection
  }

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  async request() {
    this.loading = true
    await this.leadRejectedModule.fetchAll()
    this.loading = false
  }
}
