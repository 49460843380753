























import { Component, Prop, Vue } from 'vue-property-decorator'
import AButtonLight from '@/components/atoms/button/AButtonLight.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonSecondary from '@/components/atoms/button/AButtonSecondary.vue'

@Component({
  components: {
    AButtonLight,
    AButtonPrimary,
    AButtonSecondary
  }
})
export default class MLeadActions extends Vue {
  @Prop() step!: number
}
