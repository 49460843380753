




import { Component, PropSync } from 'vue-property-decorator'
import { VSwitch } from 'vuetify/lib'

@Component
export default class ASwitch extends VSwitch {
  @PropSync('value') valueSync!: boolean
}
