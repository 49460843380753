import Vue from 'vue'
import App from '@/App.vue'
import '@/assets/scss/main.scss'

// Usefull for Vue instance
import router from '@/router'
import store from '@/store'
import { i18n } from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'

// Auto execute
import '@/plugins/vee-validate'
import '@/plugins/casl'
import '@/services/filters'
//Hightcharts
import '@/plugins/hightcharts'

import dayjs from '@/plugins/dayjs'
import axios from '@/plugins/axios'
import { AxiosInstance } from 'axios'

// Sentry.init({
//   Vue,
//   dsn: process.env.DNS_SENTRY,
//   trackComponents: true,
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: [process.env.APP_DOMAIN as string, /^\//]
//     })
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0
// })

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV === 'production' ? false : true
Vue.prototype.$http = axios() as AxiosInstance
Vue.prototype.$dayjs = dayjs

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
