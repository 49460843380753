import { render, staticRenderFns } from "./OAdminProviderProfil.vue?vue&type=template&id=6a5019db&scoped=true&"
import script from "./OAdminProviderProfil.vue?vue&type=script&lang=ts&"
export * from "./OAdminProviderProfil.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a5019db",
  null
  
)

export default component.exports