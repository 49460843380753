








import { Component, PropSync } from 'vue-property-decorator'
import { VProgressLinear } from 'vuetify/lib/components'

@Component
export default class AProgressBar extends VProgressLinear {
  @PropSync('value') valueSync!: number
}
