

















import { Component, Mixins, Watch } from 'vue-property-decorator'

//Molécules
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import MixinTables from '@/components/molecules/table/MixinTables.vue'

//Store
import { getModule } from 'vuex-module-decorators'
import AreasModule, { Area } from '@/store/area'

//Plugins
import { DataTableHeader } from 'vuetify'

@Component({
  components: {
    MTableHeading,
    MTableFooter,
    MTable
  }
})
export default class OTableAdminAreas extends Mixins(MixinTables) {
  public areaModule = getModule(AreasModule, this.$store)

  public headers: DataTableHeader<Area>[] = [
    { text: 'Nom', value: 'displayName' }
  ]

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  async request() {
    this.loading = true
    await this.areaModule.fetchAll()
    this.loading = false
  }
}
