








import { Component, Vue } from 'vue-property-decorator'
import AOverlay from '@/components/atoms/AOverlay.vue'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import ATitleSmall from '@/components/atoms/title/ATitleSmall.vue'
import MDisableTextField from '@/components/molecules/MDisableTextField.vue'
import MMark from '@/components/molecules/MMark.vue'
import ADisableLabel from '@/components/atoms/ADisableLabel.vue'
import MDrawer from '@/components/molecules/MDrawer.vue'
import { getModule } from 'vuex-module-decorators'
import LeadModule from '@/store/lead'
import OAdminLeadDetail from '@/components/organisms/admin/OAdminLeadDetail.vue'

@Component({
  components: {
    AOverlay,
    ADisableLabel,
    ATitleMedium,
    ATitleSmall,
    MDrawer,
    MDisableTextField,
    MMark,
    OAdminLeadDetail
  }
})
export default class ODrawerAdminLeadDetail extends Vue {
  public leadModule = getModule(LeadModule, this.$store)

  drawer = true

  async toggleDrawer() {
    const path = '/leads'
    if (this.$route.path !== path) {
      await this.$router.push({ path, query: this.$route.query })
    }
    this.leadModule.clearLead()
    this.drawer = !this.drawer
  }
}
