




























import { Component, Prop } from 'vue-property-decorator'
import { VTreeview } from 'vuetify/lib'
interface ColorIcone {
  default: string
  other: string
}
interface ParamsLogs {
  files?: string
  reject?: string
  color: { default: string; other: string }
}
@Component
export default class ATreeviewLogs extends VTreeview {
  @Prop() items!: []
  @Prop() paramsLogs?: ParamsLogs
}
