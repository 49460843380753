












































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import { getModule } from 'vuex-module-decorators'
import ProviderModule, { Provider } from '@/store/provider'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import MixinTables, {
  DEFAULT_ITEMS_PER_PAGE
} from '@/components/molecules/table/MixinTables.vue'
import { TableActionsProps } from '@/types'
import AuthenticationModule from '@/store/authentication'
import NotificationModule from '@/store/notification'
import UserModule, { CurrentUser } from '@/store/user'
import MBaseModal, {
  ModalProps
} from '@/components/molecules/modal/MBaseModal.vue'
import CsvModule from '@/store/csv'

@Component({
  components: {
    MTableHeading,
    MTableFooter,
    MTableActions,
    MTable,
    AButtonPrimary,
    MBaseModal
  }
})
export default class OTableAdminProviders extends Mixins(MixinTables) {
  public providerModule = getModule(ProviderModule, this.$store)
  public authenticationModule = getModule(AuthenticationModule, this.$store)
  public notificationModule = getModule(NotificationModule, this.$store)
  public userModule = getModule(UserModule, this.$store)
  private csvModule = getModule(CsvModule, this.$store)
  public currentProvider: Provider | null = null
  public headers: DataTableHeader<Provider>[] = [
    { text: 'Nom', value: 'name' },
    { text: 'Téléphone', value: 'phone', sortable: false },
    { text: 'E-mail', value: 'email' },
    { text: 'Actions', value: 'actions', sortable: false }
  ]

  get itemsPerPage() {
    // return this.providerModule.providers.meta?.itemsPerPage || DEFAULT_ITEMS_PER_PAGE
    return DEFAULT_ITEMS_PER_PAGE
  }

  get tableActions(): TableActionsProps[] {
    return [
      {
        icon: '$view',
        action: this.read,
        tooltip: this.$t('provider.actions.show') as string
      },
      {
        icon: '$edit',
        action: this.edit,
        tooltip: this.$t('provider.actions.edit') as string
      },
      {
        icon: '$download',
        action: this.downloadCsv,
        tooltip: this.$t('provider.actions.export') as string
      },
      {
        icon: '$share',
        action: this.logasProvider,
        tooltip: this.$t('provider.actions.logas') as string
      }
    ]
  }

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  async request() {
    this.loading = true
    await this.providerModule.fetchAll()
    this.loading = false
  }

  public read(item: Provider) {
    this.$router.push({ path: `/partenaires/${item.providerId}` })
  }

  public edit(item: Provider) {
    this.$router.push({ path: `partenaires/${item.providerId}/profil` })
  }

  public downloadCsv(item: Provider) {
    this.modal.dialog = true
    this.currentProvider = item
  }

  get modal() {
    return this.$refs.modal as MBaseModal
  }

  public newProvider() {
    this.$router.push({ path: '/partenaires/creation' })
  }

  public async logasProvider(item: Provider) {
    await Promise.all([
      this.authenticationModule.logas({
        id: { providerId: +item.providerId },
        currentRoute: this.$route.fullPath
      }),
      this.userModule.me()
    ])
    this.$router.push('/dashboard')
    const { firstName, lastName } = this.userModule.currentUser as CurrentUser
    this.notificationModule.add({
      color: 'yellow',
      timeout: -1,
      show: true,
      message: `Connecté en tant que ${firstName} ${lastName}`,
      persist: true
    })
  }

  public get modalProps(): ModalProps {
    return {
      title: this.title,
      actionText: this.$tc('provider.actions.export'),
      modalAction: this.submit
    }
  }

  public async submit() {
    await this.csvModule.downloadProviderLeads({
      providerId: +this.currentProvider!.providerId,
      data: {
        setAsExported: false
      }
    })
    this.modal.dialog = false
  }

  get title() {
    return `${this.$t('provider.actions.export')} ${
      this.providerModule.provider
        ? this.providerModule.provider.company.name
        : ''
    }`
  }
}
