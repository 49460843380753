


















































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
//Plugins
import { DataTableHeader } from 'vuetify'
//Store
import { getModule } from 'vuex-module-decorators'
import StatisticModule, { Provider } from '@/store/statistic'
//Molecules
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import MixinTables, {
  DEFAULT_ITEMS_PER_PAGE
} from '@/components/molecules/table/MixinTables.vue'
import MTableFooterFrontSorting from '@/components/molecules/table/MTableFooterFrontSorting.vue'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
//Types
import { TableActionsProps } from '@/types'

@Component({
  components: {
    MTableHeading,
    MTableActions,
    MTable,
    MTableFooterFrontSorting,
    MTableFooter
  }
})
export default class OTableAdminStatisticsProviders extends Mixins(
  MixinTables
) {
  public statisticModule = getModule(StatisticModule, this.$store)

  public headers: DataTableHeader<Provider>[] = [
    {
      text: this.$t('stats.provider.name') as string,
      value: 'name',
      sortable: false
    },
    {
      text: this.$tc('stats.lead', 1) as string,
      value: 'leads',
      sortable: false
    },
    {
      text: this.$tc('stats.leadNet', 1) as string,
      value: 'leadsSold',
      sortable: false
    },
    { text: this.$t('stats.ca') as string, value: 'sales', sortable: false },
    {
      text: this.$t('stats.caNet') as string,
      value: 'netSales',
      sortable: false
    },
    {
      text: this.$t('stats.marginBrut') as string,
      value: 'margin',
      sortable: false
    },
    {
      text: this.$t('stats.marginNet') as string,
      value: 'netMargin',
      sortable: false
    },
    { text: 'Actions', value: 'actions', sortable: false }
  ]
  //Which value sort
  public sortBy = ['leads']
  //Active sort
  public sortDesc = false

  get itemsPerPage() {
    return (
      this.statisticModule.providers.meta?.itemsPerPage ||
      DEFAULT_ITEMS_PER_PAGE
    )
  }

  get tableActions(): TableActionsProps[] {
    return [
      {
        icon: '$leads',
        action: this.seeLeads,
        tooltip: this.$i18n.tc('lead.actions.show') as string
      },
      {
        icon: '$stats',
        action: this.seeStats,
        tooltip: this.$i18n.tc('statistic.actions.show') as string
      }
    ]
  }

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  async request() {
    this.loading = true
    await this.statisticModule.fetchAllProviders()
    this.loading = false
  }

  public seeLeads(item: Provider) {
    this.$router.push({
      path: '/leads',
      query: { provider: item.id.toString() }
    })
  }

  public seeStats(item: Provider) {
    this.$router.push({
      path: `/partenaires/${item.id.toString()}/dashboard`
    })
  }
}
