






import { Component, Prop, Vue } from 'vue-property-decorator'
import ANavRouter from '@/components/atoms/nav/ANavRouter.vue'
//Interfaces
import { NavBar } from '@/store/interfaces/components/INavigation'
@Component({
  components: {
    ANavRouter
  }
})
export default class MMenu extends Vue {
  @Prop() items!: NavBar[]
}
