






























import { Component, Vue } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import MFormSitekey from '@/components/molecules/form/MFormSitekey.vue'
import { ValidationObserver } from 'vee-validate'
import UserModule, { CurrentUser } from '@/store/user'
import SitekeyModule, { SitekeyFields } from '@/store/sitekey'

import { getModule } from 'vuex-module-decorators'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonLight from '@/components/atoms/button/AButtonLight.vue'

@Component({
  components: {
    ATitleMedium,
    MFormSitekey,
    ValidationObserver,
    AButtonPrimary,
    AButtonLight
  }
})
export default class OProviderSitekeyNew extends Vue {
  private userModule = getModule(UserModule, this.$store)
  private sitekeyModule = getModule(SitekeyModule, this.$store)

  public sitekeyFields: SitekeyFields = {
    name: '',
    providerId: (this.userModule.currentUser as CurrentUser)
      .providerId as number
  }

  public async submit() {
    await this.sitekeyModule.new(this.sitekeyFields)
    this.$router.push({ path: '/sitekeys' })
  }
}
