
















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

@Component({})
export default class AInputAutoComplete extends Vue {
  @Prop() items: unknown
  @PropSync('value') valueSynced!: number | string
  @PropSync('search-input') searchInputSynced!: string
}
