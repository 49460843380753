

















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ABaseButton extends Vue {
  @Prop() text!: string
}
