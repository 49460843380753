import Vue from 'vue'
import { Action, Mutation, VuexModule, Module } from 'vuex-module-decorators'
import { Zip } from '@/store/zip'
import {
  LeadProperty,
  LeadPropsProperty,
  LeadPropsWsLog,
  LeadStatusEnum
} from '@/store/lead'
import { FiltersMeta, FiltersRequest } from '@/store/filter'
import { Webhook } from '@/store/webhook'
import { Area } from '@/store/area'
import { Company } from '@/store/user'
import { OrderStatus, Order } from '@/store/order'
import store from '.'
import { i18n } from '@/plugins/i18n'

export interface ClientOrder {
  area: Area
  capping: number
  cappingMax: number
  clientsAreas: ClientsAreas
  createdAt: string
  endAt: string
  isActive: string
  name: string
  orderId: string
  price: number
  shareLimit: number
}
export interface ClientState {
  clients: ClientRequest
  client: Client | null
  orders: OrderRequest
  invoices: any
  webhooks: Webhook[]
  webhook: Webhook | null
  order: Order | null
  leads: LeadRequest
  lead: ClientLead | null
  leadDetail: ClientLeadDetailModel | null
  reminders: Reminder[]
}

export interface Client {
  /**
   * @description Here we're adding an optional property (id) because we're getting from the back-end 'id' instead of 'ClientId'
   */
  id?: number
  clientId: number
  weight: number
  company: Company
  webhook?: Webhook
  createdAt: Date
  email: string
  name: string
  phone: string
  sharingBlocklist: number[]
  connectors: string[]
  thankYouUrl: string
  logo: string
  redirection: boolean
}

export interface ClientDashboard {
  leads: number
  leadsSold: number
  spent: number
}
export enum OrderLeadStatusEnum {
  UNREAD = 'UNREAD',
  READ = 'READ'
}

export interface ClientLeadDetailModel {
  createdAt: Date
  firstName: string
  lastName: string
  email: string
  phone: string
  status: LeadStatusEnum
  orderLeadComment: string
  orderLeadStatus: OrderLeadStatusEnum
  zipCode: string
  price: number
  saleDate: Date
  area: string
  order: string
  properties: LeadPropsProperty[]
  wsLogs: LeadPropsWsLog[]
}
export interface ClientLead {
  createdAt: Date
  leadId: number
  email: string
  firstName: string
  lastName: string
  phone: string
  status: string
  area: string
  price: string
  transactionDate: Date
  order: string
  zipCode: string
  zip: Zip
  leadProperties: LeadProperty[]
}

export interface Lead {
  createdAt: Date
  leadId: number
  email: string
  firstName: string
  lastName: string
  phone: string
  status: string
  area: string
  price: number
  order: string
  zipname: string
  zipdigit: string
}

export interface OrderRequest {
  filters?: FiltersRequest
  items: Order[]
  meta?: FiltersMeta
}

export interface ClientsAreas {
  id: number
  tpLeadSharingLimit: number
  tpPrice: number
  tpSharePrice: number
  tpShouldLeadQualified: boolean
  client: Client
  area: Area
  webhook?: Webhook
}

export interface LeadRequest {
  items: Lead[]
  meta?: FiltersMeta
}

export interface ClientRequest {
  items: Client[]
  meta?: FiltersMeta
}
export interface UpdateClientWeight {
  id: string
  weight: number
}

export interface PaymentSetup {
  sessionId: string
  customer: string
}
export interface ClientCompanies {
  clientId: number
  companyName: string
}
export interface ClientConnector {
  id: number
  connector_name: string
  client_id: number
  created_at: string
  updated_at: string
}

export interface Reminder {
  id: number
  companyname: string
  useremail: string
  clientreminder: boolean
}

export const CLIENTS_LIMIT = 10000

@Module({ namespaced: true, name: 'client' })
export default class ClientModule extends VuexModule implements ClientState {
  clientDashboard: ClientDashboard | null = null
  clients: ClientRequest = {
    items: []
  }
  client: Client | null = null
  aloneConnectors: string[] = []
  clientCompanies: ClientCompanies[] = []
  orders: OrderRequest = {
    filters: {
      areas: [],
      clients: [],
      sitekeys: [],
      providers: [],
      status: [],
      orders: []
    },
    items: []
  }
  invoices: [] = []
  leads: LeadRequest = {
    items: []
  }
  leadDetail: ClientLeadDetailModel | null = null
  webhooks: Webhook[] = []
  webhook: Webhook | null = null
  reminders: Reminder[] = []
  order: Order | null = null
  lead: ClientLead | null = null
  connectors: string[] = []

  @Mutation
  saveAll(clients: ClientRequest) {
    this.clients = clients
  }

  @Mutation
  saveCurrentClient(client: Client) {
    this.client = client
  }

  @Mutation
  saveAllOrders(orders: OrderRequest) {
    this.orders = { ...this.orders, ...orders }
  }

  @Mutation
  saveDuplicateOrder(order: Order) {
    this.orders.items.unshift(order)
  }

  @Mutation
  saveAllInvoices(invoices: any) {
    this.invoices = invoices
  }

  @Mutation
  saveAllLeads(leads: LeadRequest) {
    this.leads = { ...this.leads, ...leads }
  }

  @Mutation
  saveAllWebhooks(webhooks: Webhook[]) {
    this.webhooks = webhooks
  }

  @Mutation
  saveAllReminders(reminders: Reminder[]) {
    this.reminders = reminders
  }

  @Mutation
  removeWebhook(webhookId: number) {
    this.webhooks = this.webhooks.filter((webhook) => webhook.id !== webhookId)
  }

  @Mutation
  saveOneLeadDetail(leadDetail: ClientLeadDetailModel) {
    this.leadDetail = leadDetail
  }

  @Mutation
  saveOneWebhook(webhook: Webhook) {
    this.webhook = webhook
  }

  @Mutation
  saveOneOrder(order: Order) {
    this.order = order
  }

  @Mutation
  saveDashboard(dashboard: ClientDashboard) {
    this.clientDashboard = dashboard
  }

  @Mutation
  saveClientCompanies(clientCompanies: ClientCompanies[]) {
    this.clientCompanies = clientCompanies
  }

  @Mutation
  saveConnectors(connectors: string[]) {
    this.connectors = connectors
  }

  @Mutation
  saveAloneConnectors(aloneConnectors: string[]) {
    this.aloneConnectors = aloneConnectors
  }

  @Mutation
  removeLogo(client: Client) {
    this.client = client
  }

  @Mutation
  uptadeInfosClient(client: Client) {
    this.client = client
  }

  /**
   * @function clearLead @description clear lead
   */
  @Mutation
  clearLead() {
    this.lead = null
  }

  @Action({ commit: 'removeLogo' })
  async removeClientLogo(clientId: number): Promise<Client> {
    const { data } = await await Vue.prototype.$http.delete(
      `/clients/${clientId}/logo`
    )
    return data
  }

  @Action({ commit: 'saveAloneConnectors' })
  async fetchAloneConnectors(): Promise<string[]> {
    const { data } = await Vue.prototype.$http.get('/connectors')
    return data
  }

  @Action({ commit: 'saveDashboard' })
  async fetchClientDashboard(id: number): Promise<ClientDashboard> {
    const { data } = await Vue.prototype.$http.get(`/clients/${id}/dashboard`, {
      useQueryParams: true
    })
    return data
  }

  @Action({ commit: 'saveAll' })
  async fetchAll(): Promise<ClientRequest> {
    const { data } = await Vue.prototype.$http.get('/clients', {
      useQueryParams: true
    })
    return data
  }

  @Action({ commit: 'saveAllOrders' })
  async fetchAllOrders(id: number) {
    const { data } = await Vue.prototype.$http.get(`/clients/${id}/orders`, {
      useQueryParams: true
    })
    return data
  }

  @Action({ commit: 'saveAllOrders' })
  async fetchAllActiveOrders(id: number) {
    const query = { orderStatus: OrderStatus.LIVE }
    const { data } = await Vue.prototype.$http.get(`/clients/${id}/orders`, {
      useQueryParams: true,
      params: query
    })
    return data
  }

  @Action({ commit: 'saveAllInvoices' })
  async fetchAllInvoices(id: number) {
    const { data } = await Vue.prototype.$http.get(`/clients/${id}/invoices`, {
      useQueryParams: true
    })
    return data
  }

  @Action({ commit: 'saveAllLeads' })
  async fetchAllLeads(id: number) {
    const { data } = await Vue.prototype.$http.get(`/clients/${id}/leads`, {
      useQueryParams: true
    })
    return data
  }

  @Action({ commit: 'saveAllWebhooks' })
  async fetchAllWebhooks(id: number) {
    const { data } = await Vue.prototype.$http.get(`/clients/${id}/webhooks`)

    return data
  }

  @Action({ commit: 'saveAllReminders' })
  async fetchAllReminders() {
    const { data } = await Vue.prototype.$http.get('/clients/reminders/all')

    return data
  }

  /**
   * @function fetchOneLead @description fetch lead for lead detail
   * @param param object{ clientID; leadId}
   * @returns {ClientLeadDetailModel}
   */
  @Action({ commit: 'saveOneLeadDetail' })
  async fetchOneLead(param: {
    clientId: number
    leadId: number
  }): Promise<ClientLeadDetailModel> {
    const { data } = await Vue.prototype.$http.get(
      `/clients/${param.clientId}/leads/${param.leadId}`
    )

    return data
  }

  @Action({ commit: 'saveOneWebhook' })
  async fetchOneWebhook(webhookId: number): Promise<Webhook> {
    const { data } = await Vue.prototype.$http.get(`/webhooks/${webhookId}`)

    return data
  }

  @Action({ commit: 'saveOneOrder' })
  async fetchOneOrder(orderId: string): Promise<Webhook> {
    const { data } = await Vue.prototype.$http.get(`/orders/${orderId}`)
    return data
  }

  @Action({ commit: 'saveCurrentClient' })
  async fetchOneClient(clientId: number): Promise<Client> {
    const { data } = await Vue.prototype.$http.get(`/clients/${clientId}`)
    return data
  }
  @Action({ commit: 'saveCurrentClient' })
  async updateClient(editedFields: {
    id: string
    sharingBlocklist: number[]
    weight: number
    connectors: string[]
    thankYouUrl: string | undefined
    logo: string | undefined
    redirection: boolean | undefined
  }): Promise<Client> {
    const { data } = await Vue.prototype.$http.put(
      `/clients/${editedFields.id}`,
      {
        sharingBlocklist: editedFields.sharingBlocklist,
        weight: editedFields.weight,
        connectors: editedFields.connectors,
        thankYouUrl: editedFields.thankYouUrl,
        logo: editedFields.logo,
        redirection: editedFields.redirection
      },
      {
        successHandler: {
          message: 'Informations mises à jour avec succès'
        },
        errorHandler: {
          message: 'Vérifier les champs remplies'
        }
      }
    )
    return data
  }

  @Action
  async updateClientComment({
    clientId,
    leadId,
    comment
  }: {
    clientId: number
    leadId: number
    comment: string | null
  }) {
    await Vue.prototype.$http.put(
      `/clients/${clientId}/leads/${leadId}`,
      {
        comment: comment
      },
      {
        errorHandler: {
          message: "Une erreur s'est produite lors de la mise à jour"
        },
        successHandler: {
          message: 'Votre note a bien été mise à jour'
        }
      }
    )
  }

  @Action
  async updateClientReminder({
    clientId,
    reminder
  }: {
    clientId: number
    reminder: boolean
  }) {
    await Vue.prototype.$http.put(
      `/clients/${clientId}/reminder`,
      {
        reminder
      },
      {
        errorHandler: {
          message: "Une erreur s'est produite lors de la mise à jour"
        },
        successHandler: {
          message: 'Statut de la relance mise à jour'
        }
      }
    )
  }

  @Action
  async updateClientStatusLead({
    clientId,
    leadId,
    status
  }: {
    clientId: number
    leadId: number
    status: OrderLeadStatusEnum
  }) {
    await Vue.prototype.$http.put(
      `/clients/${clientId}/leads/${leadId}`,
      {
        status: status
      },
      {
        errorHandler: {
          message: "Une erreur s'est produite lors de la mise à jour"
        },
        successHandler: {
          message: 'Le statut de votre lead a bien été mis à jour'
        }
      }
    )
  }

  @Action
  async paymentSetup() {
    const { data } = await Vue.prototype.$http.post('/payments/setup', {
      errorHandler: {
        message: 'Un problème est survenu'
      }
    })

    return data
  }

  @Action
  async paymentSetupSucess() {
    store.dispatch('notification/add', {
      color: 'primary',
      message: `${i18n.t('payment.STRIPE_SETUP_SUCESS')}`,
      show: true
    })
  }
  @Action({ commit: 'saveClientCompanies' })
  async fetchClientCompanies() {
    const { data } = await Vue.prototype.$http.get('/clients/companies')
    return data
  }

  @Action({ commit: 'saveConnectors' })
  async fetchConnectors(clientId: number) {
    const { data } = await Vue.prototype.$http.get(
      `/clients/${clientId}/connectors`
    )
    return data?.map((line: any) => line.connector_name) ?? []
  }
}
