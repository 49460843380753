










import { Component, Mixins } from 'vue-property-decorator'
import MFilterDates from '@/components/molecules/filter/MFilterDates.vue'
import MixinFilters from '@/components/molecules/filter/MixinFilters.vue'
import MFilterHeading from '@/components/molecules/filter/MFilterHeading.vue'
import ABaseSelect from '@/components/atoms/select/ABaseSelect.vue'
import { getModule } from 'vuex-module-decorators'
import StatisticModule from '@/store/statistic'
import AInputAutoComplete from '@/components/atoms/input/AInputAutoComplete.vue'

@Component({
  components: {
    MFilterDates,
    MFilterHeading,
    ABaseSelect,
    AInputAutoComplete
  }
})
export default class OClientTableInvoicesFilters extends Mixins(MixinFilters) {
  public statisticModule = getModule(StatisticModule, this.$store)
}
