








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ASideBarItem extends Vue {
  @Prop() route?: string
  @Prop() title!: string
}
