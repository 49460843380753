









































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
//Atoms
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import ABaseTitle from '@/components/atoms/title/ABaseTitle.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import AInputNumber from '@/components/atoms/input/AInputNumber.vue'
//Molecules
import MOrderStepOne from '@/components/molecules/order/MOrderStepOne.vue'
import MOrderStepTwo from '@/components/molecules/order/MOrderStepTwo.vue'
import MOrderStepThree from '@/components/molecules/order/MOrderStepThree.vue'
import MOrderStepFour from '@/components/molecules/order/MOrderStepFour.vue'
import MOrderStepFive from '@/components/molecules/order/MOrderStepFive.vue'
import MOrderStepWS from '@/components/molecules/order/MOrderStepWS.vue'
import MOrderStepSix from '@/components/molecules/order/MOrderStepSix.vue'
import MOrderActions from '@/components/molecules/order/MOrderActions.vue'
import MOrderStepCalendarDelivery from '@/components/molecules/order/MOrderStepCalendarDelivery.vue'
//Store
import { getModule } from 'vuex-module-decorators'
import ZipModule, { Zip, ZipByRegion } from '@/store/zip'
import OrderModule, { CreateOrderDto, UpdateOrderDto } from '@/store/order'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ClientModule from '@/store/client'
import UserModule from '@/store/user'
import AreaModule, { Area, AreaAndIcon, TypePriceResponse } from '@/store/area'
import OrderStepModule from '@/store/orderStep'
import ProductModule, { Product } from '@/store/product'
//Enum
@Component({
  components: {
    MOrderStepOne,
    MOrderStepTwo,
    MOrderStepThree,
    MOrderStepFour,
    MOrderStepFive,
    MOrderStepWS,
    MOrderStepSix,
    MOrderActions,
    ATitleMedium,
    ABaseTitle,
    ValidationObserver,
    ValidationProvider,
    AInputTextField,
    AInputNumber,
    MOrderStepCalendarDelivery
  }
})
export default class OOrderSteps extends Vue {
  public zipModule = getModule(ZipModule, this.$store)
  public orderModule = getModule(OrderModule, this.$store)
  public clientModule = getModule(ClientModule, this.$store)
  public areaModule = getModule(AreaModule, this.$store)
  public userModule = getModule(UserModule, this.$store)
  public productModule = getModule(ProductModule, this.$store)
  //Store for manage info stepper
  public orderStepModule = getModule(OrderStepModule, this.$store)
  public isUpdate = this.$route.params.orderId
  public selectedZips: Zip[] = []

  //Step 1 for skeleton
  public loading = false
  //Step 1

  ////// Step 6 : Delivery planning ////////////

  public daysHours = [
    {
      day: 'Dimanche',
      hours: [
        { text: 'de 00H à 01H', value: false },
        { text: 'de 01H à 02H', value: false },
        { text: 'de 02H à 03H', value: false },
        { text: 'de 03H à 04H', value: false },
        { text: 'de 04H à 05H', value: false },
        { text: 'de 05H à 06H', value: false },
        { text: 'de 06H à 07H', value: false },
        { text: 'de 07H à 08H', value: false },
        { text: 'de 08H à 09H', value: false },
        { text: 'de 09H à 10H', value: false },
        { text: 'de 10H à 11H', value: false },
        { text: 'de 11H à 12H', value: false },
        { text: 'de 12H à 13H', value: false },
        { text: 'de 13H à 14H', value: false },
        { text: 'de 14H à 15H', value: false },
        { text: 'de 15H à 16H', value: false },
        { text: 'de 16H à 17H', value: false },
        { text: 'de 17H à 18H', value: false },
        { text: 'de 18H à 19H', value: false },
        { text: 'de 19H à 20H', value: false },
        { text: 'de 20H à 21H', value: false },
        { text: 'de 21H à 22H', value: false },
        { text: 'de 22H à 23H', value: false },
        { text: 'de 23H à 00H', value: false }
      ],
      display: false,
      sendData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ]
    },
    {
      day: 'Lundi',
      hours: [
        { text: 'de 00H à 01H', value: false },
        { text: 'de 01H à 02H', value: false },
        { text: 'de 02H à 03H', value: false },
        { text: 'de 03H à 04H', value: false },
        { text: 'de 04H à 05H', value: false },
        { text: 'de 05H à 06H', value: false },
        { text: 'de 06H à 07H', value: false },
        { text: 'de 07H à 08H', value: false },
        { text: 'de 08H à 09H', value: false },
        { text: 'de 09H à 10H', value: false },
        { text: 'de 10H à 11H', value: false },
        { text: 'de 11H à 12H', value: false },
        { text: 'de 12H à 13H', value: false },
        { text: 'de 13H à 14H', value: false },
        { text: 'de 14H à 15H', value: false },
        { text: 'de 15H à 16H', value: false },
        { text: 'de 16H à 17H', value: false },
        { text: 'de 17H à 18H', value: false },
        { text: 'de 18H à 19H', value: false },
        { text: 'de 19H à 20H', value: false },
        { text: 'de 20H à 21H', value: false },
        { text: 'de 21H à 22H', value: false },
        { text: 'de 22H à 23H', value: false },
        { text: 'de 23H à 00H', value: false }
      ],
      display: false,
      sendData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ]
    },
    {
      day: 'Mardi',
      hours: [
        { text: 'de 00H à 01H', value: false },
        { text: 'de 01H à 02H', value: false },
        { text: 'de 02H à 03H', value: false },
        { text: 'de 03H à 04H', value: false },
        { text: 'de 04H à 05H', value: false },
        { text: 'de 05H à 06H', value: false },
        { text: 'de 06H à 07H', value: false },
        { text: 'de 07H à 08H', value: false },
        { text: 'de 08H à 09H', value: false },
        { text: 'de 09H à 10H', value: false },
        { text: 'de 10H à 11H', value: false },
        { text: 'de 11H à 12H', value: false },
        { text: 'de 12H à 13H', value: false },
        { text: 'de 13H à 14H', value: false },
        { text: 'de 14H à 15H', value: false },
        { text: 'de 15H à 16H', value: false },
        { text: 'de 16H à 17H', value: false },
        { text: 'de 17H à 18H', value: false },
        { text: 'de 18H à 19H', value: false },
        { text: 'de 19H à 20H', value: false },
        { text: 'de 20H à 21H', value: false },
        { text: 'de 21H à 22H', value: false },
        { text: 'de 22H à 23H', value: false },
        { text: 'de 23H à 00H', value: false }
      ],
      display: false,
      sendData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ]
    },
    {
      day: 'Mercredi',
      hours: [
        { text: 'de 00H à 01H', value: false },
        { text: 'de 01H à 02H', value: false },
        { text: 'de 02H à 03H', value: false },
        { text: 'de 03H à 04H', value: false },
        { text: 'de 04H à 05H', value: false },
        { text: 'de 05H à 06H', value: false },
        { text: 'de 06H à 07H', value: false },
        { text: 'de 07H à 08H', value: false },
        { text: 'de 08H à 09H', value: false },
        { text: 'de 09H à 10H', value: false },
        { text: 'de 10H à 11H', value: false },
        { text: 'de 11H à 12H', value: false },
        { text: 'de 12H à 13H', value: false },
        { text: 'de 13H à 14H', value: false },
        { text: 'de 14H à 15H', value: false },
        { text: 'de 15H à 16H', value: false },
        { text: 'de 16H à 17H', value: false },
        { text: 'de 17H à 18H', value: false },
        { text: 'de 18H à 19H', value: false },
        { text: 'de 19H à 20H', value: false },
        { text: 'de 20H à 21H', value: false },
        { text: 'de 21H à 22H', value: false },
        { text: 'de 22H à 23H', value: false },
        { text: 'de 23H à 00H', value: false }
      ],
      display: false,
      sendData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ]
    },
    {
      day: 'Jeudi',
      hours: [
        { text: 'de 00H à 01H', value: false },
        { text: 'de 01H à 02H', value: false },
        { text: 'de 02H à 03H', value: false },
        { text: 'de 03H à 04H', value: false },
        { text: 'de 04H à 05H', value: false },
        { text: 'de 05H à 06H', value: false },
        { text: 'de 06H à 07H', value: false },
        { text: 'de 07H à 08H', value: false },
        { text: 'de 08H à 09H', value: false },
        { text: 'de 09H à 10H', value: false },
        { text: 'de 10H à 11H', value: false },
        { text: 'de 11H à 12H', value: false },
        { text: 'de 12H à 13H', value: false },
        { text: 'de 13H à 14H', value: false },
        { text: 'de 14H à 15H', value: false },
        { text: 'de 15H à 16H', value: false },
        { text: 'de 16H à 17H', value: false },
        { text: 'de 17H à 18H', value: false },
        { text: 'de 18H à 19H', value: false },
        { text: 'de 19H à 20H', value: false },
        { text: 'de 20H à 21H', value: false },
        { text: 'de 21H à 22H', value: false },
        { text: 'de 22H à 23H', value: false },
        { text: 'de 23H à 00H', value: false }
      ],
      display: false,
      sendData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ]
    },
    {
      day: 'Vendredi',
      hours: [
        { text: 'de 00H à 01H', value: false },
        { text: 'de 01H à 02H', value: false },
        { text: 'de 02H à 03H', value: false },
        { text: 'de 03H à 04H', value: false },
        { text: 'de 04H à 05H', value: false },
        { text: 'de 05H à 06H', value: false },
        { text: 'de 06H à 07H', value: false },
        { text: 'de 07H à 08H', value: false },
        { text: 'de 08H à 09H', value: false },
        { text: 'de 09H à 10H', value: false },
        { text: 'de 10H à 11H', value: false },
        { text: 'de 11H à 12H', value: false },
        { text: 'de 12H à 13H', value: false },
        { text: 'de 13H à 14H', value: false },
        { text: 'de 14H à 15H', value: false },
        { text: 'de 15H à 16H', value: false },
        { text: 'de 16H à 17H', value: false },
        { text: 'de 17H à 18H', value: false },
        { text: 'de 18H à 19H', value: false },
        { text: 'de 19H à 20H', value: false },
        { text: 'de 20H à 21H', value: false },
        { text: 'de 21H à 22H', value: false },
        { text: 'de 22H à 23H', value: false },
        { text: 'de 23H à 00H', value: false }
      ],
      display: false,
      sendData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ]
    },
    {
      day: 'Samedi',
      hours: [
        { text: 'de 00H à 01H', value: false },
        { text: 'de 01H à 02H', value: false },
        { text: 'de 02H à 03H', value: false },
        { text: 'de 03H à 04H', value: false },
        { text: 'de 04H à 05H', value: false },
        { text: 'de 05H à 06H', value: false },
        { text: 'de 06H à 07H', value: false },
        { text: 'de 07H à 08H', value: false },
        { text: 'de 08H à 09H', value: false },
        { text: 'de 09H à 10H', value: false },
        { text: 'de 10H à 11H', value: false },
        { text: 'de 11H à 12H', value: false },
        { text: 'de 12H à 13H', value: false },
        { text: 'de 13H à 14H', value: false },
        { text: 'de 14H à 15H', value: false },
        { text: 'de 15H à 16H', value: false },
        { text: 'de 16H à 17H', value: false },
        { text: 'de 17H à 18H', value: false },
        { text: 'de 18H à 19H', value: false },
        { text: 'de 19H à 20H', value: false },
        { text: 'de 20H à 21H', value: false },
        { text: 'de 21H à 22H', value: false },
        { text: 'de 22H à 23H', value: false },
        { text: 'de 23H à 00H', value: false }
      ],
      display: false,
      sendData: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
      ]
    }
  ]
  ///////////////////////////////////////////////

  /**
   * Init area for inject more information
   * @type {
      displayName: string
      areaId: number
      icon: string
      }
   */
  public areaAndIcon: AreaAndIcon[] = []

  step = 1
  created() {
    this.preloadOrder()
    this.preloadArea()
  }
  /**
   * @type { number | undefined }
   * @description calc price in fucntion of shareLimit number
   * Only for areas who's not have product
   */
  get returnPrice(): number | undefined {
    if (!this.isUpdate) {
      //if exlu
      if (this.orderStepModule.orderDto.shareLimit >= 2)
        return (this.orderStepModule.orderDto.price = this.selectedArea
          ?.defaultSharePrice as number)
      //if mutu
      else
        return (this.orderStepModule.orderDto.price = this.selectedArea
          ?.defaultPrice as number)
    } else return this.orderStepModule.orderDto.price
  }
  /**
   * @param value @type { number | undefined }
   *  catch change value and set this
   */
  set returnPrice(price: number | undefined) {
    this.orderStepModule.orderDto.price = price as number
  }
  //Start Step One
  /**
   * Inject more information inside array for display card step 1
   * @async
   * @function preloafArea
   * @return {areaAndIcon<sting | number>} 
   * @type {
      displayName: string
      areaId: number
      icon: string
      }
    * @var loading @description for the skeleton load
   */
  private async preloadArea() {
    this.loading = false
    await this.areaModule.fetchAll()
    this.areaAndIcon = {
      ...this.areaAndIcon,
      ...{
        areaKeys: [
          {
            displayName: this.areaModule.areas.find(
              (area: Area) => area.name === 'RAC'
            )?.displayName,
            areaId: this.areaModule.areas.find(
              (area: Area) => area.name === 'RAC'
            )?.id,
            icon: 'mdi-bank'
          },
          {
            displayName: this.areaModule.areas.find(
              (area: Area) => area.name === 'PINEL'
            )?.displayName,
            areaId: this.areaModule.areas.find(
              (area: Area) => area.name === 'PINEL'
            )?.id,
            icon: 'mdi-home'
          },
          {
            displayName: this.areaModule.areas.find(
              (area: Area) => area.name === 'ISO'
            )?.displayName,
            areaId: this.areaModule.areas.find(
              (area: Area) => area.name === 'ISO'
            )?.id,
            icon: 'mdi-snowflake-alert'
          },
          {
            displayName: this.areaModule.areas.find(
              (area: Area) => area.name === 'CPF'
            )?.displayName,
            areaId: this.areaModule.areas.find(
              (area: Area) => area.name === 'CPF'
            )?.id,
            icon: 'mdi-school'
          },
          {
            displayName: this.areaModule.areas.find(
              (area: Area) => area.name === 'CHAUD'
            )?.displayName,
            areaId: this.areaModule.areas.find(
              (area: Area) => area.name === 'CHAUD'
            )?.id,
            icon: 'mdi-fire'
          },
          {
            displayName: this.areaModule.areas.find(
              (area: Area) => area.name === 'PAC'
            )?.displayName,
            areaId: this.areaModule.areas.find(
              (area: Area) => area.name === 'PAC'
            )?.id,
            icon: 'mdi-snowflake-alert'
          },
          {
            displayName: this.areaModule.areas.find(
              (area: Area) => area.name === 'PHOTO'
            )?.displayName,
            areaId: this.areaModule.areas.find(
              (area: Area) => area.name === 'PHOTO'
            )?.id,
            icon: 'mdi-solar-panel'
          },
          {
            displayName: this.areaModule.areas.find(
              (area: Area) => area.name === 'DIAG'
            )?.displayName,
            areaId: this.areaModule.areas.find(
              (area: Area) => area.name === 'DIAG'
            )?.id,
            icon: 'mdi-office-building'
          },
          {
            displayName: this.areaModule.areas.find(
              (area: Area) => area.name === 'ADE'
            )?.displayName,
            areaId: this.areaModule.areas.find(
              (area: Area) => area.name === 'ADE'
            )?.id,
            icon: 'mdi-briefcase-account-outline'
          },
          {
            displayName: this.areaModule.areas.find(
              (area: Area) => area.name === 'MUTU'
            )?.displayName,
            areaId: this.areaModule.areas.find(
              (area: Area) => area.name === 'MUTU'
            )?.id,
            icon: 'mdi-medical-bag'
          },
          {
            displayName: this.areaModule.areas.find(
              (area: Area) => area.name === 'GENF'
            )?.displayName,
            areaId: this.areaModule.areas.find(
              (area: Area) => area.name === 'GENF'
            )?.id,
            icon: 'mdi-baby-buggy'
          },
          {
            displayName: this.areaModule.areas.find(
              (area: Area) => area.name === 'ADOM'
            )?.displayName,
            areaId: this.areaModule.areas.find(
              (area: Area) => area.name === 'ADOM'
            )?.id,
            icon: 'mdi-home-variant'
          },
          {
            displayName: this.areaModule.areas.find(
              (area: Area) => area.name === 'POOL'
            )?.displayName,
            areaId: this.areaModule.areas.find(
              (area: Area) => area.name === 'POOL'
            )?.id,
            icon: 'mdi-swim'
          },
          {
            displayName: this.areaModule.areas.find(
              (area: Area) => area.name === 'SCPI'
            )?.displayName,
            areaId: this.areaModule.areas.find(
              (area: Area) => area.name === 'SCPI'
            )?.id,
            icon: 'mdi-cash-usd'
          },
          {
            displayName: this.areaModule.areas.find(
              (area: Area) => area.name === 'PER'
            )?.displayName,
            areaId: this.areaModule.areas.find(
              (area: Area) => area.name === 'PER'
            )?.id,
            icon: 'mdi-wallet'
          },
          {
            displayName: this.areaModule.areas.find(
              (area: Area) => area.name === 'ADEREQ'
            )?.displayName,
            areaId: this.areaModule.areas.find(
              (area: Area) => area.name === 'ADEREQ'
            )?.id,
            icon: 'mdi-briefcase-edit-outline'
          },
          {
            displayName: this.areaModule.areas.find(
              (area: Area) => area.name === 'MUTU_ACTIF'
            )?.displayName,
            areaId: this.areaModule.areas.find(
              (area: Area) => area.name === 'MUTU_ACTIF'
            )?.id,
            icon: 'mdi-medical-bag'
          }
        ]
      }
    }
    this.loading = true
    return this.areaAndIcon
  }
  //End Step one

  //Start step three
  /**
   * compare for display price of lead
   * @enum {Exclu | Mutu}
   * @type { string }
   * @returns Exclusif | Mutualisé
   */
  public price = {
    EXCLU: TypePriceResponse.EXCLU,
    MUTU: TypePriceResponse.MUTU
  }

  customPrices = null
  //End Step Three
  /**
   * Whoever reads this shall inherit my treasure
   * Follow the leads to the rabbit hole
   * Todo Just for update order
   */
  public updateZips: Zip[] = []
  private async preloadOrder() {
    if (this.isUpdate) {
      await this.orderModule.fetchOneOrder(this.$route.params.orderId)
      this.orderStepModule.updateOrderDto(this.orderModule.order)
      this.selectedZips = this.orderModule.order!.zips.map(({ zip }) => {
        return zip
      })
    }
  }

  /**
   * @function onChangeStatus
   * @description Change the order's status according the checkbox checked or not
   */
  onChangeStatus(check: boolean) {
    check
      ? (this.orderStepModule.orderDto.status = 'LIVE')
      : (this.orderStepModule.orderDto.status = 'PAUSED')
  }

  mounted() {
    if (!this.isUpdate) {
      //clean object before creation order
      this.orderStepModule.clearOrderDto()
      this.orderStepModule.orderDto.clientId = (this.$route.params.clientId ||
        this.userModule.currentUser?.clientId) as number
    }
    this.zipModule.fetchAll()
    this.areaModule.fetchAll()

    this.clientModule.fetchConnectors(
      this.orderStepModule.orderDto.clientId ||
        Number(this.$route.params.clientId)
    )
  }

  async validateStep(newStep: number) {
    const ref = this.$refs.observer as InstanceType<typeof ValidationObserver>

    // Set mails array to null object if is empty
    this.orderStepModule.orderDto.notificationMails?.length === 0
      ? (this.orderStepModule.orderDto.notificationMails = null)
      : null

    if (!ref) this.step = newStep

    if (ref && (await ref.validate())) {
      this.step = newStep
    }
  }

  public onDatesPicked(dates: string[]) {
    if (dates.length === 2) {
      dates.sort((a, b) =>
        Vue.prototype.$dayjs(a).isAfter(Vue.prototype.$dayjs(b)) ? 1 : -1
      )
      this.orderStepModule.orderDto.startAt = new Date(dates[0]).toISOString()
      this.orderStepModule.orderDto.endAt = new Date(dates[1]).toISOString()
    } else if (dates.length === 1) {
      this.orderStepModule.orderDto.startAt = new Date(dates[0]).toISOString()
      this.orderStepModule.orderDto.endAt = undefined
    }
  }

  //Step five
  get formattedPeriod() {
    //Init parse date from order
    /**
     * @constant parseStartAt @description parse startAt from order @type { Date | null }
     * @constant parseEndAt @description parse endAt from order @type { Date | null }
     */
    const parseStartAt = Vue.prototype
      .$dayjs(this.orderStepModule.orderDto?.startAt)
      .format('DD/MM/YYYY')
    const parseEndAt = Vue.prototype
      .$dayjs(this.orderStepModule.orderDto?.endAt)
      .format('DD/MM/YYYY')
    //End Init parse date from order
    //Init parsed date from order
    /**
     * @constant allDatesParsed @description get startAt and endAt @type { Date | null }
     * @constant startAtParsed @description get only startAt @type { Date | null }
     */
    const allDatesParsed = this.$tc('order.date', 1, {
      startAt: Vue.prototype
        .$dayjs(this.orderStepModule.orderDto.startAt)
        .format('DD/MM/YYYY'),
      endAt: Vue.prototype
        .$dayjs(this.orderStepModule.orderDto.endAt)
        .format('DD/MM/YYYY')
    })
    const startAtParsed = this.$tc('order.date', 2, {
      startAt: Vue.prototype
        .$dayjs(this.orderStepModule.orderDto.startAt)
        .format('DD/MM/YYYY')
    })
    //End Init parsed date from orderDto
    //If only startAt
    if (
      (parseStartAt !== null && parseEndAt === null) ||
      (parseStartAt !== null && parseEndAt === 'Invalid Date')
    )
      return startAtParsed
    //End IF
    //If startAt === endAt => startAt only
    else if (
      parseStartAt !== null &&
      parseEndAt !== null &&
      parseStartAt === parseEndAt
    )
      return startAtParsed
    //End Else IF
    //If startAt and endAt
    else if (
      parseStartAt !== null &&
      parseEndAt !== null &&
      parseStartAt !== parseEndAt
    )
      return allDatesParsed //End Else IF

    return this.$tc('order.date', 0)
  }

  get globalBudget() {
    const { capping, cappingMax, startAt, endAt, price } =
      this.orderStepModule.orderDto
    /**
     * @constant parseEndAt @description parse endAt from order @type { Date | null }
     */
    const parseEndAt = Vue.prototype.$dayjs(endAt).format('DD/MM/YYYY')
    // eslint-disable-next-line @typescript-eslint/ban-types
    const filters = this.$options.filters as { [key: string]: Function }
    if (cappingMax > 0) return filters.formatPrice(cappingMax * price)
    else if (parseEndAt === 'Invalid Date' && capping > 0)
      //manage error 'Invalid date'
      return filters.formatPrice(capping * price)
    else if (
      parseEndAt !== null &&
      parseEndAt !== 'Invalid Date' &&
      capping > 0
    )
      return filters.formatPrice(
        (Vue.prototype.$dayjs(endAt).diff(startAt, 'day') + 1) * capping * price
      )
    else return this.$tc('order.capping', 1) //return Illimité
  }

  public async submit() {
    if (this.isUpdate) {
      await this.update(
        this.orderStepModule.orderDto,
        this.$route.params.orderId
      )
    } else {
      this.orderStepModule.orderDto.clientId = parseInt(
        this.$route.params.clientId
      )
      await this.create(this.orderStepModule.orderDto)
    }
    this.redirectTo()
    //clean data
    this.orderStepModule.clearOrderDto()
  }

  private async update(createOrderDto: CreateOrderDto, orderId: string) {
    // deletes areaId and clientId from createOrderDto
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { areaId, clientId, ...createDto } = createOrderDto
    const updateDto: UpdateOrderDto = {
      ...createDto
    }
    await this.orderModule.update({ updateOrderDto: updateDto, orderId })
  }

  private async create(createOrderDto: CreateOrderDto) {
    await this.orderModule.create(createOrderDto)
  }

  private redirectTo() {
    if (this.$route.params.clientId) {
      this.$router.push({
        path: `/clients/${this.$route.params.clientId}/commandes`
      })
    } else if (this.step == 6) {
      //add this else event on input is shitty
      this.$router.push({
        path: '/commandes'
      })
    }
  }

  get selectedArea() {
    return this.areaModule.responseAreaSort.find(
      (area: Area) => area.id === this.orderStepModule.orderDto.areaId
    )
  }
  public clearDates() {
    this.orderStepModule.orderDto.startAt = new Date().toISOString()
    this.orderStepModule.orderDto.endAt = undefined
  }
  /**
   * @function selectCountry
   * @param { checked } @type { boolean }
   * @description add zip on multiselect and add zip id for send data at API /order
   */
  public selectCountry(checked: boolean) {
    const ungroupedZips: Zip[] = []
    if (checked) {
      this.zipModule.zips.forEach((zipRegion: ZipByRegion) =>
        ungroupedZips.push(...zipRegion.data)
      )
    }
    this.selectedZips = ungroupedZips
  }
  /**
   * @function sortByZipsCodeNumber
   * @return @type { Zip[] }
   * @event [change]
   * @description
   * fetch zipcode and sort from the smallest to the biggest
   */
  public get sortByZipsCodeNumber(): Zip[] {
    let sortZipsCode: Zip[] = []
    for (const element of this.selectedZips) {
      sortZipsCode.push(element)
    }
    //Sorting smalest to the biggest
    sortZipsCode = sortZipsCode.sort((a, b) => {
      return a.code - b.code
    })
    //spreat inside selectedZips state
    return (this.selectedZips = [...sortZipsCode])
  }
  public set sortByZipsCodeNumber(value: Zip[]) {
    this.selectedZips = [...value]
  }
  @Watch('selectedZips', { deep: true })
  onChangeZips() {
    this.orderStepModule.orderDto.zips = this.selectedZips.map(({ id }) => id)
  }

  public setCustomPrices(item: Product) {
    if (item === null) {
      this.customPrices = null
      return
    }
    this.$set(this, 'customPrices', {
      defaultPrice: item.defaultExcluPrice,
      defaultSharePrice: item.defaultMutuPrice
    })
  }
}
