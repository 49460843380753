import Vue from 'vue'
import { Action, Mutation, VuexModule, Module } from 'vuex-module-decorators'
import { OrderCondition, TokenOperator } from './order'
import { ProductDefaultCondition, Product } from './product'
/**
 * @interface AreaAndIcon
 * @file OOrderStep
 * For add icon in card order creation
 */
export interface AreaAndIcon {
  areakeys: [
    {
      displayName: string
      areaId: number
      icon: string
    }
  ]
}
export interface AreaState {
  areas: Area[]
  area: Area | null
}

export interface Area {
  areaKeys: AreaKey[]
  createAt: Date
  defaultConditions: AreaDefaultCondition[]
  defaultPrice: number
  defaultSharePrice: number
  defaultProduct: Product
  displayName: string
  id: number
  name: string
  products: Product[]
  updateAt: Date
}
export interface AreaDefaultCondition {
  id: number
  area: Area
  conditionTokens: ConditionToken[]
}
export interface ConditionToken {
  id: number
  areaDefaultCondition?: AreaDefaultCondition
  productDefaultCondition?: ProductDefaultCondition
  orderCondition?: OrderCondition
  type: TokenTypeEnum
  areaKey?: AreaKey
  operator?: TokenOperator
  values?: string[]
}
export interface FormatedConditionToken {
  type: TokenTypeEnum
  areaId?: number
  areaKeyId?: number
  operator?: TokenOperator
  values?: Array<string | undefined>
}

export interface AreaKey {
  id: number
  name: string
  type: AreakeyTypeEnum
  dataType: AreakeyDataTypeEnum
  dataEnum: string[]
  displayName?: string
  optional: boolean
  computed?: string
  operator?: string
  value?: string | number | boolean
  area: Area
}
//For exclu or mutu type
export interface TypePrice {
  title: string
  icon: string
  price: number
  shareLimit: 1 | 2 | 3 | 4
}
//Type
//For card inside MOrderStepTwo
export enum TypePriceShareLimit {
  EXCLU = 1,
  MUTU2 = 2,
  MUTU3 = 3,
  MUTU4 = 4
}
//ENUM
export enum TypePriceResponse {
  EXCLU = 'Exclusif',
  MUTU = 'Mutualisé'
}
/**
 * Enum for tri comparaison value
 * @readonly
 * @enum {string}
 */
export enum TokenTypeEnum {
  // Logical
  IF = 'IF',
  THEN = 'THEN',
  AND = 'AND',
  OR = 'OR',
  // Functional
  COMPARAISON = 'COMPARAISON'
}
export enum AreakeyDataTypeEnum {
  INT = 'INT',
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  BOOL = 'BOOL',
  ENUM = 'ENUM',
  DATE = 'DATE'
}
export enum BasicOperatorEnum {
  // Basic
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  MORE = 'MORE',
  MORE_EQUAL = 'MORE_EQUAL',
  LESS = 'LESS',
  LESS_EQUAL = 'LESS_EQUAL'
}

export enum ArrayOperatorEnum {
  // Array
  IS_IN = 'IS_IN',
  IS_NOT_IN = 'IS_NOT_IN'
}

export enum IntervalOperatorEnum {
  // Min/Max
  IN_INTERVAL = 'IN_INTERVAL',
  NOT_IN_INTERVAL = 'NOT_IN_INTERVAL'
}
//Area
export enum AreakeyTypeEnum {
  PROFILE = 'PROFILE',
  ADDRESS = 'ADDRESS',
  FAMILY = 'FAMILY',
  FINANCES = 'FINANCES',
  CPF = 'CPF',
  WORK = 'WORK',
  HOUSING = 'HOUSING',
  COBOROWER = 'COBOROWER'
}

@Module({ namespaced: true, name: 'area' })
export default class AreaModule extends VuexModule implements AreaState {
  areas: Area[] = []
  area: Area | null = null

  @Mutation
  saveAll(areas: Area[]) {
    this.areas = areas
  }

  @Mutation
  saveOne(area: Area) {
    this.area = area
  }

  @Action({ commit: 'saveAll' })
  async fetchAll() {
    const { data } = await Vue.prototype.$http.get('/areas')
    return data
  }
  @Action({ commit: 'saveOne' })
  async fetchOne(areaId: number) {
    const { data } = await Vue.prototype.$http.get(`/areas/${areaId}`)
    return data
  }

  /**
   * @var {areas} @type {Area}
   * @description
   * we sort ids so that he display on right order
   * @returns {Area[]}
   */

  get responseAreaSort(): Area[] {
    this.areas.forEach((area: Area) =>
      area.products.forEach((product: Product) =>
        product.defaultConditions.forEach(
          (condition: ProductDefaultCondition) =>
            condition.conditionTokens.sort((a, b) => a.id - b.id)
        )
      )
    )
    return this.areas
  }
}
