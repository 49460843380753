
































import { Component, Mixins, Watch } from 'vue-property-decorator'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import { DataTableHeader } from 'vuetify'
import OrderModule from '@/store/order'
import { TableActionsProps } from '@/types'
import ClientModule from '@/store/client'
import { getModule } from 'vuex-module-decorators'
import ACheckboxSwitchOrderStatus from '@/components/atoms/checkbox/ACheckboxSwitchOrderStatus.vue'
import AIconExclu from '@/components/atoms/icons/AIconExclu.vue'
import AIconMutu from '@/components/atoms/icons/AIconMutu.vue'
import MixinTables from '@/components/molecules/table/MixinTables.vue'
import MWebhookDeleteModal from '@/components/molecules/webhook/MWebhookDeleteModal.vue'
import WebhookModule, { Webhook } from '@/store/webhook'

@Component({
  components: {
    MTableHeading,
    MTableFooter,
    MTableActions,
    MTable,
    ACheckboxSwitchOrderStatus,
    AIconExclu,
    AIconMutu,
    AButtonPrimary,
    MWebhookDeleteModal
  }
})
export default class OAdminClientTableWebhooks extends Mixins(MixinTables) {
  public clientModule = getModule(ClientModule, this.$store)
  public orderModule = getModule(OrderModule, this.$store)
  public webhookModule = getModule(WebhookModule, this.$store)

  public headers: DataTableHeader<Webhook>[] = [
    { text: 'Nom', value: 'name', sortable: false },
    {
      text: 'Type',
      value: 'linkedTo',
      sortable: false
    },
    { text: 'Verbe', value: 'type', sortable: false },
    { text: 'Url', value: 'url', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false }
  ]

  get tableActions(): TableActionsProps[] {
    return [
      { icon: '$edit', action: this.edit },
      { icon: '$trash', action: this.remove }
    ]
  }

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  linkedToType(item: Webhook) {
    return (
      item.order?.clientsAreas?.area.displayName ||
      item.clientsAreas?.area?.displayName
    )
  }

  async request() {
    this.loading = true
    this.clientModule.fetchAllWebhooks(+this.$route.params.clientId)
    this.loading = false
  }

  public newWebhook() {
    this.$router.push({
      path: `/clients/${this.$route.params.clientId}/connecteurs/creation`
    })
  }

  public remove(item: Webhook) {
    const modal = this.$refs.modal as MWebhookDeleteModal
    modal.dialog = true
    modal.name = item.name
    modal.$once('delete', async () => {
      await this.webhookModule.delete(item.id as number)
      this.clientModule.removeWebhook(item.id as number)
      modal.dialog = false
      modal.name = null
    })
  }

  public edit(item: Webhook) {
    this.$router.push({
      path: `/clients/${this.$route.params.clientId}/connecteurs/${item.id}/edition`
    })
  }
}
