



























import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'

@Component
export default class ACardPrice extends Vue {
  @Prop() text!: string
  @Prop() active!: boolean
  @Prop() icon!: string[]
  @Prop({ type: Number }) price?: number
  @PropSync('value') valueSynced!: string[]
}
