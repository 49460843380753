var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-step-six steper__card mt-5"},[_c('ATextMediumLarge',{staticClass:"d-flex mx-2 mx-lg-4 my-1 my-lg-2",attrs:{"text":"Planning de livraion"}}),_c('div',{staticClass:"d-block px-4 pt-3"},[_c('h3',{staticClass:"mr-3"},[_vm._v("Pré-remplissage :")]),_c('v-row',_vm._l((_vm.helpers),function(item,indexHelpers){return _c('v-col',{key:indexHelpers,attrs:{"cols":"auto"}},[_c('ACheckbox',{attrs:{"label":item.label,"value":item.display},on:{"update:value":function($event){return _vm.$set(item, "display", $event)},"click":function($event){return _vm.updateWeek(item.plan, item.display, indexHelpers)}}})],1)}),1)],1),_c('div',{staticClass:"steper__card"},_vm._l((_vm.daysHoursSync),function(itemDay,indexDay){return _c('div',{key:indexDay,staticClass:"d-inline-flex align-center py-2 mt-6"},[_c('v-row',{staticClass:"ml-5"},[_c('v-col',{staticClass:"order-step-six__period",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-block px-5"},[_c('ACheckbox',{attrs:{"label":itemDay.day,"value":itemDay.display},on:{"update:value":function($event){return _vm.$set(itemDay, "display", $event)},"click":function($event){return _vm.clearHours(itemDay, indexDay)}}}),_c('v-expand-transition',[_c('v-virtual-scroll',{staticClass:"rounded-lg elevation-1",attrs:{"height":itemDay.display === true ? 200 : 0,"width":180,"item-height":40,"items":itemDay.hours},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(itemDay.display)?_c('div',{staticClass:"d-block align-center ml-2"},[_c('ACheckbox',{key:index,attrs:{"label":item.text,"value":item.value},on:{"update:value":function($event){return _vm.$set(item, "value", $event)},"click":function($event){return _vm.getHours(
                          item.value,
                          itemDay.sendData,
                          index,
                          indexDay
                        )}}})],1):_vm._e()]}}],null,true)})],1)],1)])],1)],1)}),0),_c('div',{staticClass:"d-flex justify-end align-center mt-3"},[_c('h3',{staticClass:"warning--text mx-3"},[_vm._v(" /!\\ ( Pensez à valider vos choix avant de passer à la suite ! ) ")]),_c('AButtonSecondary',{attrs:{"text":"Valider votre configuration"},nativeOn:{"click":function($event){return _vm.validate()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }