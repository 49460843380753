




import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ABaseImage extends Vue {
  @Prop({ type: Number, default: 220, required: false }) width!: number
}
