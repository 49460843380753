import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import defineAbilityFor from '@/services/ability'
import { routes } from './routes'
import { getModule } from 'vuex-module-decorators'
import UserModule from '@/store/user'
import AuthenticationModule from '@/store/authentication'
import FilterModule, { Filters } from '@/store/filter'

Vue.use(VueRouter)

/*
 * Mode router : local = history(docker) | staging and prod = hash (cdn)
 */
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const DEFAULT_TITLE = 'Leads.fr'

router.beforeEach(async (to, from, next) => {
  const filterModule = getModule(FilterModule, store)

  const queryKeys = Object.keys(to.query)

  if (queryKeys.length > 0) {
    const convertedQuery: Filters = {
      ...to.query,
      ...{
        area: parseInt(to.query.area as string) || null,
        page: parseInt(to.query.page as string) || 1,
        provider: parseInt(to.query.provider as string) || null,
        sitekey: parseInt(to.query.sitekey as string) || null,
        client: parseInt(to.query.client as string) || null,
        tracker: (to.query.tracker as string) || null,
        trackerValue: (to.query.trackerValue as string) || null
      }
    }

    filterModule.initFilter(convertedQuery)
  } else if (
    queryKeys.length === 0 &&
    Object.keys(filterModule.filters).length > 0
  ) {
    filterModule.resetFilter()
  }

  /*
   * Change page title depending meta set in the routes
   * declaration file
   */
  if (to.matched.some((record) => record.meta.title)) {
    const TITLE = to.matched.find((record) => record.meta.title)?.meta.title
    document.title = `Leads | ${TITLE}` || DEFAULT_TITLE
  } else {
    document.title = DEFAULT_TITLE
  }

  /*
   * Redirection and authentication handling
   */
  const authenticationModule = getModule(AuthenticationModule, store)
  const IS_AUTHENTICATED = authenticationModule.isAuthenticated

  if (IS_AUTHENTICATED && to.name === 'Login') {
    next({
      path: '/dashboard'
    })
  }

  if (
    to.matched.some((record) => record.meta.requiresAuth) ||
    (to?.meta && to.meta?.title === "La page n'existe pas")
  ) {
    if (IS_AUTHENTICATED) {
      try {
        /*
         *fetch user information when login
         */
        const userModule = getModule(UserModule, store)
        const USER = await userModule.me()
        /*
         * Load policie (ability)
         */
        Vue.prototype.$ability.update(defineAbilityFor(USER))
        /*
         *
         * Policies
         */
        const canNavigate = to.matched.some((route) => {
          return Vue.prototype.$ability.can(
            route.meta.action || 'read',
            route.meta.resource || route.name
          )
        })
        //redirect
        if (canNavigate === false) {
          return next({
            name: 'NotFound'
          })
        }
      } finally {
        // pass
        next()
      }
    } else {
      /*
       * If you are trying to connect with wrong credentials, avoid redirecting
       */
      if (from.name === 'Login') return
      /*
       * Redirect
       */
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    if (to.path === '/') next({ path: '/login' })
    /*
     * Redirect
     */
    next()
  }
  next()
})

export default router
