




import { Component, Prop, Vue } from 'vue-property-decorator'
import ABaseText from '@/components/atoms/text/ABaseText.vue'

@Component({
  components: {
    ABaseText
  }
})
export default class ATextMedium extends Vue {
  @Prop({ type: String, required: true }) text!: string
}
