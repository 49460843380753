var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"order-step-six steper__card pa-10 my-8",attrs:{"fluid":""}},[_c('ABaseTitle',{staticClass:"blueDark--text mb-4",attrs:{"tag":"h1","text":"Récapitulatif de la commande"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-layout',[_c('ATitleMedium',{staticClass:"blueDark--text",attrs:{"text":"Type de commande"}})],1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('MDisableTextField',{attrs:{"label":"Thème","input":_vm.area}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('MDisableTextField',{attrs:{"design":"primary--text font-weight-black","label":"Formule","input":_vm.type,"icon":_vm.type === _vm.typePrice.EXCLU
                ? 'mdi-account'
                : 'mdi-account-multiple'}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('MDisableTextField',{attrs:{"label":"Zone géographique","input":_vm.$tc('order.department', _vm.zips, { count: _vm.zips })}})],1)],1)],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-layout',[_c('ATitleMedium',{staticClass:"blueDark--text",attrs:{"text":"Mes limites"}})],1),_c('v-layout',[_c('v-col',{attrs:{"cols":"6","lg":"6"}},[_c('MDisableTextField',{attrs:{"label":"Limite quotidienne","design":"font-weight-black","input":_vm.limits.daily <= 0
                ? _vm.$tc('lead.limit', 1)
                : _vm.$tc('lead.limit', 2, { count: _vm.limits.daily })}})],1),_c('v-col',{attrs:{"cols":"6","lg":"6"}},[_c('MDisableTextField',{attrs:{"design":"primary--text  font-weight-black","label":"Limite globale","input":_vm.limits.global <= 0
                ? _vm.$tc('lead.limit', 1)
                : _vm.$tc('lead.limitGlobal', 2, { count: _vm.limits.global })}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-layout',[_c('ATitleMedium',{staticClass:"blueDark--text",attrs:{"text":"Webservice"}})],1),_c('v-layout',[_c('v-col',{attrs:{"cols":"6"}},[_c('MDisableTextField',{attrs:{"label":"Notifications email","input":_vm.notificationsMail !== null ? 'Oui' : 'Non'}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('MDisableTextField',{attrs:{"label":"Connecteur","input":_vm.connectorName || 'Aucun connecteur'}})],1)],1)],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ATitleMedium',{staticClass:"blueDark--text",attrs:{"text":"Date de commande"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('MDisableTextField',{attrs:{"label":"Période","input":_vm.period}})],1)],1),(!_vm.isUpdate)?_c('v-row',[_c('ACheckbox',{staticClass:"ml-4 mt-6",attrs:{"value":_vm.checkStatus,"label":"Activer la commande","labelStyle":"title mt-1 ml-1"},on:{"update:value":function($event){_vm.checkStatus=$event}},nativeOn:{"click":function($event){return _vm.$emit('onClick', _vm.checkStatus)}}})],1):_c('v-row',[(_vm.status === 'LIVE')?_c('ATextField',{staticClass:"mt-1",attrs:{"textField":{
        icon: 'mdi-check',
        iconSize: '25',
        iconStyle: 'green--text white',
        text: 'Commande activée',
        style: 'rounded-lg black--text align-center text-left'
      }}}):_vm._e(),(_vm.status === 'PAUSED')?_c('ATextField',{staticClass:"mt-1",attrs:{"textField":{
        icon: 'mdi-close',
        iconSize: '25',
        iconStyle: 'red--text white',
        text: 'Commande désactivée',
        style: 'rounded-lg black--text align-center text-left'
      }}}):_vm._e()],1),_c('v-row',{staticClass:"mb-5"},[_c('ATitleMedium',{staticClass:"blueDark--text mt-5 mr-2",attrs:{"text":"Budget: "}}),_c('ATitleMedium',{staticClass:"green--text mt-5",attrs:{"text":_vm.budget}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }