






















import { Component, Prop, PropSync } from 'vue-property-decorator'
import { VList } from 'vuetify/lib/components'

@Component
export default class AListTooltip extends VList {
  @Prop() title!: string
  @Prop() items!: unknown
  @PropSync('itemSelected') itemSelectedSynced!: () => void
}
