


















import { Component, Vue } from 'vue-property-decorator'
//template
import TPanelWithImage from '@/components/templates/TPanelWithImage.vue'
//Organisms
import OForgotPassword from '@/components/organisms/common/OForgotPassword.vue'
import ABaseImage from '@/components/atoms/image/ABaseImage.vue'

@Component({
  components: {
    TPanelWithImage,
    OForgotPassword,
    ABaseImage
  }
})
export default class PForgotPassword extends Vue {}
