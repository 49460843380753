






























































































import { Component, Vue, PropSync } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import ABaseSelect from '@/components/atoms/select/ABaseSelect.vue'
import { LeadRejected } from '@/store/leadRejected'
import { getModule } from 'vuex-module-decorators'
import AreaModule from '@/store/area'
import { NoOptionals } from '@/helpers/types'

@Component({
  components: {
    ATitleMedium,
    AInputTextField,
    ABaseSelect
  }
})
export default class MFormLeadRejected extends Vue {
  @PropSync('leadRejected')
  leadRejectedSynced!: NoOptionals<LeadRejected>

  public areaModule = getModule(AreaModule, this.$store)

  async mounted() {
    await this.areaModule.fetchAll()
  }
}
