



















import MTable from '@/components/molecules/table/MTable.vue'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import ClientModule, { Reminder } from '@/store/client'
import { Component, Vue } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {
    MTableHeading,
    MTableFooter,
    MTable
  }
})
export default class PAdminReminders extends Vue {
  public clientModule = getModule(ClientModule, this.$store)
  public loading = true

  public headers: DataTableHeader<Reminder>[] = [
    { text: "Nom de l'entreprise", value: 'companyname', sortable: false },
    { text: 'Email', value: 'useremail', sortable: false },
    { text: 'Relance', value: 'reminder', sortable: false }
  ]

  async created() {
    await this.clientModule.fetchAllReminders()
    this.loading = false
  }

  onCheckboxClicked(reminder: Reminder) {
    this.clientModule.updateClientReminder({
      clientId: reminder.id,
      reminder: reminder.clientreminder
    })
  }
}
