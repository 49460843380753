import { render, staticRenderFns } from "./AIconEdit.vue?vue&type=template&id=b030d362&scoped=true&"
import script from "./AIconEdit.vue?vue&type=script&lang=ts&"
export * from "./AIconEdit.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b030d362",
  null
  
)

export default component.exports