import { render, staticRenderFns } from "./OAdminProviderNew.vue?vue&type=template&id=620bf75c&scoped=true&"
import script from "./OAdminProviderNew.vue?vue&type=script&lang=ts&"
export * from "./OAdminProviderNew.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "620bf75c",
  null
  
)

export default component.exports