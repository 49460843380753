




import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ADisableInput extends Vue {
  @Prop() text!: string
  @Prop({ default: 'p' }) tag!: string
}
