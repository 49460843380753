













import { Component, Prop, Vue } from 'vue-property-decorator'
import ABaseButton from '@/components/atoms/button/ABaseButton.vue'

@Component({
  components: {
    ABaseButton
  }
})
export default class ACard extends Vue {
  @Prop() text!: string
  @Prop() active!: boolean
}
