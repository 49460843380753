














































































import { Component, Vue } from 'vue-property-decorator'
//Atoms
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import ATreeviewLogs from '@/components/atoms/treeview/ATreeviewLogs.vue'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import ATitleSmall from '@/components/atoms/title/ATitleSmall.vue'
import ATextSmall from '@/components/atoms/text/ATextSmall.vue'
//Molecules
import MLeadDetail from '@/components/molecules/lead/MLeadDetail.vue'
import MLeadTransaction from '@/components/molecules/lead/MLeadTransaction.vue'
import MBaseModal, {
  ModalProps
} from '@/components/molecules/modal/MBaseModal.vue'

//Store
import { getModule } from 'vuex-module-decorators'
import LeadModule, {
  LeadStatus,
  UpdateLeadStatusParam,
  WsLogs
} from '@/store/lead'
import MatchingModule, { MatchingLogs } from '@/store/matching'
import { ThreeviewChildren } from '@/store/interfaces/components/ITreeView'

//Template
import TSkeleton from '@/components/templates/TSkeleton.vue'
@Component({
  components: {
    AButtonPrimary,
    ATextSmall,
    ATitleMedium,
    ATitleSmall,
    ATreeviewLogs,
    MBaseModal,
    MLeadTransaction,
    MLeadDetail,
    TSkeleton
  }
})
export default class OAdminLeadDetail extends Vue {
  public leadModule = getModule(LeadModule, this.$store)
  public matchingModule = getModule(MatchingModule, this.$store)
  //Just for TSkeleton
  public loading = false
  public items = [
    {
      id: 1,
      name: 'Réponse client',
      children: [
        {
          name: ''
        }
      ]
    },
    {
      id: 2,
      name: 'Payload envoyé',
      children: [
        {
          name: ''
        }
      ]
    },
    {
      id: 3,
      name: 'Payload Leads.fr',
      children: [
        {
          name: ''
        }
      ]
    },
    {
      id: 4,
      name: 'S2S facebook payload',
      children: [
        {
          name: ''
        }
      ]
    }
  ]

  public sendDTO = {
    name: '',
    leadId: +this.$route.params.id
  }

  /**
   * @descriphttps://vuetifyjs.com/en/components/treeview/#append-and-label }
   */
  public files = {
    client: 'mdi-account',
    reason: 'mdi-badge-account-alert-outline'
  }

  /**
   * @getter { itemMatchingLogs }
   * @return { ThreeviewChildren[] }
   * @description fetch data api and transfome for the vuetify Treeview type
   * @exemple  @link { ITreeView.ts }
   * id: number
   * name: string
   * children: ChildrenTreeview[]
   */
  public get itemsMatchingLogs(): ThreeviewChildren[] {
    const items: ThreeviewChildren[] = []
    const stepsLogs: MatchingLogs[] =
      this.matchingModule.responseMatchingLogs.steps
    for (let i = 0; i < stepsLogs.length; i++) {
      items.push({
        id: stepsLogs[i].id,
        name: stepsLogs[i].name,
        children: stepsLogs[i].rejectedOrders.map((rejectedOrders, index) => {
          return {
            id: index,
            name: rejectedOrders.orderName,
            children: [
              {
                id: index,
                name: rejectedOrders.clientName,
                file: 'client',
                children: [
                  {
                    name: rejectedOrders.reason,
                    file: 'reason'
                  }
                ]
              }
            ]
          }
        })
      })
    }
    return items
  }

  get leadDetail() {
    return this.leadModule.leadDetail
  }

  public get modalRgpd(): ModalProps {
    return {
      title: this.$tc('lead.selection.report'),
      actionText: this.$tc('lead.actions.report'),
      modalAction: this.addRgpdStatus
    }
  }

  public get modalLog(): ModalProps {
    return {
      title: 'Détails de la requête'
    }
  }

  get rgpdModal() {
    return this.$refs.rgpd as MBaseModal
  }

  get logModal() {
    return this.$refs.log as MBaseModal
  }

  async mounted() {
    this.leadModule.clearLead()
    const leadId = +this.$route.params.id

    if (leadId) {
      this.loading = false
      await Promise.all([
        this.matchingModule.fetchMatchingLogsLeads(leadId),
        this.leadModule.fetchLeadDetail(leadId)
      ])
      this.loading = true
    }
  }

  openRgpdModal() {
    this.rgpdModal.dialog = true
  }

  jsonParse(value: string) {
    try {
      return JSON.parse(value)
    } catch (e) {
      return value
    }
  }

  openLogModal(log: WsLogs) {
    this.items[0].children[0].name = this.jsonParse(log.clientResponse)
    this.items[1].children[0].name = this.jsonParse(log.clientPayload)
    this.items[2].children[0].name = this.jsonParse(log.leadPayload)
    this.items[3].children[0].name = this.jsonParse(log.s2sPayload)
      ? this.jsonParse(log.s2sPayload)
      : "Le payload n'a pas été envoyé."
    this.logModal.dialog = true
  }

  async addRgpdStatus() {
    const updateLeadStatus: UpdateLeadStatusParam = {
      id: +this.$route.params.id,
      dto: { status: LeadStatus.WAITING_FOR_ANONYMISE }
    }
    await this.leadModule.updateLeadStatus(updateLeadStatus)
    this.rgpdModal.dialog = false
  }

  async sendLead(log: WsLogs) {
    this.sendDTO.name = log.clientName
    await this.leadModule.forceSendlead(this.sendDTO)
    this.openLogModal(log)
  }
}
