





















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider
  }
})
export default class AInputTextfieldLimit extends Vue {
  @Prop() suffix!: string
  @Prop() min!: number
  @PropSync('limit', { type: Number }) limitSynced!: number
}
