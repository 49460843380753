




























import LeadRejectedModule from '@/store/leadRejected'
import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import MDrawer from '@/components/molecules/MDrawer.vue'
import MFormLeadRejected from '@/components/molecules/form/MFormLeadRejected.vue'
import LeadModule, { CreateLeadDto } from '@/store/lead'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import ATitleMedium from '@/components/atoms/title/ABaseTitle.vue'
import MAlert from '@/components/molecules/MAlert.vue'
//Plugins
/**
 * @deprecated
 * not use here beacause we don't have rule for this forms
 * if data is not good, the back end send an error message and don't take this
 * if information is not good whe you write inside the input, he return a message error with focus class css
 */
//import { ValidationObserver } from 'vee-validate'

@Component({
  components: {
    MDrawer,
    MFormLeadRejected,
    AButtonPrimary,
    ATitleMedium,
    MAlert
    //ValidationObserver
  }
})
export default class OAdminDrawerLeadRejectedDetail extends Vue {
  public leadRejectedModule = getModule(LeadRejectedModule, this.$store)
  public leadModule = getModule(LeadModule, this.$store)

  public id = parseFloat(this.$route.params.id)
  drawer = true

  async mounted() {
    if (this.id) {
      await this.leadRejectedModule.fetchOne(this.id)
    }
  }
  async destroyed() {
    await this.leadRejectedModule.clearLeadsRejectedDrawer()
  }
  async redirect() {
    const path = '/leads/leads-rejected'
    if (this.$route.path !== path) {
      await this.$router.push({ path, query: this.$route.query })
    }
  }

  async toggleDrawer() {
    await this.redirect()
    this.drawer = !this.drawer
  }

  async submit() {
    await this.leadModule.createLead(
      this.leadRejectedModule.leadRejected?.leadPayload as CreateLeadDto
    )
    await this.leadRejectedModule.update({
      id: this.leadRejectedModule.leadRejected!.id,
      updateLeadRejectedDto: { isReposted: true }
    })
    await this.redirect()
    this.drawer = false
  }

  public get type(): string | undefined {
    return this.leadRejectedModule.leadRejected?.type.toString()
  }

  public get errorPayload(): string | undefined {
    return this.leadRejectedModule.leadRejected?.errorPayload?.toString()
  }
}
