























import { Component, Prop, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider
  }
})
export default class AInputNumber extends Vue {
  @Prop() value!: number
  get number(): number {
    return this.value
  }

  set number(value) {
    this.$emit('update:value', Number(value))
  }
}
