import { render, staticRenderFns } from "./TTableWithFiltersAndAction.vue?vue&type=template&id=0678a0c7&scoped=true&"
var script = {}
import style0 from "./TTableWithFiltersAndAction.vue?vue&type=style&index=0&id=0678a0c7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0678a0c7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
