












































































import { Component, Vue, PropSync } from 'vue-property-decorator'
//Atoms
import AExpansionPanels from '@/components/atoms/expansion/AExpansionPanels.vue'
import ABaseTitle from '@/components/atoms/title/ABaseTitle.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import ACheckbox from '@/components/atoms/checkbox/ACheckbox.vue'
import ABaseButton from '@/components/atoms/button/ABaseButton.vue'
import ABaseImage from '@/components/atoms/image/ABaseImage.vue'
//Icon
import AIconClients from '@/components/atoms/icons/AIconClients.vue'
import AIconAction from '@/components/atoms/AIconAction.vue'
//Molecules
import MTable from '@/components/molecules/table/MTable.vue'

@Component({
  components: {
    AExpansionPanels,
    AButtonPrimary,
    ABaseTitle,
    ACheckbox,
    ABaseButton,
    AIconClients,
    MTable,
    ABaseImage,
    AInputTextField,
    AIconAction
  }
})
export default class MRedirectionClient extends Vue {
  @PropSync('logo') currentLogo!: string | ArrayBuffer | null
  @PropSync('url') thankYouUrl!: string
  @PropSync('redirect') active!: boolean

  public image: File | null = null

  public async display(event: File) {
    let reader = new FileReader()
    reader.readAsDataURL(event)
    reader.onloadend = () => {
      const base64 = reader.result
      this.currentLogo = base64
    }
  }
}
