









import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class ACheckboxNoLimit extends Vue {
  @PropSync('limit') limitSynced!: boolean
}
