





















































































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
//Atoms
import AButtonShowData from '@/components/atoms/button/AButtonShowData.vue'
import ABaseTite from '@/components/atoms/title/ABaseTitle.vue'
import ABaseSelect from '@/components/atoms/select/ABaseSelect.vue'
//Molécules
import MCardCustomCondition from '@/components/molecules/order/stepper/MCardCustomCondition.vue'
//Tempalte
import TFilterCondition from '@/components/templates/TfilterCondition.vue'
//Interface
import { FormatedOrderCondition, OrderCondition } from '@/store/order'
import MixinOrder from '../../filter/MixinOrder.vue'
import { Area, TokenTypeEnum } from '@/store/area'
//Store
import { getModule } from 'vuex-module-decorators'
import OrderModule from '@/store/order'
import OrderStepModule from '@/store/orderStep'

@Component({
  components: {
    AButtonShowData,
    ABaseTite,
    ABaseSelect,
    MCardCustomCondition,
    TFilterCondition
  }
})
export default class MCardCondition extends Mixins(MixinOrder) {
  @Prop() area!: Area
  @Prop() isUpdate?: number
  //@PropSync() valueAreaConditionSynced!:
  public orderModule = getModule(OrderModule, this.$store)
  public orderStepModule = getModule(OrderStepModule, this.$store)
  public areaConditions: FormatedOrderCondition[] = []

  public customConditions: FormatedOrderCondition[] = []

  public showDefaultConditions = false

  public tokenTypeFirstCondition = [
    { type: TokenTypeEnum.IF },
    { type: TokenTypeEnum.COMPARAISON }
  ]
  @Watch('areaConditions', { immediate: true, deep: true })
  public onChangeAreaConditions() {
    this.orderStepModule.orderDto.areaConditions = this.buildConditionsDto(
      this.areaConditions
    )
  }
  @Watch('customConditions', { immediate: true, deep: true })
  public onChangeCustomConditions() {
    this.orderStepModule.orderDto.customConditions = this.buildConditionsDto(
      this.customConditions
    )
  }
  /**
   * @function addAreaDefaultCondition
   * @description
   * add areacondition when create order
   */
  public addAreaDefaultCondition() {
    this.areaConditions = this.formatDefaultCondition(
      this.area.defaultConditions,
      this.area.id
    )
  }

  public addCustomCondition(isIf: boolean) {
    const ifConditionChain = [
      {
        type: TokenTypeEnum.COMPARAISON,
        areaId: this.area.id,
        areaKeyId: undefined,
        operator: undefined,
        values: [undefined]
      }
    ]
    this.customConditions.push({
      isIf,
      ifConditionChain: isIf ? ifConditionChain : [],
      comparaisonChain: [
        {
          type: TokenTypeEnum.COMPARAISON,
          areaId: this.area.id,
          areaKeyId: undefined,
          operator: undefined,
          values: [undefined]
        }
      ]
    })
  }
  /**
   * @function Delete
   * @param conditionIndex @type { number }
   */
  public deleteAreaCondition(conditionIndex: number) {
    this.areaConditions = this.areaConditions.filter(
      (_condition, index) => index !== conditionIndex
    )
  }
  public deleteCustomCondition(conditionIndex: number) {
    this.customConditions = this.customConditions.filter(
      (_condition, index) => index !== conditionIndex
    )
  }

  public deleteAreaIfConditionToken(
    conditionIndex: number,
    tokenIndex: number
  ) {
    const previousToken =
      tokenIndex > 0 &&
      ['AND', 'OR'].includes(
        this.areaConditions[conditionIndex].ifConditionChain[tokenIndex - 1]
          .type
      )
    this.areaConditions[conditionIndex].ifConditionChain = this.areaConditions[
      conditionIndex
    ].ifConditionChain.filter(
      (_token, index) =>
        index !== tokenIndex && (!previousToken || index !== tokenIndex - 1)
    )
  }
  public deleteAreaComparaisonToken(
    conditionIndex: number,
    tokenIndex: number
  ) {
    const previousToken =
      tokenIndex > 0 &&
      ['AND', 'OR'].includes(
        this.areaConditions[conditionIndex].comparaisonChain[tokenIndex - 1]
          .type
      )
    this.areaConditions[conditionIndex].comparaisonChain = this.areaConditions[
      conditionIndex
    ].comparaisonChain.filter(
      (_token, index) =>
        index !== tokenIndex && (!previousToken || index !== tokenIndex - 1)
    )
  }

  public deleteCustomIfConditionToken(
    conditionIndex: number,
    tokenIndex: number
  ) {
    const previousToken =
      tokenIndex > 0 &&
      ['AND', 'OR'].includes(
        this.customConditions[conditionIndex].ifConditionChain[tokenIndex - 1]
          .type
      )
    this.customConditions[conditionIndex].ifConditionChain =
      this.customConditions[conditionIndex].ifConditionChain.filter(
        (_token, index) =>
          index !== tokenIndex && (!previousToken || index !== tokenIndex - 1)
      )
  }
  public deleteCustomComparaisonToken(
    conditionIndex: number,
    tokenIndex: number
  ) {
    const previousToken =
      tokenIndex > 0 &&
      ['AND', 'OR'].includes(
        this.customConditions[conditionIndex].comparaisonChain[tokenIndex - 1]
          .type
      )
    this.customConditions[conditionIndex].comparaisonChain =
      this.customConditions[conditionIndex].comparaisonChain.filter(
        (_token, index) =>
          index !== tokenIndex && (!previousToken || index !== tokenIndex - 1)
      )
  }
  /**
   * @function Add
   * @param conditionIndex @type { number }
   * @param logicalToken @type { TokenTypeEnum }
   */
  public addAreaIfConditionToken(
    conditionIndex: number,
    logicalToken: TokenTypeEnum
  ) {
    this.areaConditions[conditionIndex].ifConditionChain.push(
      { type: logicalToken },
      {
        type: TokenTypeEnum.COMPARAISON,
        areaId: this.area.id,
        areaKeyId: undefined,
        operator: undefined,
        values: [undefined]
      }
    )
  }
  public addAreaComparaisonToken(
    conditionIndex: number,
    logicalToken: TokenTypeEnum
  ) {
    this.areaConditions[conditionIndex].comparaisonChain.push(
      { type: logicalToken },
      {
        type: TokenTypeEnum.COMPARAISON,
        areaId: this.area.id,
        areaKeyId: undefined,
        operator: undefined,
        values: [undefined]
      }
    )
  }
  public addCustomIfConditionToken(
    conditionIndex: number,
    logicalToken: TokenTypeEnum
  ) {
    this.customConditions[conditionIndex].ifConditionChain.push(
      { type: logicalToken },
      {
        type: TokenTypeEnum.COMPARAISON,
        areaId: this.area.id,
        areaKeyId: undefined,
        operator: undefined,
        values: [undefined]
      }
    )
  }
  public addCustomComparaisonToken(
    conditionIndex: number,
    logicalToken: TokenTypeEnum
  ) {
    this.customConditions[conditionIndex].comparaisonChain.push(
      { type: logicalToken },
      {
        type: TokenTypeEnum.COMPARAISON,
        areaId: this.area.id,
        areaKeyId: undefined,
        operator: undefined,
        values: [undefined]
      }
    )
  }
  created() {
    //just for update order
    if (this.isUpdate) {
      this.areaConditions = this.formatDefaultCondition(
        this.orderModule.order!.orderConditions.filter(
          (condition: OrderCondition) => condition.areaCondition === true
        ),
        this.area.id
      )
      this.customConditions = this.formatDefaultCondition(
        this.orderModule.order!.orderConditions.filter(
          (condition: OrderCondition) =>
            condition.areaCondition === false &&
            condition.productCondition === false
        ),
        this.area.id
      )
    } else {
      this.addAreaDefaultCondition()
    }

    this.orderModule.fetchOperator()
  }
}
