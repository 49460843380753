































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import MixinTables from '@/components/molecules/table/MixinTables.vue'
import { getModule } from 'vuex-module-decorators'
import ProviderModule from '@/store/provider'
import SitekeyModule, { SitekeyAssocClient } from '@/store/sitekey'
import { TableActionsProps } from '@/types'
import { SitekeyList, SitekeyClients } from '@/store/sitekey'
import MBaseModal, {
  ModalProps
} from '@/components/molecules/modal/MBaseModal.vue'
import CsvModule from '@/store/csv'
import MModal, { MModalProps } from '@/components/molecules/modal/MModal.vue'
import MFormSitekey from '../../molecules/form/MFormSitekey.vue'
import AMultipleSelectChips from '@/components/atoms/select/AMultipleSelectChips.vue'
import ABaseTitle from '@/components/atoms/title/ABaseTitle.vue'
import { FilterRequest } from '@/store/filter'
import ClientModule, { Client, CLIENTS_LIMIT } from '@/store/client'
import ABaseButton from '@/components/atoms/button/ABaseButton.vue'
import AIconInfo from '@/components/atoms/icons/AIconInfo.vue'

@Component({
  components: {
    MTableHeading,
    MTableFooter,
    MTableActions,
    MTable,
    AButtonPrimary,
    MBaseModal,
    MModal,
    MFormSitekey,
    AMultipleSelectChips,
    ABaseTitle,
    ABaseButton,
    AIconInfo
  }
})
export default class OAdminProviderTableSitekeys extends Mixins(MixinTables) {
  public providerModule = getModule(ProviderModule, this.$store)
  private csvModule = getModule(CsvModule, this.$store)
  private sitekeyModule = getModule(SitekeyModule, this.$store)
  private clientModule = getModule(ClientModule, this.$store)

  public currentSitekey: SitekeyList | null = null
  public sitekeys: SitekeyList[] = []
  public selectedClients: FilterRequest[] | number[] = []
  public clientsToSelect: FilterRequest[] = []
  public clientsBySitekeys: FilterRequest[] = []

  public headers: DataTableHeader[] = [
    { text: 'Nom', value: 'name' },
    { text: 'Nombre de leads associés', value: 'leads' },
    { text: 'Nom de ma conversion', value: 'conversionName' },
    { text: 'Nom du serveur', value: 'conversionType' },
    { text: 'Mon identifiant', value: 'customerId' },
    // { text: 'Livraison', value: 'clients' },
    { text: 'Actions', value: 'actions', sortable: false }
  ]

  get tableActions(): TableActionsProps[] {
    return [
      { icon: '$download', action: this.downloadCsv, tooltip: 'Exporter' },
      {
        icon: '$edit',
        action: this.openEditModalSitekey,
        tooltip: 'Modifier'
      },
      {
        icon: 'mdi-account-multiple-plus',
        action: this.openFluxModalSitekey,
        tooltip: 'Prioriser la livraison'
      },
      {
        icon: 'mdi-account-multiple-minus',
        action: this.openExclusivityFluxModalSitekey,
        tooltip: 'Exclure de la livraison'
      }
    ]
  }

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  async request() {
    const initialLimit = this.filterModule.filters.limit
    this.loading = true
    await this.providerModule.fetchAllSitekeysByProvider(
      this.$route.params.providerId
    )
    this.sitekeys = await Promise.all(
      this.providerModule.sitekeys.items.map(async (sitekey) => {
        // await this.sitekeyModule.fetchClientsBySitekey(sitekey.id)
        // const clientsNameArray = this.sitekeyModule.sitekeyAssoc.map(
        //   (assoc: SitekeyClients) => {
        //     return assoc.client.company.name
        //   }
        // )
        return {
          id: sitekey.id,
          name: sitekey.name,
          createdAt: sitekey.createdAt,
          leads: sitekey.leads,
          conversionName: sitekey.conversionname,
          conversionType: sitekey.conversiontype,
          customerId: sitekey.customerid // ,
          // clients: clientsNameArray.join(' | ')
        }
      })
    )
    this.filterModule.filters.limit = CLIENTS_LIMIT
    await this.clientModule.fetchAll()
    this.filterModule.filters.limit = initialLimit
    this.clientsToSelect = this.clientModule.clients.items.map(
      (client: Client) => {
        return { name: client.name, id: client.clientId }
      }
    )
    this.loading = false
  }

  public get modalProps(): ModalProps {
    return {
      title: this.title,
      actionText: this.$tc('provider.actions.export'),
      modalAction: this.submit
    }
  }

  public get modalEditProps(): MModalProps {
    return {
      title: this.$tc('sitekey.update.title', 0) as string,
      actionText: this.$tc('sitekey.update.validate', 0) as string,
      width: '600px',
      modalActionSubmit: this.editSitekey,
      modalActionCancel: this.cancelModalEdit,
      peristent: true
    }
  }

  public get modalFluxProps(): MModalProps {
    return {
      title: this.$tc('sitekey.update.title', 1) as string,
      actionText: this.$tc('sitekey.update.validate', 1) as string,
      width: '600px',
      modalActionSubmit: this.editFluxSitekey,
      modalActionCancel: this.cancelModalFlux,
      peristent: true
    }
  }

  public get modalFluxExclusionProps(): MModalProps {
    return {
      title: this.$tc('sitekey.update.title', 2) as string,
      actionText: this.$tc('sitekey.update.validate', 2) as string,
      width: '600px',
      modalActionSubmit: this.editFluxExclusionSitekey,
      modalActionCancel: this.cancelModalFluxExclusion,
      peristent: true
    }
  }

  public downloadCsv(item: SitekeyList) {
    this.modal.dialog = true
    this.currentSitekey = item
  }

  public newSitekey() {
    this.$router.push({
      path: `/partenaires/${this.$route.params.providerId}/sitekeys/creation`
    })
  }

  public openEditModalSitekey(item: SitekeyList) {
    this.currentSitekey = item
    this.modalEdit.dialog = true
  }

  public cancelModalEdit() {
    this.currentSitekey = null
    this.modalEdit.dialog = false
    this.request()
  }

  public async openFluxModalSitekey(item: SitekeyList) {
    this.currentSitekey = item
    await this.sitekeyModule.fetchAssocClientBySitekey(item.id)
    this.selectedClients = this.sitekeyModule.sitekeyAssoc.map(
      (assoc: SitekeyClients) => {
        return { name: assoc.client.company.name, id: assoc.client.id }
      }
    )
    this.modalFlux.dialog = true
  }

  public async openExclusivityFluxModalSitekey(item: SitekeyList) {
    this.currentSitekey = item
    await this.sitekeyModule.fetchAssocExclusionClientBySitekey(item.id)
    this.selectedClients = this.sitekeyModule.sitekeyExclusion.map(
      (assoc: SitekeyClients) => {
        return { name: assoc.client.company.name, id: assoc.client.id }
      }
    )
    this.modalExclusionFlux.dialog = true
  }

  public cancelModalFlux() {
    this.currentSitekey = null
    this.modalFlux.dialog = false
  }

  public cancelModalFluxExclusion() {
    this.currentSitekey = null
    this.modalExclusionFlux.dialog = false
  }

  public async editSitekey() {
    await this.sitekeyModule.updateSitekeyConversion(this.currentSitekey!)
    this.request()
    this.modalEdit.dialog = false
  }

  public async editFluxSitekey() {
    const dataToPost: SitekeyAssocClient = {
      clientId: this.selectedClients.map((client) => {
        if (typeof client !== 'number') {
          return client.id
        } else {
          return client
        }
      }),
      sitekeyId: this.currentSitekey?.id
    }
    await this.sitekeyModule.newAssociation(dataToPost)
    this.request()
    this.modalFlux.dialog = false
  }

  public async editFluxExclusionSitekey() {
    const dataToPost: SitekeyAssocClient = {
      clientId: this.selectedClients.map((client) => {
        if (typeof client !== 'number') {
          return client.id
        } else {
          return client
        }
      }),
      sitekeyId: this.currentSitekey?.id
    }
    await this.sitekeyModule.newExclusion(dataToPost)
    this.request()
    this.modalExclusionFlux.dialog = false
  }

  public async noExcluExclusion() {
    const data: SitekeyAssocClient = {
      clientId: [],
      sitekeyId: this.currentSitekey?.id
    }
    await this.sitekeyModule.newExclusion(data)
    this.request()
    this.modalExclusionFlux.dialog = false
  }

  public async noExclu() {
    const data: SitekeyAssocClient = {
      clientId: [],
      sitekeyId: this.currentSitekey?.id
    }
    await this.sitekeyModule.newAssociation(data)
    this.request()
    this.modalFlux.dialog = false
  }

  get modal() {
    return this.$refs.modal as MBaseModal
  }

  get modalEdit() {
    return this.$refs.modalEdit as MBaseModal
  }

  get modalFlux() {
    return this.$refs.modalFlux as MBaseModal
  }

  get modalExclusionFlux() {
    return this.$refs.modalFluxExclusion as MBaseModal
  }

  public async submit() {
    await this.csvModule.downloadProviderLeads({
      providerId: +this.$route.params.providerId,
      data: {
        sitekeyId: this.currentSitekey?.id as number,
        setAsExported: false
      }
    })
    this.modal.dialog = false
  }

  get title() {
    return `${this.$t('provider.actions.export')} ${this.currentSitekey?.name}`
  }
}
