













































import { Component, PropSync, Vue } from 'vue-property-decorator'

import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import { EditMultipleProviderAreas } from '@/store/provider'
import ASwitch from '@/components/atoms/switch/ASwitch.vue'

@Component({
  components: {
    AInputTextField,
    AButtonPrimary,
    ASwitch
  }
})
export default class MFormProviderAreas extends Vue {
  @PropSync('editProviderAreasPayload')
  editProviderAreasPayloadSynced!: EditMultipleProviderAreas
}
