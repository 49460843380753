var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tableProperties && _vm.tableProperties.totalItems > 0)?_c('div',{staticClass:"table-footer d-sm-flex justify-sm-end"},[_c('div',[_vm._t("btn")],2),_c('div',{staticClass:"d-flex justify-center align-center"},[_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.$t('table.footer.nbRow')))]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dark":"","text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.tableProperties.itemsPerPage)+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,3446461662)},[_c('v-list',_vm._l((_vm.itemsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateItemsPerPage(number)}}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1)],1),_c('div',{staticClass:"table-pagination d-flex flex-column flex-sm-row align-center"},[_c('div',{class:[
        'ma-2',
        'pr-sm-2',
        { 'border-right-md': _vm.$vuetify.breakpoint.mdAndUp }
      ],staticStyle:{"width":"150px"}},[_c('APaginationSearch',{staticClass:"table-footer__text",attrs:{"placeholder":"Aller à"},on:{"fieldInputAction":function($event){_vm.page = $event}}})],1),_c('v-col',{staticClass:"d-inline-flex flex-row justify-center align-center"},[_c('APaginationButtonIcon',{attrs:{"icon":"$prevEnd"},on:{"buttonIconAction":function($event){_vm.page = 1}}}),_c('APagination',{attrs:{"tableProperties":_vm.tableProperties},on:{"onPageChanges":function($event){_vm.page = $event}}}),_c('APaginationButtonIcon',{attrs:{"icon":"$nextEnd"},on:{"buttonIconAction":function($event){_vm.page = _vm.tableProperties.totalPages}}})],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }