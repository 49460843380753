




























































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
//Atoms
import AExpansionPanels from '@/components/atoms/expansion/AExpansionPanels.vue'
import ABaseTitle from '@/components/atoms/title/ABaseTitle.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import ACheckbox from '@/components/atoms/checkbox/ACheckbox.vue'
import ABaseButton from '@/components/atoms/button/ABaseButton.vue'
//Icon
import AIconClients from '@/components/atoms/icons/AIconClients.vue'
//Molecules
import MTable from '@/components/molecules/table/MTable.vue'
import ABaseIcon from '../../atoms/icons/ABaseIcon.vue'

@Component({
  components: {
    AExpansionPanels,
    AButtonPrimary,
    ABaseTitle,
    ACheckbox,
    ABaseButton,
    AIconClients,
    ABaseIcon,
    MTable
  }
})
export default class MConnectorPanel extends Vue {
  @Prop() headers!: string[]
  @Prop() items!: { item: string[]; key: string }
  @Prop() loading!: boolean
  @PropSync('selected') selectedSynced!: string[]
  destoyed() {
    this.items.item = []
  }
}
