var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MTableHeading',{attrs:{"title":_vm.$t('invoice.title'),"disableSearch":""}}),_c('MTable',{attrs:{"headers":_vm.headers,"items":_vm.manualInvoices.items,"loading":_vm.loading},on:{"sort":_vm.sort},scopedSlots:_vm._u([{key:"item.issuedAmt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.issuedAmt))+" ")]}},{key:"item.invoiceAmt",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('MTableProgressCircular',{directives:[{name:"show",rawName:"v-show",value:(item.isLoading),expression:"item.isLoading"}]}),(!item.isLoading)?_c('MTableEditDialog',{attrs:{"return-value":item.invoiceAmt,"item":item.invoiceAmt,"invalid":invalid,"formatPrice":"","rules":"required","name":"invoiceAmt"},on:{"update:returnValue":function($event){return _vm.$set(item, "invoiceAmt", $event)},"update:return-value":function($event){return _vm.$set(item, "invoiceAmt", $event)},"update:item":function($event){return _vm.$set(item, "invoiceAmt", $event)},"save":function($event){return _vm.editContestationWithInvoiceAmt(
              index,
              invalid,
              item.invoiceAmt,
              item.manualInvoiceId,
              item.issuedAmt,
              item.billingDate
            )},"cancel":function($event){return _vm.loadDataFromState()}}}):_vm._e()]}}],null,true)})]}},{key:"item.billingDate",fn:function(ref){
            var item = ref.item;
return [(!item.isLoading)?_c('div',[(item.billingDate === null)?_c('ADatePickerManualInvoice',{attrs:{"label":_vm.$t('addOrder.input.date'),"value":item.billingDate,"message":"Entrez une date"},on:{"onChange":function($event){_vm.dateBilling = $event},"saveDate":function($event){return _vm.saveDate(_vm.dateBilling, item.manualInvoiceId, item.invoiceAmt)}}}):(_vm.role === 'SUPER' && item.billingDate !== null)?_c('ADatePickerManualInvoice',{attrs:{"label":_vm.$t('addOrder.input.date'),"value":item.billingDate},on:{"onChange":function($event){_vm.dateBilling = $event},"saveDate":function($event){return _vm.saveDate(_vm.dateBilling, item.manualInvoiceId, item.invoiceAmt)}}}):_c('span',[_vm._v(_vm._s(_vm.formatDate(item.billingDate)))])],1):_vm._e()]}},{key:"item.contestationAmt",fn:function(ref){
            var item = ref.item;
return [_c('MTableProgressCircular',{directives:[{name:"show",rawName:"v-show",value:(item.isLoading),expression:"item.isLoading"}]}),(!item.isLoading)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.contestationAmt))+" ")]):_vm._e()]}},{key:"item.contestationPercent",fn:function(ref){
            var item = ref.item;
return [_c('MTableProgressCircular',{directives:[{name:"show",rawName:"v-show",value:(item.isLoading),expression:"item.isLoading"}]}),(!item.isLoading)?_c('MTableEditDialog',{attrs:{"return-value":item.contestationPercent,"item":item.contestationPercent,"percent":"","rules":"required","name":"contestationPercent"},on:{"update:returnValue":function($event){return _vm.$set(item, "contestationPercent", $event)},"update:return-value":function($event){return _vm.$set(item, "contestationPercent", $event)},"update:item":function($event){return _vm.$set(item, "contestationPercent", $event)},"save":function($event){return _vm.editContestationWithPercent(
            item.contestationPercent,
            item.manualInvoiceId,
            item.billingDate
          )},"cancel":function($event){return _vm.loadDataFromState()}}}):_vm._e()]}}],null,true)}),_c('MTableFooter',{attrs:{"tableProperties":_vm.manualInvoices.meta}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }