import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import {
  LeadPropsProperty,
  LeadPropsWsLog,
  LeadResponse,
  LeadStatusEnum
} from '@/store/lead'
import { AreaRequest, SiteKeyRequest } from '@/store/statistic'
import { DashboardAreas, DashboardSitekeys } from '@/store/statistic'
import { Sitekey } from './sitekey'
import { Payment, Address } from './company'
import { Area } from './area'
import { Lead } from '@/store/lead'
import { PaginationResponse } from '@/types'
import { User } from './user'
import { Client } from './client'

export interface Company {
  id: number
  name: string
  displayName?: string
  registrationNumber?: string
  vat?: string
  users?: User[]
  payment?: Payment
  client?: Client
  provider?: Provider
  notification: Notification
  address: Address
}
export interface ProvidersAreas {
  id: number
  cpl?: number
  refShare?: number
  isCpl: boolean
  provider: Provider
  area: Area
}
/**
 * @interface ProviderArea
 * @description when you use just one Providerarea
 * @example { @link  OAdminProviderAreasEdit  }
 * editProviderAreasPayload => re-assign object
 */
export interface ProviderArea extends ProvidersAreas {
  refShare?: number
  isCpl: boolean
  cpl?: number
  id: number
  area: Area
}
export interface EditMultipleProviderAreas {
  refShare?: number
  isCpl: boolean
  cpl?: number
  id: number
  name?: string
}
export interface Provider {
  apiKey: string
  providerId: string
  company: Company
  areas: ProvidersAreas[]
  sitekeys: Sitekey[]
}
interface ProviderState {
  providers: Provider[]
  provider: Provider | null
  pricings: number[]
  dashboard: Dashboard | null
  sitekeys: SiteKeyRequest
  leads: LeadResponse
  lead: Lead | null
  leadDetail: ProviderLeadDetailModel | null
  statistics: Statistics
}

export interface Users {
  firstName: string
  lastName: string
  phone: string
  email: string
}

export interface ProviderLeadDetailModel {
  createdAt: Date
  firstName: string
  lastName: string
  email: string
  phone: string
  status: LeadStatusEnum
  zipCode: string
  area: string
  price: number
  sitekey: string
  properties: LeadPropsProperty[]
  wsLogs: LeadPropsWsLog[]
}
export interface ProviderDashboard {
  leads: number
  leadsSold: number
  sales: string
}

export interface Sitekeys {
  name: string
  createdAt: Date
  leads: number
}

export interface Statistics {
  areas: AreaRequest
  sitekeys: SiteKeyRequest
}

export interface DashboardStats {
  avgExclu: number[]
  avgMutu: number[]
  dates: Date[]
  leadsSold: number[]
  sales: number[]
}

export interface Dashboard {
  areas: {
    items: DashboardAreas[]
  }
  leads: number
  leadsSold: number
  sales: number
  sitekeys: {
    items: DashboardSitekeys[]
  }
  stats: DashboardStats
}

export interface ProviderStatisticItem {
  name: string
  leads: number // LEADS BRUT
  leadsSold: number // LEADS VENDUS
  sales: number // CA BRUT
  salesPerLead: number // CA BRUT / LEAD
  netSales: number // CA NET
  netSalesPerLead: number // CA NET / LEAD
}
export type ProviderAreaStatisticsItem = ProviderStatisticItem

export type ProviderSitekeyStatisticsItem = ProviderStatisticItem

export interface ProviderStatisticPaginate {
  items: ProviderAreaStatisticsItem[]
  meta: PaginationResponse
}

export type ProviderAreasStatistics = ProviderStatisticPaginate
export type ProviderSitekeysStatistics = ProviderStatisticPaginate

//Todo check for delete implements ProviderState because it's not util
@Module({ namespaced: true, name: 'provider' })
export default class ProviderModule
  extends VuexModule
  implements ProviderState
{
  providers: Provider[] = []
  users: Users[] = []
  dashboard: Dashboard | null = null
  statistics: Statistics = {
    areas: {
      filters: {
        areas: [],
        clients: [],
        sitekeys: [],
        providers: [],
        status: [],
        orders: []
      },
      items: []
    },
    sitekeys: {
      filters: {
        areas: [],
        clients: [],
        sitekeys: [],
        providers: [],
        status: [],
        orders: []
      },
      items: []
    }
  }
  sitekeys: SiteKeyRequest = {
    filters: {
      areas: [],
      clients: [],
      sitekeys: [],
      providers: [],
      status: [],
      orders: []
    },
    items: []
  }
  leads: LeadResponse = {
    filters: {
      areas: [],
      clients: [],
      sitekeys: [],
      providers: []
    },
    items: []
  }
  lead: Lead | null = null
  leadDetail: ProviderLeadDetailModel | null = null
  provider: Provider | null = null
  pricings: number[] = []
  providerDashboard: ProviderDashboard | null = null

  @Mutation
  saveOne(provider: Provider) {
    this.provider = provider
  }
  @Mutation
  saveAll(providers: Provider[]) {
    this.providers = providers
  }

  @Mutation
  saveAllLeadsByProvider(leads: LeadResponse) {
    this.leads = { ...this.leads, ...leads }
  }

  @Mutation
  saveAllStatisticsAreas(areas: ProviderAreaStatisticsItem) {
    this.statistics.areas = { ...this.statistics.areas, ...areas }
  }

  @Mutation
  saveAllStatisticsSitekeys(sitekeys: ProviderSitekeyStatisticsItem) {
    this.statistics.sitekeys = { ...this.statistics.sitekeys, ...sitekeys }
  }

  @Mutation
  savePricings(pricings: number[]) {
    this.pricings = pricings
  }

  @Mutation
  save(provider: Provider) {
    this.provider = provider
  }

  @Mutation
  saveUsers(users: Users[]) {
    this.users = users
  }

  @Mutation
  saveDashboard(dashboard: Dashboard) {
    this.dashboard = { ...this.dashboard, ...dashboard }
  }

  @Mutation
  saveAllSitekeysByProvider(sitekeys: SiteKeyRequest) {
    this.sitekeys = { ...this.sitekeys, ...sitekeys }
  }
  //Todo util on Provider interface
  // @Mutation
  // saveOneLead(lead: Lead | null) {
  //   this.lead = lead
  // }

  @Mutation
  saveOneLeadDetail(leadDetail: ProviderLeadDetailModel) {
    this.leadDetail = leadDetail
  }

  @Action({ commit: 'saveAll' })
  async fetchAll() {
    const { data } = await Vue.prototype.$http.get('/providers', {
      useQueryParams: true
    })
    return data
  }

  @Action({ commit: 'saveOne' })
  async fetchOneProvider(providerId: number) {
    const { data } = await Vue.prototype.$http.get(`/providers/${providerId}`)
    return data
  }
  @Action({ commit: 'saveAllLeadsByProvider' })
  async fetchAllLeadsByProvider(providerId: number) {
    const { data } = await Vue.prototype.$http.get(
      `/providers/${providerId}/leads`,
      {
        useQueryParams: true
      }
    )
    return data
  }

  @Action({ commit: 'saveUsers' })
  async fetchUsers(id: number) {
    const { data } = await Vue.prototype.$http.get(`providers/${id}/users`, {
      useQueryParams: true
    })
    return data
  }

  @Action({ commit: 'saveAllSitekeysByProvider' })
  async fetchAllSitekeysByProvider(providerId: string) {
    const { data } = await Vue.prototype.$http.get(
      `providers/${providerId}/sitekeys`,
      {
        useQueryParams: true
      }
    )
    return data
  }

  @Action({ commit: 'savePricings' })
  async fetchPricings(id: number) {
    const { data } = await Vue.prototype.$http.get(`/providers/${id}/pricing`)
    return data
  }

  @Action({ commit: 'save' })
  async fetchOne(providerId: string) {
    const { data } = await Vue.prototype.$http.get(`/providers/${providerId}`, {
      useQueryParams: true
    })
    return data
  }

  @Action({ commit: 'saveAllStatisticsAreas' })
  async fetchAllStatisticsAreas(providerId: string) {
    const { data } = await Vue.prototype.$http.get(
      `/providers/${providerId}/stats/areas`,
      {
        useQueryParams: true
      }
    )
    return data
  }

  @Action({ commit: 'saveAllStatisticsSitekeys' })
  async fetchAllStatisticsSitekeys(providerId: string) {
    const { data } = await Vue.prototype.$http.get(
      `/providers/${providerId}/stats/sitekeys`,
      {
        useQueryParams: true
      }
    )
    return data
  }

  @Action({ commit: 'saveDashboard' })
  async fetchDashboard(providerId: string) {
    const { data } = await Vue.prototype.$http.get(
      `/providers/${providerId}/dashboard`,
      {
        useQueryParams: true
      }
    )
    return data
  }
  //Todo check is util on Provider interface
  // @Action({ commit: 'saveOneLead' })
  // async fetchOneLead(leadId: number): Promise<unknown> {
  //   const { data } = await Vue.prototype.$http.get(`/providers/leads/${leadId}`)

  //   return data
  // }

  @Action({ commit: 'saveOneLeadDetail' })
  async fetchOneLeadDetail(leadId: number): Promise<ProviderLeadDetailModel> {
    const { data } = await Vue.prototype.$http.get(`/providers/leads/${leadId}`)

    return data
  }

  @Action
  async editMultipleAreas(editFields: {
    id: number
    areas: EditMultipleProviderAreas[]
  }): Promise<unknown> {
    const { data } = await Vue.prototype.$http.put(
      `/providers/${editFields.id}/areas`,
      {
        areas: editFields.areas
      },
      {
        successHandler: {
          message: 'Les refshares des thématiques ont bien été mis à jour'
        }
      }
    )

    return data
  }
}

//TODO: add the external mutations

//   SAVE_NEW_SITEKEY(state, sitekey: SiteKey) {
//     state.provider?.sitekeys.push(sitekey)
//   },
//   EMPTY_STATE(state) {
//     Object.assign(state, initialState)
//   }
// }
