


























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import Multiselect from 'vue-multiselect'

@Component({
  components: {
    Multiselect,
    ValidationProvider
  }
})
export default class AMultiSelectBase extends Vue {
  @PropSync('value') valueSynced!: Array<string>
  @Prop() options!: Array<string>
}
