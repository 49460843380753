
















import { Component, Mixins, Watch } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import TTable from '@/components/templates/TTable.vue'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import MixinTables from '@/components/molecules/table/MixinTables.vue'
import { TableActionsProps } from '@/types'

@Component({
  components: {
    TTable,
    MTableHeading,
    MTableFooter,
    MTableActions,
    MTable
  }
})
export default class OAdminProviderTableSources extends Mixins(MixinTables) {
  public headers: DataTableHeader<string | boolean>[] = [
    { text: 'Nom', value: '' },
    { text: 'Téléphone', value: '' },
    { text: 'E-mail', value: '' },
    { text: 'Actions', value: '', sortable: false }
  ]

  get tableActions(): TableActionsProps[] {
    return [
      { icon: '$view', action: this.read },
      { icon: '$edit', action: this.edit },
      { icon: '$download', action: this.share },
      { icon: '$trash', action: this.remove }
    ]
  }

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  async request() {
    this.loading = true
    // TODO REQUEST
    this.loading = false
  }

  public remove() {
    return
  }

  public read() {
    return
  }

  public edit() {
    return
  }

  public share() {
    return
  }
}
