










import { Component, Mixins } from 'vue-property-decorator'
import MFilterHeading from '@/components/molecules/filter/MFilterHeading.vue'
import MixinFilters from '@/components/molecules/filter/MixinFilters.vue'
import MFilterDates from '@/components/molecules/filter/MFilterDates.vue'

@Component({
  components: {
    MFilterDates,
    MFilterHeading
  }
})
export default class OTableClientOrdersFilters extends Mixins(MixinFilters) {}
