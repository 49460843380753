






import { ValidationProvider } from 'vee-validate'
import { Component, Prop } from 'vue-property-decorator'
import { VTextField } from 'vuetify/lib'

@Component({
  components: {
    ValidationProvider
  }
})
export default class AInputTextFieldRefacto extends VTextField {
  @Prop({ type: String }) custoRules!: string
  @Prop({ type: String }) name!: string
}
