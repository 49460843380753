import { render, staticRenderFns } from "./PActionsRgpd.vue?vue&type=template&id=60a6529a&scoped=true&"
import script from "./PActionsRgpd.vue?vue&type=script&lang=ts&"
export * from "./PActionsRgpd.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60a6529a",
  null
  
)

export default component.exports