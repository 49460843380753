















































import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'

/**
 * @interface
 * @description
 * Props of dynamic text field components.
 * Can be modified.
 */
export interface TextFieldProps {
  component: string
  type: string
  label: string
  name: string
  rules: string
}
@Component({
  components: {
    AInputTextField
  }
})
export default class MInputTextFieldMultiple extends Vue {
  @PropSync('fields') mainField!: string[]
  @Prop() propsComponent!: TextFieldProps

  public tabFields: TextFieldProps[] = []

  mounted() {
    this.loadTextFieldComponent()
  }

  /**
   * @function loadTextFieldComponent
   * @description
   * Load dynamic component if exist.
   */
  loadTextFieldComponent() {
    const start = 1
    if (this.mainField.length > start) {
      for (let index = start; index < this.mainField.length; index++) {
        this.tabFields.push(this.propsComponent)
      }
    }
  }

  /**
   * @function removeTextField
   * @description
   * Remove field if delete button is clicked.
   */
  removeTextField(index: number) {
    this.mainField.splice(index + 1, 1)
    this.tabFields.splice(index, 1)
  }

  /**
   * @function addTextFieldComponent
   * @description
   * Add field if add button is clicked.
   */
  addTextFieldComponent() {
    this.tabFields.push(this.propsComponent)
  }
}
