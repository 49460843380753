
















































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import { getModule } from 'vuex-module-decorators'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import { TableActionsProps } from '@/types'
import MixinTables from '@/components/molecules/table/MixinTables.vue'
import UserModule, { User, UsersFields } from '@/store/user'
import { subject } from '@casl/ability'
import MBaseModal, {
  ModalProps
} from '@/components/molecules/modal/MBaseModal.vue'
import MModal from '@/components/molecules/modal/MModal.vue'

import MFormEditAdmin from '@/components/molecules/form/MFormEditAdmin.vue'
@Component({
  components: {
    MTableHeading,
    MTableFooter,
    MTableActions,
    MTable,
    MBaseModal,
    AButtonPrimary,
    MFormEditAdmin
  }
})
export default class OTableAdminUsers extends Mixins(MixinTables) {
  public userModule = getModule(UserModule, this.$store)

  public headers: DataTableHeader<User>[] = [
    { text: 'Nom', value: 'lastName' },
    { text: 'Prénom', value: 'firstName' },
    { text: 'E-mail', value: 'email' },
    { text: 'Téléphone', value: 'phone', sortable: false },
    { text: 'Rôle', value: 'role.name' },
    { text: 'Actions', value: 'actions', sortable: false }
  ]

  //properties modal summary order
  public get modalsummaryEditUser(): ModalProps {
    return {
      title: this.$t('user.actions.edit') as string,
      actionText: "Modifiez l'utilisateur",
      modalAction: this.submitEditUser,
      maxWidth: '800px'
    }
  }
  //Ref modal edit users
  public get modalEditUser() {
    return this.$refs.editUsers as MModal
  }
  private editUser: { userId: string; editField: UsersFields | null } = {
    userId: '',
    editField: null
  }
  public editFields: UsersFields | null = null

  get tableActions(): TableActionsProps[] {
    return [
      {
        icon: '$edit',
        action: this.edit,
        tooltip: this.$t('provider.actions.edit') as string
      },
      {
        icon: '$trash',
        action: this.delete,
        tooltip: this.$t('user.actions.delete') as string,
        ability: (item: User) => {
          return this.$ability.can('delete', subject('Users', item))
        }
      }
    ]
  }

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  async request() {
    this.loading = true
    await this.userModule.fetchAll()
    this.loading = false
  }

  public delete(item: User) {
    this.$router.push({ path: `/utilisateurs/${item.id}/suppression` })
  }

  public edit(item: User) {
    // this.$router.push({ path: `/utilisateurs/${item.id}/edition` })
    this.modalEditUser.dialog = true
    //Add id inside object editUser
    this.editUser.userId = item.id
    //fetch only data aree interested in
    const { email, firstName, lastName, phone, password } = item
    this.editUser.editField = {
      email,
      firstName,
      lastName,
      phone,
      password
    }
  }

  public newUser() {
    this.$router.push({ path: '/utilisateurs/creation' })
  }

  private async submitEditUser() {
    //delete password if not modified
    if (
      this.editUser.editField?.password === '' ||
      this.editUser.editField?.password === undefined ||
      !this.editUser.editField?.password
    ) {
      delete this.editUser.editField?.password
      delete this.editUser.editField?.currentPassword
    }
    //Send result at API
    await this.userModule.edit({
      id: this.editUser.userId,
      data: this.editUser.editField
    })
    //Close modal edit Users
    this.modalEditUser.dialog = false
    this.request()
  }
  destroy() {
    this.editUser = {
      userId: '',
      editField: null
    }
  }
}
