


































































import { Product } from '@/store/product'
import { Area, TypePriceShareLimit } from '@/store/area'
import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'
//Template
import TFilterProduct from '../../../templates/TFilterProduct.vue'
//Atoms
import AButtonPrimary from '../../../atoms/button/AButtonPrimary.vue'
import AButtonShowData from '../../../atoms/button/AButtonShowData.vue'
//Molecules
import MCardCustomCondition from './MCardCustomCondition.vue'
import MCardCondition from './MCardCondition.vue'

//Store
import { getModule } from 'vuex-module-decorators'
import OrderStepModule from '@/store/orderStep'
import OrderModule, { FormatedOrderCondition } from '@/store/order'
//Mixins
import MixinOrder from '../../filter/MixinOrder.vue'

@Component({
  components: {
    TFilterProduct,
    AButtonPrimary,
    MCardCustomCondition,
    AButtonShowData,
    MCardCondition
  }
})
export default class MCardProductRefactor extends Mixins(MixinOrder) {
  /**
   * @description area prop return area select at step One
   * @prop {object}
   * @alias Area
   * */
  @Prop() area!: Area
  @Prop() isUpdate?: number
  @PropSync('value') valueSynced!: number | null

  public orderModule = getModule(OrderModule, this.$store)
  public orderStepModule = getModule(OrderStepModule, this.$store)

  public product: Product | undefined = undefined
  public countHowManyClick = 0

  public showProduct = false
  public productConditions: FormatedOrderCondition[] = [] // Load data in array on product select

  // init productionConditions
  public get productionConditions(): FormatedOrderCondition[] {
    return []
  }
  // reactive productionConditions
  public set productionConditions(value: FormatedOrderCondition[]) {
    this.productionConditions = value
  }
  /**
   * @function incrementProductCondition
   */
  public incrementProductCondition() {
    this.productConditions = this.formatDefaultCondition(
      this.product!.defaultConditions,
      this.area.id
    )
  }

  public addOnProduct(product: Product | undefined) {
    this.product = product
    //count how many click
    this.countHowManyClick++
    this.initPriceProductCreateOrder()

    this.incrementProductCondition()
    this.orderStepModule.orderDto.productConditions =
      this.formatProductCondition(this.product!.defaultConditions)
  }

  /**
   * @function initPriceProductCreateOrder
   * @returns { number }
   * @description Change price when you click on product
   */
  private initPriceProductCreateOrder(): number {
    //Exclu
    if (
      this.orderStepModule.orderDto?.shareLimit === TypePriceShareLimit.EXCLU
    ) {
      return (this.orderStepModule.orderDto.price =
        this.product!.defaultExcluPrice)
    }
    //Mutu *2 & *3
    if (
      this.orderStepModule.orderDto?.shareLimit === TypePriceShareLimit.MUTU2 ||
      this.orderStepModule.orderDto?.shareLimit === TypePriceShareLimit.MUTU3
    )
      return (this.orderStepModule.orderDto.price =
        this.product!.defaultMutuPrice)
    else {
      return (this.orderStepModule.orderDto.price = 0)
    }
  }
  /**
   * @function defaultProductFirstOrder
   * @return { void }
   * @description
   * If first order
   * then increment product with default product
   * and increment valuesynced with id (for display active class on button selector)
   */
  private defaultProductFirstOrder(): void {
    this.product = this.area.defaultProduct
    this.valueSynced = this.product.id
  }
  public updateResetProduct() {
    return
  }
  /**
   * @function addIfUpdateOrder
   * @description
   * add when update
   */
  public addIfUpdateOrder() {
    this.productConditions = this.formatDefaultCondition(
      this.orderModule.order!.orderConditions.filter(
        (orderCondition) => orderCondition.productCondition === true
      ),
      this.area.id
    )
    this.orderStepModule.orderDto.productConditions = this.buildConditionsDto(
      this.productConditions
    )
    this.product = this.orderModule.order?.product
    this.valueSynced = this.orderModule.order!.product!.id
  }

  created() {
    if (this.isUpdate) this.addIfUpdateOrder()
    else {
      this.defaultProductFirstOrder()

      if (this.countHowManyClick === 0) {
        this.initPriceProductCreateOrder()
      }
    }
  }

  destroyed() {
    this.orderStepModule.orderDto.productConditions = this.buildConditionsDto(
      this.productConditions
    )
  }
}
