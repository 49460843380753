






import { Component, Vue, Prop } from 'vue-property-decorator'
interface AnchorBalise {
  text: string
  src: string
}
@Component
export default class ATextAnchor extends Vue {
  @Prop() anchorBalise!: AnchorBalise
  @Prop() src!: string
}
