import { render, staticRenderFns } from "./OSideBar.vue?vue&type=template&id=0da5b588&scoped=true&"
import script from "./OSideBar.vue?vue&type=script&lang=ts&"
export * from "./OSideBar.vue?vue&type=script&lang=ts&"
import style0 from "./OSideBar.vue?vue&type=style&index=0&id=0da5b588&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0da5b588",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VAppBarNavIcon,VIcon,VList,VNavigationDrawer})
