




























import { Component, Vue } from 'vue-property-decorator'
import MAnonymizePanel from '@/components/molecules/rgpd/MAnonymizePanel.vue'
import MExtractLead from '@/components/molecules/rgpd/MExtractLead.vue'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import ATextLarge from '@/components/atoms/text/ATextLarge.vue'

import { getModule } from 'vuex-module-decorators'
import LeadModule from '@/store/lead'

@Component({
  components: {
    MAnonymizePanel,
    ATitleMedium,
    ATextLarge,
    MExtractLead
  }
})
export default class OActionsRgpd extends Vue {
  public leadModule = getModule(LeadModule, this.$store)

  public anonymizeLead = {
    email: '',
    sendEmail: true
  }

  public extractLead = {
    email: ''
  }

  public urlExtract = '/leads/extract/data'

  public async anonymizeOneLead() {
    await this.leadModule.anonymiseOneLeadbyEmail(this.anonymizeLead)
    this.anonymizeLead.email = ''
  }

  public async extractOneLead() {
    const link = document.createElement('a')
    const url =
      process.env.VUE_APP_API_BASE_URL +
      `/leads/extract/data?email=${this.extractLead.email}`
    link.setAttribute('href', url)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    this.extractLead.email = ''
  }
}
