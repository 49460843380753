
import { Component, Vue } from 'vue-property-decorator'
//import { getModule } from 'vuex-module-decorators'
import { BasicOperatorEnum, ProductDefaultCondition } from '@/store/product'
import {
  AreaDefaultCondition,
  ConditionToken,
  FormatedConditionToken,
  TokenTypeEnum
} from '@/store/area'
import {
  CreateConditionTokenDto,
  CreateOrderConditionDto,
  FormatedOrderCondition,
  OrderCondition
} from '@/store/order'
//Store
import { getModule } from 'vuex-module-decorators'
import OrderStepModule from '@/store/orderStep'
@Component
export default class MixinOrder extends Vue {
  public orderStepModule = getModule(OrderStepModule, this.$store)
  //Product
  /**
   * Parsing
   * Todo add text with i18n
   * @example (this.$t('order.name') as string)
   */
  parseOperator(value: string): string {
    switch (value) {
      case BasicOperatorEnum.EQUAL:
        return 'Égale à'
      case BasicOperatorEnum.NOT_EQUAL:
        return "n'est pas égale à"
      case BasicOperatorEnum.MORE:
        return 'plus'
      case BasicOperatorEnum.LESS:
        return 'Moins de'
      case BasicOperatorEnum.LESS_EQUAL:
        return 'Moins égal'
      case BasicOperatorEnum.MORE_EQUAL:
        return 'Plus ou égales à'
      case BasicOperatorEnum.OLDER_THAN:
        return 'Plus âgé que'
      case BasicOperatorEnum.IS_NOT_IN:
        return 'Pas dans la catégorie'
      case BasicOperatorEnum.NOT_OLDER_THAN:
        return 'Pas plus âgé que'
    }
    return value
  }
  parseTypes(value: string): string {
    switch (value) {
      case TokenTypeEnum.IF:
        return 'Si'
      case TokenTypeEnum.THEN:
        return 'Alors'
      case TokenTypeEnum.AND:
        return 'Et'
      case TokenTypeEnum.OR:
        return 'Ou'
      case TokenTypeEnum.COMPARAISON:
        return 'Comparaison: '
    }
    return value
  }
  /**
   * @function formatDefaultCondition
   * @param defaultConditions @type { ProductDefaultCondition[] | AreaDefaultCondition[] | OrderCondition[]}
   * @param areaId @type  { number }
   * @return @type { FormatedOrderCondition[] }
   */
  formatDefaultCondition(
    defaultConditions:
      | ProductDefaultCondition[]
      | AreaDefaultCondition[]
      | OrderCondition[],
    areaId: number
  ): FormatedOrderCondition[] {
    const formatedConditions: FormatedOrderCondition[] = []
    if (defaultConditions) {
      for (let i = 0; i < defaultConditions.length; i++) {
        const tokens = defaultConditions[i].conditionTokens.map(
          (token: ConditionToken): FormatedConditionToken => {
            if (token?.areaKey) {
              const formatedToken = {
                ...token,
                areaId,
                areaKeyId: token.areaKey.id
              }
              delete formatedToken.areaKey
              return formatedToken
            }
            return token as FormatedConditionToken
          }
        )
        if (tokens && tokens.length > 0) {
          if (tokens[0].type === TokenTypeEnum.IF) {
            if (
              tokens.findIndex(
                (subtoken) => subtoken.type === TokenTypeEnum.THEN
              ) > 0
            ) {
              formatedConditions.push({
                isIf: tokens[0].type === TokenTypeEnum.IF,
                ifConditionChain: tokens.slice(
                  1,
                  tokens.findIndex(
                    (subtoken) => subtoken.type === TokenTypeEnum.THEN
                  )
                ),
                comparaisonChain: tokens.slice(
                  tokens.findIndex(
                    (subtoken) => subtoken.type === TokenTypeEnum.THEN
                  ) + 1
                )
              })
            }
          } else
            formatedConditions.push({
              isIf: false,
              ifConditionChain: [],
              comparaisonChain: tokens
            })
        }
      }
    }
    return formatedConditions
  }

  private buildConditionTokenDto(
    token: FormatedConditionToken
  ): CreateConditionTokenDto {
    const { type, areaKeyId, operator, values } = token
    if (type === TokenTypeEnum.COMPARAISON)
      return {
        type,
        areaKeyId,
        operator,
        values: values!.map((value: unknown) => String(value)) as string[]
      }
    return { type: type }
  }
  //for send data at API
  buildConditionsDto(
    formatedConditions: FormatedOrderCondition[]
  ): CreateOrderConditionDto[] {
    return formatedConditions.map(
      (formatedCondition: FormatedOrderCondition) => {
        const conditionTokens: CreateConditionTokenDto[] = []
        if (formatedCondition.isIf)
          conditionTokens.push({ type: TokenTypeEnum.IF })
        conditionTokens.push(
          ...formatedCondition.ifConditionChain.map(this.buildConditionTokenDto)
        )
        if (formatedCondition.isIf)
          conditionTokens.push({ type: TokenTypeEnum.THEN })
        conditionTokens.push(
          ...formatedCondition.comparaisonChain.map(this.buildConditionTokenDto)
        )
        return { conditionTokens }
      }
    )
  }
  public formatProductCondition(
    defaultConditions: any
  ): CreateOrderConditionDto[] {
    let productDefaultConditions: CreateOrderConditionDto[] = []

    for (const defaultCondition of defaultConditions) {
      productDefaultConditions.push({
        conditionTokens: defaultCondition.conditionTokens.map(
          (conditionTokens: ConditionToken) => {
            return {
              type: conditionTokens.type,
              operator: conditionTokens.operator,
              areaKeyId: conditionTokens.areaKey?.id,
              values: conditionTokens.values
            }
          }
        )
      })
    }
    return productDefaultConditions
  }
}
