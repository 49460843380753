













import { VChip } from 'vuetify/lib'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class AChip extends VChip {
  @Prop() color!: string
  @Prop() textColor?: string
  @Prop() closeIcon?: boolean
}
