

























































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import { DataTableHeader } from 'vuetify'
import OrderModule, { Order } from '@/store/order'
import { TableActionsProps } from '@/types'
import ClientModule, { ClientOrder } from '@/store/client'
import { getModule } from 'vuex-module-decorators'
import ACheckboxSwitchOrderStatus from '@/components/atoms/checkbox/ACheckboxSwitchOrderStatus.vue'
import AIconExclu from '@/components/atoms/icons/AIconExclu.vue'
import AIconMutu from '@/components/atoms/icons/AIconMutu.vue'
import MixinTables from '@/components/molecules/table/MixinTables.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import MBaseModal, {
  ModalProps
} from '@/components/molecules/modal/MBaseModal.vue'
import MSummaryOrder from '@/components/molecules/order/MSummaryOrder.vue'
//Store
import CsvModule from '@/store/csv'
import MModal from '@/components/molecules/modal/MModal.vue'
import AiconNumberOne from '@/components/atoms/icons/AiconNumberOne.vue'
import AiconNumberTwo from '@/components/atoms/icons/AiconNumberTwo.vue'
import AiconNumberThree from '@/components/atoms/icons/AiconNumberThree.vue'
import AiconNumberFour from '@/components/atoms/icons/AiconNumberFour.vue'
@Component({
  components: {
    MTableHeading,
    MTableFooter,
    MTableActions,
    MTable,
    MBaseModal,
    MSummaryOrder,
    ACheckboxSwitchOrderStatus,
    AIconExclu,
    AIconMutu,
    AButtonPrimary,
    AiconNumberOne,
    AiconNumberTwo,
    AiconNumberThree,
    AiconNumberFour
  }
})
export default class OAdminClientTableOrders extends Mixins(MixinTables) {
  @Prop() clientId!: number

  public clientModule = getModule(ClientModule, this.$store)
  public orderModule = getModule(OrderModule, this.$store)
  public csvModule = getModule(CsvModule, this.$store)
  public selectedOrder: ClientOrder | null = null
  public currentClient!: ClientOrder
  public itemSummaryOrder: Order | null = null

  public get modalDuplicateOrder(): ModalProps {
    return {
      title: this.$tc('order.actions.duplicate', 2),
      actionText: this.$tc('order.actions.duplicate', 1),
      modalAction: this.duplicateOrder
    }
  }
  //properties modal summary order
  public get modalsummaryOrder(): ModalProps {
    return {
      title: 'Résumé de commande',
      actionText: 'Modifiez la commande',
      modalAction: this.summaryOrder,
      maxWidth: '800px'
    }
  }

  get duplicateOrderModal() {
    return this.$refs.duplicateOrder as MBaseModal
  }

  public headers: DataTableHeader<Order>[] = [
    { text: this.$tc('orderItems.isActive'), value: 'state', sortable: false },
    { text: this.$tc('orderItems.orderName'), value: 'name', sortable: false },
    { text: this.$tc('orderItems.area'), value: 'area', sortable: false },
    {
      text: this.$tc('orderItems.shareLimit'),
      value: 'customValueIcon',
      sortable: false
    },
    { text: this.$tc('orderItems.capping'), value: 'capping', sortable: false },
    {
      text: this.$tc('orderItems.cappingMax'),
      value: 'cappingMax',
      sortable: false
    },
    { text: this.$tc('orderItems.price'), value: 'price', sortable: false },
    { text: 'Durée', value: 'customValue', sortable: false },
    {
      text: this.$tc('orderItems.connector'),
      value: 'connectorName',
      sortable: false
    },
    {
      text: this.$tc('orderItems.createAt'),
      value: 'createdAt',
      sortable: false
    },
    { text: this.$tc('orderItems.actions'), value: 'actions', sortable: false }
  ]

  get tableActions(): TableActionsProps[] {
    return [
      {
        icon: '$view',
        action: this.read,
        tooltip: this.$t('order.actions.show') as string
      },
      {
        icon: '$edit',
        action: this.edit,
        tooltip: this.$t('order.actions.edit') as string
      },
      {
        icon: '$duplicate',
        action: this.openModalDuplicateOrder,
        tooltip: this.$tc('order.actions.duplicate', 1) as string
      },
      {
        icon: '$summaryOrder',
        action: this.openModalSummaryOrder,
        tooltip: this.$tc('order.summaryOrder.title')
      }
      // {
      //   icon: '$download',
      //   action: this.downloadCSV,
      //   tooltip: this.$t('order.actions.share') as string
      // }
    ]
  }

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  async request() {
    this.loading = true
    await Promise.all([
      this.clientModule.fetchAllOrders(this.clientId),
      //check is util
      this.clientModule.fetchAll()
    ])
    this.loading = false
  }

  openModalDuplicateOrder(item: ClientOrder) {
    this.selectedOrder = item
    this.duplicateOrderModal.dialog = true
  }

  duration(item: Order): string {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const filters = this.$options.filters as { [key: string]: Function }

    if (!item.endAt) return ' '

    const dayRemaining = filters.diff(new Date(), item.endAt)

    const word = dayRemaining > 1 ? 'j. restants' : 'j. restant' // todo: i18n ?

    const totalDuration = filters.diff(item.createdAt, item.endAt)

    return `${dayRemaining} ${word} / ${totalDuration}`
  }

  orderType(item: Order) {
    switch (item.shareLimit) {
      case 1:
        return 'AiconNumberOne'
      case 2:
        return 'AiconNumberTwo'
      case 3:
        return 'AiconNumberThree'
      case 4:
        return 'AiconNumberFour'
    }
  }

  public read(item: ClientOrder) {
    this.$router.push({
      path: '/leads',
      query: {
        client: this.$route.params.clientId,
        area: item.clientsAreas.area.id.toString(),
        order: item.orderId
      }
    })
  }

  public edit(item: ClientOrder) {
    this.$router.push({
      path: `/clients/${this.clientId}/commandes/${item.orderId}/edition`
    })
  }
  //Todo feature for fetch lead from orderId
  public downloadCSV(item: ClientOrder) {
    this.modal.dialog = true
    this.currentClient = item
  }
  get modal() {
    return this.$refs.modal as MBaseModal
  }

  public get modalProps(): ModalProps {
    return {
      title: this.$tc('client.actions.export'),
      actionText: this.$tc('client.actions.export'),
      modalAction: this.submit
    }
  }

  public async submit() {
    const clientId = parseInt(this.$route.params.clientId)
    await this.csvModule.downloadClientLeads({
      clientId: clientId
    })
    this.modal.dialog = false
  }

  public newOrder() {
    this.$router.push({
      path: `/clients/${this.clientId}/commandes/creation`
    })
  }

  public async duplicateOrder() {
    const newOrderPersisted = await this.orderModule.duplicateOrder(
      this.selectedOrder!
    )
    this.clientModule.saveDuplicateOrder(newOrderPersisted)
    this.request()
    this.duplicateOrderModal.dialog = false
  }

  public async updateOrderStatus(order: ClientOrder) {
    await this.orderModule.updateOrderStatus(order)
  }
  //Summary order
  public get summaryOrderModal() {
    return this.$refs.summaryOrder as MModal
  }
  public async openModalSummaryOrder(item: ClientOrder) {
    //request for fetch order object
    await this.orderModule.fetchOneOrder(item.orderId)
    //inject data (order)
    this.itemSummaryOrder = this.orderModule.order
    //active modal
    this.summaryOrderModal.dialog = true
  }
  //button inside modal
  public summaryOrder() {
    //redirect for update order
    this.$router.push({
      path: `/clients/${this.clientId}/commandes/${
        this.itemSummaryOrder!.id
      }/edition`
    })
    //close modal
    this.summaryOrderModal.dialog = false
    //clean object
    this.itemSummaryOrder = null
  }
}
