























import { Component, Vue } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonLight from '@/components/atoms/button/AButtonLight.vue'

@Component({
  components: {
    ATitleMedium,
    AButtonPrimary,
    AButtonLight
  }
})
export default class MFormDeleteAdmin extends Vue {
  public dialog = false
  public name: string | null = null
}
