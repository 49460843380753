
































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import Multiselect from 'vue-multiselect'
import { Zip, ZipByRegion } from '@/store/zip'

@Component({
  components: {
    Multiselect,
    ValidationProvider
  }
})
export default class AMultiselectZip extends Vue {
  @PropSync('zips') zipsSynced!: Zip[]
  @Prop() formattedZips!: ZipByRegion[]

  customLabel(zip: Zip) {
    return `${zip.code} - ${zip.name}`
  }
}
