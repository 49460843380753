import { render, staticRenderFns } from "./ATextAnchor.vue?vue&type=template&id=19fbfa84&scoped=true&"
import script from "./ATextAnchor.vue?vue&type=script&lang=ts&"
export * from "./ATextAnchor.vue?vue&type=script&lang=ts&"
import style0 from "./ATextAnchor.vue?vue&type=style&index=0&id=19fbfa84&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19fbfa84",
  null
  
)

export default component.exports