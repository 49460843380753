




import { Component, Vue } from 'vue-property-decorator'
import UserModule from '@/store/user'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {
    PAdminStatistics: () =>
      import('@/components/pages/admin/PAdminStatistics.vue'),
    PProviderStatistics: () =>
      import('@/components/pages/provider/PProviderStatistics.vue')
  }
})
export default class RStatistics extends Vue {
  public userModule = getModule(UserModule, this.$store)
  public component: string | null = null

  created() {
    this.componentFromRole()
  }

  private componentFromRole() {
    if (this.userModule.isAdmin) {
      this.component = 'PAdminStatistics'
    }

    if (this.userModule.isProvider) {
      this.component = 'PProviderStatistics'
    }
  }
}
