import Vue from 'vue'
import { Action, Mutation, VuexModule, Module } from 'vuex-module-decorators'
import { NestApiResponse } from '@/types'

interface LeadRejectedState {
  leadRejected: LeadRejected
  leadRejectedCollection: LeadsRejectedResponse
}

export interface LeadPayload {
  firstName: string
  lastName: string
  phone: string
  email: string
  sitekey: string
  link: string
  area: string
  zip: string
  keys: Record<string, any>
}

export interface LeadRejected {
  email: string
  phone: string
  id: number
  createdAt: Date
  message: string
  type: string
  leadPayload?: LeadPayload
  errorPayload?: string
  isExported?: boolean
}

export type LeadsRejectedResponse = NestApiResponse<LeadRejected>

@Module({ namespaced: true, name: 'leadRejected' })
export default class LeadRejectedModule
  extends VuexModule
  implements LeadRejectedState
{
  leadRejected: LeadRejected = {
    email: '',
    phone: '',
    id: 0,
    createdAt: new Date(),
    message: '',
    type: '',
    leadPayload: undefined,
    errorPayload: '',
    isExported: undefined
  }
  leadRejectedCollection: LeadsRejectedResponse = {
    items: [],
    meta: {}
  }

  @Mutation
  saveOne(leadsRejected: LeadRejected) {
    this.leadRejected = { ...this.leadRejected, ...leadsRejected }
  }
  @Mutation
  clearLeadsRejectedDrawer() {
    this.leadRejected = {
      email: '',
      phone: '',
      id: 0,
      createdAt: new Date(),
      message: '',
      type: '',
      leadPayload: undefined,
      errorPayload: '',
      isExported: undefined
    }
  }

  @Mutation
  saveAll(leadRejectedCollection: LeadsRejectedResponse) {
    this.leadRejectedCollection = {
      ...this.leadRejectedCollection,
      ...leadRejectedCollection
    }
  }

  @Action({ commit: 'saveOne' })
  async fetchOne(id: number): Promise<LeadRejected> {
    const { data } = await Vue.prototype.$http.get(`/leads-rejected/${id}`)
    return data
  }

  @Action({ commit: 'saveAll' })
  async fetchAll(): Promise<LeadsRejectedResponse> {
    const { data } = await Vue.prototype.$http.get('/leads-rejected', {
      useQueryParams: true
    })
    return data
  }

  @Action
  async update({
    id,
    updateLeadRejectedDto
  }: {
    id: number
    updateLeadRejectedDto: { isReposted: boolean }
  }): Promise<LeadRejected> {
    const { data } = await Vue.prototype.$http.put(
      `/leads-rejected/${id}`,
      updateLeadRejectedDto
    )
    return data
  }
}
