






























import { Component, Vue } from 'vue-property-decorator'
import TTableWithFiltersAndAction from '@/components/templates/TTableWithFiltersAndAction.vue'
import OTableClientOrdersFilters from '@/components/organisms/client/OTableClientOrdersFilters.vue'
import OTableClientOrders, {
  ClientOrdersProps
} from '@/components/organisms/client/OTableClientOrders.vue'
import MDivAction from '@/components/molecules/MDivAction.vue'
import { getModule } from 'vuex-module-decorators'
import UserModule from '@/store/user'
import ClientModule from '@/store/client'
import NotificationModule from '@/store/notification'

@Component({
  components: {
    TTableWithFiltersAndAction,
    OTableClientOrdersFilters,
    OTableClientOrders,
    MDivAction
  }
})
export default class PClientOrders extends Vue {
  public userModule = getModule(UserModule, this.$store)
  public clientModule = getModule(ClientModule, this.$store)
  public notificationModule = getModule(NotificationModule, this.$store)

  get clientOrdersProps(): ClientOrdersProps {
    return {
      title: this.$tc('order.asClient')
    }
  }

  get clientId(): number {
    return +this.userModule.currentUser!.clientId!
  }
}
