import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { AuthenticationState } from './authentication'
import { Lead } from './lead'
import { SnackBarState } from './notification'
import { Client, Provider } from './statistic'

/**
 * Automatically imports all the modules and exports as a single module object
 */
const requireModule = require.context('.', false, /.ts$/)
const modules = {}

requireModule.keys().forEach((filename) => {
  const moduleName = filename
    .replace('.ts', '')
    .replace('./', '')
    .replace(/-./g, (x) => x.toUpperCase()[1])

  if (!moduleName || moduleName === 'index') return
  ;(modules as any)[moduleName] =
    requireModule(filename).default || requireModule(filename)[moduleName]
})

Vue.use(Vuex)

const vuexLocal = new VuexPersistence<RootState>({
  key: 'joinventure',
  storage: window.localStorage,
  reducer: (state) => ({
    authentication: {
      loggedIn: state.authentication.loggedIn,
      loggedAs: state.authentication.loggedAs,
      fromRoute: state.authentication.fromRoute
    },
    notification: {
      notifications: state.notification.notifications.filter(
        (notification) => notification.persist
      )
    }
  })
})

interface RootState {
  notification: SnackBarState
  authentication: AuthenticationState
  provider: Provider
  client: Client
  lead: Lead
  zip: any
}

const store = new Vuex.Store<RootState>({
  mutations: {},
  actions: {
    logout({ commit }) {
      // resets state of all the modules
      Object.keys(modules).forEach((moduleName) => {
        commit(`${moduleName}/EMPTY_STATE`)
      })
    }
  },
  modules,
  plugins: [vuexLocal.plugin]
})

// Create Vuex Store and register database through Vuex ORM.
export default store
