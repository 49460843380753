



























































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import { DataTableHeader } from 'vuetify'
import OrderModule, { Order } from '@/store/order'
import { TableActionsProps } from '@/types'
import ClientModule, { ClientOrder } from '@/store/client'
import { getModule } from 'vuex-module-decorators'
import ACheckboxSwitchOrderStatus from '@/components/atoms/checkbox/ACheckboxSwitchOrderStatus.vue'
import AIconExclu from '@/components/atoms/icons/AIconExclu.vue'
import AIconMutu from '@/components/atoms/icons/AIconMutu.vue'
import MixinTables from '@/components/molecules/table/MixinTables.vue'
//Molecules
import MSummaryOrder from '@/components/molecules/order/MSummaryOrder.vue'
//Modal
import MBaseModal, {
  ModalProps
} from '@/components/molecules/modal/MBaseModal.vue'
import CsvModule from '@/store/csv'
import UserModule from '@/store/user'
import MModal from '@/components/molecules/modal/MModal.vue'
import AiconNumberOne from '@/components/atoms/icons/AiconNumberOne.vue'
import AiconNumberTwo from '@/components/atoms/icons/AiconNumberTwo.vue'
import AiconNumberThree from '@/components/atoms/icons/AiconNumberThree.vue'
import AiconNumberFour from '@/components/atoms/icons/AiconNumberFour.vue'

export interface ClientOrdersProps {
  title: string
  onlyNewOrders?: boolean
}

@Component({
  components: {
    MTableHeading,
    MTableFooter,
    MTableActions,
    MTable,
    ACheckboxSwitchOrderStatus,
    AIconExclu,
    AIconMutu,
    MBaseModal,
    MSummaryOrder,
    AiconNumberOne,
    AiconNumberTwo,
    AiconNumberThree,
    AiconNumberFour
  }
})
export default class OTableClientOrders extends Mixins(MixinTables) {
  @Prop() clientOrdersProps!: ClientOrdersProps
  @Prop() clientId!: number

  public clientModule = getModule(ClientModule, this.$store)
  public userModule = getModule(UserModule, this.$store)
  public orderModule = getModule(OrderModule, this.$store)
  public csvModule = getModule(CsvModule, this.$store)
  public currentOrder: ClientOrder | null = null

  public headers: DataTableHeader<Order>[] = [
    { text: 'Actif', value: 'isActive', sortable: false },
    { text: 'Nom', value: 'name', sortable: false },
    { text: 'Thème', value: 'area', sortable: false },
    { text: 'Type', value: 'customValueIcon', sortable: false },
    { text: 'Limite / jr.', value: 'capping', sortable: false },
    { text: 'Limite globale', value: 'cappingMax', sortable: false },
    { text: 'Prix unitaire', value: 'price', sortable: false },
    { text: 'Durée', value: 'customValue', sortable: false },
    { text: 'Date de création', value: 'createdAt', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false }
  ]

  public itemSummaryOrder: Order | null = null

  get tableActions(): TableActionsProps[] {
    return [
      //Display filter in lateral sidebar, not create for this moment
      // {
      //   icon: '$filter',
      //   action: this.filter,
      //   tooltip: this.$t('order.action.filter') as string
      // },
      {
        icon: '$download',
        action: this.downloadCSV,
        tooltip: this.$t('order.actions.export') as string
      },
      {
        icon: '$summaryOrder',
        action: this.openModalSummaryOrder,
        tooltip: this.$tc('order.summaryOrder.title')
      }
    ]
  }

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  public async request() {
    this.loading = true
    if (this.clientOrdersProps.onlyNewOrders) {
      await this.clientModule.fetchAllActiveOrders(this.clientId)
    } else {
      await this.clientModule.fetchAllOrders(this.clientId)
    }
    this.loading = false
  }

  duration(item: Order): string {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const filters = this.$options.filters as { [key: string]: Function }

    if (!item.endAt) return 'Illimité'

    const dayRemaining = filters.diff(new Date(), item.endAt)

    const word = dayRemaining > 1 ? 'j. restants' : 'j. restant' // todo: i18n ?

    const totalDuration = filters.diff(item.createdAt, item.endAt)

    return `${dayRemaining} ${word} / ${totalDuration}`
  }

  orderType(item: Order) {
    switch (item.shareLimit) {
      case 1:
        return 'AiconNumberOne'
      case 2:
        return 'AiconNumberTwo'
      case 3:
        return 'AiconNumberThree'
      case 4:
        return 'AiconNumberFour'
    }
  }

  public edit(item: Order) {
    this.$router.push({ path: `/commandes/${item.id}/edition` })
  }

  public async updateOrderStatus(order: ClientOrder) {
    await this.orderModule.updateOrderStatus(order)
  }

  public async downloadCSV(item: ClientOrder) {
    this.modal.dialog = true
    this.currentOrder = item
  }
  convertString(element: string) {
    return parseInt(element)
  }
  get modal() {
    return this.$refs.modal as MBaseModal
  }

  public get modalProps(): ModalProps {
    return {
      title: this.$tc('provider.actions.export'),
      actionText: this.$tc('provider.actions.export'),
      modalAction: this.submit
    }
  }

  public async submit() {
    await this.csvModule.downloadClientLeads({
      clientId: this.currentOrder?.clientsAreas?.client?.id as number,
      data: {
        orderId: this.currentOrder?.orderId as string
      }
    })
    this.modal.dialog = false
  }
  //Summary order
  public get summaryOrderModal() {
    return this.$refs.summaryOrder as MModal
  }
  //button inside modal
  public summaryOrder() {
    //redirect for update order
    this.$router.push({
      path: `/clients/${this.clientId}/commandes/${
        this.itemSummaryOrder!.id
      }/edition`
    })
    //close modal
    this.summaryOrderModal.dialog = false
    //clean object
    this.itemSummaryOrder = null
  }
  //properties modal summary order
  public get modalsummaryOrder(): ModalProps {
    return {
      title: 'Résumé de commande',
      actionText: 'Modifiez la commande',
      modalAction: this.summaryOrder,
      maxWidth: '800px'
    }
  }
  public async openModalSummaryOrder(item: ClientOrder) {
    //request for fetch order object
    await this.orderModule.fetchOneOrder(item.orderId)
    //inject data (order)
    this.itemSummaryOrder = this.orderModule.order
    //active modal
    this.summaryOrderModal.dialog = true
  }
}
