import Vue from 'vue'
import { Action, Mutation, VuexModule, Module } from 'vuex-module-decorators'

export interface BillingState {
  billingCompanyInvoices: BillingCompanyInvoicesRequest | null
  invoices: BillingCompanyInvoicesRequest | null
}

export interface BillingCompanyInvoicesRequest {
  items: StripeItem[]
  meta?: Meta
}

export interface AutomaticTax {
  enabled: boolean
  status?: string
}

export interface CustomerAddress {
  city: string
  country: string
  line1: string
  line2: string
  postal_code: string
  state: string
}

export interface CustomerTaxId {
  type: string
  value: string
}

export interface DefaultTaxRate {
  id: string
  object: string
  active: boolean
  country: string
  created: number
  description?: string
  display_name: string
  inclusive: boolean
  jurisdiction?: string
  livemode: boolean
  metadata: Record<string, unknown>
  percentage: number
  state?: string
}

export interface Period {
  end: number
  start: number
}

export interface Price {
  id: string
  object: string
  active: boolean
  billing_scheme: string
  created: number
  currency: string
  livemode: boolean
  lookup_key?: string
  metadata: Record<string, unknown>
  nickname?: string
  product: string
  recurring?: string
  tiers_mode?: string
  transform_quantity?: string | number
  type: string
  unit_amount: number
  unit_amount_decimal: string
}

export interface TaxAmount {
  amount: number
  inclusive: boolean
  tax_rate: string
}

export interface Datum {
  id: string
  object: string
  amount: number
  currency: string
  description: string
  discount_amounts: any[]
  discountable: boolean
  discounts: any[]
  invoice_item: string
  livemode: boolean
  metadata: Record<string, unknown>
  period: Period
  plan?: any
  price: Price
  proration: boolean
  quantity: number
  subscription?: any
  tax_amounts: TaxAmount[]
  tax_rates: any[]
  type: string
}

export interface Lines {
  object: string
  data: Datum[]
  has_more: boolean
  total_count: number
  url: string
}

export interface PaymentSettings {
  payment_method_options?: any
  payment_method_types?: any
}

export interface StatusTransitions {
  finalized_at: number
  marked_uncollectible_at?: any
  paid_at: number
  voided_at?: any
}

export interface TotalTaxAmount {
  amount: number
  inclusive: boolean
  tax_rate: string
}

export interface StripeInvoice {
  id: string
  object: string
  account_country: string
  account_name: string
  account_tax_ids?: any
  amount_due: number
  amount_paid: number
  amount_remaining: number
  application_fee_amount?: any
  attempt_count: number
  attempted: boolean
  auto_advance: boolean
  automatic_tax: AutomaticTax
  billing_reason: string
  charge: string
  collection_method: string
  created: number
  currency: string
  custom_fields?: any
  customer: string
  customer_address: CustomerAddress
  customer_email: string
  customer_name: string
  customer_phone: string
  customer_shipping?: any
  customer_tax_exempt: string
  customer_tax_ids: CustomerTaxId[]
  default_payment_method?: any
  default_source?: any
  default_tax_rates: DefaultTaxRate[]
  description?: any
  discount?: any
  discounts: any[]
  due_date?: any
  ending_balance: number
  footer?: any
  hosted_invoice_url: string
  invoice_pdf: string
  last_finalization_error?: any
  lines: Lines
  livemode: boolean
  metadata: Record<string, unknown>
  next_payment_attempt?: any
  number: string
  on_behalf_of?: any
  paid: boolean
  payment_intent: string
  payment_settings: PaymentSettings
  period_end: number
  period_start: number
  post_payment_credit_notes_amount: number
  pre_payment_credit_notes_amount: number
  receipt_number?: any
  starting_balance: number
  statement_descriptor?: any
  status: string
  status_transitions: StatusTransitions
  subscription?: any
  subtotal: number
  tax: number
  total: number
  total_discount_amounts: any[]
  total_tax_amounts: TotalTaxAmount[]
  transfer_data?: any
  webhooks_delivered_at: number
}

export interface StripeItem {
  id: number
  type: string
  status: string
  date: string
  number: string
  orders: string[]
  amount: number
  stripeInvoice: StripeInvoice
}

export interface Meta {
  totalItems: number
  itemCount: number
  itemsPerPage: number
  totalPages: number
  currentPage: number
}

@Module({ namespaced: true, name: 'billing' })
export default class BillingModule extends VuexModule implements BillingState {
  billingCompanyInvoices: BillingCompanyInvoicesRequest | null = {
    items: []
  }
  invoices: BillingCompanyInvoicesRequest | null = {
    items: []
  }

  @Mutation
  saveBillingCompanyInvoices(
    billingCompanyInvoices: BillingCompanyInvoicesRequest
  ) {
    this.billingCompanyInvoices = billingCompanyInvoices
  }

  @Mutation
  saveAllInvoices(invoices: BillingCompanyInvoicesRequest) {
    this.invoices = invoices
  }

  @Action({ commit: 'saveBillingCompanyInvoices' })
  async fetchBillingompanyInvoices(
    companyId: number
  ): Promise<BillingCompanyInvoicesRequest> {
    const { data } = await Vue.prototype.$http.get(
      `/billing/${companyId}/invoices`,
      {
        useQueryParams: true
      }
    )
    return data
  }

  @Action({ commit: 'saveAllInvoices' })
  async fetchAllInvoices(): Promise<BillingCompanyInvoicesRequest> {
    const { data } = await Vue.prototype.$http.get('/billing/invoices', {
      useQueryParams: true
    })
    return data
  }

  @Action
  async downloadInvoice(stripeItem: StripeItem): Promise<void> {
    const { data } = await Vue.prototype.$http.post(
      `/billing/invoice/${stripeItem.id}/pdf`,
      {},
      {
        responseType: 'blob',
        errorHandler: {
          message: 'Un problème est survenu'
        }
      }
    )

    const blob = new Blob([data], { type: 'application/pdf' })

    const blobURL = window.URL.createObjectURL(blob)

    const tempLink = document.createElement('a')

    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', `Leads.fr-Facture-${stripeItem.date}.pdf`)

    document.body.appendChild(tempLink)

    tempLink.click()

    document.body.removeChild(tempLink)
    setTimeout(() => {
      window.URL.revokeObjectURL(blobURL)
    }, 100)
  }
}
