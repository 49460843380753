





































import { Component, Prop, Vue } from 'vue-property-decorator'
import ASideBarItem from '@/components/atoms/ASideBarItem.vue'
interface AvatarSidebar {
  title: string
  route: string
  icon: string
}
@Component({
  components: {
    ASideBarItem
  }
})
export default class ASelectAvatar extends Vue {
  @Prop() user!: string
  @Prop() items!: AvatarSidebar[]
  public offset = true
}
