
















































































import { Component, PropSync, Vue } from 'vue-property-decorator'

// Atoms
import AInputTextFieldPassword from '@/components/atoms/input/AInputTextFieldPassword.vue'
import AProgressBar from '@/components/atoms/progressBar/AProgressBar.vue'
import ATextField from '@/components/atoms/text/ATextField.vue'
import ATextSmall from '@/components/atoms/text/ATextSmall.vue'
import ATextMedium from '@/components/atoms/text/ATextMedium.vue'

//Interfaces
import { PasswordSolid } from '@/store/interfaces/components/IPasswordSolid'

@Component({
  components: {
    AInputTextFieldPassword,
    AProgressBar,
    ATextField,
    ATextSmall,
    ATextMedium
  }
})
export default class MInputPasswordSolid extends Vue {
  @PropSync('newPass') pass!: string
  @PropSync('confirmedPass') confirmed!: string
  @PropSync('currentPass') current!: string

  public regex = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?&]).{12,}$/
  )
  public criteriaUpper = {
    regex: new RegExp(/^(?=.*[A-Z]).+$/),
    text: this.$t('user.password.criteria.upper') as string,
    valid: false
  }

  public criteriaDigit = {
    regex: new RegExp(/^(?=.*\d).+$/),
    text: this.$t('user.password.criteria.digit') as string,
    valid: false
  }

  public criteriaSpecial = {
    regex: new RegExp(/^(?=.*[@$!%?&]).+$/),
    text: this.$t('user.password.criteria.special') as string,
    valid: false
  }

  public criteriaLength = {
    regex: new RegExp(/^.{12,}$/),
    text: this.$t('user.password.criteria.length') as string,
    valid: false
  }

  public criteriaArray: PasswordSolid[] = [
    this.criteriaLength,
    this.criteriaUpper,
    this.criteriaDigit,
    this.criteriaSpecial
  ]

  public onProfilPage = this.$route.name === 'Profil' ? true : false
  public onNewUser = this.$route.params.name! === 'newProvider' ? true : false

  public validationDisplay = !this.onProfilPage && !this.onNewUser

  public valueProgress = 0
  public colorProgress = 'red'
  public message = this.$t('user.password.criteria.message.weak') as string

  public regexValidation(regexp: RegExp) {
    return regexp.test(this.pass)
  }

  public progressValidation(pass: string) {
    let cpt = 0
    for (let index = 0; index < this.criteriaArray.length; index++) {
      const element = this.criteriaArray[index]
      element.valid = element.regex.test(pass)
      if (element.valid) {
        cpt += 1
      }
      switch (cpt) {
        case 0:
          this.valueProgress = 0
          this.colorProgress = 'red'
          this.message = this.$t(
            'user.password.criteria.message.weak'
          ) as string
          break
        case 1:
          this.valueProgress = 25
          this.colorProgress = 'red'
          this.message = this.$t(
            'user.password.criteria.message.weak'
          ) as string
          break
        case 2:
          this.valueProgress = 50
          this.colorProgress = 'orange'
          this.message = this.$t(
            'user.password.criteria.message.medium'
          ) as string
          break
        case 3:
          this.valueProgress = 75
          this.colorProgress = 'green'
          this.message = this.$t(
            'user.password.criteria.message.almost'
          ) as string
          break
        case 4:
          this.valueProgress = 100
          this.colorProgress = 'green'
          this.message = this.$t(
            'user.password.criteria.message.strong'
          ) as string
          break
        default:
          break
      }
    }
  }
}
