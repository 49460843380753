










































































import { Component, PropSync, Vue } from 'vue-property-decorator'
import ATitleSmall from '@/components/atoms/title/ATitleSmall.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import { Roles, SignUp } from '@/store/user'
import ABaseSelect from '@/components/atoms/select/ABaseSelect.vue'
import { subject } from '@casl/ability'
//Types
import { Dict } from '@/helpers/types'
@Component({
  components: {
    ATitleSmall,
    AInputTextField,
    ABaseSelect
  }
})
export default class MFormCreateAdminUser extends Vue {
  public passwordConfirm = ''

  @PropSync('userFields') userFieldsSynced!: SignUp

  get roles() {
    const roles = this.$t('roles') as object
    const availableRoles = []

    for (const key in roles) {
      if (
        this.$ability.can(
          'create',
          subject('Roles', { role: (Roles as Dict)[key] })
        )
      ) {
        availableRoles.push({
          value: key,
          text: this.$t(`roles.${key}`)
        })
      }
    }
    return availableRoles
  }
}
