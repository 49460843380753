import { render, staticRenderFns } from "./OAdminProviderTableUsers.vue?vue&type=template&id=146921b6&scoped=true&"
import script from "./OAdminProviderTableUsers.vue?vue&type=script&lang=ts&"
export * from "./OAdminProviderTableUsers.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "146921b6",
  null
  
)

export default component.exports