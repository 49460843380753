





























import { Component, PropSync, Vue } from 'vue-property-decorator'
import AInputRadio from '@/components/atoms/input/AInputRadio.vue'
import ATextMedium from '@/components/atoms/text/ATextMedium.vue'

@Component({
  components: {
    AInputRadio,
    ATextMedium
  }
})
export default class MFormExportCsv extends Vue {
  @PropSync('exportGroup') exportGroupSynced!: number

  /* eslint-disable indent */
  get filtersText() {
    return this.$route.query.startAt
      ? this.$tc('order.date', 1, {
          startAt: this.$options.filters!.timestampToDate(
            this.$route.query.startAt
          ),
          endAt: this.$options.filters!.timestampToDate(this.$route.query.endAt)
        })
      : ''
  }
}
