import { extend, localize } from 'vee-validate'
import veeValidateFr from 'vee-validate/dist/locale/fr.json'
import * as rules from 'vee-validate/dist/rules'
import PhoneNumber from 'awesome-phonenumber'

// Vee Validate
for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation
  })
}

extend('after', {
  params: [{ name: 'target', isTarget: true }],
  message: 'La date de fin doit être supérieure',
  validate: (value, data: any) => {
    return new Date(data.target) < new Date(value)
  }
})

extend('phone', {
  message(fieldName) {
    return `Le champ ${fieldName} n'est pas valide`
  },
  validate(value) {
    return new Promise((resolve) => {
      const phone = new PhoneNumber(value, 'FR')
      resolve({ valid: phone.isValid() })
    })
  }
})

localize('fr', veeValidateFr)
