import { render, staticRenderFns } from "./ATextMedium.vue?vue&type=template&id=44f08bae&scoped=true&"
import script from "./ATextMedium.vue?vue&type=script&lang=ts&"
export * from "./ATextMedium.vue?vue&type=script&lang=ts&"
import style0 from "./ATextMedium.vue?vue&type=style&index=0&id=44f08bae&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44f08bae",
  null
  
)

export default component.exports