


































































































































































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
//Atoms
import ATextField from '@/components/atoms/text/ATextField.vue'
import AExpansionPanels from '@/components/atoms/expansion/AExpansionPanels.vue'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AChip from '@/components/atoms/chips/AChip.vue'
//Mixins
import MixinDates from '@/components/molecules/filter/MixinDates.vue'
@Component({
  components: {
    ATextField,
    AExpansionPanels,
    AChip,
    ATitleMedium,
    MixinDates
  }
})
export default class MSummaryOrder extends Mixins(MixinDates) {
  @Prop() item!: unknown
}
