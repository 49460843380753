




















import { Component, Vue } from 'vue-property-decorator'
import TPanelWithImage from '@/components/templates/TPanelWithImage.vue'
import OLogin from '@/components/organisms/common/OLogin.vue'
import ABaseImage from '@/components/atoms/image/ABaseImage.vue'
import ATitleLogo from '@/components/atoms/title/ATitleLogo.vue'

@Component({
  components: {
    TPanelWithImage,
    OLogin,
    ABaseImage,
    ATitleLogo
  }
})
export default class PLogin extends Vue {}
