import { render, staticRenderFns } from "./MOrderStepCalendarDelivery.vue?vue&type=template&id=5f961832&scoped=true&"
import script from "./MOrderStepCalendarDelivery.vue?vue&type=script&lang=ts&"
export * from "./MOrderStepCalendarDelivery.vue?vue&type=script&lang=ts&"
import style0 from "./MOrderStepCalendarDelivery.vue?vue&type=style&index=0&id=5f961832&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f961832",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VCol,VExpandTransition,VRow,VVirtualScroll})
