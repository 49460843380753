












import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
import ABaseText from '@/components/atoms/text/ABaseText.vue'
@Component({
  components: {
    ABaseText
  }
})
export default class AButtonShowData extends Vue {
  @PropSync('show') showSynced!: boolean
  @Prop() css?: string
  @Prop() textTrue!: string
  @Prop() textFalse!: string
}
