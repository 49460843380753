
import FilterModule, { LeadTrackers } from '@/store/filter'
import LeadModule from '@/store/lead'
import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

@Component
export default class MixinFilters extends Vue {
  public filterModule = getModule(FilterModule, this.$store)
  public leadModule = getModule(LeadModule, this.$store)

  get area(): number | null {
    return this.filterModule.filters.area || null
  }
  set area(area: number | null) {
    this.filterModule.updateFilter({ area, page: 1 })
  }

  get order(): number | null {
    return this.filterModule.filters.order || null
  }
  set order(order: number | null) {
    this.filterModule.updateFilter({ order, page: 1 })
  }

  get provider(): number | null {
    return this.filterModule.filters.provider || null
  }

  set provider(provider: number | null) {
    this.filterModule.updateFilter({ provider, page: 1 })
  }

  get sitekey(): number | null {
    return this.filterModule.filters.sitekey || null
  }

  set sitekey(sitekey: number | null) {
    this.filterModule.updateFilter({ sitekey, page: 1 })
  }

  get isCommented(): boolean | null {
    return this.filterModule.filters.isCommented || null
  }

  set isCommented(isCommented: boolean | null) {
    this.filterModule.updateFilter({ isCommented, page: 1 })
  }

  get isRead(): boolean | null {
    return this.filterModule.filters.isRead || null
  }

  set isRead(isRead: boolean | null) {
    this.filterModule.updateFilter({ isRead, page: 1 })
  }
  get hasPendingInvoices(): boolean | null {
    return this.filterModule.filters.hasPendingInvoices || null
  }

  set hasPendingInvoices(hasPendingInvoices: boolean | null) {
    this.filterModule.updateFilter({ hasPendingInvoices, page: 1 })
  }

  //////////////////////////////////////////////////////
  // WAITING DATA FROM BACK //

  // get zip(): number | null {
  //   return null
  // }

  // set zip(zip: number | null) {
  //   zip = null
  // }

  //////////////////////////////////////////////////////

  get client(): number | null {
    return this.filterModule.filters.client || null
  }

  set client(client: number | null) {
    this.filterModule.updateFilter({ client, page: 1 })
  }

  get tracker() {
    return this.filterModule.filters.tracker || null
  }

  set tracker(tracker: string | null) {
    this.filterModule.updateFilter({
      tracker,
      page: 1
    })
  }

  get trackerValue() {
    return this.filterModule.filters.trackerValue || null
  }

  set trackerValue(trackerValue: string | null) {
    this.filterModule.updateFilter({
      trackerValue,
      page: 1
    })
  }

  public preloadFilters() {
    if (this.filterModule.filters.startAt || this.filterModule.filters.endAt) {
      return
    }

    const period = this.filterModule.predefinedPeriod.find(
      (period) => period.value === 'thisMonth'
    )

    this.filterModule.initFilter({
      startAt: period?.startAt,
      endAt: period?.endAt
    })
  }

  // TODO: Each filters need to have his own file

  async getCustomTrackers() {
    if (
      Object.keys(this.filterModule.filtersRequest.customLeadTrackers!)
        .length === 0
    ) {
      await this.filterModule.fetchFilterLeadTrackers({ custom: true })
    }
  }

  resetTrackerValue() {
    if (this.filterModule.filters.trackerValue)
      this.filterModule.updateFilter({ trackerValue: null })
  }

  async selectTracker(tracker: string) {
    this.resetTrackerValue()
    this.filterModule.updateFilter({ tracker: tracker })
    await this.filterModule.fetchFilterLeadTrackers({ tracker: tracker })
  }

  get getTrackerValues() {
    const tracker = this.filterModule.filters.tracker
    if ((tracker as string) in this.filterModule.filtersRequest.leadTrackers!) {
      return this.filterModule.filtersRequest.leadTrackers![`${tracker}`]
    }
    return this.filterModule.filtersRequest.customLeadTrackers![`${tracker}`]
  }
  /**
   * @function reset
   * @return { void }
   * @description
   * clear fitler table and area selected
   */
  public reset() {
    this.filterModule.reset()
    /**
     * @description
     * For exports leads when you're Super or Admin
     * @file Manage lead.ts
     */
    this.leadModule.areaFilterSelected(null)
  }
}
