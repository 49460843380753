



































import { Component, Prop, Vue } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import ATextMedium from '@/components/atoms/text/ATextMedium.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonLight from '@/components/atoms/button/AButtonLight.vue'

export interface MModalProps {
  title?: string
  actionText?: string
  redirect?: string
  width?: string
  modalActionSubmit?: () => void
  modalActionCancel?: () => void
  peristent?: boolean
}

@Component({
  components: {
    ATitleMedium,
    AButtonPrimary,
    AButtonLight,
    ATextMedium
  }
})
export default class MModal extends Vue {
  @Prop() modalProps!: MModalProps
  public dialog = false
  destroyed() {
    this.modalProps.actionText = ''
  }
}
