
import FilterModule from '@/store/filter'
import NotificationModule from '@/store/notification'
import { LeadStatus } from '@/store/lead'
import { Component } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import MixinDates from '../filter/MixinDates.vue'

type Sort = {
  sortBy: string[]
  sortDesc: boolean[]
}

export const DEFAULT_ITEMS_PER_PAGE = 50

@Component
export default class MixinTables extends MixinDates {
  public loading = true
  public filterModule = getModule(FilterModule, this.$store)
  public notificationModule = getModule(NotificationModule, this.$store)

  customFilterList = ['invoice-period']

  sort({ sortBy, sortDesc }: Sort): void {
    let sortFilter = ''

    if (sortBy.length == 0 && sortDesc.length == 0) {
      this.filterModule.updateFilter({ sort: '' })
    } else {
      sortFilter = this.$options.filters!.camelCaseToKebabCase(sortBy[0])
      sortFilter = this.transformFilters(sortFilter)
      sortDesc[0] ? (sortFilter += ' desc') : (sortFilter += ' asc')
      this.filterModule.updateFilter({
        sort: sortFilter
      })
    }
  }

  transformFilters(customSortFilter: string): string {
    if (this.customFilterList.includes(customSortFilter)) return 'start-date'
    return customSortFilter
  }
  //fetch inside i18n for return enum
  returnStatus(enumStatus: LeadStatus) {
    return this.$t(`lead.status.${enumStatus}`)
  }
  /**
   * @function exportLead
   * @param exportData @type { header: string[], data: any[], areaName: string}
   * @decription
   * exporte lead and put on container .csv with name of area and date of day
   */
  public exportLead(exportData: {
    header: string[]
    data: any[]
    areaName: string
  }) {
    let csvData = ''
    for (const row of exportData.data) {
      //create line
      let line = ''
      //loop on the array
      for (const col in row) {
        //add , on the end line
        if (col !== 'isSelectable') {
          if (line != '') line += ';'
          // add format to the value for ignoring comma if there are several names
          let rowFormated: string = row[col] === null ? '' : row[col] + ''
          if (col === 'ca_Net' || col === 'ca_Brut') {
            const splited = rowFormated.split('.')
            line += splited.join(',')
          } else {
            line += rowFormated
          }
        }
        //add value on line
      }
      //return on the new line
      csvData += line + '\r\n'
    }
    //formatting header
    const headerFromatted = exportData.header.join(';')
    // add header and value for display data
    const csvFile = `${headerFromatted}\n${csvData}`
    // create a blob file in utf8
    const myBlob = new Blob([csvFile], { type: 'text/csv;charset=utf-8' })

    //Create a faker element
    const link = document.createElement('a')
    const url = URL.createObjectURL(myBlob)
    //add attribute for element html (DOM)
    link.setAttribute('href', url)
    link.setAttribute(
      'download',
      `${exportData.areaName}-${this.formatDate(new Date())}.csv`
    )
    // Don't display element
    link.style.visibility = 'hidden'
    //add element in the DOM
    document.body.appendChild(link)
    //Simulate event
    link.click()
    //Delete element in the DOM
    document.body.removeChild(link)
    // add a notification when is finish
    this.notificationModule.add({
      color: 'green',
      message: this.$t('notification.export.success') as string,
      show: true
    })
  }
}
