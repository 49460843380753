













































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
//Atoms
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
//Molecules
import MFormProfilIdentity, {
  ProfilIdentity
} from '@/components/molecules/form/MFormProfilIdentity.vue'
import MFormProfilCredentials, {
  ProfilCredentials
} from '@/components/molecules/form/MFormProfilCredentials.vue'
import { ValidationObserver } from 'vee-validate'
import MFormProfilBilling, {
  ProfilBilling
} from '@/components/molecules/form/MFormProfilBilling.vue'

import MFormProfilPayment, {
  ProfilPayment
} from '@/components/molecules/form/MFormProfilPayment.vue'
import MKeyApi from '@/components/molecules/keyAPI/MKeyApi.vue'
//Store
import { getModule } from 'vuex-module-decorators'
import ClientModule from '@/store/client'

//Template
import TCardFluid from '@/components/templates/TCardFluid.vue'
import { CurrentUser } from '@/store/user'

export interface ProfilProperties {
  profilIdentity: ProfilIdentity
  profilCredentials?: ProfilCredentials
  profilBilling?: ProfilBilling
  adminView?: boolean
  profilPayment?: ProfilPayment
}

export interface IsProfilDirty {
  isProfilIdentityDirty: boolean
  isProfilBillingDirty: boolean
  isProfilCredentialsDirty: boolean
  [key: string]: boolean | undefined
}

type formObserverRef = {
  [key: string]: InstanceType<typeof ValidationObserver>
}

@Component({
  components: {
    ATitleMedium,
    AButtonPrimary,
    MFormProfilIdentity,
    MFormProfilCredentials,
    MFormProfilBilling,
    ValidationObserver,
    MFormProfilPayment,
    MKeyApi,
    TCardFluid
  }
})
export default class MFormProfil extends Vue {
  public clientModule = getModule(ClientModule, this.$store)

  @PropSync('profilProperties') profilPropertiesSynced!: ProfilProperties
  @Prop() user!: CurrentUser
  @Prop() apiKey?: string

  isProfilDirty: IsProfilDirty = {
    isProfilIdentityDirty: false,
    isProfilBillingDirty: false,
    isProfilCredentialsDirty: false
  }

  submit() {
    // if an other form is needed :
    //      : add property in interface : eg. isProfil<Name>Dirty
    //      : add ref string name in observers constant : eg. observerProfil<Name>

    const observers = [
      'observerProfilIdentity',
      'observerProfilBilling',
      'observerProfilCredentials'
    ]

    const formsRefs = this.getFormsRef(observers)

    this.updateIsProfilDirty(formsRefs)

    this.$emit('submit', this.isProfilDirty)

    this.resetForm(formsRefs, observers)
  }

  getFormsRef(str: string[]): formObserverRef[] {
    const arr: formObserverRef[] = []

    for (let i = 0; i < str.length; i++) {
      const e = str[i]
      const obj: formObserverRef = {}
      const ref = this.$refs[e] as InstanceType<typeof ValidationObserver>
      if (ref) {
        obj[e] = ref
        arr.push(obj)
      }
    }

    return arr
  }

  updateIsProfilDirty(allRefs: formObserverRef[]): void {
    for (const profilDirtykey in this.isProfilDirty) {
      for (let i = 0; i < allRefs.length; i++) {
        const ref = allRefs[i]
        for (const refKey in ref) {
          const refName = refKey.split(/(?=[A-Z])/)[2]
          const profilDirtyName = profilDirtykey.split(/(?=[A-Z])/)[2]
          if (refName === profilDirtyName) {
            this.isProfilDirty[profilDirtykey] = ref[refKey].flags.dirty
          }
        }
      }
    }
  }

  resetForm(allRefs: formObserverRef[], str: string[]): void {
    for (let i = 0; i < allRefs.length; i++) {
      const ref = allRefs[i]
      for (let i = 0; i < str.length; i++) {
        const element = str[i]
        if (ref[element]) ref[element].reset()
      }
    }
  }
}
