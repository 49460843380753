import Vue from 'vue'

import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { FiltersMeta, FiltersRequest } from './filter'

export interface StatisticState {
  providers: ProviderRequest

  adminDashboard: DashboardModel | null
  // Todo: warning useless states
  providerDashboard: DashboardModel | null
  clientDashboard: ClientDashboard | null
  // End Todo
  areas: AreaRequest
  sitekeys: SiteKeyRequest
  dates: any
  clients: ClientRequest
  globals: any
}

export interface AreaRequest {
  filters?: FiltersRequest
  items: Area[]
  meta?: FiltersMeta
}

export interface SiteKeyRequest {
  filters?: FiltersRequest
  items: SiteKey[]
  meta?: FiltersMeta
}

export interface ProviderRequest {
  filters?: FiltersRequest
  items: Provider[]
  meta?: FiltersMeta
}

export interface ClientRequest {
  filters?: FiltersRequest
  items: Client[]
  meta?: FiltersMeta
}

// export interface Dashboard {
//   leads: number
//   leadsPool: number
//   leadsSold: number
//   sales: string
//   spent: string
//   margin: string
//   areas: {
//     items: DashboardAreas[]
//   }
//   sitekeys: {
//     items: DashboardSitekeys[]
//   }
//   stats: DashboardStats
// }
//NEW
export interface AdminDashboardLinearChart {
  dates: string[]

  leads: number[]
  leadsSold: number[]

  sales: number[]
  netSales: number[]

  margin: number[]
  netMargin: number[]
}
export interface PieChartItem {
  id: number
  name: string
  y: number
}
export interface DashboardModel {
  leads: number
  leadsSold: number

  sales: number
  netSales: number

  spent: number

  margin: number
  netMargin: number

  linearChart: AdminDashboardLinearChart
  areasChart: PieChartItem[]
  sitekeysChart: PieChartItem[]
}

//END NEW
export interface DashboardStats {
  leads: number[]
  margin: number[]
  dates: Date[]
  leadsSold: number[]
  sales: number[]
}

export interface DashboardSitekeys {
  id: number
  name: string
  y: number
}

export interface DashboardAreas {
  id: number
  name: string
  y: number
}
/**
 * @instance AdminStatisticsTable
 * @description Only for tables statistics
 */
export interface AdminStatisticsTable {
  name: string
  leads: number
  leadsSold: number
  sales: number
  salesPerLead: number
  netSales: number
  netSalesPerLead: number
  margin: number
  marginPerLead: number
  netMargin: number
  netMarginPerLead: number
}

export interface Client {
  id: number
  createdAt: Date
  leads: number
  leadsContested: number
  leadsOutOfTarget: number
  leadsSold: number
  margin: number
  name: string
  netLeads: number
  netMargin: number
  netSales: string
  orders: number
  sales: string
  spent: string
  pendingInvoices: number
}

export interface SiteKey {
  id: number
  name: string
  createdAt: Date
  leads: number
  netLeads: number
  leadsSold: number
  leadsContested: number
  leadsTargeted: number
  sales: number
  conversionname: string | null
  conversiontype: string | null
  customerid: string | null
}
//Todo check that
export interface Area {
  id: number
  name: string
  createdAt: Date
  leadsSold: number
  margin: number
  netMargin: number
  leads: number
  netLeads: number
  leadsContested: number
  leadsOutOfTarget: number
  sales: string
  netSales: string
  spent: string
}

export interface Provider {
  id: number
  name: string
  createdAt: Date
  leadsSold: number
  margin: number
  netMargin: number
  leads: number
  netLeads: number
  leadsContested: number
  leadsOutOfTarget: number
  sales: string
  netSales: string
  spent: string
}
export interface ClientDashboard {
  orders: number
  leadsBought: number
  spent: number
}

@Module({ namespaced: true, name: 'statistic' })
export default class StatisticModule
  extends VuexModule
  implements StatisticState
{
  adminDashboard: DashboardModel | null = null
  providerDashboard: DashboardModel | null = null
  clientDashboard: ClientDashboard | null = null
  providers: ProviderRequest = {
    filters: {
      areas: [],
      clients: [],
      sitekeys: [],
      providers: [],
      status: [],
      orders: []
    },
    items: []
  }
  sitekeys: SiteKeyRequest = {
    filters: {
      areas: [],
      clients: [],
      sitekeys: [],
      providers: [],
      status: [],
      orders: []
    },
    items: []
  }
  areas: AreaRequest = {
    filters: {
      areas: [],
      clients: [],
      sitekeys: [],
      providers: [],
      status: [],
      orders: []
    },
    items: []
  }
  dates: any = []
  clients: ClientRequest = {
    filters: {
      areas: [],
      clients: [],
      sitekeys: [],
      providers: [],
      status: [],
      orders: []
    },
    items: []
  }
  globals: any = {}

  @Mutation
  saveAllProviders(providers: ProviderRequest) {
    this.providers = providers
  }

  @Mutation
  saveAllAreas(areas: AreaRequest) {
    this.areas = areas
  }

  @Mutation
  saveAllSitekeys(sitekeys: SiteKeyRequest) {
    this.sitekeys = sitekeys
  }

  @Mutation
  saveAdminDashboard(adminDashboard: DashboardModel) {
    this.adminDashboard = adminDashboard
  }

  @Mutation
  saveProviderDashboard(providerDashboard: DashboardModel) {
    this.providerDashboard = providerDashboard
  }

  @Mutation
  saveClientDashboard(clientDashboard: ClientDashboard) {
    this.clientDashboard = clientDashboard
  }

  @Mutation
  saveAllDates(dates: any) {
    this.dates = dates
  }

  @Mutation
  saveAllClients(clients: ClientRequest) {
    this.clients = clients
  }

  @Action({ commit: 'saveAdminDashboard' })
  async fetchAdminDashboard(): Promise<DashboardModel> {
    const { data } = await Vue.prototype.$http.get('/statistics/dashboard', {
      useQueryParams: true
    })
    return data
  }

  @Action({ commit: 'saveProviderDashboard' })
  async fetchProviderDashboard(providerId: string) {
    const { data } = await Vue.prototype.$http.get(
      `/statistics/providers/dashboard/${providerId}`,
      {
        useQueryParams: true
      }
    )
    return data
  }

  @Action({ commit: 'saveClientDashboard' })
  async fetchClientDashboard(clientId: number): Promise<ClientDashboard> {
    const { data } = await Vue.prototype.$http.get(
      `/statistics/clients/dashboard/${clientId}`,
      {
        useQueryParams: true
      }
    )
    return data
  }
  //TODO limit=50 is tempory, we waiting filter par date
  @Action({ commit: 'saveAllProviders' })
  async fetchAllProviders() {
    const { data } = await Vue.prototype.$http.get('/statistics/providers', {
      useQueryParams: true
    })
    return data
  }

  @Action({ commit: 'saveAllAreas' })
  async fetchAllAreas() {
    const { data } = await Vue.prototype.$http.get(
      '/statistics/areas?limit=50',
      {
        useQueryParams: true
      }
    )
    return data
  }

  @Action({ commit: 'saveAllSitekeys' })
  async fetchAllSitekeys() {
    const { data } = await Vue.prototype.$http.get('/statistics/sitekeys', {
      useQueryParams: true
    })
    return data
  }

  @Action({ commit: 'saveAllDates' })
  async fetchAllDates() {
    const { data } = await Vue.prototype.$http.get('/statistics/dates', {
      useQueryParams: true
    })
    return data
  }

  @Action({ commit: 'saveAllClients' })
  async fetchAllClients(): Promise<Client[]> {
    const { data } = await Vue.prototype.$http.get('/statistics/clients', {
      useQueryParams: true
    })
    return data
  }
}
