










































































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
//Atoms
import ABaseTitle from '@/components/atoms/title/ABaseTitle.vue'
import ACardPrice from '@/components/atoms/card/ACardPrice.vue'
import ABaseSlider from '@/components/atoms/slider/ABaseSlider.vue'

//Store
import { getModule } from 'vuex-module-decorators'
import { Area, TypePrice, TypePriceShareLimit } from '@/store/area'
import { Order } from '@/store/order'
import OrderStepModule from '@/store/orderStep'

@Component({
  components: {
    ABaseTitle,
    ACardPrice,
    ABaseSlider
  }
})
export default class MOrderStepTwo extends Vue {
  @Prop() area!: Area
  @Prop() order!: Order
  @PropSync('shareLimit') shareLimitSynced!: number
  @Prop() isUpdate!: boolean
  public price = 0
  /**
   * @state typePriceValue
   * we call state typePriceValue from store for communicate with other Molécule
   */
  public orderStepModule = getModule(OrderStepModule, this.$store)
  //Todo call i18n for text edit
  get typePrice(): TypePrice[] {
    return [
      {
        title: 'Exclusif',
        icon: 'mdi-numeric-1-box-outline',
        price: this.area.defaultPrice,
        shareLimit: TypePriceShareLimit.EXCLU
      },
      {
        title: 'Mutualisé * 2',
        icon: 'mdi-numeric-2-box-outline',
        price: this.area.defaultSharePrice,
        shareLimit: TypePriceShareLimit.MUTU2
      },
      {
        title: 'Mutualisé * 3',
        icon: 'mdi-numeric-3-box-outline',
        price: this.area.defaultSharePrice,
        shareLimit: TypePriceShareLimit.MUTU3
      },
      {
        title: 'Mutualisé * 4',
        icon: 'mdi-numeric-4-box-outline',
        price: this.area.defaultSharePrice,
        shareLimit: TypePriceShareLimit.MUTU4
      }
    ]
  }
}
