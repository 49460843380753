

































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
//Atoms
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonSecondary from '@/components/atoms/button/AButtonSecondary.vue'
@Component({
  components: {
    AButtonPrimary,
    AButtonSecondary
  }
})
export default class ADatePicker extends Vue {
  @Prop() label!: string
  @PropSync('value') valueSynced!: Array<string>
  public menu: boolean | null = null
}
