




import { Component, Prop, Vue } from 'vue-property-decorator'
import { AIcon } from '@/store/interfaces/components/IIcon'
@Component
export default class AIconValidation extends Vue {
  @Prop() icon!: AIcon
}
