
























































import { Component, PropSync, Vue } from 'vue-property-decorator'
import ATitleSmall from '@/components/atoms/title/ATitleSmall.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import MInputPasswordSolid from '@/components/molecules/input/MInputPasswordSolid.vue'
import { SignUp } from '@/store/user'

@Component({
  components: {
    ATitleSmall,
    AInputTextField,
    MInputPasswordSolid
  }
})
export default class MFormSignUp extends Vue {
  public passwordConfirm = ''

  @PropSync('signUpFields') signUpFieldsSynced!: SignUp
}
