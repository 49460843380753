
















import { Component, PropSync, Vue } from 'vue-property-decorator'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'

@Component({
  components: {
    AInputTextField
  }
})
export default class MFormClientWeight extends Vue {
  @PropSync('weight') weightSynced!: number
}
