



























import { Component, Vue } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import MFormDeleteAdmin from '@/components/molecules/form/MFormDeleteAdmin.vue'
import { ValidationObserver } from 'vee-validate'
import UserModule from '@/store/user'
import { getModule } from 'vuex-module-decorators'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonLight from '@/components/atoms/button/AButtonLight.vue'

@Component({
  components: {
    ATitleMedium,
    MFormDeleteAdmin,
    ValidationObserver,
    AButtonPrimary,
    AButtonLight
  }
})
export default class OAdminUserDelete extends Vue {
  private userModule = getModule(UserModule, this.$store)

  public async submit() {
    await this.userModule.delete(this.$route.params.id)
    this.$router.push({ path: '/utilisateurs' })
  }
}
