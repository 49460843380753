var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.lead)?_c('div',{staticClass:"lead-transaction"},[_c('ATitleMedium',{staticClass:"mb-4 primary-text--text",attrs:{"text":_vm.$tc('lead.actions.wsLogs', 2)}}),(_vm.lead.wsLogs.checkApi && _vm.lead.wsLogs.checkApi.length !== 0)?_c('ATitleSmall',{staticClass:"my-3 grey--text text--darken-3 font-weight-bold",attrs:{"text":_vm.$t('lead.actions.check_api')}}):_vm._e(),_vm._l((_vm.lead.wsLogs.checkApi),function(log){return _c('div',{key:log.id,staticClass:"mx-4 my-2"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"d-flex justify-space-around",attrs:{"cols":"6"}},[_c('ATextMedium',{staticClass:"grey--text text--darken-3 font-weight-bold",attrs:{"text":log.clientName}}),(log.status === 'OK')?_c('ATextSmall',{staticClass:"status status__ok",attrs:{"text":log.status}}):_c('ATextSmall',{staticClass:"status status__ko",attrs:{"text":log.status}})],1),_c('v-col',{staticClass:"align-center text-right",attrs:{"cols":"6.5"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 primary white--text rounded-lg pa-1",attrs:{"size":"20"},on:{"click":function($event){return _vm.$emit('show', log)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}],null,true)},[_c('span',[_vm._v("Ouvrir les logs")])])],1)],1)],1)}),(_vm.lead.wsLogs.sendLead && _vm.lead.wsLogs.sendLead.length !== 0)?_c('ATitleSmall',{staticClass:"my-3 grey--text text--darken-3 font-weight-bold",attrs:{"text":_vm.$t('lead.actions.send_lead')}}):_vm._e(),_vm._l((_vm.lead.wsLogs.sendLead),function(log){return _c('div',{key:log.id,staticClass:"mx-4"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"d-flex justify-space-around",attrs:{"cols":"6"}},[_c('ATextMedium',{staticClass:"grey--text text--darken-3 font-weight-bold",attrs:{"text":log.clientName}}),(log.status === 'OK')?_c('ATextSmall',{staticClass:"status status__ok",attrs:{"text":log.status}}):_c('ATextSmall',{staticClass:"status status__ko",attrs:{"text":log.status}})],1),_c('v-col',{staticClass:"align-center text-right",attrs:{"cols":"6.5"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 primary white--text rounded-circle pa-1",attrs:{"size":"20"},on:{"click":function($event){return _vm.$emit('forceSendLead', log)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-refresh ")])]}}],null,true)},[_c('span',[_vm._v("Renvoyer le lead")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 primary white--text rounded-lg pa-1",attrs:{"size":"20"},on:{"click":function($event){return _vm.$emit('show', log)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}],null,true)},[_c('span',[_vm._v("Ouvrir les logs")])])],1)],1)],1)}),(_vm.lead.wsLogs.mailing && _vm.lead.wsLogs.mailing.length !== 0)?_c('ATitleSmall',{staticClass:"my-3 grey--text text--darken-3 font-weight-bold",attrs:{"text":_vm.$t('lead.actions.mailing')}}):_vm._e(),_vm._l((_vm.lead.wsLogs.mailing),function(log){return _c('div',{key:log.id,staticClass:"mx-4",on:{"click":function($event){$event.preventDefault();return _vm.$emit('show', log)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('ATextMedium',{staticClass:"grey--text text--darken-3 font-weight-bold",attrs:{"text":log.clientName}})],1),_c('v-col',{attrs:{"cols":"4"}},[(log.status === 'OK')?_c('ATextSmall',{staticClass:"status status__ok",attrs:{"text":log.status}}):_c('ATextSmall',{staticClass:"status status__ko",attrs:{"text":log.status}})],1)],1)],1)}),(
      _vm.lead.wsLogs.checkApi.length === 0 &&
      _vm.lead.wsLogs.sendLead.length === 0 &&
      _vm.lead.wsLogs.mailing.length === 0
    )?_c('div',[_c('ATextMedium',{staticClass:"grey--text text--darken-4",attrs:{"text":_vm.$t('lead.actions.no_wsLogs')}})],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }