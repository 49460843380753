import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { i18n } from '@/plugins/i18n'
import { Zip } from '@/store/zip'
import { ClientOrder, ClientsAreas } from '@/store/client'
import {
  AreaKey,
  AreakeyDataTypeEnum,
  ConditionToken,
  FormatedConditionToken,
  TokenTypeEnum
} from './area'
import { Webhook } from './webhook'
import { Product } from './product'
import { Lead } from '@/store/lead'
export interface OrderState {
  orders: Order
  // ordersDashboard: OrdersDashboard[]
}
//Todo change invoice after push
export interface OrdersLeads {
  id: number
  status: string
  sellingPrice: number
  isExported: boolean
  lead: Lead
  order: Order
  invoice?: any
}
export interface OrdersZips {
  id: number
  order: Order
  zip: Zip
}
export interface Order {
  id: string
  orderId?: string
  name: string
  price: number
  capping: number
  cappingMax: number
  dailyBudget: number
  shareLimit: number
  startAt: Date
  endAt?: Date
  createdAt?: Date
  updatedAt?: Date
  status: 'LIVE' | 'PAUSED'
  clientsAreas: ClientsAreas
  webhook?: Webhook
  product?: Product
  orderConditions: OrderCondition[]
  zips: OrdersZips[]
  webserviceMailingList: string[]
  connectorName?: string
  dropper?: number
  planning?: number[]
}
export interface CreateOrderDto {
  name: string
  capping: number
  cappingMax: number
  price: number
  startAt: string
  endAt?: string
  dailyBudget: number
  shareLimit: number
  status: 'LIVE' | 'PAUSED'
  clientId: number
  zips: number[]
  areaId: number
  productId: number
  areaConditions: CreateOrderConditionDto[]
  productConditions: CreateOrderConditionDto[]
  customConditions: CreateOrderConditionDto[]
  notificationMails?: string[] | null
  connectorName?: string
  dropper?: number
  planning?: number[]
}

export interface WebServciesSheet {
  id: number
  clientId: number
  areaId: number
  sheetName: string
  sheetId?: string
  status: 'LIVE' | 'WAITING_FOR_ID'
}
export interface CreateOrderConditionDto {
  conditionTokens: CreateConditionTokenDto[]
}
export interface CreateConditionTokenDto {
  type: TokenTypeEnum
  areaKeyId?: number
  operator?: string
  values?: string[]
}

export interface UpdateOrderConditions {
  id: string
  data: OrderCondition[]
}
export interface UpdateOrderDto {
  name: string
  capping: number
  cappingMax: number
  price: number
  startAt: string
  endAt?: string
  dailyBudget: number
  shareLimit: number
  status: 'LIVE' | 'PAUSED'
  zips: number[]
  productId: number
  areaConditions: CreateOrderConditionDto[]
  productConditions: CreateOrderConditionDto[]
  customConditions: CreateOrderConditionDto[]
  notificationMails?: string[] | null
  connectorName?: string
  dropper?: number
  planning?: number[]
}

export interface OrderConditionsResponse {
  operator: string
  value: string
  areaKeyId: number
  order?: Order
  areaKey: AreaKey[]
}
export interface OrderConditionsRequest {
  type?: string[] | null
  areaKey?: AreaKey[] | null
  operator?: number[] | null
  value?: string[] | null
}
export interface OrderCondition {
  id: number
  order: Order
  areaCondition: boolean
  productCondition: boolean
  conditionTokens: ConditionToken[]
}
export interface Operator {
  operator: TokenOperator
  expression: string
  inputDataType: AreakeyDataTypeEnum
}
export interface GetOperatorResponse {
  INT: Operator[]
  NUMBER: Operator[]
  STRING: Operator[]
  BOOL: Operator[]
  ENUM: Operator[]
  DATE: Operator[]
}
export interface OrdersDashboard {
  id: string
  name: string
  capping: number
  cappingMax: number
  endAt: Date
  statut: string
  area: string
  shareLimit: number
  clientName: string
  weekEndExclusion: boolean
  connector: string
  dropper: number
}
export interface orderCompletion {
  area: string
  companyName: string
  orderName: string
  type: number
  capping: number
  cappingMax: number
  nbLeads: number
  planning: number[]
  completionTotal: number
  livedDay: number
  todayObjectif: number
}
export interface otherCompletion {
  lavel: string
  nbLeads: number
  cappingMax: number
  completion: number
}

export enum BasicOperatorEnum {
  // Basic
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  MORE = 'MORE',
  MORE_EQUAL = 'MORE_EQUAL',
  LESS = 'LESS',
  LESS_EQUAL = 'LESS_EQUAL',
  // Date operators
  AGE_LESS = 'AGE_LESS',
  AGE_MORE = 'AGE_MORE'
}

export enum ArrayOperatorEnum {
  // Array (ENUM)
  IS_IN = 'IS_IN',
  IS_NOT_IN = 'IS_NOT_IN'
}

export enum IntervalOperatorEnum {
  // Min/Max
  IN_INTERVAL = 'IN_INTERVAL',
  NOT_IN_INTERVAL = 'NOT_IN_INTERVAL',
  AGE_BETWEEN = 'AGE_BETWEEN'
}
//Type
export type TokenOperator =
  | BasicOperatorEnum
  | ArrayOperatorEnum
  | IntervalOperatorEnum

export type FormatedOrderCondition = {
  isIf: boolean
  ifConditionChain: FormatedConditionToken[]
  comparaisonChain: FormatedConditionToken[]
  id?: number | string
}
//Enum
export enum OrderStatus {
  PAUSED = 'PAUSED',
  LIVE = 'LIVE'
}
@Module({ namespaced: true, name: 'order' })
export default class OrderModule extends VuexModule {
  order: Order | null = null
  operator!: Operator
  operators: GetOperatorResponse = {
    INT: [],
    NUMBER: [],
    STRING: [],
    BOOL: [],
    ENUM: [],
    DATE: []
  }
  ordersDashboard: OrdersDashboard[] = []
  completions: orderCompletion[] = []
  otherCompletions: otherCompletion[] = []

  // Mutations

  @Mutation
  saveOperators(operators: GetOperatorResponse) {
    this.operators = operators
  }
  @Mutation
  saveOrder(order: Order) {
    this.order = order
  }

  @Mutation
  saveAllOrdersDashboard(ordersDashboard: OrdersDashboard[]) {
    this.ordersDashboard = ordersDashboard
  }

  @Mutation
  saveOneOrder(order: Order) {
    this.order = order
  }

  @Mutation
  saveAllCompletions(completions: orderCompletion[]) {
    this.completions = completions
  }

  // Actions

  @Action({ commit: 'saveOneOrder' })
  async fetchOneOrder(id: string): Promise<Order> {
    const { data } = await Vue.prototype.$http.get(`/orders/${id}`)
    return data
  }

  @Action
  async create(orderFields: CreateOrderDto) {
    const { data } = await Vue.prototype.$http.post('/orders', orderFields, {
      successHandler: true
    })

    return data
  }
  @Action
  async update({
    updateOrderDto,
    orderId
  }: {
    updateOrderDto: UpdateOrderDto
    orderId: string
  }) {
    const { data } = await Vue.prototype.$http.put(
      `/orders/${orderId}`,
      updateOrderDto,
      {
        successHandler: {
          message: `Votre commande ${updateOrderDto.name} a bien été mise à jour`
        }
      }
    )

    return data
  }
  /**
   * @deprecated
   * @param orderFields
   */
  @Action
  async updateConditions(orderFields: UpdateOrderConditions) {
    await Vue.prototype.$http.put(`/orders/${orderFields.id}/conditions`, {
      conditions: orderFields.data
    })
  }

  @Action
  async updateOrderStatus(order: ClientOrder) {
    await Vue.prototype.$http.put(
      `/orders/${order.orderId}/status`,
      {
        status: order.isActive
      },
      {
        successHandler: {
          message: `${order.name} est désormais ${i18n.t(
            `status.orders.${order.isActive}`
          )} `
        }
      }
    )
  }

  /**
   * @function duplicateOrder
   * @param clientOrder
   * @returns { Promise Order }
   * @description get client for duplicate a command
   */
  @Action
  async duplicateOrder(clientOrder: ClientOrder): Promise<Order> {
    const { data } = await Vue.prototype.$http.put(
      `/orders/${clientOrder.orderId}/duplicate`,
      {},
      {
        successHandler: {
          message: `La commande: ${clientOrder.name} à bien été dupliquée`
        }
      }
    )
    return data
  }
  @Action({ commit: 'saveOperators' })
  async fetchOperator(): Promise<GetOperatorResponse> {
    const { data } = await Vue.prototype.$http.get('/orders/operators')
    return data
  }

  @Action({ commit: 'saveAllOrdersDashboard' })
  async fetchAllOrdersDashboard() {
    const { data } = await Vue.prototype.$http.get(
      '/orders/dashboard/ordersClients'
    )

    return data
  }

  @Action({ commit: 'saveAllCompletions' })
  async fetchCompletions(category: string) {
    const { data } = await Vue.prototype.$http.get(
      `/orders/dashboard/completion/${category}`
    )
    return data
  }
}
