








import { Component, Vue } from 'vue-property-decorator'
import OWebhookSteps from '@/components/organisms/common/OWebhookSteps.vue'
import TCardWithStepper from '@/components/templates/TCardWithStepper.vue'

@Component({
  components: {
    TCardWithStepper,
    OWebhookSteps
  }
})
export default class PWebhook extends Vue {}
