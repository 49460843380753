










































import { Component, Vue } from 'vue-property-decorator'
import MLeadStepOne from '@/components/molecules/lead/MLeadStepOne.vue'
import MLeadStepTwo from '@/components/molecules/lead/MLeadStepTwo.vue'
import MOrderStepThree from '@/components/molecules/order/MOrderStepThree.vue'
import MOrderStepFour from '@/components/molecules/order/MOrderStepFour.vue'
import MOrderStepFive from '@/components/molecules/order/MOrderStepFive.vue'
import MLeadActions from '@/components/molecules/lead/MLeadActions.vue'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'

import { ValidationObserver } from 'vee-validate'
import { getModule } from 'vuex-module-decorators'
import AreaModule from '@/store/area'

@Component({
  components: {
    MLeadStepOne,
    MLeadStepTwo,
    MOrderStepThree,
    MOrderStepFour,
    MOrderStepFive,
    MLeadActions,
    ATitleMedium,
    ValidationObserver
  }
})
export default class OLeadSteps extends Vue {
  public areaModule = getModule(AreaModule, this.$store)

  // TODO ADD TYPINGS
  lead: any = {
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    theme: 0
  }

  step = 1

  mounted() {
    this.areaModule.fetchAll()
  }

  get title() {
    return `${this.lead.lastname} ${this.lead.firstname} ${
      this.lead.theme || this.$t('lead.actions.new')
    }`
  }

  async validateStep(newStep: number) {
    const ref = this.$refs.observer as InstanceType<typeof ValidationObserver>

    if (!ref) {
      this.step = newStep
    }

    if (ref && (await ref.validate())) {
      this.step = newStep
    }
  }

  public submit() {
    //console.log('New lead', this.lead)
  }
}
