import Vue from 'vue'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Client } from './client'
import { FilterRequest } from './filter'

export interface SitekeyFields {
  name: string
  providerId: number | null
  conversionName?: string | null
  conversionType?: string | null
  customerId?: string | null
}

export interface SitekeyAssocClient {
  clientId: (number | undefined)[] | null
  sitekeyId: number | undefined | null
}

export interface SitekeyClients {
  id: number | null
  client: Client
  sitekey: SitekeyConversion
}

export interface Sitekey {
  createdAt: Date
  id: number
  name: string
  updatedAt: Date
}

export interface SitekeyConversion {
  createdAt: string
  updatedAt: string
  id: number
  name: string
  conversionName: string | null
  conversionType: string | null
  customerId: string | null
}

export interface SitekeyList {
  id: number
  leads: number
  name: string
  createdAt: Date
  conversionName?: string | null
  conversionType?: string | null
  customerId?: string | null
  clients?: string
}

@Module({ namespaced: true, name: 'sitekey' })
export default class SitekeyModule extends VuexModule {
  sitekey: Sitekey | null = null
  sitekeyConversion: SitekeyConversion | null = null
  sitekeyAssoc: SitekeyClients[] = []
  sitekeyExclusion: SitekeyClients[] = []

  @Mutation
  saveOneSitekey(sitekey: Sitekey) {
    this.sitekey = sitekey
  }

  @Mutation
  saveOneSitekeyConversion(sitekeyConverison: SitekeyConversion) {
    this.sitekeyConversion = sitekeyConverison
  }

  @Mutation
  saveSitekeysClient(sitekeyAssoc: SitekeyClients[]) {
    this.sitekeyAssoc = sitekeyAssoc
  }

  @Mutation
  saveExclusionSitekeysClient(sitekeyExclusion: SitekeyClients[]) {
    this.sitekeyExclusion = sitekeyExclusion
  }

  @Action
  async new(sitekeyFields: SitekeyFields) {
    const { data } = await Vue.prototype.$http.post('/sitekeys', sitekeyFields)
    return data
  }

  @Action
  async newAssociation(sitekeyFields: SitekeyAssocClient) {
    const { data } = await Vue.prototype.$http.post(
      '/sitekeys/assocClientSitekey',
      {
        clientId: sitekeyFields.clientId,
        sitekeyId: sitekeyFields.sitekeyId
      }
    )
    return data
  }

  @Action
  async newExclusion(sitekeyFields: SitekeyAssocClient) {
    const { data } = await Vue.prototype.$http.post(
      '/sitekeys/exclusionClientSitekey',
      {
        clientId: sitekeyFields.clientId,
        sitekeyId: sitekeyFields.sitekeyId
      }
    )
    return data
  }

  @Action({ commit: 'saveOneSitekey' })
  async fetchOne(sitekeyId: number): Promise<Sitekey> {
    const { data } = await Vue.prototype.$http.get(`/sitekeys/${sitekeyId}`)
    return data
  }

  @Action({ commit: 'saveOneSitekeyConversion' })
  async fetchOneSitekeyConversion(
    sitekeyName: string
  ): Promise<SitekeyConversion> {
    const { data } = await Vue.prototype.$http.get(
      `/sitekeys/sitekeyName/${sitekeyName}`
    )
    return data
  }

  @Action
  async updateSitekeyConversion(
    sitekey: SitekeyList
  ): Promise<SitekeyConversion> {
    const { data } = await Vue.prototype.$http.put(
      `/sitekeys/${sitekey.name}/conversion`,
      {
        conversionName: sitekey.conversionName,
        conversionType: sitekey.conversionType,
        customerId: sitekey.customerId
      },
      {
        successHandler: {
          message: 'Informations mises à jour avec succès'
        },
        errorHandler: {
          message: 'Vérifier les champs remplis'
        }
      }
    )
    return data
  }

  @Action({ commit: 'saveSitekeysClient' })
  async fetchAssocClientBySitekey(sitekeyId: number): Promise<Sitekey> {
    const { data } = await Vue.prototype.$http.get(
      `/sitekeys/assocClientSitekey/sitekeyId/${sitekeyId}`
    )
    return data
  }

  @Action({ commit: 'saveExclusionSitekeysClient' })
  async fetchAssocExclusionClientBySitekey(
    sitekeyId: number
  ): Promise<Sitekey> {
    const { data } = await Vue.prototype.$http.get(
      `/sitekeys/exclusionClientSitekey/sitekeyId/${sitekeyId}`
    )
    return data
  }
}
