



























import MTable from '@/components/molecules/table/MTable.vue'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import OrderModule, { otherCompletion } from '@/store/order'
import { Component, Vue } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {
    MTableHeading,
    MTableFooter,
    MTable
  }
})
export default class PAdminClientCompletion extends Vue {
  public orderModule = getModule(OrderModule, this.$store)
  public loading = true

  public headers: DataTableHeader<otherCompletion>[] = [
    {
      text: this.$tc('orderItems.area'),
      value: 'label',
      sortable: false,
      width: 250
    },
    {
      text: this.$tc('orderItems.completionTotal'),
      value: 'completion',
      sortable: false,
      width: 250
    },
    {
      text: this.$tc('orderItems.cappingMax'),
      value: 'cappingMax',
      sortable: true,
      width: 100,
      align: 'center'
    },
    {
      text: this.$tc('orderItems.nbLeads'),
      value: 'nbLeads',
      sortable: false,
      width: 100,
      align: 'center'
    }
  ]

  async created() {
    await this.orderModule.fetchCompletions('clients')
    this.loading = false
  }
}
