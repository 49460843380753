





















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class AIconView extends Vue {}
