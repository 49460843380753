



























import { Component, Prop, Vue } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import MDisableTextField from '@/components/molecules/MDisableTextField.vue'
import ADisableLabel from '@/components/atoms/ADisableLabel.vue'
import { Payload, WebhookCategories, WebhookFields } from '@/store/webhook'

@Component({
  components: {
    ATitleMedium,
    MDisableTextField,
    ADisableLabel
  }
})
export default class MWebhookStepFour extends Vue {
  @Prop() webhook!: WebhookFields
  @Prop() category!: WebhookCategories
}
