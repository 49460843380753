





























import { Component, Prop, Vue } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import ATextMedium from '@/components/atoms/text/ATextMedium.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonLight from '@/components/atoms/button/AButtonLight.vue'

export interface ModalProps {
  title: string
  actionText?: string
  redirect?: string
  modalAction?: () => void
  maxWidth?: string
}

@Component({
  components: {
    ATitleMedium,
    AButtonPrimary,
    AButtonLight,
    ATextMedium
  }
})
export default class MBaseModal extends Vue {
  @Prop() modalProps!: ModalProps
  public dialog = false
}
