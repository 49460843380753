






































import { Component, Prop, Vue } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import ATextMedium from '@/components/atoms/text/ATextMedium.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonLight from '@/components/atoms/button/AButtonLight.vue'
import { ValidationObserver } from 'vee-validate'
import { ModalProps } from './MBaseModal.vue'

@Component({
  components: {
    ATitleMedium,
    AButtonPrimary,
    AButtonLight,
    ATextMedium,
    ValidationObserver
  }
})
export default class MModalForm extends Vue {
  @Prop() modalProps!: ModalProps
}
