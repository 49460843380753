
























import { Component, PropSync, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider
  }
})
export default class ABaseSlider extends Vue {
  @PropSync('value') valueSynced!: unknown
}
