



















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class AButtonAndOr extends Vue {
  @Prop() translate!: Array<{ and: string; or: string }>
  @Prop() word!: Array<{ and: string; or: string }>
}
