









































import { ClientOrder } from '@/store/client'
import { OrderStatus } from '@/store/order'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ACheckboxSwitchOrderStatus extends Vue {
  @Prop() order!: ClientOrder
  @Prop() id!: string | number

  public menu = false

  /**
   * @function closeMenu
   * @description Close the menu and restore the initial value of the status
   */
  closeMenu() {
    if (this.order.isActive === this.falseValue) {
      this.order.isActive = this.trueValue
    } else {
      this.order.isActive = this.falseValue
    }
    this.menu = false
  }

  get falseValue() {
    return OrderStatus.PAUSED
  }

  get trueValue() {
    return OrderStatus.LIVE
  }
}
