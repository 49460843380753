













































import { Component, Vue, Prop } from 'vue-property-decorator'
import ABaseSelect from '@/components/atoms/select/ABaseSelect.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
//Template
import TFilterCondition from '@/components/templates/TfilterCondition.vue'
import { Area } from '@/store/area'
@Component({
  components: {
    ABaseSelect,
    AInputTextField,
    TFilterCondition
  }
})
export default class MConditionalFitler extends Vue {
  @Prop() area!: Area
  public inputCondition!: object[]
  public responseAreakeys?: string | number = ''
}
