
























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import ATextMedium from '@/components/atoms/text/ATextMedium.vue'
import MCards from '@/components/molecules/MCards.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'

@Component({
  components: {
    AInputTextField,
    ATextMedium,
    MCards
  }
})
export default class MLeadStepOne extends Vue {
  @PropSync('lastname') lastnameSynced!: string
  @PropSync('firstname') firstnameSynced!: string
  @PropSync('phone') phoneSynced!: string
  @PropSync('email') emailSynced!: string
  @PropSync('theme') themeSynced!: number | null
  @Prop() themes!: { id: string | number; displayName: string }

  public atoms = [
    {
      text: this.lastnameSynced,
      rules: 'required',
      label: 'Nom',
      name: 'nom'
    },
    {
      text: this.firstnameSynced,
      rules: 'required',
      label: 'Prénom',
      name: 'prénom'
    },
    {
      text: this.phoneSynced,
      rules: {
        required: true,
        digits: 10
      },
      label: 'Téléphone',
      name: 'téléphone'
    },
    {
      text: this.emailSynced,
      rules: {
        required: true,
        email: true
      },
      label: 'E-mail',
      name: 'e-mail'
    }
  ]
}
