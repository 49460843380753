













import { Component, Vue } from 'vue-property-decorator'
import { ProfilIdentity } from '@/components/molecules/form/MFormProfilIdentity.vue'
import { ValidationObserver } from 'vee-validate'
import { getModule } from 'vuex-module-decorators'
import ClientModule from '@/store/client'
import UserModule from '@/store/user'
import CompanyModule from '@/store/company'
import MFormProfil, {
  IsProfilDirty,
  ProfilProperties
} from '@/components/molecules/form/MFormProfil.vue'
import AuthenticationModule from '@/store/authentication'

interface FieldsToPost extends ProfilIdentity {
  currentPassword?: string
  password?: string
}

@Component({
  components: {
    ValidationObserver,
    MFormProfil
  }
})
export default class OAdminClientProfil extends Vue {
  private companyModule = getModule(CompanyModule, this.$store)
  public userModule = getModule(UserModule, this.$store)
  private clientModule = getModule(ClientModule, this.$store)
  public authenticationModule = getModule(AuthenticationModule, this.$store)

  profilProperties: ProfilProperties | null = null

  async mounted() {
    const clientId = +this.$route.params.clientId
    await this.clientModule.fetchOneClient(clientId)
    this.buildProfilProperties()
  }

  get profilProps() {
    return this.profilProperties
  }

  buildProfilProperties(): void {
    this.profilProperties = {
      profilIdentity: {
        lastName: this.getCompanyCreator()!.lastName!,
        firstName: this.getCompanyCreator()!.firstName!,
        email: this.getCompanyCreator()!.email!,
        phone: this.getCompanyCreator()!.phone!
      },
      profilBilling: {
        displayName: this.getCompany().displayName || '',
        registrationNumber: this.getCompany().registrationNumber || '',
        vat: this.getCompany().vat || '',
        address: {
          line1: this.getCompany().address?.line1 || '',
          line2: this.getCompany().address?.line2 || '',
          country: this.getCompany().address?.country || '',
          city: this.getCompany().address?.city || '',
          postalCode: this.getCompany().address?.postalCode || '',
          state: this.getCompany().address?.state
        }
      },
      adminView: true
    }
  }

  getCompanyCreator() {
    return this.clientModule.client?.company.users![0]
  }

  getCompany() {
    return this.clientModule.client!.company
  }

  async resetPassword() {
    await this.authenticationModule.resetPassword(
      this.profilProperties!.profilIdentity.email
    )
  }

  async submit(isProfilDirty: IsProfilDirty) {
    const fieldsToPost: FieldsToPost = this.profilProperties!.profilIdentity
    const companyId = this.getCompany().id!

    if (this.profilProperties?.profilCredentials?.current !== '') {
      fieldsToPost.currentPassword =
        this.profilProperties?.profilCredentials?.current
      fieldsToPost.password = this.profilProperties?.profilCredentials?.new
    }

    if (
      isProfilDirty.isProfilIdentityDirty ||
      isProfilDirty.isProfilCredentialsDirty
    ) {
      await this.userModule.edit({
        id: this.getCompanyCreator()!.id!,
        data: fieldsToPost
      })
    }

    if (isProfilDirty.isProfilBillingDirty) {
      await this.companyModule.editCompanyAddress({
        id: companyId,
        companyAdressPayload: this.profilProperties!.profilBilling!
      })
    }
  }
}
