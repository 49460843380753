






































































import {
  Component,
  Prop,
  Mixins,
  Watch,
  PropSync
} from 'vue-property-decorator'
//Atoms
import ABaseTite from '@/components/atoms/title/ABaseTitle.vue'
import AButtonShowData from '@/components/atoms/button/AButtonShowData.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
//Molécules
import MCardCustomCondition from '@/components/molecules/order/stepper/MCardCustomCondition.vue'
//Template
import TFilterProduct from '@/components/templates/TFilterProduct.vue'
//Interfaces
import {
  Area,
  TokenTypeEnum,
  TypePriceResponse,
  TypePriceShareLimit
} from '@/store/area'
import { Product } from '@/store/product'
import { FormatedOrderCondition } from '@/store/order'
//Mixin
import MixinOrder from '@/components/molecules/filter/MixinOrder.vue'
//Store
import { getModule } from 'vuex-module-decorators'
import OrderModule from '@/store/order'
import OrderStepModule from '@/store/orderStep'

@Component({
  components: {
    AButtonShowData,
    ABaseTite,
    AButtonPrimary,
    MCardCustomCondition,
    TFilterProduct
  }
})
export default class MCardProduct extends Mixins(MixinOrder) {
  /**
   * @description area prop return area select at step One
   * @prop {object}
   * @alias Area
   * */
  @Prop() area!: Area
  @Prop() isUpdate?: number
  @PropSync('value') valueSynced!: number | null

  public orderModule = getModule(OrderModule, this.$store)
  public orderStepModule = getModule(OrderStepModule, this.$store)
  //Check how many click you do on product choice
  //use for set price
  public countHowManyClick = 0
  //Step 3
  /**
   * compare for display price of lead
   * @enum {Exclu | Mutu}
   * @type { string }
   * @returns Exclusif | Mutualisé
   */
  public price = {
    EXCLU: TypePriceResponse.EXCLU,
    MUTU: TypePriceResponse.MUTU
  }

  public product: Product | undefined =
    this.area?.defaultProduct || this.area.products.length === 0
      ? this.area?.defaultProduct
      : this.area.products[0]

  public productConditions: FormatedOrderCondition[] = [] // Load data in array on product select

  public showProduct = false
  created() {
    this.initPriceProductCreateOrder()
  }
  mounted() {
    const currentOrder = this.orderModule?.order
    //just for update order
    if (currentOrder?.product) {
      this.product = this.area.products.find(
        ({ id }) => id === currentOrder!.product!.id
      )
    }
    // if (this.orderStepModule.orderDto.shareLimit === 1)
    //   return (this.orderStepModule.orderDto.price = this.product
    //     ?.defaultExcluPrice as number)
    // if (this.orderStepModule.orderDto.shareLimit >= 2)
    //   return (this.orderStepModule.orderDto.price = this.product
    //     ?.defaultMutuPrice as number)
    this.orderModule.fetchOperator()
  }
  //Watcher
  @Watch('product')
  public onChangeProduct() {
    const currentOrder = this.orderModule?.order
    //if product exist
    if (
      currentOrder?.product &&
      this.product!.id === currentOrder.product?.id
    ) {
      //get and return condition
      this.productConditions = this.formatDefaultCondition(
        currentOrder!.orderConditions.filter(
          (condition) => condition.productCondition === true
        ),
        this.area.id
      )
    } else {
      this.productConditions = this.formatDefaultCondition(
        this.product!.defaultConditions,
        this.area.id
      )
    }
    this.changeDefaultPrice()
    /**
     * @var productId
     * @see ./store/orderStepModule
     * @type { number }
     */
    this.orderStepModule.orderDto.productId = this.product!.id
  }
  @Watch('productConditions', { deep: true })
  onChangeProductConditions() {
    this.orderStepModule.orderDto.productConditions = this.buildConditionsDto(
      this.productConditions
    )
  }
  //End Watcher

  /**
   * @function updateResetProduct
   * @description reset productCondition when you click on button reset
   */
  updateResetProduct() {
    if (this.orderModule?.order) {
      const currentOrder = this.orderModule!.order
      if (
        this?.product?.id &&
        currentOrder?.product?.id &&
        currentOrder!.product!.id === this.product!.id
      ) {
        this.orderModule!.order!.product = undefined
      }
      this.onChangeProduct()
    }
  }
  public addOnProduct(product: Product) {
    this.product = product
    //count how many click
    this.countHowManyClick++
  }
  /**
   * @function initPriceProductCreateOrder
   * @returns { number }
   * @description just for created mode. When you arrived in step 3, the app select first product and init price.
   */
  public initPriceProductCreateOrder() {
    if (!this.isUpdate) {
      //Exclu
      if (
        this.orderStepModule.orderDto?.shareLimit === TypePriceShareLimit.EXCLU
      )
        return (this.orderStepModule.orderDto.price =
          this.area?.products[0].defaultExcluPrice)
      //Mutu *2 & *3
      if (
        this.orderStepModule.orderDto?.shareLimit ===
          TypePriceShareLimit.MUTU2 ||
        this.orderStepModule.orderDto?.shareLimit === TypePriceShareLimit.MUTU3
      )
        return (this.orderStepModule.orderDto.price =
          this.area?.products[0].defaultMutuPrice)
      else {
        this.orderStepModule.orderDto.price = 0
      }
    }
  }
  /**
   * @function changeDefaultPrice
   * @description change price of lead in terms of product selected, only for area have product. Add price on orderDto
   * @return { number | undefined }
   */
  public changeDefaultPrice(): number | undefined {
    if (!this.isUpdate) {
      //Exclu
      if (
        this.orderStepModule.orderDto?.shareLimit === TypePriceShareLimit.EXCLU
      )
        return (this.orderStepModule.orderDto.price =
          this.product!.defaultExcluPrice)
      //Mutu *2
      if (
        this.orderStepModule.orderDto?.shareLimit ===
          TypePriceShareLimit.MUTU2 ||
        this.orderStepModule.orderDto?.shareLimit === TypePriceShareLimit.MUTU3
      )
        return (this.orderStepModule.orderDto.price =
          this.product!.defaultMutuPrice)
    } else {
      //If one click you set price at price of creation order
      //else you add default price
      if (this.countHowManyClick >= 1) {
        if (
          this.orderStepModule.orderDto?.shareLimit ===
          TypePriceShareLimit.EXCLU
        )
          return (this.orderStepModule.orderDto.price =
            this.product!.defaultExcluPrice)
        //Mutu *2
        if (
          this.orderStepModule.orderDto?.shareLimit ===
            TypePriceShareLimit.MUTU2 ||
          this.orderStepModule.orderDto?.shareLimit ===
            TypePriceShareLimit.MUTU3
        )
          return (this.orderStepModule.orderDto.price =
            this.product!.defaultMutuPrice)
      }
    }
  }

  public deleteProductCondition(conditionIndex: number) {
    this.productConditions = this.productConditions.filter(
      (condition, index) => index !== conditionIndex
    )
  }
  public deleteProductIfConditionToken(
    conditionIndex: number,
    tokenIndex: number
  ) {
    const previousToken =
      tokenIndex > 0 &&
      ['AND', 'OR'].includes(
        this.productConditions[conditionIndex].ifConditionChain[tokenIndex - 1]
          .type
      )
    this.productConditions[conditionIndex].ifConditionChain =
      this.productConditions[conditionIndex].ifConditionChain.filter(
        (token, index) =>
          index !== tokenIndex && (!previousToken || index !== tokenIndex - 1)
      )
  }
  public deleteProductComparaisonToken(
    conditionIndex: number,
    tokenIndex: number
  ) {
    const previousToken =
      tokenIndex > 0 &&
      ['AND', 'OR'].includes(
        this.productConditions[conditionIndex].comparaisonChain[tokenIndex - 1]
          .type
      )
    this.productConditions[conditionIndex].comparaisonChain =
      this.productConditions[conditionIndex].ifConditionChain.filter(
        (token, index) =>
          index !== tokenIndex && (!previousToken || index !== tokenIndex - 1)
      )
  }

  public addProductIfConditionToken(
    conditionIndex: number,
    logicalToken: TokenTypeEnum
  ) {
    this.productConditions[conditionIndex].ifConditionChain.push(
      { type: logicalToken },
      {
        type: TokenTypeEnum.COMPARAISON,
        areaId: this.area.id,
        areaKeyId: undefined,
        operator: undefined,
        values: [undefined]
      }
    )
  }
  addProductComparaisonToken(
    conditionIndex: number,
    logicalToken: TokenTypeEnum
  ) {
    this.productConditions[conditionIndex].comparaisonChain.push(
      { type: logicalToken },
      {
        type: TokenTypeEnum.COMPARAISON,
        areaId: this.area.id,
        areaKeyId: undefined,
        operator: undefined,
        values: [undefined]
      }
    )
  }
}
