var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-provider-table-users section white"},[_c('MTableHeading',{attrs:{"title":_vm.$tc('user.name', 2)}},[_c('template',{slot:"btn"},[_c('AButtonPrimary',{staticClass:"ml-4 font-weight-bold",attrs:{"text":_vm.$t('user.actions.new')},nativeOn:{"click":function($event){return _vm.newProvider.apply(null, arguments)}}})],1)],2),_c('MTable',{attrs:{"headers":_vm.headers,"items":_vm.providerModule.users.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.name))])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"grey--text text--darken-3"},[_vm._v(_vm._s(item.phone))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"grey--text text--darken-3"},[_vm._v(_vm._s(item.email))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('MTableActions',{attrs:{"itemId":item.id,"tableActions":_vm.tableActions},on:{"iconAction":function($event){return $event(item.id)}}})]}}],null,true)}),_c('MTableFooter',{attrs:{"tableProperties":_vm.providerModule.users.meta}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }