





























import { Component, PropSync, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider
  }
})
export default class AInputTextFieldPassword extends Vue {
  @PropSync('value') valueSynced!: string
  public show = false
}
