import Vue from 'vue'
import { Action, Mutation, VuexModule, Module } from 'vuex-module-decorators'
import store from '@/store'
import { SnackBar } from '@/store/notification'
import { NestApiResponse } from '@/types'
import { Area, AreakeyDataTypeEnum } from './area'
import { Zip } from './zip'
import { Sitekey } from './sitekey'
import { LeadTrackers } from './filter'
import { AxiosError } from 'axios'

//TYPES
export enum LeadStatusEnum {
  NEW = 'NEW',
  READY_FOR_SALE = 'READY_FOR_SALE',
  WAITING_DEMAND = 'WAITING_DEMAND',
  WAITING_FOR_QUALIFICATION = 'WAITING_FOR_QUALIFICATION',
  QUALIFIED = 'QUALIFIED',
  SOLD_MUTU = 'SOLD_MUTU',
  SOLD_EXCLU = 'SOLD_EXCLU',
  UNREAD = 'UNREAD',
  TEST_REJECTED = 'TEST_REJECTED',
  ANONYMISED = 'ANONYMISED',
  WAITING_FOR_ANONYMISE = 'WAITING_FOR_ANONYMISE',
  OUT_OF_TARGET = 'OUT_OF_TARGET',
  OP_REFUSED = 'OP_REFUSED',
  QUEUED = 'QUEUED',
  MATCHING = 'MATCHING'
}
export enum WsLogsTypeEnum {
  CHECK_API = 'check_api',
  SEND_LEAD = 'send_lead',
  MAIL = 'mail'
}
interface LeadState {
  leads: LeadResponse
  leadDetail: LeadDetailModel | null
  transactions: string[]
  lead: Lead | null
  rgpdLeads: any
}
export interface RgpdLeads {
  leadsIds: number[]
}
export type LeadResponse = NestApiResponse<Lead>

export interface CreateLeadDto {
  firstName: string
  lastName: string
  phone: string
  email: string
  zip: string
  link: string
  area: AreakeyDataTypeEnum
  keys: Record<string, unknown>
}

export interface Lead {
  id: number
  firstName: string
  lastName: string
  phone: string
  email: string
  zipCode: string
  refersTo?: number
  status: LeadStatus
  reason?: string
  rating: number
  zip: Zip
  sitekey: Sitekey
  area: Area
  leadsProperties: []
  ordersLeads: []
  leadTrackers: LeadTrackers
  logs: []
  isSelectable?: boolean
}
//NEW
export interface LeadDetailModel {
  createdAt: Date
  firstName: string
  lastName: string
  email: string
  phone: string
  status: LeadStatusEnum
  zipCode: string
  buyPrice: number
  sellPrice: number
  contestedPrice: number
  margin: number
  provider: string
  area: string
  rating: number
  sitekey: string
  orders: string[]
  clients: string[]
  salesDates: Date[]
  properties: LeadPropsProperty[]
  wsLogs: LeadPropsWsLog[]
}
export interface LeadPropsProperty {
  areaKeyId: number
  name: string
  displayName: string
  value: string
  optional: boolean
  computed: string
}

export interface LeadPropsWsLog {
  clientName: string
  status: string
  type: WsLogsTypeEnum
  leadPayload: string
  clientPayload: string
  clientResponse: string
}
//End NEW
export interface WsLogs {
  id: number
  clientName: string
  status: string
  type: string
  leadPayload: string
  s2sPayload: string
  clientPayload: string
  clientResponse: string
  createdAt?: Date
  updatedAt?: Date
}

export interface LeadProperty {
  id: number
  area_key_id: number
  name: string
  displayName: string
  value: string
}

export enum LeadStatus {
  NEW = 'NEW',
  READY_FOR_SALE = 'READY_FOR_SALE',
  WAITING_DEMAND = 'WAITING_DEMAND',
  WAITING_FOR_QUALIFICATION = 'WAITING_FOR_QUALIFICATION',
  QUALIFIED = 'QUALIFIED',
  SOLD_MUTU = 'SOLD_MUTU',
  SOLD_EXCLU = 'SOLD_EXCLU',
  UNREAD = 'UNREAD',
  TEST_REJECTED = 'TEST_REJECTED',
  ANONYMISED = 'ANONYMISED',
  WAITING_FOR_ANONYMISE = 'WAITING_FOR_ANONYMISE',
  OUT_OF_TARGET = 'OUT_OF_TARGET',
  OP_REFUSED = 'OP_REFUSED',
  QUEUED = 'QUEUED',
  MATCHING = 'MATCHING'
}

export interface UpdateLeadStatusDto {
  status: LeadStatus
}

export interface UpdateLeadStatusParam {
  id: number
  dto: UpdateLeadStatusDto
}

export interface UpdateLeadStatusParam {
  id: number
  dto: UpdateLeadStatusDto
}

export interface UpdateLeadsRgpdDto {
  leadsIds: Array<number>
}

export interface AnonymiseOneLead {
  email: string
  sendEmail: boolean
}

const groupByType = (array: WsLogs[], type: string) => {
  return array.filter((log: WsLogs) => log.type === type)
}

@Module({ namespaced: true, name: 'lead' })
export default class LeadModule extends VuexModule implements LeadState {
  leads: LeadResponse = {
    filters: {
      areas: [],
      clients: [],
      sitekeys: [],
      providers: []
    },
    items: []
  }
  leadDetail: LeadDetailModel | null = null
  transactions: string[] = []
  lead: Lead | null = null
  rgpdLeads = {
    items: [],
    meta: []
  }
  /**
   * @var areaSelected
   * @type { number | null } Area id
   * @description
   * Use when selected area filter on leads table
   */
  areaSelected: number | null = null

  extractedData: any = null

  /**
   * @function areaFilterSelected
   * @param area @type { number | null } Area id
   * @description
   * increment areaSelected when to use filter
   * you can clear areaSelected when you click on "reset filter" button
   */
  @Mutation
  areaFilterSelected(area: number | null) {
    this.areaSelected = area
  }

  /**
   * @function clearLead @description clear lead
   */
  @Mutation
  clearLead() {
    this.lead = null
  }
  /**
   * @function saveOne @description add lead value in state lead
   * @param lead @type { Lead }
   */
  @Mutation
  saveOne(lead: Lead) {
    this.lead = { ...this.lead, ...lead }
  }

  @Mutation
  saveLeadDetail(leadDetail: LeadDetailModel) {
    this.leadDetail = leadDetail
  }

  @Mutation
  saveAll(leads: LeadResponse) {
    this.leads = { ...this.leads, ...leads }
  }

  @Mutation
  saveAllRgpdLeads(leads: any) {
    this.rgpdLeads = { ...this.rgpdLeads, ...leads }
  }

  @Mutation
  udpateRgpdLeads(leadsIds: number[]) {
    this.rgpdLeads.items = this.rgpdLeads.items.filter(
      (lead: Lead) => !leadsIds.includes(lead.id)
    )
  }

  @Mutation
  public saveExportLeads(leads: Lead[]) {
    this.leads.items = leads
  }

  @Mutation
  public saveExtractedData(data: any) {
    this.extractedData = data
  }

  @Action({ commit: 'saveOne' })
  async fetchOne(id: number) {
    const { data } = await Vue.prototype.$http.get(`/leads/${id}/props`)
    const wsLogscheck = groupByType(data.wsLogs, 'check_api')
    const wsLogssend = groupByType(data.wsLogs, 'send_lead')
    const wsMailing = groupByType(data.wsLogs, 'mail')
    return {
      ...data,
      wsLogs: {
        checkApi: wsLogscheck,
        sendLead: wsLogssend,
        mailing: wsMailing
      }
    }
  }

  @Action({ commit: 'saveLeadDetail' })
  async fetchLeadDetail(id: number): Promise<LeadDetailModel> {
    const { data } = await Vue.prototype.$http.get(`/leads/${id}/detail`)
    const wsLogscheck = groupByType(data.wsLogs, 'check_api')
    const wsLogssend = groupByType(data.wsLogs, 'send_lead')
    const wsMailing = groupByType(data.wsLogs, 'mail')
    return {
      ...data,
      wsLogs: {
        checkApi: wsLogscheck,
        sendLead: wsLogssend,
        mailing: wsMailing
      }
    }
  }

  @Action({ commit: 'saveAll' })
  async fetchAll(): Promise<LeadResponse> {
    const { data } = await Vue.prototype.$http.get('/leads', {
      useQueryParams: true,
      params: { isAnonymised: false }
    })
    return data
  }

  @Action({ commit: 'saveAllRgpdLeads' })
  async fetchAllRgpdLeads() {
    const { data } = await Vue.prototype.$http.get('/leads', {
      params: { status: LeadStatus.WAITING_FOR_ANONYMISE },
      useQueryParams: true
    })
    return data
  }

  @Action({ commit: 'saveOne' })
  async updateLeadStatus({
    id,
    dto
  }: UpdateLeadStatusParam): Promise<LeadResponse> {
    const { data } = await Vue.prototype.$http.put(`/leads/${id}`, dto, {
      successHandler: {
        message: 'Statut mis à jour avec succès'
      }
    })
    return data
  }

  @Action({ commit: 'udpateRgpdLeads' })
  async anonymiseLeads(updateLeadsRgpdDto: UpdateLeadsRgpdDto) {
    const { data } = await Vue.prototype.$http.put(
      '/leads/rgpd',
      updateLeadsRgpdDto,
      {
        successHandler: {
          message: 'Les leads sélectionnés sont maintenant anonymes'
        }
      }
    )

    return data.raw
  }

  @Action
  async anonymiseOneLeadbyEmail(anonymize: AnonymiseOneLead) {
    const { data } = await Vue.prototype.$http.put(
      '/leads/rgpd',
      { email: anonymize.email, sendEmail: anonymize.sendEmail },
      {
        successHandler: {
          message: `Le propspect ${anonymize.email} est maintenant anonyme`
        }
      }
    )

    return data
  }

  @Action
  async rematchLeadsPool(leadsIds: number[]): Promise<void> {
    const { data } = await Vue.prototype.$http.post('/leads/rematch', {
      leadsIds
    })

    const notification: SnackBar = {
      color: 'primary',
      timeout: 8000,
      message: data.msg,
      show: true
    }

    await store.dispatch('notification/add', notification)
  }

  @Action
  async createLead(lead: CreateLeadDto) {
    const { data } = await Vue.prototype.$http.post('/leads', lead, {
      successHandler: {
        message: 'Le lead a bien été enregistré'
      }
    })

    return data.raw
  }
  /**
   * @function exportLeads
   * @param area @type { string }
   * @returns @type { Promise<Lead[]> }
   */

  @Action({ commit: 'saveExportLeads' })
  async exportLeads(area: string): Promise<Lead[]> {
    try {
      const response = await Vue.prototype.$http.get(`/leads/export/${area}`, {
        useQueryParams: true,
        errorHandler: {
          message:
            'Un export est en cours veuillez patienter quelque instants ...'
        }
      })

      return response.data
    } catch (error: any) {
      return error.response
    }

    return []
  }

  @Action({ commit: 'saveExtractedData' })
  async fetchExtractedData(email: string): Promise<any> {
    const { data } = await Vue.prototype.$http.get(
      '/leads/extract/data',
      {
        useQueryParams: true,
        params: { email: email }
      },
      {
        successHandler: {
          message: `Les données du propspect ${email} sont maintenant extraites`
        }
      }
    )
    return data
  }

  @Action
  async forceSendlead(sendDTO: {
    name: string
    leadId: number
    orderId?: string
  }): Promise<any> {
    const { data } = await Vue.prototype.$http.post(
      '/connectors/sendlead',
      {
        name: sendDTO.name,
        leadId: sendDTO.leadId,
        orderId: null
      },
      {
        successHandler: {
          message: "L'envoie du send lead à été fait"
        }
      }
    )
    return data
  }
}
