
















































import { Component, Vue, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import { DataTableHeader } from 'vuetify'
import { Lead } from '@/store/lead'
import ProviderModule from '@/store/provider'
import UserModule, { CurrentUser } from '@/store/user'
import SitekeyModule from '@/store/sitekey'
import { SitekeyList } from '@/store/sitekey'
import { TableActionsProps } from '@/types'
import MModal, { MModalProps } from '@/components/molecules/modal/MModal.vue'
import MBaseModal from '@/components/molecules/modal/MBaseModal.vue'
import MFormSitekey from '../../molecules/form/MFormSitekey.vue'

export interface LeadTableStatus {
  icon: string
  text: string
}

@Component({
  components: {
    MTableHeading,
    MTableFooter,
    MTableActions,
    MTable,
    MFormSitekey,
    AButtonPrimary,
    MModal
  }
})
export default class OTableProviderSitekeys extends Vue {
  public loading = false
  public providerModule = getModule(ProviderModule, this.$store)
  public userModule = getModule(UserModule, this.$store)
  private sitekeyModule = getModule(SitekeyModule, this.$store)
  public sitekeys: SitekeyList[] = []
  public currentSitekey: SitekeyList | null = null

  public headers: DataTableHeader<Lead>[] = [
    { text: 'Source', value: 'name', sortable: false },
    { text: 'Nom de ma conversion', value: 'conversionName' },
    { text: 'Nom du serveur', value: 'conversionType' },
    { text: 'Mon identifiant', value: 'customerId' },
    { text: 'Nombre de leads associés', value: 'leads', sortable: false }
    // En tant que provider je ne peux pas modifier mon Sitekey (pour l'instant)

    // ---- //
    // { text: 'Actions', value: 'actions', sortable: false }
    // ---- //
  ]

  public get modalEditProps(): MModalProps {
    return {
      title: this.$t('sitekey.update.title') as string,
      actionText: this.$tc('sitekey.update.validate', 1) as string,
      width: '600px',
      modalActionSubmit: this.editSitekey,
      modalActionCancel: this.cancelModalEdit,
      peristent: true
    }
  }

  get tableActions(): TableActionsProps[] {
    return [{ icon: '$edit', action: this.openEditModalSitekey }]
  }

  get modalEdit() {
    return this.$refs.modalEdit as MBaseModal
  }

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  public openEditModalSitekey(item: SitekeyList) {
    this.currentSitekey = item
    this.modalEdit.dialog = true
  }

  public cancelModalEdit() {
    this.currentSitekey = null
    this.modalEdit.dialog = false
    this.request()
  }

  public async editSitekey() {
    await this.sitekeyModule.updateSitekeyConversion(this.currentSitekey!)
    this.request()
    this.modalEdit.dialog = false
  }

  async request() {
    this.loading = true
    await this.providerModule.fetchAllSitekeysByProvider(
      (this.userModule.currentUser as CurrentUser).providerId!.toString()
    )
    this.sitekeys = await Promise.all(
      this.providerModule.sitekeys.items.map(async (sitekey) => {
        return {
          id: sitekey.id,
          name: sitekey.name,
          createdAt: sitekey.createdAt,
          leads: sitekey.leads,
          conversionName: sitekey.conversionname,
          conversionType: sitekey.conversiontype,
          customerId: sitekey.customerid
        }
      })
    )
    this.loading = false
  }

  public newSitekey() {
    this.$router.push({ path: '/sitekeys/creation' })
  }
}
