var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-client-leads"},[_c('MTableHeading',{attrs:{"title":'Mes factures'}}),_c('MTable',{attrs:{"show-select":"","selected":_vm.selected,"headers":_vm.headers,"items":_vm.invoices.items,"loading":_vm.loading},on:{"update:selected":function($event){_vm.selected=$event},"sort":_vm.sort},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('MTableActions',{attrs:{"itemId":item.id,"tableActions":_vm.tableActions},on:{"iconAction":function($event){return $event(item)}}})]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.amount))+" ")]}},{key:"item.orders",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOrders(item.orders))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$tc(("invoice.status." + (item.status))))+" ")]}}],null,true)}),_c('MTableFooter',{attrs:{"tableProperties":_vm.invoices.meta},scopedSlots:_vm._u([{key:"btn",fn:function(){return [_c('AButtonPrimary',{staticClass:"ml-4 font-weight-bold",attrs:{"text":'Télégarger la sélection'},nativeOn:{"click":function($event){return _vm.downloadSelection.apply(null, arguments)}}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }