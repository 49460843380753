




import { Component, Vue } from 'vue-property-decorator'
import OActionsRgpd from '@/components/organisms/admin/OActionsRgpd.vue'

@Component({
  components: {
    OActionsRgpd
  }
})
export default class PActionsRgpd extends Vue {}
