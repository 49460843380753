import Vue from 'vue'
import { AxiosInstance } from 'axios'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Area, ConditionToken } from './area'

export interface Product {
  id: number
  name: string
  area: Area
  displayName: string
  defaultExcluPrice: number
  defaultMutuPrice: number
  defaultConditions: ProductDefaultCondition[]
}
export interface ProductList {
  loc: Product[]
  prop: Product[]
}
export interface ProductDefaultCondition {
  id: number
  product: Product
  conditionTokens: ConditionToken[]
}
export enum BasicOperatorEnum {
  // Basic
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  MORE = 'MORE',
  MORE_EQUAL = 'MORE_EQUAL',
  LESS = 'LESS',
  LESS_EQUAL = 'LESS_EQUAL',
  IS_NOT_IN = 'IS_NOT_IN',
  // Date operators
  OLDER_THAN = 'OLDER_THAN',
  NOT_OLDER_THAN = 'NOT_OLDER_THAN'
}

export enum ArrayOperatorEnum {
  // Array
  IS_IN = 'IS_IN',
  IS_NOT_IN = 'IS_NOT_IN'
}

export enum IntervalOperatorEnum {
  // Min/Max
  IN_INTERVAL = 'IN_INTERVAL',
  NOT_IN_INTERVAL = 'NOT_IN_INTERVAL'
}
@Module({ namespaced: true, name: 'product' })
export default class ProductModule extends VuexModule {
  products!: ProductList
  product!: Product
  @Mutation
  saveAll(products: ProductList) {
    this.products = products
  }
  @Mutation
  saveProductCondition(product: Product) {
    this.product = product
  }
  @Action({ commit: 'saveAll' })
  async fetchAll() {
    const { data } = await Vue.prototype.$http.get('/products/filters')
    return data
  }
  //Todo show interface api for product
  @Action({ commit: 'saveProductCondition' })
  async fetchAllProductCondition() {
    const { data } = await (Vue.prototype.$http as AxiosInstance).get(
      '/products'
    )
    return data
  }
}
