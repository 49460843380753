















import { Component, Vue, PropSync } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'

@Component({
  components: {
    ATitleMedium,
    AInputTextField
  }
})
export default class MFormLeadImmo extends Vue {
  @PropSync('immo') immoSynced!: string
  @PropSync('value-immo') valueSynced!: string
  @PropSync('capital') capitalSynced!: string

  public atoms = [
    {
      text: this.immoSynced,
      rules: {
        required: true,
        numeric: true
      },
      label: 'Crédit immobilier',
      name: 'Crédit immobilier'
    },
    {
      text: this.valueSynced,
      rules: {
        required: true,
        numeric: true
      },
      label: 'Valeur immobilière',
      name: 'Valeur immobilière'
    },
    {
      text: this.capitalSynced,
      rules: {
        required: true,
        numeric: true
      },
      label: 'Capital immobilier',
      name: 'Capital immobilier'
    }
  ]
}
