




import { Component, Vue } from 'vue-property-decorator'
import MLeadDetail from '@/components/molecules/lead/MLeadDetail.vue'
import { getModule } from 'vuex-module-decorators'
import ProviderModule from '@/store/provider'

@Component({
  components: {
    MLeadDetail
  }
})
export default class OProviderLeadDetail extends Vue {
  public providerModule = getModule(ProviderModule, this.$store)

  // eslint-disable-next-line @typescript-eslint/ban-types
  public filters = this.$options.filters as { [key: string]: Function }

  get leadDetail() {
    return this.providerModule.leadDetail
  }

  async mounted() {
    const leadId = +this.$route.params.id
    if (leadId) {
      await this.providerModule.fetchOneLeadDetail(leadId)
    }
  }
}
