import Vue from 'vue'
import VueI18n from 'vue-i18n'
import fr from '../locales/fr.json'
import { LocaleMessageObject, LocaleMessages } from 'vue-i18n'

export enum Locales {
  FR = 'fr'
}

export const LOCALES = [{ value: Locales.FR, caption: 'Français' }]

export const messages: LocaleMessages = {
  [Locales.FR]: fr as LocaleMessageObject
}

export const defaultLocale = Locales.FR

Vue.use(VueI18n)

export const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  silentTranslationWarn: process.env.NODE_ENV === 'production' || true //Todo it's just for delete log
})
