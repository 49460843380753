








import { Component, Vue } from 'vue-property-decorator'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import MFormSignUp from '@/components/molecules/form/MFormSignUp.vue'
import { ValidationObserver } from 'vee-validate'
import UserModule, { SignUp } from '@/store/user'
import { getModule } from 'vuex-module-decorators'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AButtonLight from '@/components/atoms/button/AButtonLight.vue'
import MModalForm from '@/components/molecules/modal/MModalForm.vue'
import { ModalProps } from '@/components/molecules/modal/MBaseModal.vue'

@Component({
  components: {
    ATitleMedium,
    MFormSignUp,
    ValidationObserver,
    AButtonPrimary,
    AButtonLight,
    MModalForm
  }
})
export default class OAdminProviderNew extends Vue {
  private userModule = getModule(UserModule, this.$store)

  public signUpFields: SignUp = {
    companyName: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    role: 'PROVIDER'
  }

  public get modalProps(): ModalProps {
    return {
      title: this.$tc('provider.actions.new'),
      actionText: this.$tc('provider.actions.new'),
      redirect: 'partenaires',
      modalAction: this.submit
    }
  }

  public async submit() {
    await this.userModule.signup(this.signUpFields)
    this.$router.push({ path: '/partenaires' })
  }
}
