










import { Component, Prop, Vue } from 'vue-property-decorator'
import ADisableInput from '@/components/atoms/ADisableInput.vue'
import ADisableLabel from '@/components/atoms/ADisableLabel.vue'

@Component({
  components: {
    ADisableInput,
    ADisableLabel
  }
})
export default class MDisableTextField extends Vue {
  @Prop() label!: string
  @Prop() input!: string
  @Prop() tag!: string
  @Prop() icon!: string
  @Prop() design!: string
}
