








import { Component, Vue } from 'vue-property-decorator'
import TPanelCenter from '@/components/templates/TPanelCenter.vue'
import ORenewPassword from '@/components/organisms/common/ORenewPassword.vue'

@Component({
  components: {
    TPanelCenter,
    ORenewPassword
  }
})
export default class PRenewPassword extends Vue {}
