

























import { Vue, Component } from 'vue-property-decorator'
//Organism
import OSideBar from '@/components/organisms/OSideBar.vue'
//Molécules
import MSnackBar from '@/components/molecules/MSnackBar.vue'
// Atoms
import ATextAnchor from '@/components/atoms/text/ATextAnchor.vue'
import ALoaderLogo from '@/components/atoms/loader/ALoaderLogo.vue'

//Store
import { getModule } from 'vuex-module-decorators'
import UserModule, { CurrentUser } from './store/user'
import { Filters } from '@/store/filter'
import AuthenticationModule from '@/store/authentication'
import LoaderModule from '@/store/loader'
import NotificationModule from './store/notification'

@Component({
  components: {
    OSideBar,
    MSnackBar,
    ATextAnchor,
    ALoaderLogo
  }
})
export default class App extends Vue {
  public authenticationModule = getModule(AuthenticationModule, this.$store)
  public userModule = getModule(UserModule, this.$store)
  public loaderModule = getModule(LoaderModule, this.$store)
  public notificationModule = getModule(NotificationModule, this.$store)
  // for ALoaderLogo
  public zIndex = 5

  public anchorBalise = {
    src: 'https://leads.fr/mentions-legales/',
    text: this.$t('legalMention')
  }
  mounted() {
    this.subscribeToStore()
  }
  /**
   * @function onRemoveLogas
   * @return {void}
   */
  public async onRemoveLogas() {
    // When remove logas, renew token for fetch SUPER or ADMIN token
    // Don't check token when you're log as provider or client
    await Promise.all([
      this.authenticationModule.renewToken(),
      this.userModule.me() //load user information
    ])
    //When logas we saved path route
    const routeSavedBeforLogas = this.authenticationModule.fromRoute!
    //replace path when remove logas
    this.$router.replace(routeSavedBeforLogas)
    //fetch only name un surname
    const { firstName, lastName } = this.userModule.currentUser as CurrentUser
    //load notification success
    this.notificationModule.add({
      color: 'green',
      timeout: 5000,
      show: true,
      message: `Connecté en tant que ${firstName} ${lastName}`,
      persist: false
    })
  }

  private subscribeToStore() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'filter/updateFilter') {
        const newQuery = this.convertFiltersToQuery(state.filter.filters)
        if (JSON.stringify(this.$route.query) === JSON.stringify(newQuery)) {
          return
        }

        this.$router
          .replace({
            path: this.$route.path,
            query: this.convertFiltersToQuery(state.filter.filters)
          })
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          .catch(() => {})
      }

      if (mutation.type === 'filter/reset') {
        this.$router
          .replace({
            path: this.$route.path
          })
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          .catch(() => {})
      }
    })
  }

  private convertFiltersToQuery(filters: Filters) {
    return Object.entries(filters).reduce((acc, [key, val]) => {
      if (!val) return acc
      return { ...acc, [key]: val }
    }, {})
  }
}
