








import { Component, Prop } from 'vue-property-decorator'
import { VAvatar } from 'vuetify/lib'
//Interface
import { Avatar } from '@/store/interfaces/components/IAvatar'

@Component
export default class AAvatar extends VAvatar {
  @Prop() avatar!: Avatar
}
