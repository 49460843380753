import Vue from 'vue'
import { abilitiesPlugin } from '@casl/vue'
import { Ability, detectSubjectType } from '@casl/ability'
import defineAbilityFor from '@/services/ability'

// Vue.use(
//   abilitiesPlugin,
//   new Ability(defineAbilityFor(null), {
//     detectSubjectType: function(subject: unknown): string {
//       if (subject && subject.modelName) return subject.modelName
//       else return detectSubjectType(subject)
//     }
//   })
// )
interface SubjectTest {
  modelName: string
  role?: number
}
// Vue.use(
//   abilitiesPlugin,
//   new Ability(defineAbilityFor(null), {
//     detectSubjectType: (subject: SubjectClass) =>
//       subject && subject.modelName
//         ? subject.modelName
//         : detectSubjectType(subject)
//   })
// )

Vue.use(
  abilitiesPlugin,
  new Ability(defineAbilityFor(null), {
    detectSubjectType: (subject: any) =>
      subject && subject.modelName
        ? subject.modelName
        : detectSubjectType(subject)
  })
)
