

















































import { Component, PropSync, Vue, Watch } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import ABaseTextArea from '@/components/atoms/textarea/ABaseTextArea.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import ABaseButton from '@/components/atoms/button/ABaseButton.vue'

import MDisableTextField from '@/components/molecules/MDisableTextField.vue'

@Component({
  components: {
    ABaseTextArea,
    AButtonPrimary,
    ABaseButton,
    MDisableTextField,
    ValidationObserver
  }
})
export default class MNoteEditable extends Vue {
  @PropSync('message') msgSynced!: string | undefined

  public editable = false
  public validation = false

  // variable where the comment currently written is stocked.
  public currentMsg!: string | undefined

  @Watch('$route.params', { immediate: true })
  onRouteChanges() {
    this.editable = false
    this.currentMsg = undefined
  }

  toggleEdtion() {
    this.editable = true
  }

  /**
   * @function saveEdition
   * @description Close edtion mode and affect to the variable syncronize the current comment written in text area.
   */
  saveEdition() {
    this.editable = false
    this.currentMsg = this.msgSynced
  }

  /**
   * @function closeEdtion
   * @description close edition mode and reset the initial comment.
   */
  closeEdition() {
    this.editable = false
    if (this.currentMsg !== undefined) {
      this.msgSynced = this.currentMsg
    }
  }

  /**
   * @function updateMessage
   * @description set currently the message while it is writting.
   */
  updateMessage(message: string) {
    this.currentMsg = this.msgSynced
    this.msgSynced = message
  }

  /**
   * @function validMessage
   * @description check if the message respect the rule of validation.
   */
  validMessage(text: string) {
    const valid = (text || '').length <= 120
    this.validation = valid
    return valid
  }
}
