



























import { Component, Vue, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import { DataTableHeader } from 'vuetify'
import LeadModule, { Lead } from '@/store/lead'
import MBaseModal, {
  ModalProps
} from '@/components/molecules/modal/MBaseModal.vue'

@Component({
  components: {
    MTableHeading,
    MTableFooter,
    MTableActions,
    MTable,
    AButtonPrimary,
    MBaseModal
  }
})
export default class OTableAdminLeadsRgpd extends Vue {
  public loading = false
  public leadModule = getModule(LeadModule, this.$store)
  public selected: Lead[] = []

  // public get modalProps(): ModalProps {
  //   return {
  //     title: this.$tc('lead.selection.anonymise'),
  //     actionText: this.$tc('lead.actions.anonymise'),
  //     modalAction: this.anonymiseLeads
  //   }
  // }

  get modal() {
    return this.$refs.modal as MBaseModal
  }

  public headers: DataTableHeader<Lead>[] = [
    { text: 'Lead', value: 'name', sortable: false },
    { text: 'Email', value: 'email', sortable: false },
    { text: 'Téléphone', value: 'phone', sortable: false }
  ]

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  async request() {
    this.loading = true
    await this.leadModule.fetchAllRgpdLeads()
    this.loading = false
  }

  openModal() {
    if (!this.selected.length) return
    this.modal.dialog = true
  }

  // async anonymiseLeads() {
  //   if (!this.selected.length) return
  //   const ids: number[] = []
  //   this.selected.map((lead: Lead) => {
  //     ids.push(lead.id)
  //   })
  //   await this.leadModule.anonymiseLeads({ leadsIds: ids })
  //   this.modal.dialog = false
  // }
}
