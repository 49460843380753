




import { Component, Vue } from 'vue-property-decorator'
import UserModule from '@/store/user'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {
    PAdminProfil: () => import('@/components/pages/admin/PAdminProfil.vue'),
    PProviderProfil: () =>
      import('@/components/pages/provider/PProviderProfil.vue'),
    PClientProfil: () => import('@/components/pages/client/PClientProfil.vue')
  }
})
export default class RProfil extends Vue {
  public userModule = getModule(UserModule, this.$store)
  public component: string | null = null

  created() {
    this.componentFromRole()
  }

  private componentFromRole() {
    if (this.userModule.isAdmin) {
      this.component = 'PAdminProfil'
    }

    if (this.userModule.isClient) {
      this.component = 'PClientProfil'
    }

    if (this.userModule.isProvider) {
      this.component = 'PProviderProfil'
    }
  }
}
