var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MBaseModal',{ref:"modal",attrs:{"modalProps":_vm.modalProps}}),_c('MTableHeading',{attrs:{"title":_vm.clientOrdersProps.title}}),_c('MTable',{attrs:{"headers":_vm.headers,"items":_vm.clientModule.orders.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('MTableActions',{attrs:{"itemId":item.id,"tableActions":_vm.tableActions},on:{"iconAction":function($event){return $event(item)}}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$options.filters.timestampToDate(item.createdAt))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.price))+" ")]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('ACheckboxSwitchOrderStatus',{attrs:{"order":item},on:{"switched":_vm.updateOrderStatus}})]}},{key:"item.capping",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$tc('order.capping', item.capping > 0 ? 2 : 1, { capping: item.capping }))+" ")]}},{key:"item.cappingMax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$tc('order.capping', item.cappingMax > 0 ? 2 : 1, { capping: item.cappingMax }))+" ")]}},{key:"item.customValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.duration(item))+" ")]}},{key:"item.customValueIcon",fn:function(ref){
var item = ref.item;
return [_c(_vm.orderType(item),{tag:"component"})]}}],null,true)}),_c('MTableFooter',{attrs:{"tableProperties":_vm.clientModule.orders.meta}}),_c('MBaseModal',{ref:"summaryOrder",attrs:{"modalProps":_vm.modalsummaryOrder},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('MSummaryOrder',{attrs:{"item":_vm.itemSummaryOrder}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }