


































































import { Component, Mixins, Watch } from 'vue-property-decorator'
//Plugins
import { DataTableHeader } from 'vuetify'
//Store
import { getModule } from 'vuex-module-decorators'
import CustomeTranckingModule, { TrackerDto } from '@/store/customTracking'
import UserModule, { CurrentUser } from '@/store/user'
//Molecules
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import MixinTables, {
  DEFAULT_ITEMS_PER_PAGE
} from '@/components/molecules/table/MixinTables.vue'
import MTableFooterFrontSorting from '@/components/molecules/table/MTableFooterFrontSorting.vue'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import { NestApiResponse } from '@/types'

@Component({
  components: {
    MTableHeading,
    MTable,
    MTableFooterFrontSorting,
    MTableFooter
  }
})
export default class OTableProviderCustomTrackerAdId extends Mixins(
  MixinTables
) {
  public userModule = getModule(UserModule, this.$store)
  public customTrackingModule = getModule(CustomeTranckingModule, this.$store)
  public trackerCampaignId: NestApiResponse<TrackerDto> = {
    items: []
  }

  public headers: DataTableHeader<TrackerDto>[] = [
    {
      text: this.$tc('order.value', 1) as string,
      value: 'name',
      sortable: false
    },
    {
      text: this.$tc('stats.lead', 1) as string,
      value: 'leads',
      sortable: false
    },
    {
      text: this.$tc('stats.leadNet', 1) as string,
      value: 'leadsSold',
      sortable: false
    },
    { text: this.$t('stats.ca') as string, value: 'sales', sortable: false },
    {
      text: this.$t('stats.caNet') as string,
      value: 'netSales',
      sortable: false
    }
  ]
  public providerId = (
    this.userModule.currentUser as CurrentUser
  ).providerId!.toString()

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  async request() {
    this.loading = true
    const trackerName = this.$route.meta?.type
    const fields = { providerId: this.providerId, trackerName: trackerName }
    this.trackerCampaignId =
      await this.customTrackingModule.fetchCustomTrackingProvider(fields)
    this.loading = false
  }
  get itemPerPage() {
    return (
      this.customTrackingModule.tracker.meta?.itemsPerPage ||
      DEFAULT_ITEMS_PER_PAGE
    )
  }
}
