























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import Multiselect from 'vue-multiselect'

import ATextMedium from '@/components/atoms/text/ATextMedium.vue'
import AMultiselectZip from '@/components/atoms/multiselect/AMultiselectZip.vue'
import ACheckboxZip from '@/components/atoms/checkbox/ACheckboxZip.vue'
import { Zip, ZipByRegion } from '@/store/zip'

@Component({
  components: {
    Multiselect,
    ValidationProvider,
    ATextMedium,
    AMultiselectZip,
    ACheckboxZip
  }
})
export default class MOrderStepFour extends Vue {
  @Prop() formattedZips!: ZipByRegion[]
  @PropSync('selectedZips') selectedZipsSynced!: Zip[]
}
