
























import { Component, Prop, Vue } from 'vue-property-decorator'
import AIconAction from '@/components/atoms/AIconAction.vue'
import { TableActionsProps } from '@/types'

@Component({
  components: {
    AIconAction
  }
})
export default class MTableActions extends Vue {
  @Prop() tableActions!: TableActionsProps[]
  @Prop() item?: unknown
}
