























































import { Component, Vue } from 'vue-property-decorator'
import ATitleLogo from '@/components/atoms/title/ATitleLogo.vue'

@Component({
  components: {
    ATitleLogo
  }
})
export default class MNotFound extends Vue {}
