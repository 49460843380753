import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/src/styles/main.sass'

import fr from 'vuetify/src/locale/fr'
import AIconSold from '@/components/atoms/icons/AIconSold.vue'
import AIconDownload from '@/components/atoms/icons/AIconDownload.vue'
import AIconEdit from '@/components/atoms/icons/AIconEdit.vue'
import AIconView from '@/components/atoms/icons/AIconView.vue'
import AIconTrash from '@/components/atoms/icons/AIconTrash.vue'
import AIconStar from '@/components/atoms/icons/AIconStar.vue'
import AIconPrev from '@/components/atoms/icons/AIconPrev.vue'
import AIconPrevEnd from '@/components/atoms/icons/AIconPrevEnd.vue'
import AIconNext from '@/components/atoms/icons/AIconNext.vue'
import AIconNextEnd from '@/components/atoms/icons/AIconNextEnd.vue'
import AIconLeads from '@/components/atoms/icons/AIconLeads.vue'
import AIconStats from '@/components/atoms/icons/AIconStats.vue'
import AIconExclu from '@/components/atoms/icons/AIconExclu.vue'
import AIconMutu from '@/components/atoms/icons/AIconMutu.vue'
import AIconAdd from '@/components/atoms/icons/AIconAdd.vue'
import AIconUnsold from '@/components/atoms/icons/AIconUnsold.vue'
import AIconArea from '@/components/atoms/icons/AIconArea.vue'
import AIconDate from '@/components/atoms/icons/AIconDate.vue'
import AIconKo from '@/components/atoms/icons/AIconKo.vue'
import AIconShare from '@/components/atoms/icons/AIconShare.vue'
import AIconDuplicate from '@/components/atoms/icons/AIconDuplicate.vue'
import AIconInfo from '@/components/atoms/icons/AIconInfo.vue'
import AISummaryOrder from '@/components/atoms/icons/AISummaryOrder.vue'
import AReturnRightBack from '@/components/atoms/icons/AReturnRightBack.vue'

Vue.use(Vuetify)

const opts = {
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#2160FF',
        'primary-text': '#000F35',
        blueDark: '#000F35',
        secondary: '#6E97FF',
        light: '#F9FBFF',
        yellow: '#FDD835',
        green: '#AEDD04',
        red: '#fd6435',
        white: '#fff',
        'grey-light': '#F9FBFF',
        'primary-light': '#BCCFFF'
      }
    }
  },
  icons: {
    values: {
      sold: {
        component: AIconSold
      },
      download: {
        component: AIconDownload
      },
      edit: {
        component: AIconEdit
      },
      view: {
        component: AIconView
      },
      trash: {
        component: AIconTrash
      },
      star: {
        component: AIconStar
      },
      prev: {
        component: AIconPrev
      },
      prevEnd: {
        component: AIconPrevEnd
      },
      next: {
        component: AIconNext
      },
      nextEnd: {
        component: AIconNextEnd
      },
      leads: {
        component: AIconLeads
      },
      stats: {
        component: AIconStats
      },
      exclu: {
        component: AIconExclu
      },
      mutu: {
        component: AIconMutu
      },
      add: {
        component: AIconAdd
      },
      unsold: {
        component: AIconUnsold
      },
      area: {
        component: AIconArea
      },
      date: {
        component: AIconDate
      },
      ko: {
        component: AIconKo
      },
      share: {
        component: AIconShare
      },
      duplicate: {
        component: AIconDuplicate
      },
      info: {
        component: AIconInfo
      },
      summaryOrder: {
        component: AISummaryOrder
      },
      returnRightBack: {
        component: AReturnRightBack
      }
    }
  },
  lang: {
    locales: { fr },
    current: 'fr'
  }
}

export default new Vuetify(opts)
