



































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import ATextMedium from '@/components/atoms/text/ATextMedium.vue'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import MCardsAreas from '@/components/molecules/order/MCardsAreas.vue'
import { AreaAndIcon } from '@/store/area'
//Template
import TSkeleton from '@/components/templates/TSkeleton.vue'
@Component({
  components: {
    AInputTextField,
    ATextMedium,
    ATitleMedium,
    MCardsAreas,
    TSkeleton
  }
})
export default class MOrderStepOne extends Vue {
  @PropSync('name') nameSynced!: string
  @PropSync('theme') themeSynced!: number | null
  @Prop() themes!: AreaAndIcon[]
  @PropSync('loading') loadingSynced!: boolean
}
