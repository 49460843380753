




import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ABaseText extends Vue {
  @Prop({ required: true }) text!: string | number
}
