














import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class ACheckbox extends Vue {
  @PropSync('value') valueSynced!: boolean
  @Prop() label!: string
  @Prop() labelStyle!: string
}
