




























































import MTable from '@/components/molecules/table/MTable.vue'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import { Component, Vue } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import { getModule } from 'vuex-module-decorators'
import OrderModule, { Order, OrdersDashboard } from '@/store/order'
import ACheckboxSwitchOrderStatus from '@/components/atoms/checkbox/ACheckboxSwitchOrderStatus.vue'
import { ClientOrder } from '@/store/client'
import AiconNumberFour from '@/components/atoms/icons/AiconNumberFour.vue'
import AiconNumberOne from '@/components/atoms/icons/AiconNumberOne.vue'
import AiconNumberThree from '@/components/atoms/icons/AiconNumberThree.vue'
import AiconNumberTwo from '@/components/atoms/icons/AiconNumberTwo.vue'

@Component({
  components: {
    MTableHeading,
    MTableFooter,
    MTable,
    ACheckboxSwitchOrderStatus,
    AiconNumberOne,
    AiconNumberTwo,
    AiconNumberThree,
    AiconNumberFour
  }
})
export default class PAdminOrders extends Vue {
  public orderModule = getModule(OrderModule, this.$store)
  public loading = true

  public headers: DataTableHeader<OrdersDashboard>[] = [
    {
      text: this.$tc('orderItems.isActive'),
      value: 'isActive',
      sortable: false
    },
    {
      text: this.$tc('orderItems.clientName'),
      value: 'clientName',
      sortable: false
    },
    { text: this.$tc('orderItems.orderName'), value: 'name', sortable: false },
    { text: this.$tc('orderItems.area'), value: 'area', sortable: false },
    {
      text: this.$tc('orderItems.shareLimit'),
      value: 'customValueIcon',
      sortable: false
    },
    { text: this.$tc('orderItems.price'), value: 'price', sortable: false },
    { text: this.$tc('orderItems.weight'), value: 'weight', sortable: false },
    { text: this.$tc('orderItems.capping'), value: 'capping', sortable: false },
    {
      text: this.$tc('orderItems.cappingMax'),
      value: 'cappingMax',
      sortable: false
    },
    { text: this.$tc('orderItems.endAt'), value: 'endAt', sortable: false },
    {
      text: this.$tc('orderItems.connector'),
      value: 'connector',
      sortable: false
    }
  ]

  async created() {
    await this.orderModule.fetchAllOrdersDashboard()
    this.loading = false
  }

  orderType(item: Order) {
    switch (item.shareLimit) {
      case 1:
        return 'AiconNumberOne'
      case 2:
        return 'AiconNumberTwo'
      case 3:
        return 'AiconNumberThree'
      case 4:
        return 'AiconNumberFour'
    }
  }

  public async updateOrderStatus(order: ClientOrder) {
    await this.orderModule.updateOrderStatus(order)
  }
}
