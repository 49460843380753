















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class APaginationSearch extends Vue {
  search = ''
}
