











import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

// Molecules
import MFormNewAccount from '@/components/molecules/form/MFormNewAccount.vue'
// Templates
import TCardFluid from '@/components/templates/TCardFluid.vue'
// Interfaces
import { PasswordFields } from '@/store/interfaces/components/IPasswordFields'
// Store
import UserModule from '@/store/user'

@Component({
  components: {
    MFormNewAccount,
    TCardFluid
  }
})
export default class OSetPassword extends Vue {
  public userModule = getModule(UserModule, this.$store)
  public passwordFields: PasswordFields = {
    current: '',
    password: '',
    confirmed: ''
  }
  public userInfos = {
    userUUID: '',
    userToken: '',
    newpass: ''
  }

  async created() {
    this.userInfos.userUUID = this.$route.params.id
    this.userInfos.userToken = this.$route.params.tokenId
  }

  async beforeMount() {
    // const valid = await this.userModule.checkTokenPassword(this.userInfos)
    // valid.access ? null : this.$router.push('/login')
  }

  get passwordProps() {
    return this.passwordFields
  }

  async submit() {
    const fieldsToPost = this.userInfos
    fieldsToPost.newpass = this.passwordProps.password

    await this.userModule.setFirstPassword(fieldsToPost)
    this.$router.push('/login')
  }
}
