











import { Component, Prop, Vue } from 'vue-property-decorator'
import ASideBarItem from '@/components/atoms/ASideBarItem.vue'
import { SideBarItemsProps } from '@/components/organisms/OSideBar.vue'

@Component({
  components: {
    ASideBarItem
  }
})
export default class MSideBar extends Vue {
  @Prop() items!: SideBarItemsProps
}
