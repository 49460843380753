import { render, staticRenderFns } from "./RLeads.vue?vue&type=template&id=2d85cf89&scoped=true&"
import script from "./RLeads.vue?vue&type=script&lang=ts&"
export * from "./RLeads.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d85cf89",
  null
  
)

export default component.exports