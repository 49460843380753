












































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import ATextMedium from '@/components/atoms/text/ATextMedium.vue'
import ATextSmall from '@/components/atoms/text/ATextSmall.vue'
import ABaseText from '@/components/atoms/text/ABaseText.vue'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'
import ATitleSmall from '@/components/atoms/title/ATitleSmall.vue'
import ABaseButton from '@/components/atoms/button/ABaseButton.vue'
import AIconAction from '@/components/atoms/AIconAction.vue'

import { Lead } from '@/store/lead'

@Component({
  components: {
    ATitleMedium,
    ATextMedium,
    ATextSmall,
    ATitleSmall,
    ABaseText,
    ABaseButton,
    AIconAction
  }
})
export default class MLeadTransaction extends Vue {
  @Prop() lead!: Lead
}
