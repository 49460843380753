








import { Component, Vue } from 'vue-property-decorator'
import OSetPassword from '@/components/organisms/common/OSetPassword.vue'
import TPanelCenter from '@/components/templates/TPanelCenter.vue'

@Component({
  components: {
    OSetPassword,
    TPanelCenter
  }
})
export default class PCreateAccount extends Vue {}
