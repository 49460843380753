





























































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import { getModule } from 'vuex-module-decorators'
import AButtonRouter from '@/components/atoms/button/AButtonRouter.vue'
import MTableFooter from '@/components/molecules/table/MTableFooter.vue'
import MTableHeading from '@/components/molecules/table/MTableHeading.vue'
import MTable from '@/components/molecules/table/MTable.vue'
import MTableActions from '@/components/molecules/table/MTableActions.vue'
import MixinTables from '@/components/molecules/table/MixinTables.vue'
import { TableActionsProps } from '@/types'
import { MenuProps } from '@/components/molecules/table/MTableMenu.vue'
import ProviderModule, { ProviderAreaStatisticsItem } from '@/store/provider'
import UserModule, { CurrentUser } from '@/store/user'
import { Area } from '@/store/statistic'

@Component({
  components: {
    AButtonRouter,
    MTableHeading,
    MTableFooter,
    MTableActions,
    MTable
  }
})
export default class OTableProviderStatisticsAreas extends Mixins(MixinTables) {
  public providerModule = getModule(ProviderModule, this.$store)
  public userModule = getModule(UserModule, this.$store)

  public headers: DataTableHeader<ProviderAreaStatisticsItem>[] = [
    { text: this.$t('stats.areaTitle') as string, value: 'name' },
    { text: this.$tc('stats.lead', 1) as string, value: 'leads' },
    { text: this.$t('stats.sold') as string, value: 'leadsSold' },
    { text: this.$t('stats.ca') as string, value: 'sales' },
    { text: this.$t('stats.caNet') as string, value: 'netSales' },
    { text: 'Actions', value: 'actions', sortable: false }
  ]

  get tableActions(): TableActionsProps[] {
    return [
      {
        icon: '$leads',
        action: this.seeLeads,
        tooltip: this.$i18n.tc('lead.actions.show') as string
      },
      {
        icon: '$stats',
        action: this.seeStats,
        tooltip: this.$i18n.tc('statistic.actions.show') as string
      }
    ]
  }

  @Watch('$route', { immediate: true })
  onRouteChanges() {
    this.request()
  }

  async request() {
    this.loading = true
    await this.providerModule.fetchAllStatisticsAreas(
      (this.userModule.currentUser as CurrentUser).providerId!.toString()
    )
    this.loading = false
  }

  public seeLeads(item: Area) {
    this.$router.push({
      path: '/leads',
      query: { area: item.id.toString() }
    })
  }

  public seeStats(item: Area) {
    this.$router.push({
      path: '/dashboard',
      query: { area: item.id.toString() }
    })
  }

  public get menuStatistics(): MenuProps[] {
    return [
      {
        text: this.$tc('theme.name', 2),
        route: '/statistiques/thematiques'
      },
      {
        text: this.$tc('sitekey.name', 2),
        route: '/statistiques/sitekeys'
      }
    ]
  }
}
