








import { Component, Vue } from 'vue-property-decorator'
import TCard from '@/components/templates/TCard.vue'
import OSupport from '@/components/organisms/common/OSupport.vue'

@Component({
  components: {
    TCard,
    OSupport
  }
})
export default class PSupport extends Vue {}
