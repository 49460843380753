




































































import { Component, Vue, Watch } from 'vue-property-decorator'
import MWebhookStepOne from '@/components/molecules/webhook/MWebhookStepOne.vue'
import MWebhookStepTwo from '@/components/molecules/webhook/MWebhookStepTwo.vue'
import MWebhookStepThree from '@/components/molecules/webhook/MWebhookStepThree.vue'
import MWebhookStepFour from '@/components/molecules/webhook/MWebhookStepFour.vue'

import MWebhookActions from '@/components/molecules/webhook/MWebhookActions.vue'
import ATitleMedium from '@/components/atoms/title/ATitleMedium.vue'

import { ValidationObserver } from 'vee-validate'
import { getModule } from 'vuex-module-decorators'
import ClientModule from '@/store/client'
import WebhookModule, {
  WebhookCategories,
  WebhookCategory,
  WebhookFields
} from '@/store/webhook'
import AreaModule from '@/store/area'

@Component({
  components: {
    MWebhookStepOne,
    MWebhookStepTwo,
    MWebhookStepThree,
    MWebhookStepFour,
    MWebhookActions,
    ATitleMedium,
    ValidationObserver
  }
})
export default class OWebhookSteps extends Vue {
  public webhookModule = getModule(WebhookModule, this.$store)
  public clientModule = getModule(ClientModule, this.$store)
  public areaModule = getModule(AreaModule, this.$store)

  public category: 'global' | 'order' | 'theme' = 'global'
  public categoryId: number | string | null = null

  public categories: WebhookCategory[] = [
    {
      id: 'global',
      displayName: 'Global'
    },
    {
      id: 'order',
      displayName: 'Commande'
    },
    {
      id: 'theme',
      displayName: 'Thèmathique'
    }
  ]

  public verbs: WebhookCategory[] = [
    {
      id: 'GET',
      displayName: 'GET'
    },
    {
      id: 'POST',
      displayName: 'POST'
    }
  ]

  public variables: { name: string; displayName: string }[] | null = null

  public defaultVariables = [
    { name: 'firstName', displayName: 'Prénom' },
    { name: 'lastName', displayName: 'Nom' },
    { name: 'phone', displayName: 'Téléphone' },
    { name: 'email', displayName: 'E-mail' },
    { name: 'zip', displayName: 'Ville' },
    { name: 'zipCode', displayName: 'Code postal' }
  ]

  public webhook: WebhookFields = {
    name: '',
    type: 'GET',
    url: ''
  }

  created() {
    this.prefetch()
    this.preloadWebhook()
  }

  private async prefetch() {
    const clientRequest = this.clientModule.fetchAllOrders(
      +this.$route.params.clientId
    )

    const areaRequest = this.areaModule.fetchAll()

    await Promise.all([clientRequest, areaRequest])

    if (this.clientModule.orders.items.length === 0) {
      this.categories[1].disabled = true
    }

    if (this.areaModule.areas.length === 0) {
      this.categories[2].disabled = true
    }
  }

  private async preloadWebhook() {
    if (this.$route.params.webhookId) {
      await this.clientModule.fetchOneWebhook(+this.$route.params.webhookId)

      this.category = this.$options
        .filters!.translateWebhookType(this.clientModule.webhook?.linkedTo)
        .split('.')[2]

      switch (this.category) {
        case 'order':
          this.categoryId = this.clientModule.webhook!.order!.id
          break
        case 'theme':
          this.categoryId = this.clientModule.webhook!.clientsAreas!.area.id
          break
      }

      this.webhook = {
        ...this.webhook,
        ...{
          name: this.clientModule.webhook!.name,
          type: this.clientModule.webhook!.type,
          url: this.clientModule.webhook!.url
        }
      }
    }
  }

  step = 1

  /**
   * If create mode and categoryId changes, reset payload
   * If create mode OR edit mode, update variables array with areaKeys from order OR area
   */
  @Watch('categoryId')
  onCategoryIdChanges() {
    let filteredAreasKeys = null

    if (this.category === WebhookCategories.ORDER) {
      filteredAreasKeys = this.clientModule.orders.items
        .find((order) => order.id === this.categoryId)!
        .clientsAreas.area.areaKeys.map((areaKey) => {
          return {
            name: areaKey.name,
            displayName: areaKey.displayName as string
          }
        })
    } else if (this.category === WebhookCategories.THEME) {
      filteredAreasKeys = this.areaModule.areas
        .find((area) => area.id === this.categoryId)!
        .areaKeys.map((areaKey) => {
          return {
            name: areaKey.name,
            displayName: areaKey.displayName as string
          }
        })
    }

    this.variables = [...this.defaultVariables, ...filteredAreasKeys!]
  }

  public addToUrl(name: string) {
    if (this.webhook.url === '') return
    const lastChar = this.webhook.url.substr(this.webhook.url.length - 1)

    switch (lastChar) {
      case '=':
        this.webhook.url = this.webhook.url.concat(`{{ ${name} }}`)
        break
      case '&':
        this.webhook.url = this.webhook.url.concat(`${name}={{ ${name} }}`)
        break
      case '}':
        this.webhook.url = this.webhook.url.concat(`&${name}={{ ${name} }}`)
        break
      default:
        this.webhook.url = this.webhook.url.concat(`${name}={{ ${name} }}`)
        break
    }
  }

  public variableSelected(data: { name: string; displayName: string }) {
    if (this.webhook.type === 'GET') {
      this.addToUrl(data.name)
    }
  }

  /**
   * Change step to previous one.
   * If the webhook type is global and current step is 3,
   * step 2 is jumped
   *
   * @param {number} oldStep The previous step number.
   */
  public previousStep(oldStep: number) {
    if (this.category === 'global' && this.step === 3) {
      oldStep = 1
    }

    this.step = oldStep
  }

  /**
   * Change step to next one and validate current step form
   * If the webhook type is global and next step is 2,
   * step 3 is assigned instead
   *
   * @param {number} newStep The next step number.
   */
  async validateStep(newStep: number) {
    const ref = this.$refs.observer as InstanceType<typeof ValidationObserver>

    if (this.category === 'global' && newStep === 2) {
      newStep = 3
    }

    if (!ref) this.step = newStep

    if (ref && (await ref.validate())) {
      this.step = newStep
    }
  }

  public async submit() {
    if (this.$route.params.webhookId) {
      await this.update()
    } else {
      await this.create()
    }
    this.redirectTo()
  }

  private update() {
    this.webhookModule.update({
      id: this.$route.params.webhookId,
      data: {
        ...this.webhook
      }
    })
  }

  private create() {
    this.webhook.clientId = +this.$route.params.clientId

    if (this.category === WebhookCategories.ORDER) {
      this.webhook.orderId = this.categoryId as string
    } else if (this.category === WebhookCategories.THEME) {
      this.webhook.areaId = this.categoryId as number
    }

    this.webhookModule.new({
      ...this.webhook
    })
  }

  private redirectTo() {
    this.$router.push({
      path: `/clients/${this.$route.params.clientId}/connecteurs`
    })
  }
}
