























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider
  }
})
export default class ABaseSelect extends Vue {
  @Prop() items!: unknown[]
  @PropSync('value') valueSynced!: unknown
}
