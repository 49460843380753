import dayjs from 'dayjs'
import * as dayFr from 'dayjs/locale/fr'
import weekday from 'dayjs/plugin/weekday'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale(dayFr)
dayjs.extend(weekday)
dayjs.tz.setDefault('Europe/Paris')

export default dayjs
