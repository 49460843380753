






































import { Component, PropSync, Vue } from 'vue-property-decorator'
import MFormLeadGeneral from '@/components/molecules/form/MFormLeadGeneral.vue'
import MFormLeadBank from '@/components/molecules/form/MFormLeadBank.vue'
import MFormLeadConso from '@/components/molecules/form/MFormLeadConso.vue'
import MFormLeadImmo from '@/components/molecules/form/MFormLeadImmo.vue'
import MFormLeadOwner from '@/components/molecules/form/MFormLeadOwner.vue'
import MFormLeadCo from '@/components/molecules/form/MFormLeadCo.vue'
import MFormLeadProfession from '@/components/molecules/form/MFormLeadProfession.vue'

@Component({
  components: {
    MFormLeadGeneral,
    MFormLeadBank,
    MFormLeadConso,
    MFormLeadImmo,
    MFormLeadOwner,
    MFormLeadCo,
    MFormLeadProfession
  }
})
export default class MLeadStepTwo extends Vue {
  // General input
  @PropSync('birth') birthSynced!: string
  @PropSync('address') addressSynced!: string
  @PropSync('zip') zipSynced!: string
  @PropSync('nbChild') childrenSynced!: string
  @PropSync('details') deatilsSynced!: string

  // Bank of France input
  @PropSync('bank-of-fr') bankSynced!: string
  @PropSync('year-contract') yearContractSynced!: string
  @PropSync('charges') chargesSynced!: string
  @PropSync('val-immo') valSynced!: string
  @PropSync('seniority') senioritySynced!: string

  // Profession input
  @PropSync('profession') professionSynced!: string
  @PropSync('contract') contractSynced!: string
  @PropSync('salary') salarySynced!: number
  @PropSync('cap-consumption') capConsumSynced!: number
  @PropSync('treasury') treasurySynced!: number

  // Consumption input
  @PropSync('nb-conso') consoSynced!: string
  @PropSync('price') priceSynced!: string

  // Real estate input
  @PropSync('immo') immoSynced!: string
  @PropSync('value') valueSynced!: string
  @PropSync('capital') capitalSynced!: string

  // Owner input
  @PropSync('rent') rentSynced!: string
  @PropSync('own') ownSynced!: string

  // Co-borrower input
  @PropSync('co-revenu') coRevenuSynced!: string
  @PropSync('co-contrat') coContractSynced!: string
  @PropSync('co-profession') coProfessionSynced!: string
}
