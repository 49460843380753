import Vue from 'vue'
import { Action, Mutation, VuexModule, Module } from 'vuex-module-decorators'

export interface CompanyState {
  company: CompanyRequest | null
}

export interface CompanyRequest {
  createdAt?: Date
  updatedAt?: Date
  id?: number
  name?: string
  displayName: string | null
  registrationNumber: string | null
  vat?: string | null
  address?: Address
  payment?: Payment
}

export interface Address {
  id?: number
  createdAt?: Date
  updatedAt?: Date

  line1: string
  line2: string
  state?: string | null
  country: string
  city: string
  postalCode: string
}

export interface Payment {
  createdAt: Date
  updatedAt: Date
  id: string
  balance: number
  type: string
  stripeCustomer: string
  invoiceThreshold: number
}

export interface CompanyAddressUpdateDTO {
  displayName?: string
  registrationNumber?: string
  vat?: string
  address?: {
    line1: string
    line2?: string
    state?: string | null
    country: string
    city: string
    postalCode?: string
  }
}

@Module({ namespaced: true, name: 'company' })
export default class CompanyModule extends VuexModule implements CompanyState {
  company: CompanyRequest | null = null

  @Mutation
  saveCompany(company: CompanyRequest) {
    this.company = { ...this.company, ...company }
  }

  @Action({ commit: 'saveCompany' })
  async fetchCompany(id: number): Promise<CompanyRequest> {
    const { data } = await Vue.prototype.$http.get(`/companies/${id}`)
    return data
  }

  @Action
  async editCompanyAddress({
    id,
    companyAdressPayload
  }: {
    id: number
    companyAdressPayload: CompanyAddressUpdateDTO
  }) {
    await Vue.prototype.$http.put(`/companies/${id}`, companyAdressPayload, {
      successHandler: {
        message: "L'adresse liée à la facturation a bien été mise à jour"
      }
    })
  }
}
