

















import { Component, Vue } from 'vue-property-decorator'

//Molecules
import MMenu from '@/components/molecules/menu/MMenu.vue'

//Template
import THomepage from '@/components/templates/homepage/THomepage.vue'

//Interfaces
import { NavBar } from '@/store/interfaces/components/INavigation'

@Component({
  components: {
    MMenu,
    THomepage
  }
})
export default class PHomePageLeads extends Vue {
  public get menuDashboardAdmin(): NavBar[] {
    return [
      {
        title: this.$tc('lead.name', 2),
        route: '/leads/tableau-de-bord'
      },
      {
        title: this.$tc('lead.rejected'),
        route: '/leads/leads-rejected'
      },
      {
        title: this.$tc('lead.rgpd'),
        route: '/leads/rgpd'
      }
    ]
  }
}
