

















































import { Component, Vue } from 'vue-property-decorator'
//Atoms
import AButtonPrimary from '@/components/atoms/button/AButtonPrimary.vue'
import AInputTextField from '@/components/atoms/input/AInputTextField.vue'
import AInputTextFieldPassword from '@/components/atoms/input/AInputTextFieldPassword.vue'
import ALinkSmall from '@/components/atoms/link/ALinkSmall.vue'
import ATextExtra from '@/components/atoms/text/ATextExtra.vue'
import ATextLarge from '@/components/atoms/text/ATextLarge.vue'
import ATextMediumLarge from '@/components/atoms/text/ATextMediumLarge.vue'
//Stores
import { getModule } from 'vuex-module-decorators'
import AuthenticationModule from '@/store/authentication'
//Plugins
import { ValidationObserver } from 'vee-validate'
import LoaderModule from '@/store/loader'

@Component({
  components: {
    AButtonPrimary,
    AInputTextField,
    AInputTextFieldPassword,
    ALinkSmall,
    ATextExtra,
    ATextLarge,
    ATextMediumLarge,
    ValidationObserver
  }
})
export default class OLogin extends Vue {
  public authenticationModule = getModule(AuthenticationModule, this.$store)
  public loaderModule = getModule(LoaderModule, this.$store)

  public loginFields = {
    email: '',
    password: ''
  }

  public async login() {
    // loaderModule manage overlay from App file
    //loginFileds it's state send at api
    const log = {
      loaderModule: this.loaderModule,
      loginFields: this.loginFields
    }
    await this.authenticationModule.login(log)
    this.$router.push((this.$route.query.redirect as string) || '/dashboard')
  }
}
