

















import { Component, PropSync, Prop, Vue } from 'vue-property-decorator'
import ATextMedium from '@/components/atoms/text/ATextMedium.vue'
import AInputRadio from '@/components/atoms/input/AInputRadio.vue'

@Component({
  components: {
    ATextMedium,
    AInputRadio
  }
})
export default class MRadioMenu extends Vue {
  @PropSync('data') dataSynced!: unknown[]
  @PropSync('title') titleSynced!: string
  @Prop() numOfradio!: number
  @Prop({ default: '2', type: String }) number_cols!: string

  public components: string[] = []

  addCheckbox(nb: number) {
    for (let index = 0; index < nb; index++) {
      this.components.push('AInputRadio')
    }
  }

  created() {
    this.addCheckbox(this.numOfradio)
  }
}
